import { RouteName } from '@/constants/RouteName';
import { RouteConfig } from 'vue-router';

export const PortfolioConstructionRouter: RouteConfig[] = [
  {
    name: RouteName.PORTFOLIO_CONSTRUCTION,
    path: 'portfolio-construction/:indexUniqueIdentifier?/:substep?',
    meta: {
      title: RouteName.PORTFOLIO_CONSTRUCTION,
      shouldNavigationPanelBeShown: true,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/PortfolioConstruction.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () =>
        import(
          '@/layout/components/toolbars/AnalysisStepToolbar/PortfolioConstruction/PortfolioConstructionToolbar.vue'
        ),
    },
  },
];
