import { AuthStrategy } from '../types';
import BofaAuthRoot from './BofaAuthRoot.vue';
import BofaLogin from '@/auth/bofa/BofaLogin.vue';

export function bofaAuth(): AuthStrategy {
  return {
    rootComponent: BofaAuthRoot,
    loginComponent: BofaLogin,
    shouldHideNavbar: true,
  };
}
