<template>
  <b-container
    :fluid="isMainContentFluid"
    :class="{ 'px-6': isMainContentFluid && !isPdf && !isSmallScreen, 'px-3': isPdf }"
  >
    <div class="d-flex justify-content-between px-3 py-2">
      <div>
        <img
          src="@/assets/hsbc-black.svg"
          alt="HSBC Logo"
          style="height: 20px"
        />
      </div>
      <div>
        <small class="d-inline-block text-wrap ml-6">
          <a
            href="/terms_conditions"
            target="_blank"
            >Terms and conditions</a
          >
          |
          <a
            href="/privacy_policy"
            target="_blank"
            >Privacy policy</a
          >
          | &copy; {{ fullYear }} {{ companyName }}
        </small>
      </div>
    </div>
  </b-container>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useIsPdf } from '@/composables/usePdf';
import { useWindowSize } from '@vueuse/core';
import { BootstrapBreakpoints } from '@/constants/BootstrapBreakpoint';
import { useRouteRef } from '@/composables/useRouter';

export default defineComponent({
  name: 'WhitelabelPdfFooter',
  setup() {
    const fullYear = ref(new Date().getFullYear());

    const companyName = computed(() => {
      return import.meta.env.VITE_CLIENT;
    });

    const isPdf = useIsPdf();

    const { width } = useWindowSize();

    const isSmallScreen = computed(() => width.value <= BootstrapBreakpoints.MD);

    const route = useRouteRef();
    const isMainContentFluid = computed(() => route.value.meta?.isMainContentContainerFluid ?? false);

    return { fullYear, companyName, isMainContentFluid, isPdf, isSmallScreen };
  },
});
</script>
