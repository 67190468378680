import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';

export default createGlobalState(() => {
  const frontierWeightingMap = ref<{ [id: string]: number }>({});
  const blmFrontierWeightingMap = ref<{ [id: string]: number }>({});
  const areFrontierWeightsLocked = ref(false);

  return {
    frontierWeightingMap,
    blmFrontierWeightingMap,
    areFrontierWeightsLocked,
  };
});
