export type ClassProp = string | { [className: string]: boolean } | (string | { [className: string]: boolean })[];

/**
 * This function is designed to handle the different object types that you can pass to a Vue component.
 * You can pass in a plain string, an object, or an array. This function transforms the possible
 * objects into a standard array so that you can easily add other values to the resulting classes passed
 * to the component.
 */
export const handlePossibleClassPropTypes = (prop: ClassProp): (string | { [className: string]: boolean })[] => {
  const retval: (string | { [className: string]: boolean })[] = [];

  if (typeof prop === 'string') return prop.split(' ');
  if (Array.isArray(prop)) return prop;

  for (const [className, shouldInclude] of Object.entries(prop)) {
    if (shouldInclude) retval.push(className);
  }

  return retval;
};
