import { Country } from '@/constants/Country';
import { RouteName } from '@/constants/RouteName';

const noZeroRegex = /^[.]\d*/;
const numberInput = /[^-.0-9]+/;

type GetSignedNumberOptions =
  | number
  | {
      numDecimalPoints?: number;
      includeSpace?: boolean;
    };

export default class CommonUtils {
  public static formatNumberIntoCurrency(
    country: Country,
    num: number,
    minimumFractionDigits: number,
    maximumFractionDigits: number,
  ): string {
    switch (country) {
      case Country.US:
        return new Intl.NumberFormat('en-us', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits,
          maximumFractionDigits,
        }).format(num);
      default:
        return num.toString();
    }
  }

  public static formatNumberInput(value: string | null): string | null {
    let newVal = value;
    // Add a zero, in the case of '.xx'
    if (newVal && newVal.match(noZeroRegex) && newVal.length > 1) {
      newVal = `0${newVal}`;
    }

    // Remove all characters that are not a number, '-' or '.'
    if (newVal && newVal.match(numberInput)) {
      newVal = newVal.replace(numberInput, '');
    }

    // Remove the last character that made the number not finite
    // Or trim the infinite number to 10 characters
    if (newVal && !Number.isFinite(Number(newVal)) && newVal.length > 1) {
      newVal = newVal.slice(0, -1);
      newVal = newVal.substring(0, 10);
    }
    return newVal;
  }

  public static getExcelColumnHeaderByColumnIndexPosition(columnIndex: number) {
    const columnHeaderCodeList = [];
    while (columnIndex > 0) {
      const rem = columnIndex % 26;
      if (rem === 0) {
        columnHeaderCodeList.push('Z');
        columnIndex = Math.floor(columnIndex / 26) - 1;
      } else {
        columnHeaderCodeList.push(String.fromCharCode(rem - 1 + 'A'.charCodeAt(0)));
        columnIndex = Math.floor(columnIndex / 26);
      }
    }
    return `COLUMN ${columnHeaderCodeList.reverse().join('')}`;
  }

  /**
   * Format file name of the pdf or excel file to include the tool, slug, and date
   * @param res API output to get the slug and date
   * @param tool page used to create file
   */
  public static getSanitizedFileName(tool: RouteName, indexName: string, date?: string): string {
    let retval = `${tool} - ${indexName}`;
    if (date) retval += ` as of ${date}`;

    return retval.replace(/['`~!@#$%^&*()_|+=?;:'",.<>{}[]\\\/]/gi, '');
  }

  /**
   * Formats a number into a string with a '+' sign if positive, '-' if negative,
   * and in fixed-point notation when specified
   *
   * @param value number to format
   * @param toFixed number of fixed-point notation to round to
   * @returns
   */
  public static getSignedNumber(value: number, options?: number | GetSignedNumberOptions): string {
    /**
     * For the hotfix, preserve the original logic.
     * In an upcoming PR, the instances using getSignedNumber will be updated to use the new
     * `GetSignedNumberOptions` type
     */
    if (typeof options === 'number') {
      return value > 0
        ? `+${value.toLocaleString(undefined, {
            minimumFractionDigits: options,
            maximumFractionDigits: options,
          })}`
        : value.toLocaleString(undefined, {
            minimumFractionDigits: options,
            maximumFractionDigits: options,
          });
    }

    if (options) {
      const numDecimalPoints = options.numDecimalPoints;
      const shouldIncludeSpace = options?.includeSpace ?? false;

      const unsignedPositiveOrNegativeNumber = value.toLocaleString(undefined, {
        minimumFractionDigits: numDecimalPoints,
        maximumFractionDigits: numDecimalPoints,
      });
      const spaceIfNecessary = shouldIncludeSpace ? ' ' : '';

      return value > 0
        ? `+${spaceIfNecessary}${unsignedPositiveOrNegativeNumber}`
        : `${unsignedPositiveOrNegativeNumber.slice(0, 1)}${spaceIfNecessary}${unsignedPositiveOrNegativeNumber.slice(
            1,
          )}`;
    }

    return value > 0 ? `+${value.toLocaleString()}` : value.toLocaleString();
  }
}
