import { useRouter } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';
import { Location } from 'vue-router';

export function useResolveFactsheetRoutes() {
  const router = useRouter();

  const strategyFactsheetRoute = (code: string) => {
    return router.resolve({
      name: RouteName.STRATEGY_FACTSHEET,
      params: {
        indexUniqueIdentifier: code,
      },
    });
  };

  const getStrategyFactsheetLocation = (code: string): Location => {
    return {
      name: RouteName.STRATEGY_FACTSHEET,
      params: {
        indexUniqueIdentifier: code,
      },
    };
  };

  const strategyFactsheetPdfRoute = (code: string) => {
    return router.resolve({
      name: RouteName.STRATEGY_FACTSHEET_PDF,
      params: {
        indexUniqueIdentifier: code,
      },
    });
  };

  const PerformanceContributionRoute = (code: string) => {
    return router.resolve({
      name: RouteName.PERFORMANCE_ATTRIBUTION,
      params: {
        indexUniqueIdentifier: code,
      },
    });
  };

  const PerformanceContributionPdfRoute = (code: string, substep: string) => {
    return router.resolve({
      name: RouteName.PERFORMANCE_ATTRIBUTION_PDF,
      params: {
        indexUniqueIdentifier: code,
        substep,
      },
    });
  };

  const portfolioFactsheetRoute = (slug: string) => {
    return router.resolve({
      name: RouteName.PORTFOLIO_FACTSHEET,
      params: {
        indexUniqueIdentifier: slug,
      },
    });
  };
  const getPortfolioFactsheetLocation = (slug: string) => {
    return {
      name: RouteName.PORTFOLIO_FACTSHEET,
      params: {
        indexUniqueIdentifier: slug,
      },
    };
  };

  const portfolioFactsheetPdfRoute = (slug: string) => {
    return router.resolve({
      name: RouteName.PORTFOLIO_FACTSHEET_PDF,
      params: {
        indexUniqueIdentifier: slug,
      },
    });
  };

  return {
    strategyFactsheetRoute,
    getStrategyFactsheetLocation,
    strategyFactsheetPdfRoute,
    PerformanceContributionRoute,
    PerformanceContributionPdfRoute,
    portfolioFactsheetRoute,
    getPortfolioFactsheetLocation,
    portfolioFactsheetPdfRoute,
  };
}
