import { RouteName } from '@/constants/RouteName';
import { AppMain } from '@/layout/components';
import { RouteConfig } from 'vue-router';

export const SettingsRouter: RouteConfig[] = [
  {
    path: '/my-settings',
    components: {
      default: AppMain,
    },
    children: [
      {
        name: RouteName.USER_SETTING,
        path: '/',
        meta: {
          title: 'User Settings',
          shouldAdminConsoleSidebarBeShown: true,
        },
        components: {
          default: () => import('@/views/settings/UserSettings.vue'),
          sidebar: () => import('@/views/settings/SettingSidebar.vue'),
        },
      },
    ],
  },
];
