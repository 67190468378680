<template>
  <div class="d-flex align-items-center">
    <div v-if="filter.length">
      <b-button-group>
        <b-dropdown
          no-caret
          :title="`Filter by ${translator[title]}`"
          variant="blind-dark"
          menu-class="dropdown-menu-reverse scroll-md shadow"
          toggle-class="font-weight-500 px-6px"
        >
          <template #button-content>
            <icon
              v-if="filterActive"
              icon="filter"
            />
            {{ translate({ path: 'GLOBAL.DATABASE_TRANSLATOR', item: translator[title] }) }}
          </template>
          <!-- We want to handle differently for currency to draw the separator.
            If there are more requirements on customizing how the dropdown should be shown depending
            on the title, break them into separate component and do dynamic dispatch by `<component />`.
          -->
          <CurrencyDropdownItems v-if="title === 'currency'">
            <template #default="{ currency }">
              <b-dropdown-item
                :key="currency"
                :title="`Filter by ${currency}`"
                class="text-capitalize"
                :active="isItemActive(currency)"
                @click.stop="filterByPropertyValue({ value: currency, label: currency })"
              >
                {{ currency }}
              </b-dropdown-item>
            </template>
          </CurrencyDropdownItems>
          <!-- Wrapping with <template> to avoid confusing v-if and v-for -->
          <template v-else>
            <b-dropdown-item
              v-for="valueObj in filter"
              :key="valueObj.value"
              :title="`Filter by ${valueObj.label}`"
              :class="valueObj.value === 'erc' ? 'text-uppercase' : 'text-capitalize'"
              :active="valueObj.active"
              @click.stop="filterByPropertyValue(valueObj)"
            >
              {{ valueObj.label }}
            </b-dropdown-item>
          </template>
          <template v-if="filterActive">
            <b-dropdown-divider class="divider-margin-sm" />
            <b-dropdown-item
              class="cursor-pointer"
              link-class="text-danger"
              @click.stop="removeFilter"
            >
              {{ translate({ path: 'FACTOR_DECOMPOSITION.MODALS.IMPORT_FACTORS.REMOVE_FILTER' }) }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
        <b-button
          variant="blind-dark"
          :title="'Sort by ' + translator[title]"
          class="font-weight-500"
          style="padding-left: 6px; padding-right: 6px"
          @click="$emit('sort')"
        >
          <icon
            v-if="sorting"
            :icon="sortIcon"
          />
          <icon
            v-else
            icon="sort"
          />
        </b-button>
      </b-button-group>
    </div>

    <b-button
      v-else
      variant="blind-dark"
      :title="'Sort by ' + translator[title]"
      class="font-weight-500"
      style="padding-left: 6px; padding-right: 6px"
      @click="$emit('sort')"
    >
      {{ translator[title] }}&nbsp;
      <icon
        v-if="sorting"
        :icon="sortIcon"
      />
      <icon
        v-else
        icon="sort"
      />
    </b-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useVModel } from '@vueuse/core';
import { databaseTranslator } from '@/utils/metrics';
import useTranslation from '@/composables/useTranslation';
import { IPortfolioSubset, IStrategySubset } from '@/types/IndexOptionsTable';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { Currency } from '@/constants/Currency';
import { FactorConstants } from '@/constants/FactorConstants';
import { Region } from '@/constants/Region';
import { ReturnCategoryConstants } from '@/constants/ReturnCategoryConstants';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { StyleConstants } from '@/constants/StyleConstants';
import { ATTRIBUTE_DATABASE_NAME } from '@/types/strategy';
import { translatorForProperty } from '@/utils/strategy';
import { IFilter } from '@/types/IFilter';
import CurrencyDropdownItems from '../CurrencyDropdownItems.vue';

interface IFilterEntry {
  value: string;
  label: string;
  active: boolean;
}

export default defineComponent({
  name: 'IndexOptionsHeadItem',
  components: { CurrencyDropdownItems },
  props: {
    title: {
      type: String as PropType<keyof IStrategySubset | keyof IPortfolioSubset>,
      required: true,
    },
    /**
     * Currently active filters.
     * Available for `.sync` binding.
     */
    activeFilters: {
      type: Array as PropType<Array<IFilter<string>>>,
      required: true,
    },
    sortAscending: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If true, we are sorting by this property/title.
     */
    sorting: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    /**
     * The "sort by this column" button clicked
     */
    sort: null,
    'update:activeFilters': null,
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();
    const translator = databaseTranslator;

    const activeFiltersModel = useVModel(props, 'activeFilters', emit);

    const filterActive = computed(() => {
      return activeFiltersModel.value.map((filter) => filter.property).includes(props.title);
    });

    const filterByPropertyValue = (valueObj: { value: string; label: string }) => {
      const next = activeFiltersModel.value.filter((filter) => filter.property !== props.title);
      next.push({ property: props.title, ...valueObj });
      activeFiltersModel.value = next;
    };

    const removeFilter = () => {
      activeFiltersModel.value = activeFiltersModel.value.filter((filter) => filter.property !== props.title);
    };

    const actives = computed(() => {
      return new Set(props.activeFilters.map((f) => f.value));
    });

    /**
     * Check if the given item is active.
     *
     * Most of the time you should use property `IFilterEntry.active`.This is exposed to template
     * for currency only.
     */
    const isItemActive = (value: string) => {
      return actives.value.has(value);
    };

    const filter = computed((): Array<IFilterEntry> => {
      const mapActive = (props: Omit<IFilterEntry, 'active'>) => ({
        ...props,
        active: isItemActive(props.value),
      });

      switch (props.title) {
        case ATTRIBUTE_DATABASE_NAME.AssetClass:
          return Object.values(AssetClassConstants)
            .map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.AssetClass))
            .map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.Currency:
          return Object.values(Currency)
            .map((x: string) => ({ value: x, label: x }))
            .map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.Factor:
          return Object.values(FactorConstants)
            .map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Factor))
            .map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.ReturnCategory:
          return Object.values(ReturnCategoryConstants)
            .map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.ReturnCategory))
            .map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.ReturnType:
          return Object.values(ReturnTypeConstants)
            .sort()
            .map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.ReturnType))
            .map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.Region:
          return Object.values(Region).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Region)).map(mapActive);
        case ATTRIBUTE_DATABASE_NAME.Style:
          return Object.values(StyleConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Style)).map(mapActive);
        default:
          return [];
      }
    });

    const sortIcon = computed(() => {
      return props.sortAscending ? 'sort-up' : 'sort-down';
    });

    return {
      translate,
      translator,
      filterActive,
      filterByPropertyValue,
      removeFilter,
      filter,
      sortIcon,
      isItemActive,
    };
  },
});
</script>
