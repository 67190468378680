import { IConstituentRiskTreeMap } from '@/types/IConstituentRiskTreeMap';
import { IConstituentRiskCommodityForward } from '@/types/IConstituentRiskCommodityForward';
import { IConstituentRiskVarComponent } from '@/types/IConstituentRiskVarComponent';
import { IConstituentRiskRibbonResponse } from '@/types/IConstituentRiskRibbon';
import { IConstituentRiskStressTest } from '@/types/IConstituentRiskStressTest';
import { IConstituentRiskCreditHeatMap, IConstituentRiskHeatMap } from '@/types/IConstituentRiskHeatMap';
import { IConstituentRiskTable } from '@/types/IConstituentRiskTable';
import { IConstituentRiskMetricTrack } from '@/types/IConstituentRiskMetricTrack';
import { IConstituentRiskSpotLadder } from '@/types/IConstituentRiskSpotLadder';
import {
  CommodityFilterConstants,
  FilterConstants,
  EquityGroupByConstants,
  StressTestDropdownType,
  TableLayerConstants,
  UnitConstants,
} from './analytics/ConstituentRisk';
import { SegmentType } from './SegmentType';
import { MetricConstants } from '@/constants/MetricConstants';

export type IConstituentRisk = {
  Dates: string[] | null;
  RiskTable: IConstituentRiskTable | null;
  Ribbon: IConstituentRiskRibbonResponse[] | null;
  CommodityTreeMap: IConstituentRiskTreeMap[] | null;
  CommodityForward: IConstituentRiskCommodityForward | null;
  CommoditySpotLadder: IConstituentRiskSpotLadder | null;
  CommodityTableDelta: IConstituentRiskTable | null;
  CommodityTableGamma: IConstituentRiskTable | null;
  CommodityMetricTrack: IConstituentRiskMetricTrack | null;
  CommodityLadderTable: IConstituentRiskTable | null;
  EquityTreeMap: IConstituentRiskTreeMap[] | null;
  EquityBarChart: IConstituentRiskTreeMap[] | null;
  EquitySpotLadder: IConstituentRiskSpotLadder | null;
  EquityMetricTrack: IConstituentRiskMetricTrack | null;
  EquityLadderTable: IConstituentRiskTable | null;
  VarCharts: IConstituentRiskVarComponent | null;
  VarMetricTrack: IConstituentRiskMetricTrack | null;
  ExposureMetricTrack: IConstituentRiskMetricTrack | null;
  StressTest: IConstituentRiskStressTest[] | null;
  StressTestMetricTrack: IConstituentRiskMetricTrack | null;
  FXChart: IConstituentRiskStressTest[] | null;
  FXMetricTrack: IConstituentRiskMetricTrack | null;
  FXSpotLadder: IConstituentRiskSpotLadder | null;
  FXTable: IConstituentRiskTable | null;
  FXLadderTable: IConstituentRiskTable | null;
  IRChart: IConstituentRiskHeatMap[] | null;
  IRMetricTrack: IConstituentRiskMetricTrack | null;
  IRSpotLadder: IConstituentRiskSpotLadder | null;
  IRTable: IConstituentRiskTable | null;
  IRNormalLadderTable: IConstituentRiskTable | null;
  IRBsLadderTable: IConstituentRiskTable | null;
  CreditChart: IConstituentRiskCreditHeatMap[] | null;
  CreditMetricTrack: IConstituentRiskMetricTrack | null;
  CreditTable: IConstituentRiskTable | null;
};

export interface IConstituentRiskQuery {
  slug: string;
  component: (keyof IConstituentRisk)[];
  portfolioTreeId: string;
  /**
   * Replaces `suspendedForRisk` in the portfolioTreeDraft. Query will only be run using codes in this property
   */
  analysisCodes: string[];
  fields?: string[] | undefined;
  date: string;
  rollingDate: string | undefined;
  unit: UnitConstants;
  equityMetric: MetricConstants;
  equityFilterLevel1: EquityGroupByConstants | FilterConstants;
  equityFilterLevel2: EquityGroupByConstants | FilterConstants;
  commodityMetric: MetricConstants;
  commodityFilter: CommodityFilterConstants;
  varMetric1: string;
  varMetric1GroupBy: FilterConstants;
  varMetric2: string;
  ribbonFieldNames: string[];
  stressTestOptions: StressTestDropdownType[];
  stressTestHistoryOption: StressTestDropdownType;
  stressTestFilter: FilterConstants;
  fxFilter: FilterConstants;
  trackSegment: FilterConstants | SegmentType;
  basisPoints: number;
  riskTableLayers: TableLayerConstants[];
  isNavRescaling: boolean;
}

/**
 * @param slug Portfolio slug name
 * @param component Table component to receive
 * @param portfolioTreeId treeId of select subportfolio
 * @param date Portfolio position date
 * @param fields Portfolio fields chosen by options
 * @param rollingDate Reference date
 * @param unit Can either be % or $
 * @param exposureType Portfolio exposure chosen by options
 * @param equityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param equityFilterLevel1 First filter level
 * @param equityFilterLevel2 Second filter level
 * @param commodityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param varMetric1 Var item to fetch
 * @param varMetric2 Exposure item to fetch
 * @param ribbonFieldNames List of metrics for the ribbon
 * @param stressTestOptions Dictionary of stress tests and their shocks
 * @param stressTestHistoryOption Stress test to use for history mode
 * @param stressTestFilter Filter
 * @param fxFilter Filter
 * @param trackSegment Time Chart should be calculated at the portfolio or strategy level
 * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
 * @param riskTableLayers List of table layers
 * @param isNavRescaling Boolean to turn on navRescaling
 */
export interface IConstituentRiskTableQuery {
  slug: string;
  component: RiskDashboardTableComponentsConstants;
  analysisCodes: string[];
  portfolioTreeId: string;
  fields?: string[] | undefined;
  date: string;
  rollingDate: string | undefined;
  unit: UnitConstants;
  equityMetric: MetricConstants;
  equityFilterLevel1: EquityGroupByConstants | FilterConstants;
  equityFilterLevel2: EquityGroupByConstants | FilterConstants;
  commodityMetric: MetricConstants;
  commodityFilter: CommodityFilterConstants;
  varMetric1: string;
  varMetric2: string;
  ribbonFieldNames: string[];
  stressTestOptions: StressTestDropdownType[];
  stressTestHistoryOption: StressTestDropdownType;
  stressTestFilter: FilterConstants;
  fxFilter: FilterConstants;
  trackSegment: FilterConstants | SegmentType;
  basisPoints: number;
  riskTableLayers: TableLayerConstants[];
  isNavRescaling: boolean;
}

export enum RiskDashboardTableComponentsConstants {
  RiskTable = 'RiskTable',
  CommodityTableDelta = 'CommodityTableDelta',
  CommodityTableGamma = 'CommodityTableGamma',
  CommodityLadderTable = 'CommodityLadderTable',
  EquityLadderTable = 'EquityLadderTable',
  FXTable = 'FXTable',
  FXLadderTable = 'FXLadderTable',
  IRTable = 'IRTable',
  IRNormalLadderTable = 'IRNormalLadderTable',
  IRBsLadderTable = 'IRBsLadderTable',
  CreditTable = 'CreditTable',
}

export interface IConstituentRibbonQuery {
  slug: string;
  date: string;
  rollingDate?: string;
  unit?: UnitConstants;
  ribbonFieldNames?: string[];
  basisPoints?: number;
  isNavRescaling?: boolean;
}
