<template>
  <div class="w-100">
    <Navbar v-if="shouldShowNav" />
    <table
      v-if="isPdf"
      id="main-layout"
      class="table w-100 border-0 mb-0"
    >
      <thead class="border-0">
        <tr class="border-0">
          <th class="p-0 border-0">
            <PdfHeader />
          </th>
        </tr>
      </thead>
      <!-- tfoot weirdly enough SHOULD be located between thead and tbody -->
      <tfoot class="border-0">
        <tr class="border-0 w-100">
          <td class="border-0 p-0">
            <AppFooter />
          </td>
        </tr>
      </tfoot>
      <tbody class="border-0">
        <tr class="border-0">
          <td class="p-0 border-0">
            <div
              :class="{
                'd-flex flex-column justify-content-center': isPdf,
              }"
            >
              <router-view />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <router-view style="min-height: calc(100vh - 160px)" />
      <AppFooter />
    </div>
    <Toaster />
    <StaticDataModal />
  </div>
</template>

<script lang="ts">
import { Navbar, AppFooter, PdfHeader } from './components';
import { SettingsModule } from '@/store/barrel';
import { computed, defineComponent, onMounted } from 'vue';
import { useRouteRef } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';
import { authStrategy } from '@/auth';
import { useIsPdf } from '@/composables/usePdf';
import Toaster from '@/layout/components/Toaster.vue';
import StaticDataModal from '@/views/modals/StaticDataModal/StaticDataModal.vue';

export default defineComponent({
  name: 'LayoutComponent',
  components: {
    Navbar,
    AppFooter,
    PdfHeader,
    StaticDataModal,
    Toaster,
  },
  setup() {
    const isPdf = useIsPdf();

    // DATA
    const measureScrollbarWidth = () => {
      const scrollDiv = document.createElement('div');
      scrollDiv.className = 'scrollbar-measure';
      document.body.appendChild(scrollDiv);

      // const the = computed( scrollbar widt=> h
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      SettingsModule.SetScrollbarWidth(scrollbarWidth);

      // Delete the DIV
      document.body.removeChild(scrollDiv);
    };

    // LIFECYCLE EVENTS
    onMounted(() => {
      if (isPdf.value) return;

      measureScrollbarWidth();
    });

    const route = useRouteRef();

    const shouldShowNav = computed(() => {
      if (isPdf.value) {
        return false;
      }

      if (route.value.name === RouteName.LOGIN) {
        return authStrategy.navbarOnLogin ?? false;
      }

      return authStrategy.shouldHideNavbar ? false : true;
    });

    return {
      shouldShowNav,
      isPdf,
    };
  },
});
</script>
