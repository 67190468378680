
<script setup lang="ts">
const id = "var-historical"
 const title = "Historical VaR"
 const categories = ["EX_ANTE","VALUE_AT_RISK"]
 const hiddenCategories = ["RISK_METRICS","PERFORMANCE_METRICS"]
 const related = ["x-var"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"var-historical","title":"Historical VaR","categories":["EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS","PERFORMANCE_METRICS"],"related":["x-var"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"var-historical","title":"Historical VaR","categories":["EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS","PERFORMANCE_METRICS"],"related":["x-var"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Historical VaR method does not employ any assumption on return distribution and it is a popular estimate of VaR. It requires historical observations on assets to construct an empirical distribution of returns.</p>
<p>Computation of VaR involves first generating samples of profits or losses. Historical data of relevant market variables are used. Individual assets are repriced with the hypothetical changes in market variables to create samples of profits and losses. Aggregating them according to the portfolio allocation creates samples of portfolio profits and losses. The portfolio profit and loss on each day is weighted equally.</p>
</div>
</template>
