<script lang="ts">
import { useBsEventListener } from '@/composables/useBsEventListener';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { useRouteRef } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';
import { MorningstarTourDict } from '@/dictionaries/MorningstarTourDict';
import { useTourStore } from '@/composables/useTourStore';
import { ModalIdParameter, ModalEvents } from '@/types/Injectables';
import { Emitter } from 'mitt';
import Shepherd from 'shepherd.js';
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue';

const { eventBus } = useGlobalEventBus();

const defaultNextButtonSettings: Shepherd.Step.StepOptionsButton = {
  text: 'Next',
  classes: 'btn btn-info',
};

const defaultFinishButtonSettings: Shepherd.Step.StepOptionsButton = {
  text: 'Finish',
  classes: 'btn btn-primary',
};

export default defineComponent({
  name: 'TourGuide',
  setup() {
    const route = useRouteRef();

    const numberOfSteps = computed(() => Shepherd.activeTour?.steps.length ?? 0);

    const updateProgress = (step: string) => {
      const currentStep = Shepherd.activeTour?.getCurrentStep();
      const currentStepElement = currentStep?.getElement();
      const footer = currentStepElement?.querySelector('.shepherd-footer');

      const progress = document.createElement('span');
      progress.innerText = `${step}/${numberOfSteps.value}`;

      footer?.prepend(progress);
    };

    const showUploadIdentifierModal = () => {
      Shepherd.activeTour?.hide();

      tourStore.setIdentifierText('');
      (eventBus as Emitter<ModalIdParameter>).emit(ModalEvents.OPEN_MODAL, { id: 'uploadIdentifier' });
    };

    // On try, Upload string to UploadIdentifier Modal
    const uploadExampleIdentifier = () => {
      tourStore.setIdentifierText('US46434V4499');

      setTimeout(() => {
        Shepherd.activeTour?.next();
      }, 700);
    };

    const tourStore = useTourStore();

    const startMorningStarTour = () => {
      tourStore.setIsTourActive(true);

      const tour = new Shepherd.Tour({
        tourName: 'New Analytics Page Tour',
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          scrollTo: {
            behavior: 'smooth',
            block: 'center',
          },
        },
      });

      tour.addSteps([
        {
          id: 'step-1',
          attachTo: {
            element: document.getElementById('morningstar-step-1'),
            on: 'bottom',
          },
          title: MorningstarTourDict.step1.title,
          text: MorningstarTourDict.step1.text,
          buttons: [
            {
              ...defaultNextButtonSettings,
              action: tour.next,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          modalOverlayOpeningRadius: 3,
          when: {
            show() {
              updateProgress('1');
            },
          },
        },
        {
          id: 'step-2',
          attachTo: {
            element: document.getElementById('morningstar-step-2'),
            on: 'bottom',
          },
          title: MorningstarTourDict.step2.title,
          text: MorningstarTourDict.step2.text,
          buttons: [
            {
              ...defaultNextButtonSettings,
              action: showUploadIdentifierModal,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          modalOverlayOpeningRadius: 4,
          when: {
            show() {
              updateProgress('2');
            },
          },
        },
        {
          id: 'step-3',
          attachTo: {
            element: document.getElementById('morningstar-step-3'),
            on: 'top',
          },
          title: MorningstarTourDict.step3.title,
          text: MorningstarTourDict.step3.text,
          modalOverlayOpeningPadding: 8,
          buttons: [
            {
              text: 'Try',
              action: uploadExampleIdentifier,
              classes: 'btn btn-primary',
            },
            {
              ...defaultNextButtonSettings,
              action: tour.next,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          when: {
            show() {
              updateProgress('3');
            },
          },
        },
        {
          id: 'step-4',
          attachTo: {
            element: document.getElementById('morningstar-step-3'),
            on: 'top',
          },
          title: MorningstarTourDict.step3.title,
          text: MorningstarTourDict.step3.text,
          modalOverlayOpeningPadding: 5,
          buttons: [
            {
              ...defaultNextButtonSettings,
              action: showStep5,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          when: {
            show() {
              updateProgress('4');
            },
          },
          scrollTo: false,
        },
        {
          id: 'step-5',
          attachTo: {
            element: document.getElementById('morningstar-step-5'),
            on: 'top',
          },
          title: MorningstarTourDict.step5.title,
          text: MorningstarTourDict.step5.text,
          buttons: [
            {
              ...defaultNextButtonSettings,
              action: tour.next,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          when: {
            show() {
              updateProgress('5');
            },
          },
          // override default scrollTo so it doesn't try to scroll to the middle
          // of the possibly very long list
          scrollTo: false,
        },
        {
          id: 'step-6',
          attachTo: {
            element: document.getElementById('morningstar-step-6'),
            on: 'top',
          },
          title: MorningstarTourDict.step6.title,
          text: MorningstarTourDict.step6.text,
          buttons: [
            {
              ...defaultFinishButtonSettings,
              action: finishTour,
            },
          ],
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          modalOverlayOpeningPadding: 5,
          when: {
            show() {
              updateProgress('6');
            },
          },
          // override default scrollTo so it doesn't try to scroll to the middle
          // of the possibly very long list
          scrollTo: false,
        },
      ]);

      tour.start();
    };

    const showStep5 = () => {
      eventBus.emit('REQUEST-IDENTIFIER');

      Shepherd.activeTour?.next();
    };

    const finishTour = () => {
      Shepherd.activeTour?.complete();
    };

    const startWhichTour = () => {
      if (route.value.name === RouteName.MORNINGSTAR) {
        startMorningStarTour();
      }
    };

    useBsEventListener('bv::modal::shown', (modalId) => {
      if (modalId !== 'uploadIdentifier') return;
      Shepherd.activeTour?.show('step-3');
    });

    onMounted(() => {
      eventBus.on('START-TOUR', startWhichTour);
    });
    onBeforeUnmount(() => {
      eventBus.off('START-TOUR', startWhichTour);
    });

    return () => null;
  },
});
</script>
