<template>
  <b-modal
    v-bind="{ ...attrs, 'hide-header': !modalTitle && !$slots.header }"
    :id="id"
    v-model="isShown"
    :no-close-on-backdrop="noCloseOnBackdrop || !dismissable"
    :no-close-on-esc="noCloseOnEsc || !dismissable"
    :dialog-class="dialogClasses"
    :modal-class="modalClasses"
    :body-class="bodyClasses"
    class="d-print-none"
    @keyup.tab="shouldShowFocusOutline = true"
    @click="shouldShowFocusOutline = false"
    @show="onShow"
    @shown="onShown"
    @hide="onHide"
    @hidden="onHidden"
    @close="onCancelFn"
    @cancel="onCancelFn"
  >
    <template
      v-if="modalTitle || $slots.header"
      #modal-header
    >
      <!-- manual height applied to maintain a consistent height between
            the state of showing the form input and the plain header -->
      <b-container
        class="d-flex align-items-center justify-content-between w-100"
        style="height: 35px"
      >
        <div class="flex-grow-1 mw-0">
          <slot name="header">
            <h4 class="mb-0 text-primary text-ellipsis">
              {{ modalTitle }}
            </h4>
          </slot>
        </div>
        <!-- Don't show the overlay over the refresh button if we are already showing the overlay over everything -->
        <b-overlay
          v-if="showRefreshBtn"
          :show="refreshBusy && !shouldShowOverlay"
          :opacity="overlayOpacity"
        >
          <button
            class="close ml-n3"
            type="button"
            :disabled="refreshBusy"
            @click="onRefreshFn()"
          >
            <Icon
              class="d-block"
              icon="refresh"
              size="sm"
            />
          </button>
        </b-overlay>
        <div v-if="dismissable">
          <button
            class="close"
            type="button"
            :disabled="busy"
            style="font-size: 1.5rem; font-family: initial; margin-left: 15px"
            @click="onCancelFn()"
          >
            <!-- reapply the font-family to properly size the X
                  custom margin-left + the button's default padding of 1rem
                  exactly matches the padding on the modal body -->
            ×
          </button>
        </div>
      </b-container>
    </template>
    <b-container :class="bodyClasses">
      <div class="position-relative">
        <slot>
          <!-- default slot for modal content -->
        </slot>
        <button
          v-if="dismissable && !modalTitle && !$slots.header"
          class="close position-absolute"
          type="button"
          :disabled="busy"
          style="font-size: 1.5rem; font-family: initial; top: 0; right: -15px"
          @click="onCancelFn()"
        >
          <!-- reapply the font-family to properly size the X -->
          ×
        </button>
      </div>
    </b-container>
    <template #modal-footer>
      <b-container>
        <b-row no-gutters>
          <b-col>
            <transition
              name="fade-transform"
              mode="out-in"
            >
              <slot
                v-if="!showConfirmationSlot"
                name="footer"
                :busy="busy"
                :ok="onConfirmFn"
                :cancel="onCancelFn"
              >
                <div class="d-flex justify-content-between">
                  <b-button
                    :disabled="busy"
                    @click="onCancelFn"
                  >
                    {{ cancelLabel ?? translate({ path: 'GLOBAL.CANCEL' }) }}
                  </b-button>
                  <b-button
                    variant="info"
                    :disabled="!isConfirmEnabled || busy"
                    @click="onConfirmFn"
                  >
                    {{ confirmLabel ?? translate({ path: 'GLOBAL.APPLY' }) }}
                  </b-button>
                </div>
              </slot>
              <slot
                v-if="showConfirmationSlot"
                name="footer-confirmation"
                :busy="busy"
                :ok="onConfirmWarningFn"
                :cancel="onCancelWarningFn"
              >
                <div class="d-flex justify-content-between">
                  <b-button
                    :disabled="busy"
                    @click="onCancelWarningFn"
                  >
                    {{ cancelWarningLabel ?? translate({ path: 'GLOBAL.CANCEL' }) }}
                  </b-button>
                  <b-button
                    variant="info"
                    :disabled="busy"
                    @click="onConfirmWarningFn"
                  >
                    {{ confirmWarningLabel ?? translate({ path: 'GLOBAL.APPLY' }) }}
                  </b-button>
                </div>
              </slot>
            </transition>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <!-- it's weird to have this overlay covering nothing, but actually because
    we are using no-wrap, it doesn't need to. This automatically covers the modal -->
    <b-overlay
      no-wrap
      rounded
      :show="shouldShowOverlay"
      :opacity="overlayOpacity"
    />
  </b-modal>
</template>

<script lang="ts">
import useTranslation from '@/composables/useTranslation';
import { defineComponent, ref, PropType, computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { ClassProp, handlePossibleClassPropTypes } from '@/utils/bootstrapVue';

const { translate } = useTranslation();

/**
 * TODO: add loading indicator and disable pointer events and keyup/keydown events
 */

/**
 * For full list of props and events, please see the official docs here
 * https://bootstrap-vue.org/docs/components/modal
 */
export default defineComponent({
  name: 'StandardModal',
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      required: false,
    },
    /**
     * Controls open of the modal. This is available as `v-model` binding
     */
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    isConfirmEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    noCloseOnEsc: {
      type: Boolean,
      required: false,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Because our modals are either dismissable or not, I've exposed this one property
     * which controls the no-close-on-backdrop and no-close-on-esc properties in the
     * standard BootstrapVue modal
     */
    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Gives our modals an option to not be dismissable on confirm
     * If true, the modal will close on confirm
     * If false, the modal will be only dismissable on cancel
     */
    dismissableOnConfirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Show a loading overlay that covers the whole modal.
     *
     * Implies `busy` be true if this is true.
     */
    shouldShowOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If true, disable cancel/apply buttons in the footer and the x button on
     * top right.
     *
     * This does **not** give them a loading spinner.
     */
    busy: {
      type: Boolean,
      default: false,
    },
    /**
     * onConfirm is not required because you may overwrite the footer with
     * your own content
     *
     * Return false to prevent auto close dialog when confirm button clicked
     */
    onConfirm: {
      type: Function as PropType<() => Promise<void> | void | false | Promise<false>>,
      required: false,
      default: () => {},
    },
    confirmLabel: {
      type: String,
      required: false,
      default: null,
    },
    onConfirmWarning: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
      default: () => {},
    },
    confirmWarningLabel: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * onCancel IS required because even if you overwrite the footer, you are able
     * to close a modal in a couple different ways. We encourage cleaning up after
     * yourself when a modal is closed, so please don't use an empty function.
     */
    onCancel: {
      type: Function as PropType<() => Promise<void> | void>,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: null,
    },
    onCancelWarning: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
    },
    cancelWarningLabel: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * For some modals, for example modals where you can delete things,
     * we want to add an extra confirmation. This boolean controls
     * whether that confirmation should be shown.
     */
    shouldShowWarningConfirmationSlot: {
      type: Boolean,
      required: false,
    },
    modalClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    bodyClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    dialogClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    /**
     * Shows a refresh button next to the close button on the dialog header.
     *
     * Used for admin console only. Reserved for tasks which cause a very heavy load on the database, where we increase
     * the stale time on vue-query and we let the user manually refresh. Do NOT use on client facing pages.
     *
     * For client facing pages, we should always let vue-query handle auto-refresh and design performant APIs.
     */
    showRefreshBtn: {
      type: Boolean,
      default: false,
    },
    /**
     * Set if it is currently refreshing. When true, a spinner is shown on top of the refresh button.
     */
    refreshBusy: {
      type: Boolean,
      default: false,
    },
    /**
     * Callback when the refresh button is pressed.
     */
    onRefresh: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
      default: () => {},
    },
    overlayOpacity: {
      type: Number,
      required: false,
      default: 0.85,
    },
  },
  emits: ['show', 'shown', 'hide', 'hidden', 'input'],
  setup(props, { emit, attrs }) {
    const showConfirmationSlot = useVModel(props, 'shouldShowWarningConfirmationSlot');

    /**
     * When the child modal emits its events, we re-emit them so that any parent component
     * can hook into them as necessary
     */
    const onShow = () => emit('show');
    const onShown = () => emit('shown');
    const onHide = () => emit('hide');
    const onHidden = () => emit('hidden');
    const onCancelFn = async () => {
      await props.onCancel();
      isShown.value = false;
    };
    const onRefreshFn = () => {
      props.onRefresh();
    };

    const onCancelWarningFn = async () => {
      if (props.onCancelWarning) await props.onCancelWarning();
      showConfirmationSlot.value = false;
    };

    const onConfirmFn = async () => {
      const result = await props.onConfirm();
      if (result === false) {
        return;
      }
      isShown.value = !props.dismissableOnConfirm;
    };

    const onConfirmWarningFn = async () => {
      await props.onConfirmWarning();
      isShown.value = !props.dismissableOnConfirm;
    };

    const isShown = useVModel(props, 'value', emit, { passive: true, eventName: 'input' });

    /**
     * IMPORTANT: destructuring the attrs in this way breaks reactivity.
     * That said, I don't think it's important for our use case, so we can
     * do this here. (I believe destructuring will NOT break reactivity
     * when we move to Vue 3)
     */
    const shouldShowFocusOutline = ref(false);

    /**
     * Create and return this method so that the parent component can call it too
     */
    const show = () => {
      isShown.value = true;
    };

    /**
     * Create and return this method so that the parent component can call it too
     */
    const hide = () => {
      isShown.value = false;
    };

    const dialogClasses = computed((): (string | { [className: string]: boolean })[] => {
      return handlePossibleClassPropTypes(props.dialogClass ?? {});
    });

    const bodyClasses = computed((): (string | { [className: string]: boolean })[] => {
      return ['position-static', ...handlePossibleClassPropTypes(props.bodyClass ?? {})];
    });

    const modalClasses = computed((): (string | { [className: string]: boolean })[] => {
      return [
        {
          'no-focus-outline': !shouldShowFocusOutline.value,
        },
        'd-print-none',
        ...handlePossibleClassPropTypes(props.modalClass ?? {}),
      ];
    });

    return {
      translate,
      isShown,
      show,
      hide,
      shouldShowFocusOutline,

      showConfirmationSlot,
      onConfirmWarningFn,
      onCancelWarningFn,

      /**
       * pass all props / attrs and bind them to the original modal so that we don't have to
       * explicitly re-bind all the possible props.
       */
      props,
      attrs,
      onShow,
      onShown,
      onHide,
      onHidden,
      onCancelFn,
      onConfirmFn,
      onRefreshFn,

      dialogClasses,
      modalClasses,
      bodyClasses,
    };
  },
});
</script>
