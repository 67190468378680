import { IntervalPeriodDaily } from '@/constants/IntervalPeriodDaily';
import { IntervalPeriodMonthly } from '@/constants/IntervalPeriodMonthly';
import { IntervalPeriodWeekly } from '@/constants/IntervalPeriodWeekly';

export enum WeightingTypeConstants {
  EQUAL = 'Equal',
  MANUAL = 'Manual',
  RISK_PARITY = 'Risk Parity',
  ERC = 'ERC',
  PC1 = 'PC1',
  CUSTOM = 'Custom',
  NONE = 'None',
  MINIMUM_VARIANCE = 'Minimum Variance',
  FRONTIER_VOLATILITY_TARGET = 'Frontier Volatility Target',
}

export type IntervalPeriodConstants = IntervalPeriodDaily | IntervalPeriodWeekly | IntervalPeriodMonthly;

export enum RiskMetricConstants {
  VOLATILITY = 'Volatility',
  VALUE_AT_RISK = 'VaR',
  CONDITIONAL_VALUE_AT_RISK = 'CVaR',
  MAX_DRAWDOWN = 'MaxDD',
}

export enum MinimumVarianceInterval {
  ONE_M = '1M',
  THREE_M = '3M',
  SIX_M = '6M',
  ONE_Y = '1Y',
  TWO_Y = '2Y',
  THREE_Y = '3Y',
}

export const WeightingTypesWithAllocationConstraints = new Set([
  WeightingTypeConstants.RISK_PARITY,
  WeightingTypeConstants.ERC,
]);
