
<script setup lang="ts">
const id = "size"
 const title = "Size"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"size","title":"Size","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"size","title":"Size","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Strategies that aim to take advantage of the size factor, which is the expectation that smaller size equities
tend to outperform over the long term. Size is measured by market capitalisation of the equity.</p>
</div>
</template>
