import { PluginObject } from 'vue';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { library, config } from '@fortawesome/fontawesome-svg-core';

// Disable auto CSS inject to workaround CSP issue.
// This statement must be applied before any fontawesome api call
// Ref: https://fontawesome.com/docs/web/dig-deeper/security
config.autoAddCss = false;

/* import specific icons */
import {
  faChartMixed as fasChartMixed,
  faChartPie as fasChartPie,
  faChartLineUp as fasChartLineUp,
  faChartColumn as fasChartColumn,
  faChartScatter as fasChartScatter,
  faChartArea as fasChartArea,
  faShoppingBasket as fasShoppingBasket,

  /**
   * We use all solid versions for these icons:
   * ['caret', 'sort', 'cog', 'thumb-tack', 'plus', 'bars']
   */
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faCaretLeft as fasCaretLeft,
  faSort as fasSort,
  faSortUp as fasSortUp,
  faSortDown as fasSortDown,
  faSortAmountAsc as fasSortAmountAsc,
  faSortAmountDesc as fasSortAmountDesc,
  faCog as fasCog,
  faThumbtack as fasThumbtack,
  faPlus as fasPlus,
  faBars as fasBars,

  /**
   * The rest of these do not have rules associated with them
   */
  faDownload as fasDownload,
  faFilePdf as fasFilePdf,
  faFileExcel as fasFileExcel,
  faTimes as fasTimes,
  faPencilAlt as fasPencilAlt,
  faExclamation as fasExclamation,
  faExclamationTriangle as fasExclamationTriangle,
  faCircleQuestion as fasCircleQuestion,
  faCircle as fasCircle,
  faCirclePause as fasCirclePause,
  faCirclePlay as fasCirclePlay,
  faTrash as fasFaTrash,
  faBookmark as fasFaBookmark,
  faClone as fasFaClone,
  faSquare as fasSquare,
  faSquareMinus as fasSquareMinus,
  faSquarePlus as fasSquarePlus,
  faPlay as fasFaPlay,
  faSave as fasSave,
  faCircleCheck as fasCircleCheck,
  faShieldCheck as fasShieldCheck,
  faCircleXmark as fasCircleXmark,
  faEllipsisVertical as fasEllipsisVertical,
  faInfoCircle as fasInfoCircle,
  faClock as fasClock,
  faHyphen as fasHyphen,
  faFilter as fasFilter,
  faPause as fasPause,
  faXmark as fasXmark,
  faArrowsRotate as fasArrowsRotate,
  faExternalLink as fasExternalLink,
  faCircleUser as fasCircleUser,
  faLock as fasLock,
  faLockOpen as fasLockOpen,
} from '@fortawesome/pro-solid-svg-icons';

import { faFile as fatFile } from '@fortawesome/pro-thin-svg-icons';
import {
  faFile as falFile,
  faCirclePlus as falCirclePlus,
  faFileCirclePlus as falFileCirclePlus,
  faPlay as falPlay,
} from '@fortawesome/pro-light-svg-icons';

/**
 * Our default is regular, so we import all of them
 */
import { far } from '@fortawesome/pro-regular-svg-icons';

import Icon from '@/components/standard-components/Icon.vue';

/**
 * Flag for tracking if we have installed icons to library.
 *
 * We need to track this because in component test, the install function might be called
 * multiple times.
 */
let libraryInstalled = false;

export const fontAwesomePlugin: PluginObject<void> = {
  install(vue) {
    if (!libraryInstalled) {
      // add icons to the library
      library.add(
        // thin icons
        fatFile,

        // light icons
        falPlay,
        falFile,
        falCirclePlus,
        falFileCirclePlus,

        // regular icons
        far,

        // solid icons
        fasShoppingBasket,
        fasChartMixed,
        fasChartColumn,
        fasChartLineUp,
        fasChartPie,
        fasChartArea,
        fasChartScatter,
        fasCaretDown,
        fasCaretRight,
        fasCaretLeft,
        fasDownload,
        fasFilePdf,
        fasFileExcel,
        fasTimes,
        fasPlus,
        fasBars,
        fasCog,
        fasPencilAlt,
        fasExclamation,
        fasExclamationTriangle,
        fasSort,
        fasSortUp,
        fasSortDown,
        fasThumbtack,
        fasSortAmountAsc,
        fasSortAmountDesc,
        fasCircle,
        fasCirclePause,
        fasCirclePlay,
        fasFaTrash,
        fasCircleQuestion,
        fasFaBookmark,
        fasFaClone,
        fasSquare,
        fasSquareMinus,
        fasSquarePlus,
        fasFaPlay,
        fasSave,
        fasCircleCheck,
        fasShieldCheck,
        fasCircleXmark,
        fasEllipsisVertical,
        fasInfoCircle,
        fasClock,
        fasHyphen,
        fasFilter,
        fasPause,
        fasXmark,
        fasArrowsRotate,
        fasExternalLink,
        fasCircleUser,
        fasLock,
        fasLockOpen,
      );

      libraryInstalled = true;
    }

    // make our component globally available as <icon></icon>
    vue.component('Icon', Icon);
  },
};
