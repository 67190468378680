import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { BofAAppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

export const ResourcesRouter: RouteConfig[] = [
  {
    name: RouteName.RESOURCES,
    path: '/resources',
    components: {
      default: BofAAppMain,
    },
    redirect: {
      name: RouteName.KNOWLEDGE_BASE,
    },
    children: [
      {
        name: RouteName.KNOWLEDGE_BASE,
        path: 'knowledge-base',
        components: {
          default: () => import(`@/views/knowledge-base/KnowledgeBase.vue`),
          navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
        },
        meta: {
          title: 'Knowledge Base',
          bannerTitle: 'Knowledge Base',
          permission: [
            (perm) =>
              perm.has(UserPermission.API) || (perm.has(UserPermission.UNBLOCK) && perm.has(UserPermission.PLATFORM)),
          ],
        },
      } as RouteConfig,
      {
        name: RouteName.RESOURCES_API_CLIENT,
        path: 'api-docs',
        components: {
          default: () => import('@/views/resources/api-docs.vue'),
          navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
        },
        meta: {
          title: 'API Documentation',
          permission: [UserPermission.API],
        },
      },
    ],
  },
];
