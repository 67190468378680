import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import useTranslation from '@/composables/useTranslation';
import useEnv from '@/composables/useEnv';
import { useToasts } from '@/composables/useToasts';
import Swal from 'sweetalert2';

const { isProduction, isBofAEnvironment } = useEnv();

const blocked = (): boolean => window.open.toString().indexOf('[native code]') === -1;
export const checkBlockPopup = (): void => {
  const { warningToast } = useToasts();
  const { translate } = useTranslation();

  if (!blocked()) return;
  warningToast(
    translate({
      path: 'TOASTS.POPUPS_BLOCKED',
      params: {
        domain: import.meta.env.VITE_DOMAIN,
      },
    }),
  );
};

const service = axios.create({
  baseURL: import.meta.env.VITE_API_V2,
  timeout: 60000, // default one minute
});

/**
 * ****************** Request interceptors ******************
 * ********** Order is important, invoked by use order
 * *********** 1. Queue specific url to queue list *********
 */

// Queue specific url to queue list
service.interceptors.request.use(
  (config): Promise<InternalAxiosRequestConfig> => new Promise((resolve): void => resolve(config)),
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  },
);

/**
 * ****************** Response interceptors ******************
 * ********** Order is important, invoked by use order
 * *********** 1. Remove Queued Request **********
 * *********** 2. Check uat error *********
 * *********** 3. Check bofa validation **********
 */

// Remove queued request
service.interceptors.response.use(
  (response: AxiosResponse): Promise<AxiosResponse> => Promise.resolve(response),
  (error: AxiosError): Promise<AxiosError> => {
    // Remove this `as unknown` cast when the fix for https://github.com/axios/axios/issues/5153 or
    // https://github.com/axios/axios/pull/5595 lands.
    if (axios.isCancel(error as unknown)) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

// UAT specific error check
service.interceptors.response.use(
  (response: AxiosResponse): Promise<AxiosResponse> => {
    return Promise.resolve(response);
  },
  (error: AxiosError<{ message?: string }>): Promise<AxiosError> => {
    if (
      !isProduction.value &&
      error.response &&
      error.response.data &&
      error.response.data.message === 'Redis connection lost and command aborted. It might have been processed.'
    ) {
      const { errorToast } = useToasts();
      errorToast('UAT specific error encountered. Please refresh and try again');
    }
    return Promise.reject(error);
  },
);

// WAA-7490: Show error about invalid characters on BofA only
if (isBofAEnvironment) {
  service.interceptors.response.use(
    (response: AxiosResponse): Promise<AxiosResponse> => {
      return Promise.resolve(response);
    },
    (error: AxiosError<{ message?: string }>): Promise<AxiosError> => {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.message?.startsWith('Request contained invalid chars: ')
      ) {
        Swal.fire({
          title: `Invalid characters are not allowed`,
          text: `Please ensure the following characters are not used: ${error.response.data.message.replace(
            'Request contained invalid chars: ',
            '',
          )}`,
          confirmButtonText: 'OK',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-info btn-block w-20',
            actions: 'w-90 justify-content-around',
          },
        });
      }
      return Promise.reject(error);
    },
  );
}

export default service;
