import { ToastVariant } from '@/constants/ToastVariant';
import { DisplayToastEventBus, DISPLAY_TOAST_EVENT, ToastOptions } from '@/types/ToastEvents';
import mitt, { Emitter } from 'mitt';

const toaster: Emitter<DisplayToastEventBus> = mitt();

export function useToasts() {
  const successToast = (options: string | ToastOptions) => {
    const optsToUse = typeof options === 'string' ? { title: options } : options;

    toaster.emit(DISPLAY_TOAST_EVENT, {
      variant: ToastVariant.SUCCESS,
      options: optsToUse,
    });
  };

  const warningToast = (options: string | ToastOptions) => {
    const optsToUse = typeof options === 'string' ? { title: options } : options;

    toaster.emit(DISPLAY_TOAST_EVENT, {
      variant: ToastVariant.WARNING,
      options: optsToUse,
    });
  };

  const errorToast = (options: string | ToastOptions) => {
    const optsToUse = typeof options === 'string' ? { title: options } : options;

    toaster.emit(DISPLAY_TOAST_EVENT, {
      variant: ToastVariant.ERROR,
      options: optsToUse,
    });
  };

  return {
    successToast,
    warningToast,
    errorToast,
    toaster,
  };
}
