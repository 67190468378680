import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { BofAAppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

export const adminChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.ACCOUNT_MANAGEMENT,
    path: 'users',
    components: {
      default: () => import('@/views/admin/users/AdminUsers.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Users',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_USER,
    path: 'users/:id',
    components: {
      default: () => import('@/views/admin/users/AdminUserEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit User',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TRACK_MANAGEMENT,
    path: 'strategies',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminStrategies.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Strategies',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CREATE_STRATEGY,
    path: 'strategies/new',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyCreate.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_STRATEGY,
    path: 'strategies/:id',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },

  {
    name: RouteName.TEAM_MANAGEMENT,
    path: 'teams',
    components: {
      default: () => import('@/views/admin/team/AdminTeams.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Teams',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_TEAM,
    path: 'teams/:id',
    components: {
      default: () => import('@/views/admin/team/AdminTeamEdit.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Team',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONTENT_MANAGEMENT,
    path: 'articles',
    components: {
      default: () => import('@/views/admin/articles/AdminContentManagement.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Content Management',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
];

export const AdminRouter: RouteConfig[] = [
  {
    path: '/console',
    name: RouteName.CONSOLE,
    components: {
      default: BofAAppMain,
    },
    redirect: { name: RouteName.TRACK_MANAGEMENT },
    meta: {
      title: 'Admin',
      permission: [UserPermission.ADMIN],
    },
    children: adminChildrenRouter,
  },
];
