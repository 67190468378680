export enum AssetClassConstants {
  COMMODITY = 'COMMODITY',
  CREDIT = 'CREDIT',
  EQUITY = 'EQUITY',
  FIXED_INCOME = 'FIXED_INCOME',
  FOREIGN_EXCHANGE = 'FOREIGN_EXCHANGE',
  MULTI_ASSETS = 'MULTI_ASSETS',
  REAL_ESTATE = 'REAL_ESTATE',
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
}

const assetClassOrderedList = [
  AssetClassConstants.FOREIGN_EXCHANGE,
  AssetClassConstants.CREDIT,
  AssetClassConstants.FIXED_INCOME,
  AssetClassConstants.EQUITY,
  AssetClassConstants.COMMODITY,
];

export const sortAssetClasses = (a: AssetClassConstants, b: AssetClassConstants): number => {
  const orderA = assetClassOrderedList.indexOf(a);
  const orderB = assetClassOrderedList.indexOf(b);
  if (orderA === -1) return -1;
  if (orderB === -1) return 1;
  return orderA - orderB;
};
