export enum ACCEPTED_TRACKED_TITLES {
  PAGE_VIEWED = 'Page Viewed',
  TAB_VIEWED = 'Tab Viewed',
  BUTTON_CLICKED = 'Button Clicked',
  PAGE_VIEW_DURATION = 'Page View Duration',
  MODAL_SHOWN = 'Modal Shown',

  USER_INFORMATION = 'User Information',

  PORTFOLIO_CREATED = 'Portfolio Created',
  PORTFOLIO_MODIFIED = 'Portfolio Modified',

  REGRESSION_MODIFIED = 'Factor Regression Modified',
  CONSTITUENT_MODIFIED = 'Constituent Modified',

  UNIVERSE_MODIFIED = 'Universe Modified',
  GLOSSARY_MODIFIED = 'Glossary Modified',

  PERFORMANCE_METRIC_RECORDED = 'Performance Metric Recorded',

  PCA_MODIFIED = 'PCA Modified',
  PCA_CORRELATION_CIRCLE_CALCULATED = 'PCA Correlation Circle Calculated',
  PCA_COMPUTED = 'PCA Computed',

  DSB_MOUNTED = 'DSB Mounted',

  SEARCH_INPUT = 'Search Input',
  SEARCH_HIT = 'Search Hit',
}

export enum TRACKING_PAGE_NAME {
  DATA_PAGE = 'Discover',
}

export enum TRACKING_BUTTON_NAME {
  EXPORT_PDF = 'Export PDF',
  EXPORT_EXCEL = 'Export Excel',
  EXPORT_CSV = 'Export CSV',
  EXPORT_JPG = 'Export JPG',
  EXPORT_PNG = 'Export PNG',

  EXPORT_PORTFOLIO = 'Portfolio Export',
  ADD_STRATEGIES_PORTFOLIO = 'Add Strategies to Portfolio',

  REQUEST_IDENTIFIERS = 'Request Identifiers',
  UPLOAD_DROP_ZONE = 'Drop Zone File Upload',
  COLUMN_SETTINGS = 'Columns Settings',
  DOWNLOAD_WHITEPAPER = 'Download Whitepaper',
  DOWNLOAD_REPORT = 'Download Report',

  COMPARE_PINNED = 'Compare Pinned',
  CLEAR_PINNED = 'Clear Pinned',
  STRATEGY_PINNED = 'Strategy Pinned',

  KNOWLEDGE_BAR_CATEGORY = 'Knowledge Bar Category',
  KNOWLEDGE_BAR_ARTICLE = 'Knowledge Bar Article',
  KNOWLEDGE_BAR_OPEN = 'Open Knowledge Bar',

  DOWNLOAD_OVERVIEW = 'Index Overview Download',
  DOWNLOAD_PERFORMANCE_ATTRIBUTION = 'Attribution Download',
  OPEN_VIDEO = 'Video',
  DOWNLOAD_BROCHURE = 'Brochure Download',
  DOWNLOAD_RISK_FACTOR = 'Risk Factor Download',

  REGRESSION_MODEL_SELECTED = 'Regression Model Selected',
  SCENARIO_SELECTED_NONE = 'Scenario Selected None',
  SCENARIO_SELECTED_REGIME = 'Scenario Selected Regime',
  SCENARIO_SELECTED_BLM = 'Scenario Selected BLM',
}
