import { RouteConfig } from 'vue-router';
import { PERFORMANCE_ATTRIBUTION } from '@/types/analytics/AnalysisStep';
import { RouteName } from '@/constants/RouteName';
import { BofAAppMain } from '@/layout/components';
import { PerformancePageType } from '@/constants/PerformancePageType';
import { useHasPermission } from '@/composables/usePermission';
import { UserPermission } from '@/constants/UserPermission';

export const PerformanceContributionRouter: RouteConfig[] = [
  {
    path: PERFORMANCE_ATTRIBUTION.route,
    alias: '/performance-contribution',
    components: {
      default: BofAAppMain,
    },
    children: [
      {
        path: '',
        // leave this as DATA. if user is in a faux whitelabel, the `beforeEnter` callback will direct them properly
        redirect: {
          name: RouteName.DATA,
        },
      },
      {
        name: RouteName.PERFORMANCE_ATTRIBUTION,
        path: ':indexUniqueIdentifier/:substep?',
        meta: {
          title: PERFORMANCE_ATTRIBUTION.name,
          shouldNavigationPanelBeShown: false,
          isMainContentContainerFluid: true,
        },
        components: {
          default: () => import('@/views/analytics/performance/PerformanceContributionParent.vue'),
          'analysis-step-toolbar': () => import('@/views/analytics/performance/PerformanceToolbar.vue'),
        },
        beforeEnter: (to, from, next) => {
          const substepToPermissionMap = {
            [PerformancePageType.PERFORMANCE_CONTRIBUTION]: useHasPermission(
              UserPermission.STRATEGY_PERFORMANCE_CONTRIBUTION,
            ),
            [PerformancePageType.RECONCILIATION]: useHasPermission(UserPermission.STRATEGY_PERFORMANCE_RECONCILIATION),
          };

          // if current substep is not accessible, redirect to the first accessible substep
          const firstAccessiblePage = Array.from(Object.entries(substepToPermissionMap)).find((o) => o[1].value)?.[0];

          if (to.params.substep) {
            if (substepToPermissionMap[to.params.substep as PerformancePageType].value) {
              next();
              return;
            }
          }
          if (firstAccessiblePage) {
            next({
              name: RouteName.PERFORMANCE_ATTRIBUTION,
              params: { indexUniqueIdentifier: to.params.indexUniqueIdentifier, substep: firstAccessiblePage },
            });
            return;
          }
          next({ name: RouteName.NOT_FOUND });
        },
      },
      {
        name: RouteName.PERFORMANCE_ATTRIBUTION_PDF,
        path: `:indexUniqueIdentifier/:substep/pdf`,
        meta: {
          title: PERFORMANCE_ATTRIBUTION.name,
          pdf: true,
        },
        components: {
          default: () => import('@/views/analytics/performance/PerformanceContributionPdf.vue'),
        },
      },
    ],
  },
];
