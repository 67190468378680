import request from '@/api-v2/request';
import { ISwaggerFile } from './types';

export const getSwagger = (): Promise<ISwaggerFile> =>
  request({
    url: `/docs/swagger`,
    method: 'get',
  }).then(
    (o) => o.data,
    (err): void => {
      console.error(err);
      throw err;
    },
  );

export const getDeveloperSwagger = (): Promise<ISwaggerFile> =>
  request({
    url: `/docs/developer-swagger`,
    method: 'get',
  }).then(
    (o) => o.data,
    (err): void => console.error(err),
  );
