import { getCurrentInstance } from 'vue';
import { tryOnScopeDispose } from '@vueuse/core';

type BvModalEventName = 'bv::modal::hidden' | 'bv::modal::hide' | 'bv::modal::show' | 'bv::modal::shown';
type BvDropdownEventName = 'bv::dropdown::hide' | 'bv::dropdown::show';

/**
 * Listen to events fired on `$root` by bootstrap-vue.
 *
 * This is a temp solution/workaround. We need to change this when we upgrade
 * bootstrap-vue to the compatibility version.
 *
 * Note the listener's signature is not accurate.
 *
 * @param name - non exhaustive list of BootstrapVue root events. Please use the relevant component documentation
 * as reference
 */
export function useBsEventListener(name: BvModalEventName | BvDropdownEventName, listener: (modalId: string) => void) {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useBsEventListener is not called inside setup()');
  }

  vm.proxy.$root.$on(name, listener);

  tryOnScopeDispose(() => {
    vm.proxy.$root.$off(name, listener);
  });
}
