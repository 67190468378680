export enum MetricConstants {
  DELTA = 'Delta',
  GAMMA = 'Gamma',
  VEGA = 'Vega',
  THETA = 'Theta',
  /**
   * This value MAY NOT be sent to the API. Supply MetricConstants.DELTA instead.
   * This is used solely by the front end to determine which dropdown is selected
   * (and thus which graph to show)
   */
  CONVEXITY = 'Convexity',
  /**
   * WAA-5940 - In the future the two IR ladders will be combined into one, therefore back to using only CONVEXITY
   */
  CONVEXITY_NORM = 'Convexity (Normal Vol)',
  CONVEXITY_BS = 'Convexity (B&S Vol)',
}
