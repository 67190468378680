import { useQuery } from '@tanstack/vue-query';
import { SettingsModule } from '@/store/barrel';
import { ISettingRecord } from '@/api-v2/web/settings';

export default function () {
  useQuery(['colors'], (): Promise<ISettingRecord[]> => SettingsModule.GetColors(), {
    retry: 3,
    refetchOnWindowFocus: false,
  });
}
