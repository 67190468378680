import request from '@/api-v2/request';
import { RegimeAnalysisAttributeDTO, RegimeAnalysisAttributeResponseDTO } from './index';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import { IPortfolioTreeTrackResponse } from '@/types/IPortfolioTreeTracksResponse';
import { ITrackQuery } from '@/types/ITrackQuery';
import { StrategyTrackResponseDTO } from '../strategies';

export const getRegimes = (): Promise<RegimeAnalysisAttributeResponseDTO[]> =>
  request({
    url: `/regime-analysis`,
    method: 'GET',
  }).then((o): RegimeAnalysisAttributeResponseDTO[] => o.data);

export const postRegime = (payload: RegimeAnalysisAttributeDTO): Promise<RegimeAnalysisAttributeResponseDTO> =>
  request({
    url: `/regime-analysis`,
    method: 'POST',
    data: payload,
  }).then((o): RegimeAnalysisAttributeResponseDTO => o.data);

export const updateRegime = (
  id: string,
  payload: RegimeAnalysisAttributeDTO,
): Promise<RegimeAnalysisAttributeResponseDTO> =>
  request({
    url: `/regime-analysis/${id}`,
    method: 'POST',
    data: payload,
  }).then((o): RegimeAnalysisAttributeResponseDTO => o.data);

export const deleteRegime = (id: string): Promise<RegimeAnalysisAttributeResponseDTO> =>
  request({
    url: `/regime-analysis/${id}`,
    method: 'DELETE',
  }).then((o): RegimeAnalysisAttributeResponseDTO => o.data);

export const previewRegimeOnStrategy = ({
  code,
  query,
  attribute,
  signal,
}: {
  code: string;
  query?: ITrackQuery;
  attribute: RegimeAnalysisAttributeDTO;
  signal?: AbortSignal;
}): Promise<StrategyTrackResponseDTO> =>
  request({
    url: `/strategies/${code}/track`,
    method: 'POST',
    data: {
      ...query,
      attribute,
    },
    signal,
  }).then((o): StrategyTrackResponseDTO => o.data);

export const previewRegimeOnPortfolio = (
  slug: string,
  query: IPortfolioTreeTracksQuery,
  attribute: RegimeAnalysisAttributeDTO,
): Promise<IPortfolioTreeTrackResponse[]> =>
  request({
    url: `/portfolio-trees/${slug}/tracks`,
    method: 'POST',
    data: {
      ...query,
      attribute,
    },
  }).then((o): IPortfolioTreeTrackResponse[] => o.data);
