import { RouteConfig } from 'vue-router';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName } from '@/constants/RouteName';
import { CONSTITUENT_POSITION } from '@/types/analytics/AnalysisStep';

export const ConstituentPositionRouter: RouteConfig[] = [
  {
    name: RouteName.CONSTITUENT_POSITION,
    path: 'constituent-position/:indexUniqueIdentifier',
    meta: {
      title: CONSTITUENT_POSITION.name,
      shouldApplicationSidebarBeShown: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.CONSTITUENT],
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/ConstituentPosition.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ConstituentPositionToolbar.vue'),
    },
  },
];
