import { Ref, ref } from 'vue';

const shouldShow: Ref<boolean> = ref(false);
const width: Ref<string> = ref('');

const setShouldShowSidebar = (newVal: boolean): void => {
  shouldShow.value = newVal;
};

const setWidth = (newVal: string): void => {
  width.value = newVal;
};

export default function (): {
  shouldShow: Ref<boolean>;
  setShouldShowSidebar: (newVal: boolean) => void;
  setWidth: (newVal: string) => void;
  width: Ref<string>;
} {
  return {
    shouldShow,
    setShouldShowSidebar,
    width,
    setWidth,
  };
}
