import { DSBOptions } from '@/types/DSBOptions';

export const DEFAULT_OPTIONS: Required<DSBOptions> = {
  numMaxStrategiesSelectable: 3,
  selectMultiple: true,
  shouldShowSignal: false,
  shouldShowPortfolio: false,
  inputPlaceholder: 'Keyword search...',
  inputClass: '',
  canRemovePreviouslySelected: true,
  shouldUseCard: true,
  fullHeight: 390,
};

export const SELECTION_LIMITS = {
  BLM: 10,
  FACTOR_DECOMPOSITION: 60,
  FACTSHEET: 3,
};
