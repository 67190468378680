
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Value at Risk (VaR) represents the risk of an asset or a portfolio by the size
of a potential large loss. At 95% confidence level and time horizon, VaR estimates
the threshold of maximum loss over the time span according to the confidence level.
It corresponds to the (1-95%) quantile of the loss in portfolio value.</p>
</div>
</template>
