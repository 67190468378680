import { AuthenticationStatus, useIsAuthenticated } from '@/auth/shared/useIsAuthenticated';
import { watch } from 'vue';
import { useRouteRef } from '@/composables/useRouter';
import { SKIP_AUTH_ROUTES } from './SkipAuthRoutes';

/**
 * Watch if user is authenticated and do redirect to `to` parameter if the user is unauthenticated.
 *
 * This will do nothing if we are in auth-related page. See {@link SKIP_AUTH_ROUTES} for the set of routes we are not
 * doing redirect.
 *
 * @param to Function that produce the URL we redirect to. We use `location.href = ` redirect instead
 * of using the router.
 * Most likely you want to point to some auth controller in backend that handles redirect for you.
 * This is a function so you can pass in stuff like redirect parameter into it
 * if needed.
 */
export function useUnauthRedirect(to: () => string) {
  const isAuthenticated = useIsAuthenticated();
  const route = useRouteRef();

  watch(
    [isAuthenticated, route],
    () => {
      // We only interested in unauthenticated case.
      if (isAuthenticated.value !== AuthenticationStatus.UNAUTH) {
        return;
      }

      // In one of those routes we should not do unauth redirect. Skipping then.
      if (route.value.name != null && SKIP_AUTH_ROUTES.has(route.value.name)) {
        return;
      }

      console.info('User is unauthenticated. Doing redirect');
      location.href = to();
    },
    { immediate: true },
  );
}
