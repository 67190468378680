import { AuthStrategy } from '../types';
import BarclaysAuthRoot from './BarclaysAuthRoot.vue';
import BarclaysLogin from './BarclaysLogin.vue';

export function barclaysAuth(): AuthStrategy {
  return {
    rootComponent: BarclaysAuthRoot,
    loginComponent: BarclaysLogin,
  };
}
