import { RouteName } from '@/constants/RouteName';

// This file is similar to AnalysisStep.ts but its purpose is to be used for non analytics items in the bofa sidebar
export type NavItem = {
  name: string;
  route: string;
  disabled: boolean;
  icon: string;
};

export const Data: NavItem = {
  name: RouteName.DATA,
  route: '/discover',
  disabled: false,
  icon: 'chart-scatter',
};

export const Insights: NavItem = {
  name: RouteName.INSIGHTS,
  route: '/insights',
  disabled: false,
  icon: 'home',
};

export const Resources: NavItem = {
  name: RouteName.RESOURCES,
  route: '/resources',
  disabled: false,
  icon: 'book-open',
};

export const MyLab: NavItem = {
  name: RouteName.MY_LAB,
  route: '/my-lab',
  disabled: false,
  icon: 'file-import',
};

export const UserSettings: NavItem = {
  name: RouteName.USER_SETTING,
  route: '/my-settings',
  disabled: false,
  icon: 'gear',
};

export const AdminConsole: NavItem = {
  name: RouteName.CONSOLE,
  route: '/console',
  disabled: false,
  icon: 'user-secret',
};
