export enum PortfolioSidebarWidths {
  // (frontier): initial + frontier + simulation input / output
  EXPANDED_WITH_FOUR_COLS = '800px',

  // (non-frontier): initial + simulation input / output, OR
  // (frontier): frontier + simulation input / output
  EXPANDED_WITH_THREE_COLS = '725px',

  // (non-frontier): simulation input / output
  EXPANDED_WITH_TWO_COLS = '650px',

  // (frontier): frontier + simulation output
  COLLAPSED_WITH_TWO_COLS = '475px',

  // (non-frontier): simulation output
  COLLAPSED_WITH_ONE_COL = '375px',
  HIDDEN = '0px',

  EXPANDED_WITH_NINE_COLS = '1500px',
}

export const ApplicationSidebarWidth = '70px';

/**
 * BofA Application Sidebar Width
 * 18px Padding on the left and right
 * Length of longest title is 62.5px
 * 18px + 62.5px + 18px = 98.5px
 */
export const BofAApplicationSidebarWidth = '98.5px';

/**
 * BofA Expanded Sidebar Width
 * 18px Padding on the right
 * Longest title is 149.5px
 * 98.5px + 149.5px + 18px = 266px
 */
export const BofAExpandedSideBarWidth = '266px';

export const AdminConsoleSidebarFixedWith = '320px';

export const AdminConsoleSidebarCollapsedWidth = '24px';

export const SettingConsoleSidebarFixedWidth = '320px';

export const MyLabSidebarFixedWidth = '320px';

/**
 * BofA Sidebar Padding 18px on the left and right
 */
export const BofASidebarPadding = '18px';

/**
 * BofA Sidebar Section Title Padding 8px on the top and bottom
 */
export const BofASidebarSectionTitlePadding = '8px';

/**
 * BofA Sidebar Button Padding 16px on the top and bottom
 */
export const BofASidebarButtonPadding = '16px';
