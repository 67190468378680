import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { chain, zipObject } from 'lodash';
import { getColors, ISettingRecord } from '@/api-v2/web/settings';
import { IColors } from '@/types/setting';
import store from '@/store';
import { UserModule } from './user';
import { ApplicationDefaults } from '@/constants/ApplicationDefaults';
import { mimicLighterOpacity } from '@/utils/color';
import { PdfHeader } from '@/types/PdfHeader';

export interface ISettingsState {
  isSystemLoading: boolean;
  printTitle: string;
  colors: IColors;
  printSubtitle: string;
  printHeaderRightSideItems: string[];
  pdfSectionDividerColor: string;
  regimeName?: string;
}

export enum ScreenSizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface StyleObject {
  [key: string]: string | number | null;
}

@Module({ dynamic: true, store, name: 'Settings' })
class Settings extends VuexModule implements ISettingsState {
  public isSystemLoading = false;

  public colors: IColors = {};

  public portfolioColor = ApplicationDefaults.colors.Primary;
  public defaultChildColor = mimicLighterOpacity(ApplicationDefaults.colors.Info);

  public scrollbarWidth = 0; // px

  public screenSize: ScreenSizes = ScreenSizes.xs;

  public pin20Strategies = 20;
  public pin50Strategies = 50;
  public maxNumStrategiesPerSubportfolio = 100;

  public get screenSizeInt(): number {
    if (this.screenSize === ScreenSizes.xxl) return 6;
    if (this.screenSize === ScreenSizes.xl) return 5;
    if (this.screenSize === ScreenSizes.lg) return 4;
    if (this.screenSize === ScreenSizes.md) return 3;
    if (this.screenSize === ScreenSizes.sm) return 2;
    return 1;
  }

  @Mutation
  private SET_SCREEN_SIZE(newValue: ScreenSizes): void {
    this.screenSize = newValue;
  }
  @Action({ rawError: true })
  public async SetScreenSize(newValue: ScreenSizes): Promise<void> {
    this.SET_SCREEN_SIZE(newValue);
  }

  @Mutation
  private CHANGE_LOADING(value: boolean): void {
    this.isSystemLoading = value;
  }

  @Mutation
  private SET_COLORS(settings: ISettingRecord[]): void {
    this.colors = chain(settings)
      .groupBy((o) => o.aggregationField)
      .mapValues((o) =>
        zipObject(
          o.map((x) => x.key),
          o.map((x) => x.value),
        ),
      )
      .value();
  }

  @Mutation
  private SET_SCROLLBAR_WIDTH(newValue: number): void {
    this.scrollbarWidth = newValue;
  }

  /**
   * @deprecated
   * Follow the same way as other pages
   */
  @Action({ rawError: true })
  public async SetSystemLoading(value: boolean): Promise<void> {
    this.CHANGE_LOADING(value);
  }

  // ================== PDF  =======================
  public printTitle = '';
  public printSubtitle = '';
  public printAnalysisItemAttributes = '';
  public regimeName = '';
  public printHeaderRightSideItems: string[] = [];
  public style: { [key: string]: string } = {};

  /**
   * Mark this boolean as true in order to completely replace the PDF header,
   * as opposed to working with our default header
   */
  public shouldUseWhitelabelCustomPdfHeaderComponent = false;
  @Action({ rawError: true })
  public async ChangeShouldUseWhitelabelCustomPdfHeaderComponent(newVal: boolean): Promise<void> {
    this.CHANGE_SHOULD_USE_WHITELABEL_CUSTOM_PDF_HEADER_COMPONENT(newVal);
  }
  @Mutation
  private CHANGE_SHOULD_USE_WHITELABEL_CUSTOM_PDF_HEADER_COMPONENT(newVal: boolean): void {
    this.shouldUseWhitelabelCustomPdfHeaderComponent = newVal;
  }

  /**
   * Mark this boolean as true in order to completely replace the PDF footer,
   * as opposed to working with our default footer
   */
  public shouldUseWhitelabelCustomPdfFooterComponent = false;
  @Action({ rawError: true })
  public async ChangeShouldUseWhitelabelCustomPdfFooterComponent(newVal: boolean): Promise<void> {
    this.CHANGE_SHOULD_USE_WHITELABEL_CUSTOM_PDF_FOOTER_COMPONENT(newVal);
  }
  @Mutation
  private CHANGE_SHOULD_USE_WHITELABEL_CUSTOM_PDF_FOOTER_COMPONENT(newVal: boolean): void {
    this.shouldUseWhitelabelCustomPdfFooterComponent = newVal;
  }

  /**
   * When working with our default header, you can manipulate the style
   * of the title header with the following
   */
  public pdfHeaderTitleStyleCustom: StyleObject | null = null;
  public get pdfHeaderTitleStyle(): StyleObject | null {
    return this.pdfHeaderTitleStyleCustom;
  }
  @Action({ rawError: true })
  public async ChangePdfHeaderTitleStyleCustom(newVal: StyleObject | null): Promise<void> {
    this.CHANGE_PDF_HEADER_TITLE_STYLE_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_TITLE_STYLE_CUSTOM(newVal: StyleObject | null): void {
    this.pdfHeaderTitleStyleCustom = newVal;
  }

  /**
   * When working with our default header, you can manipulate the style
   * of the subtitle header with the following
   */
  public pdfHeaderSubtitleStyleCustom: StyleObject | null = null;
  public get pdfHeaderSubtitleStyle(): StyleObject | null {
    return this.pdfHeaderSubtitleStyleCustom;
  }
  @Action({ rawError: true })
  public async ChangePdfHeaderSubtitleStyleCustom(newVal: StyleObject | null): Promise<void> {
    this.CHANGE_PDF_HEADER_SUBTITLE_STYLE_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_SUBTITLE_STYLE_CUSTOM(newVal: StyleObject | null): void {
    this.pdfHeaderSubtitleStyleCustom = newVal;
  }

  /**
   * When working with our default header, you can manipulate the style
   * of the parent object by modifying this object.
   */
  public pdfHeaderStyleCustom: StyleObject | null = null;
  public get pdfPageHeaderStyle(): StyleObject {
    if (this.pdfHeaderStyleCustom) return this.pdfHeaderStyleCustom;

    let backgroundColor = ApplicationDefaults.pdf.navbarColor;
    if (UserModule.user && UserModule.user.navbarColor !== '') {
      backgroundColor = UserModule.user.navbarColor;
    }

    return {
      'background-color': `${backgroundColor} !important`,
    };
  }
  @Action({ rawError: true })
  public async ChangePdfHeaderStyleCustom(newVal: StyleObject): Promise<void> {
    this.CHANGE_PDF_HEADER_STYLE_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_STYLE_CUSTOM(newVal: StyleObject): void {
    this.pdfHeaderStyleCustom = newVal;
  }

  /**
   * Mark this boolean as true in order to completely replace the PDF footer,
   * as opposed to working with our default footer
   */
  public pdfHeaderImgSrc: string | null = null;
  @Action({ rawError: true })
  public async ChangePdfHeaderImgSrc(newVal: string | null): Promise<void> {
    this.CHANGE_PDF_HEADER_IMG_SRC(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_IMG_SRC(newVal: string | null): void {
    this.pdfHeaderImgSrc = newVal;
  }

  /**
   * Manipulate the style of the accent bar for our default header
   * By setting it to {} you can make it disappear completely
   */
  public pdfHeaderAccentBarStyleCustom: StyleObject | null = null;
  public get pdfPageHeaderAccentBarStyle(): StyleObject {
    if (this.pdfHeaderAccentBarStyleCustom) return this.pdfHeaderAccentBarStyleCustom;

    let color = ApplicationDefaults.pdf.navbarLineColor;
    if (UserModule.user) {
      color = UserModule.user.navbarLineColor;
    }
    return {
      'border-right': `3px solid ${color} !important`,
    };
  }
  @Action({ rawError: true })
  public async ChangePdfHeaderAccentBarStyleCustom(newVal: StyleObject): Promise<void> {
    this.CHANGE_PDF_HEADER_ACCENT_BAR_STYLE_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_ACCENT_BAR_STYLE_CUSTOM(newVal: StyleObject): void {
    this.pdfHeaderAccentBarStyleCustom = newVal;
  }

  /**
   * Change the style of the text in our default PDF header
   */
  public pdfHeaderTextStyleCustom: StyleObject | null = null;
  public get pdfPageHeaderTextStyle(): StyleObject {
    if (this.pdfHeaderTextStyleCustom) return this.pdfHeaderTextStyleCustom;

    let color = ApplicationDefaults.pdf.navbarTextColor;

    if (UserModule.user && UserModule.user.navbarTextColor !== '') {
      color = UserModule.user.navbarTextColor;
    }
    return {
      color: `${color} !important`,
    };
  }
  @Action({ rawError: true })
  public async ChangePdfHeaderTextStyleCustom(newVal: StyleObject): Promise<void> {
    this.CHANGE_PDF_HEADER_TEXT_STYLE_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_TEXT_STYLE_CUSTOM(newVal: StyleObject): void {
    this.pdfHeaderTextStyleCustom = newVal;
  }

  /**
   * This logo will be used by HSBC. It will change depending on which
   * strategy factsheet is being generated. This logic is handled in
   * the formatPdfHeaderWhitelabel function in
   * src/views/factsheets/StrategyFactsheetPdf.vue
   */
  public pdfPageHeaderCustomDynamicLogo: string | null = null;
  @Action({ rawError: true })
  public async ChangePdfHeaderCustomDynamicLogo(newVal: string): Promise<void> {
    this.CHANGE_PDF_HEADER_CUSTOM_DYNAMIC_LOGO(newVal);
  }
  @Mutation
  private CHANGE_PDF_HEADER_CUSTOM_DYNAMIC_LOGO(newVal: string): void {
    this.pdfPageHeaderCustomDynamicLogo = newVal;
  }

  public get pdfSectionDividerColor(): string {
    if (UserModule.user && UserModule.user.headerLineColor !== '') {
      return UserModule.user.headerLineColor;
    }
    return ApplicationDefaults.pdf.headerLineColor;
  }

  public get pdfSectionHeaderTextColor(): string {
    if (UserModule.user && UserModule.user.headerColor !== '') {
      return UserModule.user.headerColor;
    }
    return ApplicationDefaults.colors.Primary;
  }

  @Action({ rawError: true })
  public async ChangePrintHeader(newHeader: PdfHeader): Promise<void> {
    this.CHANGE_PRINT_HEADER(newHeader);
  }
  @Mutation
  private CHANGE_PRINT_HEADER(newHeader: PdfHeader): void {
    if (newHeader.title) this.printTitle = newHeader.title;
    if (newHeader.subtitle) this.printSubtitle = newHeader.subtitle;
    if (newHeader.analysisItemAttributes) this.printAnalysisItemAttributes = newHeader.analysisItemAttributes;
    if (newHeader.currentRegimeName) this.regimeName = newHeader.currentRegimeName;
    if (newHeader.rightSideItems) this.printHeaderRightSideItems = newHeader.rightSideItems;
  }

  /**
   * We set a manual height limit on the description field
   * because it can get so long. This allows us to make sure
   * that everything else that comes after the description
   * (i.e., the rest of the PDF) is laid out nicely.
   *
   * Because morningstar funds have more static data fields,
   * we allow more room for those and subtract 50px from
   * the height here.
   */
  public strategyFactsheetPdfDescriptionHeightLimit = 250;
  public strategyFactsheetPdfDescriptionHeightLimitCustom: number | null = null;

  @Action({ rawError: true })
  public async ChangeStrategyFactsheetDescriptionHeightLimitCustom(newVal: number | null): Promise<void> {
    this.CHANGE_STRATEGY_FACTSHEET_PDF_DESCRIPTION_HEIGHT_LIMIT_CUSTOM(newVal);
  }
  @Mutation
  private CHANGE_STRATEGY_FACTSHEET_PDF_DESCRIPTION_HEIGHT_LIMIT_CUSTOM(newVal: number | null): void {
    this.strategyFactsheetPdfDescriptionHeightLimitCustom = newVal;
  }
  // ================== PDF =======================

  @Action({ rawError: true })
  public async GetColors(): Promise<ISettingRecord[]> {
    const colors = await getColors();
    this.SET_COLORS(colors);

    return colors;
  }

  @Action({ rawError: true })
  public async SetScrollbarWidth(newValue: number): Promise<void> {
    this.SET_SCROLLBAR_WIDTH(newValue);
  }

  @Action({ rawError: true })
  public async CreatePerformanceMark(name: string): Promise<void> {
    if (performance.measure === undefined) return;
    performance.mark(name);
  }

  @Action({ rawError: true })
  public async CreatePerformanceMeasure(params: {
    measureName: string;
    mark1: string;
    mark2: string;
  }): Promise<PerformanceEntry | undefined> {
    if (performance.measure === undefined) {
      return;
    }
    performance.measure(params.measureName, params.mark1, params.mark2);
    const measure = await this.GetPerformanceItem({
      itemName: params.measureName,
      type: 'measure',
    });
    return measure;
  }

  @Action({ rawError: true })
  public async GetPerformanceItem(params: {
    itemName: string;
    type: 'mark' | 'measure';
  }): Promise<PerformanceEntry | undefined> {
    if (performance.measure === undefined) {
      return;
    }
    const measure = performance.getEntriesByName(params.itemName, params.type);

    if (measure && measure.length) return measure[0];
  }

  @Action({ rawError: true })
  public async ClearPerformanceMeasure(measureName: string): Promise<void> {
    if (performance.measure === undefined) {
      return;
    }
    performance.clearMeasures(measureName);
    performance.clearMarks(`${measureName}-start`);
    performance.clearMarks(`${measureName}-end`);
  }
}

export const SettingsModule = getModule(Settings);
