
<script setup lang="ts">
const id = "rolling-cumulative-residual"
 const title = "Rolling Cumulative Residual"
 const categories = ["FACTOR_REGRESSION"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"rolling-cumulative-residual","title":"Rolling Cumulative Residual","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"rolling-cumulative-residual","title":"Rolling Cumulative Residual","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Rolling Cumulative Residual is the Cumulative Residual for each recalibration period in a rolling regression.</p>
<p>For example, if Moving Window is Monthly, and Window is 250 days, this means that you are recalibrating the factor beta coefficients every month, and each regression is based on a period of 250 days.</p>
</div>
</template>
