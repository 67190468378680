<template>
  <StandardModal
    id="edit-portfolio-group-by-modal"
    v-model="show"
    :modal-title="translate({ path: 'MODALS.PORTFOLIO_GROUP_BY.GROUPING_LAYERS' })"
    :on-cancel="onCancel"
    :should-show-warning-confirmation-slot="shouldShowConfirmationSlot"
    :on-confirm-warning="onConfirmAction"
    :on-cancel-warning="onCancelConfirmAction"
    @show="onShow"
  >
    <div :style="`height: 130px`">
      <Draggable
        v-model="groupingModel"
        tag="div"
        :component-data="{ as: 'transition-group' }"
        :animation="100"
        class="draggable-layer"
      >
        <div
          v-for="(grouping, idx) of groupingModel"
          :key="`${grouping}-${idx}`"
          class="item-draggable cursor-move mx-5"
          :class="{
            'pl-3': idx === 1,
            'pl-4pt5': idx === 2,
            'pl-5': idx === 3,
          }"
        >
          <div class="d-flex justify-content-between align-items-center mb-2">
            <span class="d-flex">
              <div>{{ translate({ path: 'MODALS.PORTFOLIO_GROUP_BY.LAYER' }) }} {{ idx + 1 }}</div>
              <b-dropdown
                variant="blind-background"
                toggle-class="p-0 border-0 text-decoration-none"
                menu-class="scroll-sm shadow"
                :popper-opts="defaultPopperOptsDropdown"
              >
                <template #button-content>
                  <span
                    class="ml-3"
                    :class="idx % 2 ? 'text-info' : 'text-primary'"
                  >
                    {{ translate({ path: 'MODALS.PORTFOLIO_GROUP_BY.PORTFOLIO_GROUPING_CONSTANTS', item: grouping }) }}
                  </span>
                </template>
                <b-dropdown-item
                  v-for="option of groupingOptions"
                  :key="option"
                  :active="grouping === option"
                  :disabled="groupingModel.includes(option) && grouping !== option"
                  @click.exact.stop="updateGrouping(option, idx)"
                >
                  {{ translate({ path: 'MODALS.PORTFOLIO_GROUP_BY.PORTFOLIO_GROUPING_CONSTANTS', item: option }) }}
                </b-dropdown-item>
                <!-- Add custom properties layers here -->
              </b-dropdown>
            </span>
            <span
              class="ml-1 cursor-pointer"
              @click.exact="removeGrouping(idx)"
            >
              <icon
                icon="remove"
                class="small-icon text-danger px-2"
              />
            </span>
          </div>
        </div>
      </Draggable>
      <div
        v-if="groupingModel.length < MAX_NUM_GROUPINGS"
        class="mx-5"
        :class="{
          'pl-5': groupingModel.length === 1,
          'pl-6': groupingModel.length === 2,
          'pl-7': groupingModel.length === 3,
        }"
      >
        <div class="d-flex">
          <b-dropdown
            variant="blind-background"
            toggle-class="p-0 border-0 text-decoration-none"
            menu-class="scroll-sm shadow"
            class="d-flex"
            no-caret
            :disabled="groupingModel[0] === HIERARCHY_LAYER"
            :title="
              groupingModel[0] !== HIERARCHY_LAYER
                ? null
                : translate({ path: 'MODALS.PORTFOLIO_GROUP_BY.REMOVE_HIERARCHY' })
            "
            :popper-opts="defaultPopperOptsDropdown"
          >
            <template #button-content>
              <span
                class="ml-3"
                :class="groupingModel.length % 2 ? 'text-info' : 'text-primary'"
              >
                + {{ translate({ path: 'CONSTITUENT_RISK.ADD_NEW' }) }}
              </span>
            </template>
            <b-dropdown-item
              v-for="option of groupingOptions"
              :key="option"
              :disabled="groupingModel.includes(option)"
              @click.stop="addGrouping(option)"
            >
              {{ translate({ path: 'ANALYSIS_STEPS.GROUP_BY', item: option }) }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between">
        <StandardButton @click="onCancel">
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </StandardButton>
        <b-button
          variant="grey"
          size="sm"
          @click="discardDraft"
        >
          {{ translate({ path: 'GLOBAL.DISCARD_CHANGES' }) }}
        </b-button>
        <StandardButton
          variant="info"
          @click="onTryConfirm"
        >
          {{ translate({ path: 'GLOBAL.APPLY' }) }}
        </StandardButton>
      </div>
    </template>
    <template #footer-confirmation>
      <fieldset class="d-flex align-items-center">
        <i18n-t
          keypath="MODALS.PORTFOLIO_GROUP_BY.APPLY_WARNING"
          tag="div"
          class="flex-grow-1"
        />
        <StandardButton
          variant="info"
          class="mr-3"
          @click="onConfirmAction"
        >
          {{ translate({ path: 'GLOBAL.APPLY' }) }}
        </StandardButton>
        <b-button @click="onCancelConfirmAction">
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
      </fieldset>
    </template>
  </StandardModal>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import usePortfolioTree from '@/composables/usePortfolioTree';
import useTranslation from '@/composables/useTranslation';
import { ModalIdParameter, ModalEvents, PortfolioControllerEvents, AnalyticsCalculateEvent } from '@/types/Injectables';
import { Emitter } from 'mitt';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, set } from 'vue';
import Draggable from 'vuedraggable';
import {
  IPortfolioTreeSubportfolio,
  IPortfolioTreeStrategy,
  IPortfolioTreeStrategyStaticInfo,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { createRows, createSubportfolio, flatten } from '@/utils/portfolioTree';
import { PortfolioGroupingConstants } from '@/constants/PortfolioGroupingConstants';
import { notNull } from '@/utils/notnull';
import { Status } from '@/constants/Status';
import { WeightingTypeConstants } from '@/types/portfolio/AllocationWeightingConstants';
import { usePortfolioSidebar } from '@/composables/usePortfolioSidebar';
import {
  usePortfolioTreeDraft,
  usePortfolioTreeWeights,
  usePortfolioTreeWeightsQuery,
} from '@/composables/queries/usePortfolioTreeData';
import { SaveChangesStore, SaveItemUniqueKeys } from '@/store/modules/SaveChangesStore';
import { StrategyLikeObject, normalizeType } from '@/types/setting';
import useDropdown from '@/composables/useDropdown';
import { useIndexUniqueIdentifier } from '@/composables/useCorrectIdentifier';
import { useRouteRef } from '@/composables/useRouter';
import useRouteChecks from '@/composables/useRouteChecks';
import { useGetRiskDates } from '@/composables/queries/useRiskData';

const MAX_NUM_GROUPINGS = 4;

const PortfolioGroupingOptionConstantToStringConstant = {
  [PortfolioGroupingConstants.ASSET_CLASS]: 'assetClass',
  [PortfolioGroupingConstants.CURRENCY]: 'currency',
  [PortfolioGroupingConstants.FACTOR]: 'factor',
  [PortfolioGroupingConstants.RETURN_TYPE]: 'returnType',
  [PortfolioGroupingConstants.STYLE]: 'style',
  [PortfolioGroupingConstants.TYPE]: 'type',
  [PortfolioGroupingConstants.PROVIDER]: 'provider',
  [PortfolioGroupingConstants.REGION]: 'region',
} satisfies Partial<Record<PortfolioGroupingConstants, keyof IPortfolioTreeStrategyStaticInfo>>;

export default defineComponent({
  name: 'PortfolioGroupByModal',
  components: {
    Draggable,
  },
  setup() {
    const { eventBus } = useGlobalEventBus();
    const { translate } = useTranslation();
    const { masterPortfolioTree } = usePortfolioTree();
    const { sidebarRows } = usePortfolioSidebar();
    const { defaultPopperOptsDropdown } = useDropdown();

    const show = ref(false);
    const groupingModel = ref<PortfolioGroupingConstants[]>([]);

    const groupingOptions = Object.values(PortfolioGroupingConstants).filter(
      (val) => val !== PortfolioGroupingConstants.HIERARCHY,
    );

    const indexUniqueIdentifier = useIndexUniqueIdentifier();

    const route = useRouteRef();
    const { isOnConstituentRisk } = useRouteChecks(route);

    /**
     * We only call the draft below AFTER we have the risk dates, so that we can guarantee that we are using
     * the correct initial positionDate for a given risk portfolio
     */
    const { data: riskDates } = useGetRiskDates(indexUniqueIdentifier, { enabled: isOnConstituentRisk });

    /**
     * We cannot use the `usePortfolioDraftIdentifier` from `useCorrectIdentifier` here, because in the split second
     * that we try to navigate to another portfolio, an erroneous API call will go out.
     *
     * So we force the draft slug to be the correct one here
     */
    const { data: draft } = usePortfolioTreeDraft({
      slug: indexUniqueIdentifier,
      options: {
        enabled: computed(() => !isOnConstituentRisk.value || !!riskDates.value?.length),
      },
    });

    const draftWeightQuery = usePortfolioTreeWeightsQuery();
    const draftWeights = usePortfolioTreeWeights({
      portfolioTreeDraft: draft,
      query: draftWeightQuery,
      options: {
        enabled: computed(() => !!draft.value),
      },
    });

    const hasPortfolioTreeDraftBeenModified = computed(() => {
      return SaveChangesStore.itemsWithChanges[SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT];
    });

    const onShow = () => {
      if (masterPortfolioTree.value) {
        groupingModel.value = masterPortfolioTree.value.portfolioTree.groupingLevels ?? [
          PortfolioGroupingConstants.HIERARCHY,
        ];
      }
    };

    const onShouldOpenModal = ({ id }: { id: string }) => {
      if ('edit-portfolio-group-by-modal' === id) show.value = true;
    };

    const onCancel = () => {
      show.value = false;
    };

    const shouldShowConfirmationSlot = ref(false);
    /**
     * Warn the user that the grouping will overwrite their current weighting parameters
     * Show the confirmation slot
     */
    const onTryConfirm = () => {
      shouldShowConfirmationSlot.value = true;
    };

    /**
     * Confirm the changes to the grouping model
     * Maintain the suspended status and calculated weight for the strategies
     * Group the strategies based on the grouping model
     * Update the portfolio tree with the new grouping
     */
    const onConfirmAction = async () => {
      show.value = false;
      shouldShowConfirmationSlot.value = false;

      if (groupingModel.value[0] === PortfolioGroupingConstants.HIERARCHY) {
        return;
      }

      // Prior to 16 Aug 2024, this modal was using the UNMODIFIED draft to calculate the weights at this point
      // After this date, we calculate in order to generate the correct weights before performing the group by functions
      if (!draftWeights.data.value?.exposure || hasPortfolioTreeDraftBeenModified.value) {
        (eventBus as Emitter<AnalyticsCalculateEvent>).emit(PortfolioControllerEvents.ANALYTICS_CALCULATE);
      }

      if (masterPortfolioTree.value) {
        const flattenedTree = flatten(masterPortfolioTree.value.portfolioTree);

        const flattenedStrategies = flattenedTree
          .map((component) => (isPortfolioTreeStrategy(component) ? component : null))
          .filter(notNull);

        const flattenedSubportfolios = flattenedTree
          .map((component) => (isPortfolioTreeSubportfolio(component) ? component : null))
          .filter(notNull);

        for (const subportfolio of flattenedSubportfolios) {
          const isManualWeighting = [WeightingTypeConstants.MANUAL, WeightingTypeConstants.CUSTOM].includes(
            masterPortfolioTree.value.allocation[subportfolio.portfolioTreeId].weighting.type,
          );

          for (const component of subportfolio.components) {
            if (isPortfolioTreeStrategy(component)) {
              // Suspend all strategies in subportfolios that are not active
              // Ensures we keep the state of the strategies consistent
              if (subportfolio.suspended !== Status.ACTIVE) {
                component.suspended = Status.SUSPENDED;
              }

              // If the weighting is not MANUAL or CUSTOM, take the exposure from the server side
              // Ensures we maintain the calculated weightings when reordering
              if (!isManualWeighting) {
                component.weighting = draftWeights.data.value?.exposure?.[component.portfolioTreeId] ?? 0;
              }
            }
          }
        }

        const groupedStrategies = groupStrategies(flattenedStrategies);

        let components: (IPortfolioTreeSubportfolio | IPortfolioTreeStrategy)[] | undefined = [];
        if (groupingModel.value.length > 0) {
          components = createPortfolio(groupedStrategies);
        } else {
          // If no group by layers, create a flat portfolio
          components = flattenedStrategies;
        }

        if (!components) {
          return;
        }

        // Root level can only ever be MANUAL OR CUSTOM, change to MANUAL if anything else
        if (
          ![WeightingTypeConstants.MANUAL, WeightingTypeConstants.CUSTOM].includes(
            masterPortfolioTree.value.allocation[masterPortfolioTree.value.portfolioId].weighting.type,
          )
        ) {
          set(
            masterPortfolioTree.value.allocation[masterPortfolioTree.value.portfolioId].weighting,
            'type',
            WeightingTypeConstants.MANUAL,
          );
        }

        set(masterPortfolioTree.value.portfolioTree, 'components', components);
        set(masterPortfolioTree.value.portfolioTree, 'groupingLevels', groupingModel.value);

        // Update the sidebar rows
        // We need to create the rows again because the portfolio tree has changed
        const rows = masterPortfolioTree.value.portfolioTree.components.map((o) => createRows(o));
        sidebarRows.value = rows;

        eventBus.emit(PortfolioControllerEvents.SET_PORTFOLIO_TREE_HAS_CHANGES);
        SaveChangesStore.SetCustomSavingPrompt(translate({ path: 'SAVE_CHANGES_PROMPT.SUBTITLE.PORTFOLIO_GROUP_BY' }));

        (eventBus as Emitter<AnalyticsCalculateEvent>).emit(PortfolioControllerEvents.ANALYTICS_CALCULATE);
      }
    };

    const onCancelConfirmAction = () => {
      shouldShowConfirmationSlot.value = false;
      onCancel();
    };

    /**
     * Add a new grouping layer to the grouping model
     * Prevent hierarchy to be left as the first layer
     */
    const addGrouping = (option: PortfolioGroupingConstants) => {
      if (groupingModel.value[0] !== PortfolioGroupingConstants.HIERARCHY) {
        groupingModel.value = [...groupingModel.value, ...[option]];
      }
    };

    const updateGrouping = (name: PortfolioGroupingConstants, idx: number) => {
      set(groupingModel.value, idx, name);
    };

    const removeGrouping = (idx: number) => {
      groupingModel.value.splice(idx, 1);
    };

    const gridStyle = computed(() => {
      let layout = '130px 4fr minmax(28px, auto)';
      if (groupingModel.value.length > 1) {
        layout = `130px 10px 4fr minmax(28px, auto)`;
      }
      return {
        width: '100%',
        'grid-template-columns': layout,
        'column-gap': '1rem',
      };
    });

    const toTitleCase = (value: string) => {
      return value
        .replace(/_/g, ' ')
        .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
    };

    /**
     * Group the strategies based the grouping model
     * Repeat the process recursively until all groupings are applied
     */
    const groupStrategies = (flattenedStrategies: IPortfolioTreeStrategy[], index = 0): Record<string, any> => {
      // Reached the bottom level, return the strategies
      if (index >= groupingModel.value.length) {
        return flattenedStrategies;
      }

      const grouped: Record<string, any> = {};
      const groupingLayer = groupingModel.value[index];
      if (groupingLayer === PortfolioGroupingConstants.HIERARCHY) {
        return grouped;
      }

      const groupKey = PortfolioGroupingOptionConstantToStringConstant[groupingLayer];

      for (const strategy of flattenedStrategies) {
        // If the strategy does not have the grouping key, group it as 'Other'
        const value = strategy.strategy?.[groupKey] ?? 'Other';

        let formattedKey = toTitleCase(value);
        if (groupingLayer === PortfolioGroupingConstants.ASSET_CLASS) {
          formattedKey = translate({
            path: 'GLOBAL.ASSET_CLASS_CONSTANTS',
            item: value,
          });
        }

        if ([PortfolioGroupingConstants.CURRENCY, PortfolioGroupingConstants.PROVIDER].includes(groupingLayer)) {
          formattedKey = value;
        }

        if (groupingLayer === PortfolioGroupingConstants.FACTOR) {
          formattedKey = translate({
            path: 'GLOBAL.FACTOR_CONSTANTS',
            item: value,
          });
        }

        if (groupingLayer === PortfolioGroupingConstants.REGION) {
          formattedKey = translate({
            path: 'GLOBAL.REGION_CONSTANTS',
            item: value,
          });
        }

        if (groupingLayer === PortfolioGroupingConstants.RETURN_TYPE) {
          formattedKey = translate({
            path: 'GLOBAL.RETURN_TYPE_CONSTANTS',
            item: value,
          });
        }

        if (groupingLayer === PortfolioGroupingConstants.STYLE) {
          formattedKey = translate({
            path: 'GLOBAL.STYLE_CONSTANTS',
            item: value,
          });
        }

        if (groupingLayer === PortfolioGroupingConstants.TYPE && strategy.strategy) {
          const strategyLikeObject = {
            type: value,
            trackType: strategy.strategy.trackType,
            assetClass: strategy.strategy.assetClass,
          } as StrategyLikeObject;

          formattedKey = translate({
            path: 'GLOBAL.STRATEGY_TYPE_NAME',
            item: normalizeType(strategyLikeObject),
          });
        }

        if (!grouped[formattedKey]) {
          grouped[formattedKey] = [];
        }

        grouped[formattedKey].push(strategy);
      }

      // Recursively group the strategies
      for (const key in grouped) {
        grouped[key] = groupStrategies(grouped[key], index + 1);
      }

      return grouped;
    };

    /**
     * Create the portfolio tree based on the grouped strategies
     * Recursively create the subportfolios and strategies
     */
    const createPortfolio = (groupedStrategies: Record<string, any>) => {
      const components: (IPortfolioTreeStrategy | IPortfolioTreeSubportfolio)[] = [];

      for (const key in groupedStrategies) {
        const subportfolio = createSubportfolio(
          key,
          masterPortfolioTree.value,
          masterPortfolioTree.value?.portfolioTree.toCurrency,
          masterPortfolioTree.value?.portfolioTree.fxType,
          // All new subportfolios should be weighting type MANUAL, using the weighting calculated
          WeightingTypeConstants.MANUAL,
        );
        if (!subportfolio) return;

        // If the value is an array, it's a list of strategies
        if (Array.isArray(groupedStrategies[key])) {
          // Add the strategies to the subportfolio components
          subportfolio.components.push(...(groupedStrategies[key] as IPortfolioTreeStrategy[]));

          // Calculate weighting of subportfolio by summing up the weightings of the strategies
          subportfolio.weighting = (groupedStrategies[key] as IPortfolioTreeStrategy[])
            .map((strategy) => (strategy.suspended === Status.ACTIVE ? strategy.weighting ?? 0 : 0))
            .reduce((prev, next) => prev + next, 0);
        } else {
          // If the value is an object, it's a nested group
          // Recursively create the nested subportfolios
          const nestedSubportfolios = createPortfolio(groupedStrategies[key]);
          if (!nestedSubportfolios) return;

          // Add the nested subportfolios to this subportfolio components
          subportfolio.components.push(...nestedSubportfolios);

          // Calculate weighting of subportfolio by summing up the weightings of the strategies
          subportfolio.weighting = nestedSubportfolios
            .map((component) => (component.suspended === Status.ACTIVE ? component.weighting ?? 0 : 0))
            .reduce((prev, next) => prev + next, 0);
        }
        // Add the subportfolio to the parent components
        components.push(subportfolio);
      }

      return components;
    };

    const discardDraft = () => {
      eventBus.emit('discard-changes');
      show.value = false;
    };

    onMounted(() => {
      (eventBus as Emitter<ModalIdParameter>).on(ModalEvents.OPEN_MODAL, onShouldOpenModal);
    });

    onBeforeUnmount(() => {
      (eventBus as Emitter<ModalIdParameter>).off(ModalEvents.OPEN_MODAL, onShouldOpenModal);
    });

    return {
      translate,
      show,
      groupingModel,
      defaultPopperOptsDropdown,
      onCancel,
      onTryConfirm,
      onShow,
      updateGrouping,
      removeGrouping,
      addGrouping,
      MAX_NUM_GROUPINGS,
      groupingOptions,
      gridStyle,
      HIERARCHY_LAYER: PortfolioGroupingConstants.HIERARCHY,
      shouldShowConfirmationSlot,
      onConfirmAction,
      onCancelConfirmAction,
      discardDraft,
    };
  },
});
</script>
<style scoped>
.dashed-border {
  border-style: dashed;
}
</style>
