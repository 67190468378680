import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { AppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

export const newsChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.PURE_FACTORS,
    path: 'pure-factors',
    components: {
      default: () => import('@/views/resources/pure-factors.vue'),
      navbar: () => import('@/views/resources/NewsNavbar.vue'),
    },
    meta: {
      title: 'Pure Factors',
      bannerTitle: 'Pure Factor Monthly Report',
      permission: [UserPermission.UNBLOCK],
    },
  },
  {
    name: RouteName.WHITEPAPER,
    path: 'whitepaper',
    components: {
      default: () => import('@/views/resources/whitepaper.vue'),
      navbar: () => import('@/views/resources/NewsNavbar.vue'),
    },
    meta: {
      title: 'Whitepaper',
      bannerTitle: 'Premialab Advanced Factor Analytics',
      permission: [UserPermission.UNBLOCK],
    },
  },
];

export const NewsRouter: RouteConfig[] = [
  {
    name: RouteName.INSIGHTS,
    path: '/insights',
    components: {
      default: AppMain,
    },
    redirect: {
      name: newsChildrenRouter[0].name,
    },
    children: newsChildrenRouter,
  },
  {
    path: '/news/*',
    redirect: (to) => {
      const newPath = to.path.replace('/news', '/insights');
      return newPath;
    },
  },
];
