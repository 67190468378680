import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';

export const InactiveRouter: RouteConfig[] = [
  {
    name: RouteName.INACTIVE,
    path: '/inactive',
    meta: {
      title: 'Inactive',
      permission: [(perm) => !perm.has(UserPermission.UNBLOCK)],
    },
    components: {
      default: () => import('@/views/inactive.vue'),
    },
  },
];
