/**
 * No need to translate currencies
 */
export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
  CHF = 'CHF',

  // Above are G5 currencies

  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CNH = 'CNH',
  CZK = 'CZK',
  DKK = 'DKK',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PHP = 'PHP',
  PLN = 'PLN',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  TWD = 'TWD',
  ZAR = 'ZAR',
}

/**
 * Returns currency symbols (WAA-4946) given a Currency.
 *
 * Note: This is a bad idea in general, we should use the native currency formatting APIs to format our numbers.
 * @param ccy
 */
export function getCurrencySymbol(ccy: Currency = Currency.USD): string {
  switch (ccy) {
    case Currency.USD:
      return '$';
    case Currency.EUR:
      return '€';
    case Currency.JPY:
      return '¥';
    case Currency.GBP:
      return '£';
  }
  // In all other cases, just return the currency code verbatim
  return ccy as string;
}
