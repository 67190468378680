import { PluginObject } from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';
import { Locales } from './constants/Locales';
import { englishLocaleMessage } from '@/locales/loader';

export const i18nPlugin: PluginObject<void> = {
  install(vue) {
    vue.use(VueI18n, { bridge: true });

    const shouldSilenceWarnings = window.location.href.includes('/pdf') || window.location.href.includes('-pdf');

    const i18n = castToVueI18n(
      createI18n(
        {
          legacy: false,
          allowComposition: true,
          locale: Locales.ENGLISH,
          fallbackLocale: Locales.ENGLISH,
          messages: {
            [Locales.ENGLISH]: englishLocaleMessage(),
          },
          missingWarn: !shouldSilenceWarnings,
          fallbackWarn: !shouldSilenceWarnings,
          silentTranslationWarn: shouldSilenceWarnings,
        },
        VueI18n,
      ),
    );

    vue.use(i18n);
  },
};
