export enum FactorConstants {
  CARRY = 'CARRY',
  VOLATILITY = 'VOLATILITY',
  MOMENTUM = 'MOMENTUM',
  LOW_VOL = 'LOW_VOL',
  QUALITY = 'QUALITY',
  SIZE = 'SIZE',
  VALUE = 'VALUE',
  MULTI = 'MULTI',
}
