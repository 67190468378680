<template>
  <div
    :class="buttonClasses"
    @mouseenter="hoveredStep = option.step"
    @mouseleave="hoveredStep = null"
  >
    <b-button
      variant="link"
      class="px-0 w-100 text-decoration-none border-0"
      :style="{ zIndex: 10000, padding: BofASidebarButtonPadding }"
      :to="option.to || undefined"
      @click.exact="option.onClick"
      @click.ctrl.exact="option.onCtrlClick"
    >
      <span>
        <icon
          :icon="option.step.icon"
          size="2x"
          fixed-width
          :class="{
            'text-primary': option.step.route === currentStep.route,
            'text-secondary': option.step.route !== currentStep.route,
          }"
        />

        <!-- Extended sidebar -->
        <div v-show="shouldShowExtended">
          <div
            :class="extendedSidebarClasses"
            :style="{
              width: `calc(${BofAExpandedSideBarWidth} - ${BofAApplicationSidebarWidth})`,
              left: BofAApplicationSidebarWidth,
              top: '0%',
            }"
          >
            <span
              class="position-absolute"
              :style="{
                top: `calc(50% - 11.5px)`,
                left: '0px',
              }"
            >
              {{ option.nameToDisplay }}
            </span>
          </div>
        </div>
      </span>
    </b-button>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';
import { SidebarAnalyticsButton, SidebarNavButton } from '@/types/SidebarButton';
import { useApplicationSidebarHoveredStep } from '@/composables/useApplicationSidebar';
import { NavItem } from '@/types/NavItem';
import { AnalysisStep } from '@/types/analytics/AnalysisStep';
import {
  BofAApplicationSidebarWidth,
  BofAExpandedSideBarWidth,
  BofASidebarButtonPadding,
} from '@/constants/SidebarWidths';

export default defineComponent({
  name: 'SidebarButton',
  props: {
    option: {
      type: Object as PropType<SidebarAnalyticsButton | SidebarNavButton>,
      required: true,
    },
    currentStep: {
      type: Object as PropType<AnalysisStep | NavItem>,
      required: true,
    },
    shouldShowExtended: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isCurrentStep = computed(() => {
      return props.option.step.route === props.currentStep.route;
    });

    const isHovered = computed(() => {
      return hoveredStep.value === props.option.step;
    });

    const buttonClasses = computed(() => {
      return {
        'position-relative w-100': true,
        'text-primary': isCurrentStep.value && !props.shouldShowExtended,
        'disabled cursor-not-allowed': props.option.disabled,
        'cursor-pointer': !props.option.disabled,
        'bg-primary-lightest': isHovered.value || isCurrentStep.value,
      };
    });

    const extendedSidebarClasses = computed(() => {
      return {
        'h-100 position-absolute text-nowrap': true,
        'text-primary border-4x border-primary border-right': isCurrentStep.value && props.shouldShowExtended,
        'bg-primary-lightest text-primary font-weight-600': isHovered.value || isCurrentStep.value,
      };
    });

    const { hoveredStep } = useApplicationSidebarHoveredStep();

    return {
      BofASidebarButtonPadding,
      buttonClasses,
      hoveredStep,
      BofAApplicationSidebarWidth,
      BofAExpandedSideBarWidth,
      extendedSidebarClasses,
    };
  },
});
</script>
