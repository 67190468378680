<template>
  <!-- TODO(vue3): Remove the wrapping div -->
  <div>
    <slot />
    <component :is="authStrategy.rootComponent" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { authStrategy } from './index';

/**
 * Wrapper that setups the authentication.
 *
 * This should be placed as root component, above all components.
 */
export default defineComponent({
  name: 'AuthProvider',
  setup() {
    return {
      authStrategy,
    };
  },
});
</script>
