import { computed, ref } from 'vue';
import { useMobileResponsiveUtilities } from './useMobileResponsiveUtilities';
import { useRouteRef } from './useRouter';
import { NavItem } from '@/types/NavItem';
import { createGlobalState } from '@vueuse/core';

/**
 * Sets if the application sidebar is open (applies only on small screens).
 */
const applicationSidebarIsOpen = ref(false);

/**
 * Closes the application sidebar if it is open (applies only on small screens).
 *
 * Note: This function must not be placed inside `useApplicationSidebar()` because it needs to be called
 * in non setup context.
 */
export function closeApplicationSidebar() {
  applicationSidebarIsOpen.value = false;
}

export function useApplicationSidebar() {
  const route = useRouteRef();

  const { isLargeScreen } = useMobileResponsiveUtilities();

  const shouldShowApplicationSidebar = computed(() => {
    const routeRequiresShowApplicationSidebar = route.value.meta?.shouldApplicationSidebarBeShown ?? false;
    const isApplicationSidebarOpen = isLargeScreen.value || applicationSidebarIsOpen.value;
    return routeRequiresShowApplicationSidebar && isApplicationSidebarOpen;
  });

  const toggleApplicationSidebar = () => {
    applicationSidebarIsOpen.value = !applicationSidebarIsOpen.value;
  };

  return {
    shouldShowApplicationSidebar,
    toggleApplicationSidebar,
    closeApplicationSidebar,
  };
}
/**
 * Store the hovered step in the application sidebar to be used across different sidebar buttons.
 */
export const useApplicationSidebarHoveredStep = createGlobalState(() => {
  const hoveredStep = ref<NavItem | null>(null);

  return {
    hoveredStep,
  };
});
