
<script setup lang="ts">
const id = "momentum-intraday-trend"
 const title = "Momentum Intraday Trend "
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"momentum-intraday-trend","title":"Momentum Intraday Trend ","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"momentum-intraday-trend","title":"Momentum Intraday Trend ","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Intraday trend strategies aim to capture intraday momentum by being long on assets on an upward trend and short
on assets on a downward trend within a trading day.</p>
</div>
</template>
