<template>
  <div
    class="cursor-pointer svg-border svg-border-bottom"
    :style="rowStyle"
    @click="toggleItem"
  >
    <div class="align-self-center px-2">
      <b-form-radio
        v-model="selected"
        :value="true"
      />
    </div>
    <PortfolioSubtext
      v-if="'slug' in source"
      :class="subtextClasses"
      :portfolio="source"
      :link-should-wrap="false"
      :should-use-fx-currency="false"
    />
    <StrategySubtext
      v-else
      :class="subtextClasses"
      :strategy="source"
      :should-use-fx-currency="false"
      :link-should-wrap="false"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import PortfolioSubtext from '@/components/subtext/PortfolioSubtext.vue';
import StrategySubtext from '@/components/subtext/StrategySubtext.vue';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';

const { eventBus } = useGlobalEventBus();

export default defineComponent({
  name: 'AnalysisToolModalRowItem',
  components: {
    PortfolioSubtext,
    StrategySubtext,
  },
  props: {
    source: {
      type: Object as PropType<StrategyItemResponseDTO | PortfolioItemResponseDTO>,
      required: true,
    },
    rowHeight: {
      type: Number,
      required: true,
    },
    selectedIndexCode: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const getCode = (datum: StrategyItemResponseDTO | PortfolioItemResponseDTO): string => {
      if ('slug' in datum) return datum.slug;
      return datum.code;
    };

    const selected = computed(() => {
      return props.selectedIndexCode != null && props.selectedIndexCode === getCode(props.source);
    });

    const rowStyle = computed(() => {
      let columnTemplate = 'min-content 1fr';

      return {
        display: 'grid',
        'grid-template-columns': columnTemplate,
        height: `${props.rowHeight}px`,
      };
    });

    const toggleItem = () => {
      eventBus.emit('analysis-tool-modal-new-selection', props.source);
    };

    return {
      rowStyle,
      toggleItem,
      selected,
      subtextClasses: 'align-self-center overflow-hidden pr-2',
    };
  },
});
</script>
