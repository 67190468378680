<template>
  <FontAwesomeIcon
    v-bind="{ ...$props, ...$attrs }"
    :class="{
      ['d-print-none']: !print,
    }"
    :icon="iconToUse"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ApplicationDefaults } from '@/constants/ApplicationDefaults';

export default defineComponent({
  name: 'Icon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    fas: {
      type: [String, Boolean],
      required: false,
    },
    fat: {
      type: [String, Boolean],
      required: false,
    },
    size: {
      type: String as PropType<'1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x'>,
      required: false,
    },
    fixedWidth: {
      type: Boolean,
      required: false,
    },
    rotation: {
      type: Number as PropType<90 | 180 | 270>,
      required: false,
    },
    flip: {
      type: String as PropType<'horizontal' | 'vertical' | 'both'>,
      required: false,
    },
    /**
     * If true, include this icon when printing.
     *
     * By default this is false.
     */
    print: {
      type: Boolean,
      default: false,
    },
    transform: {
      type: Object as PropType<{ rotate: number }>,
      required: false,
    },
    border: {
      type: String as PropType<string | null>,
      required: false,
    },
  },
  setup(props) {
    /**
     * List of icons substring where we should use the solid variant for the
     * icon.
     */
    const solidIcons = ['caret', 'sort', 'cog', 'thumb-tack', 'plus', 'bars'];

    const variant = computed(() => {
      for (const icon of solidIcons) {
        if (props.icon.includes(icon)) return 'fas';
      }

      if (props.fas !== undefined && props.fas !== false) {
        return 'fas';
      }

      if (props.fat !== undefined && props.fat !== false) {
        return 'fat';
      }

      return ApplicationDefaults.iconSet;
    });

    const iconToUse = computed(() => `${variant.value} fa-${props.icon}`);

    return {
      iconToUse,
    };
  },
});
</script>
