import { PerformanceContributionGrouping } from '@/constants/PerformanceContributionGrouping';
import { AnalyticsGroupBy } from '@/constants/AnalyticsGroupBy';
import { CommodityFilterConstants, FilterConstants, RiskTableTabs } from '@/types/analytics/ConstituentRisk';
import { ChartTypes } from '@/types/ChartTypes';
import TranslateParams from '@/types/TranslateParams';
import { METRICS_DATABASE_NAME } from '@/utils/metrics';
import { computed } from 'vue';
import { TranslateResult } from 'vue-i18n';
import useEnv from './useEnv';
import { Composer, useI18n } from 'vue-i18n-bridge';

const { isProduction } = useEnv();

// A way to quickly see if a word has been correctly translated
const DEBUG = computed(() => !isProduction.value && false);

/**
 * Formats a string to be used in the translation .yaml files.
 * @param item item to format
 */
const formatTranslationItem = (item: string): string => {
  if (typeof item !== 'string') return '';
  const itemIsAllUppercase = item.match(/^[^a-z]*$/);

  const excludeFromCamelCaseConversion: string[] = [
    METRICS_DATABASE_NAME.AvgDD,
    METRICS_DATABASE_NAME.MaxDD,
    RiskTableTabs.DV01_TENOR,
    RiskTableTabs.CS01_TENOR,
    ChartTypes.VAR_CONTRIBUTION,
    'Fund & ETF',
  ];
  if (!itemIsAllUppercase && !excludeFromCamelCaseConversion.includes(item)) {
    // turn camel case into snake case
    // e.g., FactorDecomposition will become Factor_Decomposition
    item = item
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }

  return (
    item
      .toUpperCase()
      // 1. allow alphanumeric characters
      // 2. replace hyphens and colons with underscores
      // 3. allow underscores to pass through unharmed
      // 4. strip out all other symbols
      .replaceAll(/(?![-:_])[^a-zA-Z0-9\s]/g, '')
      .replaceAll(/[-: ]/g, '_')
  );
};

let i18nSingleton: Composer | null = null;

const assemblePath = ({ path, item }: { path: string; item?: string | null }): string => {
  if (item) return `${path}.${formatTranslationItem(item)}`;
  return path;
};

/**
 * Fetches the item from the translation file.
 * Checks if the translation is valid and if it is not, then it returns the original item.
 * The parameters are stored in the object format: {path: string; item?: string; params?: any; pluralIndex?: number; }
 * @param path Path of item in translation file
 * @param item item to translate
 * @param params the parameters for the translation string (if any are present)
 * @param pluralIndex the index to handle the case of pluralization, one based (i.e., it starts at 1)
 */
const translateFn = ({ path, item, params, pluralIndex, debug }: TranslateParams): TranslateResult => {
  if (debug) {
    console.log({ path, item, params, pluralIndex });
  }
  if (!i18nSingleton) return path;

  const pathToUse = assemblePath({ path, item });
  if (debug) {
    console.log({ pathToUse });
  }

  if (typeof pluralIndex !== 'undefined') {
    return i18nSingleton.t(pathToUse, params ?? {}, pluralIndex);
  }
  const translated = i18nSingleton.t(pathToUse, params ?? {});

  /**
   * Sometimes if we are using a variable, not every variable will be part of a plurality
   * in the .yaml file. To account for these cases, if we receive a translation that contains
   * the pipe operator, we will return the first item of the plurality
   */
  if (translated.toString().includes('|') && pluralIndex === undefined) {
    return i18nSingleton.t(pathToUse, 1, params);
  }

  return translated;
};

const translate = (params: TranslateParams): string => {
  const translation = translateFn(params);
  const result = translation.toString().includes(params.path) ? 'BAD' : 'GOOD';

  /**
   * If the result is bad but we DO have an item,
   * we return the item so we don't show an ugly path
   */
  if (result === 'BAD' && params.item) {
    return params.item;
  }

  /**
   * Append the translation result to the translation for easy debugging
   */
  if (DEBUG.value || params.debug) {
    if (result === 'BAD' || params.debug) {
      console.log(params);
    }
    return `${translation} ${result}`;
  }

  if (typeof translation === 'object' && 'item' in params && (params.item === undefined || params.item === null)) {
    if (isProduction.value) {
      return '';
    }
    return '!!!!!!! PASSING NULL OR UNDEFINED !!!!!!!';
  }

  return translation.toString();
};

/**
 * Applies the correct path for the translation
 * @param category Clean string of the category that should be translated
 * Supported properties are 'Asset Class', 'Factor', 'Region', 'Sector', and 'Type'
 *
 * @returns the translated item based on the path. Unknown categories, will return the original item.
 */
const translateUsingKnownProperty = (
  category: FilterConstants | AnalyticsGroupBy | PerformanceContributionGrouping | CommodityFilterConstants,
  item: string,
) => {
  let translatePath = null;
  if (category === FilterConstants.ASSET_CLASS) translatePath = 'GLOBAL.ASSET_CLASS_CONSTANTS';
  if (category === FilterConstants.FACTOR) translatePath = 'GLOBAL.FACTOR_CONSTANTS';
  if (category === AnalyticsGroupBy.REGION) translatePath = 'GLOBAL.REGION_CONSTANTS';
  if (category === FilterConstants.SECTOR) translatePath = 'GLOBAL.DATABASE_TRANSLATOR';
  if (category === AnalyticsGroupBy.TYPE) translatePath = 'GLOBAL.STRATEGY_TYPE_NAME';

  if (!translatePath) return item;
  return translate({ path: translatePath, item });
};

export function useStoreRootI18n() {
  const i18n = useI18n();

  i18nSingleton = i18n;
}

export default function (): {
  /**
   * path: string;
   *
   * item: In order to loop over a series of values, pass the parent path to 'path'
   * And pass the series index variable to 'item'
   *
   * params: In order to insert some text into the translation,
   * use this property. For example,
   * params: { interval: returnInterval }, then in your
   * translation, you can reference it with this syntax:
   * %{interval}
   * pluralIndex: one-based index, e.g. the first index is 1, second is 2...
   *
   * debug: pass true to this boolean to activate DEBUG mode for this translation
   */
  translate: ({ path, item, params, pluralIndex, debug }: TranslateParams) => string;
  translateUsingKnownProperty: (
    category: FilterConstants | AnalyticsGroupBy | PerformanceContributionGrouping | CommodityFilterConstants,
    item: string,
  ) => string;
} {
  return {
    translate,
    translateUsingKnownProperty,
  };
}
