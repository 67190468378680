import { registerLocale } from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { PluginObject } from 'vue';

let installed = false;

export const isoCountryPlugin: PluginObject<void> = {
  install() {
    if (installed) {
      return;
    }

    registerLocale(en);

    installed = true;
  },
};
