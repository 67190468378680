
<script setup lang="ts">
const id = "greeks"
 const title = "Greeks"
 const categories = ["EX_ANTE","GREEKS"]
 const hiddenCategories = ["RISK_METRICS"]
 const related = ["delta","gamma","rho","vega","theta"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"greeks","title":"Greeks","categories":["EX_ANTE","GREEKS"],"hiddenCategories":["RISK_METRICS"],"related":["delta","gamma","rho","vega","theta"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"greeks","title":"Greeks","categories":["EX_ANTE","GREEKS"],"hiddenCategories":["RISK_METRICS"],"related":["delta","gamma","rho","vega","theta"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Greeks represent the sensitivity of the derivative to the underlying assets and market environment. The Greeks in the risk reports are calculated by agitating the underlying measure (e.g., Spot for the calculation of Delta and Gamma, Volatility for Vega, etc).</p>
<h1>Aggregation</h1>
<p>Aggregation is calculated for risk measures that have the same units (e.g., Delta amounts of the same currency, Gamma (1%) of the same currency, etc).</p>
</div>
</template>
