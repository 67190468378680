
<script setup lang="ts">
const id = "carry-backwardation"
 const title = "Carry Backwardation"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"carry-backwardation","title":"Carry Backwardation","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"carry-backwardation","title":"Carry Backwardation","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Carry Backwardation takes advantage of demand-supply dynamics by taking long positions in more backwardated commodities and short positions in less backwardated commodities.</p>
</div>
</template>
