import { RebalancingMonthlyDailyReturnInterval } from '@/constants/RebalancingMonthlyDailyReturnInterval';
import { RebalancingMonthlyMonthlyReturnInterval } from '@/constants/RebalancingMonthlyMonthlyReturnInterval';
import { RebalancingMonthlyWeeklyReturnInterval } from '@/constants/RebalancingMonthlyWeeklyReturnInterval';
import { RebalancingWeeklyDailyReturnInterval } from '@/constants/RebalancingWeeklyDailyReturnInterval';

export enum RebalancingTypeConstants {
  NO_REBALANCING = 'No Rebalancing',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUAL = 'Semi-annual',
  ANNUAL = 'Annual',
  /**
   * Rebalancing type daily is only available for Manual weighting benchmark portfolios
   */
  DAILY = 'Daily',
}

export enum RebalancingAnnualMonths {
  JAN = 'Jan',
  FEB = 'Feb',
  MAR = 'Mar',
  APR = 'Apr',
  MAY = 'May',
  JUN = 'Jun',
  JUL = 'Jul',
  AUG = 'Aug',
  SEP = 'Sep',
  OCT = 'Oct',
  NOV = 'Nov',
  DEC = 'Dec',
}

export enum RebalancingSemiAnnualMonths {
  JAN = 'Jan - Jul',
  FEB = 'Feb - Aug',
  MAR = 'Mar - Sep',
  APR = 'Apr - Oct',
  MAY = 'May - Nov',
  JUN = 'Jun - Dec',
}

export enum RebalancingQuarterlyMonths {
  JAN = 'Jan - Apr - Jul - Oct',
  FEB = 'Feb - May - Aug - Nov',
  MAR = 'Mar - Jun - Sep - Dec',
}

export enum RebalancingMonthlyMonths {
  EVERY_MONTH = 'Every Month',
}

export type RebalancingMonthsConstants =
  | RebalancingAnnualMonths
  | RebalancingSemiAnnualMonths
  | RebalancingQuarterlyMonths
  | RebalancingMonthlyMonths
  | 'None';

export type RebalancingDateConstants =
  | RebalancingWeeklyDailyReturnInterval
  | RebalancingMonthlyDailyReturnInterval
  | RebalancingMonthlyWeeklyReturnInterval
  | RebalancingMonthlyMonthlyReturnInterval
  | 'None';
