<template>
  <b-form
    v-if="renderForm"
    class="container"
    @submit.prevent="basicLogin()"
  >
    <b-form-group
      label="Email address"
      required
    >
      <b-form-input
        v-model="form.username"
        type="text"
        name="email"
        required
      />
    </b-form-group>

    <b-form-group
      label="Password"
      required
    >
      <b-form-input
        v-model="form.password"
        type="password"
        name="password"
        required
      />
    </b-form-group>

    <b-button
      type="submit"
      variant="success"
      >Login</b-button
    >
  </b-form>
</template>

<script lang="ts">
import { AuthURL } from '@/api-v2/web/auth';
import { defineComponent } from 'vue';
import { whenUnauthenticatedOrHome } from '../shared/useIsAuthenticated';
import { useLoginStrategy } from '../shared/useLoginStrategy';
import { useBasicLogin } from '../shared/useBasicLogin';

const CONNECTION_TYPE_QS = 'connectionType';

export default defineComponent({
  name: 'BarclaysLogin',
  setup() {
    const { strategy, renderForm } = useLoginStrategy();
    const { basicLogin, form } = useBasicLogin();

    whenUnauthenticatedOrHome(() => {
      // Don't do SAML redirect if we intend to do basic login.
      if (strategy.value === 'basic') {
        return;
      }

      // Somehow the query is not shown in vue-router.
      // Fallback to get the query directly through DOM API.
      // This assumes the signed token will only appear in query when the app
      // loads up. So `router.push` with the signed token will not work.
      const query = new URLSearchParams(location.search);
      const connectionType = query.get(CONNECTION_TYPE_QS);

      const getProvider = () => {
        if (connectionType === 'ping-federate') {
          return 'ping-federate';
        }

        return 'barclays-live';
      };

      location.href = AuthURL.samlLogin(getProvider());
    });

    return {
      renderForm,
      basicLogin,
      form,
    };
  },
});
</script>
