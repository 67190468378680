import request from '@/api-v2/request';
import { IProvider } from '@/types/provider';

export const getProviders = (): Promise<IProvider[]> =>
  request({
    url: `/providers`,
    method: 'get',
  }).then(
    (o) => o.data,
    (err): void => console.error(err),
  );
