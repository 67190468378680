/**
 * Lodash's `groupBy`, but returning a map instead of object
 */
export function groupByMap<TItem, TKey>(collection: TItem[], mapper: (value: TItem) => TKey): Map<TKey, Array<TItem>> {
  const map = new Map<TKey, Array<TItem>>();

  for (const item of collection) {
    const key = mapper(item);

    let array = map.get(key);
    if (!array) {
      array = [];
      map.set(key, array);
    }

    array.push(item);
  }

  return map;
}
