<template>
  <div>
    <p><strong>HSBC QIS WEBSITE TERMS AND CONDITIONS</strong></p>
    <p>
      The information (the &ldquo;<strong>Content</strong>&rdquo;) contained in this website (the
      "<strong>Website</strong>") is provided by HSBC Bank plc and certain of its affiliates (collectively or
      individually, as the context requires, "<strong>HSBC</strong>"). Access to the Content is made available to
      persons on the basis of the self-certification made by such persons upon accessing this Website.
    </p>
    <p>
      Use of the Website is subject to these terms of use (these &ldquo;<strong>Terms of Use</strong>&rdquo;). Please
      read them carefully before using the Website. By accessing or otherwise using the Website, you agree to be bound
      by these Terms of Use. If you do not agree to these Terms of Use, do not access the Website.
    </p>
    <p>
      The Website comprises various pages operated by HSBC. Certain sections of, or pages on, the Website may contain
      separate Terms and Conditions, which are in addition to these Terms of Use. You should read those additional Terms
      and Conditions carefully. By accessing such sections or pages, you agree to be bound by those additional Terms and
      Conditions. In the event of a conflict, those additional Terms and Conditions will govern for your use of those
      sections or pages.
    </p>
    <p>
      By accessing and using the Website at any time, you acknowledge, accept and agree to be bound by all of HSBC's
      terms, conditions and privacy policies, including those set out in these Terms of Use. If you do not agree to be
      so bound, you are not authorized to access and use the Website and should exit immediately.
    </p>
    <p>
      <strong
        >Information Only / Not an Offer or Recommendation / Website and Content Do Not Constitute Investment
        Advice</strong
      >
    </p>
    <p>
      The Website and Content are provided for informational purposes only with respect to the indices discussed in the
      Website (each an "<strong>Index</strong>"). Nothing in the Website constitutes, forms part of or should be
      considered as (a) a recommendation or an offer to sell or a solicitation to deal in any financial product, enter
      into any transaction or adopt any investment strategy; or (b) legal, tax, regulatory, financial, accounting or
      investment advice.
    </p>
    <p>
      <strong
        >You acknowledge that the Website and its Content are for informational and/or educational purposes only without
        regard to any particular user's investment objectives, financial situation or means.</strong
      >
    </p>
    <p>
      <strong
        >Although the Website and Content may include material about the investment process generally, you are not being
        provided personalized investment advice through the Website, and the Website and Content does not represent that
        any particular securities are suitable for you. Your particular needs, investment objectives and financial
        situation were not taken into account in the preparation of the Website and the materials contained herein. You
        must make your own independent decisions regarding any financial instruments. You should consider whether the
        purchase or sale of any product is appropriate for you in light of your particular investment needs, objectives
        and financial circumstances.</strong
      >
    </p>
    <p>
      It is not possible to invest directly in an index. If you have any questions about any of the Content, you should
      obtain independent professional advice.
    </p>
    <p><strong>No Representations or Warranties</strong></p>
    <p>
      Neither HSBC (the "<strong>Index Sponsor</strong>"), any index administrator, which may not be an affiliate of
      HSBC (each, an &ldquo;<strong>Index Administrator</strong>&rdquo;), the entity named as acting as calculation
      agent for any Index (each, an "<strong>Index Calculation Agent</strong>"), any employee of HSBC nor any of their
      respective directors, officers, employees, representatives, delegates or agents (together with the Index Sponsor,
      each Index Calculation Agent and each employee of HSBC, each a "<strong>Relevant Person</strong>") makes any
      express or implied representations or warranties as to (i) the advisability of purchasing or entering into any
      financial product the performance of which is linked, in whole or in part, to any Index (an "<strong
        >Index Linked Product</strong
      >"), (ii) the levels of any Index at any particular time on any particular date, (iii) the results to be obtained
      by the issuer of, counterparty to or any investor in any Index Linked Product or any other person or entity, from
      the use of any Index or any data included therein for any purpose, (iv) the merchantability or fitness for a
      particular purpose of any Index, or (v) any other matter.
    </p>
    <p>
      No Index is in any way sponsored, endorsed or promoted by the issuer or sponsor, as applicable, of any of its
      constituents.
    </p>
    <p>
      The Content (including without limitation text, graphics, links, models, financial information or other items) is
      provided on an "as is" and "as available" basis. Each Relevant Person makes no representations or warranties with
      respect to the Content and, to the fullest extent permitted by applicable law, expressly disclaims any implied,
      express or statutory representations and warranties of any kind with respect thereto, including but not limited
      to, warranties as to accuracy, adequacy, timeliness, availability, currentness, reliability, completeness,
      non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from
      computer virus.
    </p>
    <p>The provisions of this section will survive termination of these Terms of Use.</p>
    <p><strong>Index Conditions</strong></p>
    <p>
      Each Index is described in full in the applicable Index Conditions which are available upon request from the Index
      Sponsor. Any decision to invest in an Index Linked Product should be based upon the information contained in the
      associated prospectus, offering or other legal document together with the applicable Index Conditions.
    </p>
    <p><strong>LIMITATIONS OF LIABILITY</strong></p>
    <p>
      EACH RELEVANT PERSON, AS WELL AS THE INFORMATION PROVIDERS, EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED
      BY APPLICABLE LAW, LIABILITY FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, SPECIAL, INCIDENTAL
      OR CONSEQUENTIAL DAMAGES), LOSSES OR EXPENSES OF ANY KIND ARISING IN CONNECTION WITH AND/OR PREDICATED ON THE
      WEBSITE, THE CONTENT (INCLUDING ANY THIRD PARTY CONTENT), ALL IMPLIED WARRANTIES OF ANY KIND (INCLUDING WARRANTIES
      OF TIMELINESS, COMPLETENESS, CURRENTNESS, RELIABILITY, STABILITY, READINESS, NON-INFRINGEMENT, VALIDITY,
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ERROR-FREE AND UNINTERRUPTED SERVICES) OR ANY LINKED SITE
      OR USE THEREOF OR INABILITY TO USE BY ANY PARTY, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR,
      OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE, EVEN
      IF SUCH RELEVANT PERSON OR ANY OF ITS REPRESENTATIVES, IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR
      EXPENSES.
    </p>
    <p>
      EACH RELEVANT PERSON, AS WELL AS THE INFORMATION PROVIDERS, DO NOT GUARANTY OR MAKE ANY REPRESENTATIONS OR
      WARRANTIES OR ASSUME ANY LIABILITY TO YOU REGARDING (I) THE USE OR THE RESULTS OF THE USE OF THE WEBSITE OR THE
      CONTENT, INCLUDING WITHOUT LIMITATION ANY FINANCIAL RESULTS BASED ON USE OF THE WEBSITE OR CONTENT OR ANY DELAY OR
      LOSS OF USE OF THE WEBSITE OR CONTENT, OR (II) EFFECTS ON OR DAMAGES TO SOFTWARE AND HARDWARE IN CONNECTION WITH
      ANY USE OF THE WEBSITE OR CONTENT.
    </p>
    <p>
      NO RELEVANT PERSON, NOR THE INFORMATION PROVIDERS, SHALL BE LIABLE FOR (I) ANY LOSSES INCURRED BY YOU OR BY ANY OF
      YOUR AGENTS, RELATED IN ANY WAY TO YOUR USE (OR THEIR USE) OF THE WEBSITE OR THE CONTENT OR (II) ANY CLAIM ARISING
      OUT OF ANY DELAYS, ERRORS, OMISSIONS OR INTERRUPTIONS IN THE DELIVERY OR TRANSMISSION OF CONTENT OR THIRD PARTY
      CONTENT AVAILABLE ON THE WEBSITE OR ANY WEBSITE OF ANY INFORMATION PROVIDER OR THIRD PARTY.
    </p>
    <p>
      ANY MATERIAL, INFORMATION OR CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS ACCESSED
      AT YOUR OWN RISK AND DISCRETION AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGES, LOSSES OR EXPENSES TO YOU,
      INCLUDING AND NOT LIMITED TO YOUR COMPUTER SYSTEM, LOSS OF DATA OR LOSS OR DAMAGE TO ANY PROPERTY OR LOSS OF DATA
      OR LOSS OF REVENUE THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL. IN ANY CASE HSBC&rsquo;S LIABILITY TO
      YOU SHALL NOT EXCEED THE FEES AND COMMISSIONS YOU PAY TO USE THEM. NO RELEVANT PERSON SHALL BE LIABLE FOR ANY HARM
      CAUSED BY THE TRANSMISSION THROUGH THE WEBSITE, OR CONTENT, OF A COMPUTER VIRUS OR OTHER COMPUTER CODE OR
      PROGRAMMING DEVICE THAT MIGHT BE USED TO ACCESS, MODIFY, DELETE, DAMAGE, CORRUPT, DEACTIVATE, DISABLE, DISRUPT, OR
      OTHERWISE IMPEDE IN ANY MANNER THE OPERATION OF THE WEBSITE OR ANY OF YOUR SOFTWARE, HARDWARE, DATA OR PROPERTY.
    </p>
    <p>
      NEITHER THE INDEX SPONSOR NOR ANY INDEX CALCULATION AGENT IS UNDER ANY OBLIGATION TO CONTINUE THE CALCULATION,
      PUBLICATION AND DISSEMINATION OF ANY INDEX NOR SHALL THEY HAVE ANY LIABILITY FOR ANY ERRORS, OMISSIONS,
      INTERRUPTIONS OR DELAYS RELATING TO ANY INDEX.
    </p>
    <p>
      EXCEPT AS OTHERWISE PROVIDED BY LAW, NO RELEVANT PERSON WILL HAVE ANY LIABILITY FOR DAMAGES, LOSSES OR EXPENSES
      CAUSED BY THE NEGLIGENCE, ACTIONS OR FAILURE TO ACT OF ANY INTERNET SERVICE PROVIDER OR ANY INFORMATION PROVIDER,
      AND TO THE EXTENT PERMITTED BY LAW, NO RELEVANT PERSON WILL BE LIABLE TO YOU FOR ANY DAMAGES, LOSSES OR EXPENSES),
      REGARDLESS OF WHETHER SUCH DAMAGES, LOSSES OR EXPENSES WERE REASONABLY FORESEEABLE, OR FOR ANY LOSS THAT RESULTS
      FROM A CAUSE OVER WHICH HSBC OR ANY OTHER SUCH ENTITY DO NOT HAVE CONTROL, INCLUDING BUT NOT LIMITED TO FAILURE OF
      ELECTRONIC OR MECHANICAL EQUIPMENT, UNAUTHORIZED ACCESS, STRIKES, FAILURES OF COMMON CARRIER OR UTILITY SYSTEMS,
      SEVERE WEATHER OR OTHER CAUSES COMMONLY KNOWN AS "ACTS OF GOD", WHETHER OR NOT SUCH CAUSE WAS REASONABLY
      FORESEEABLE.
    </p>
    <p>THE PROVISIONS OF THIS SECTION WILL SURVIVE TERMINATION OF THESE TERMS OF USE.</p>
    <p><strong>Use of Simulated Returns</strong></p>
    <p>
      Any historical performance information included in this Website prior to the launch date of the relevant Index
      represents only hypothetical historical results. No representation is being made that such Index will achieve a
      performance record similar to that shown. In fact, there may often be sharp differences between hypothetical
      performance and actual performance. Back-testing and other statistical analysis material provided to you in
      connection with the explanations of the potential performance of any Index use simulated analysis and hypothetical
      assumptions in order to illustrate the manner in which that Index may have performed in periods prior to the
      actual existence of that Index. Alternative modelling techniques or assumptions may produce different hypothetical
      information that might prove to be more appropriate and that might differ significantly from the hypothetical
      information included on this Website.
    </p>
    <p>
      The results obtained from &ldquo;back-testing&rdquo; information should not be considered indicative of actual
      results that might be obtained from an investment or participation in a financial instrument or transaction
      referencing any Index on this Website. You should not place undue reliance on the &ldquo;back-testing&rdquo;
      information, which is provided for illustrative purposes only. HSBC provides no assurance or guarantee that any
      such Index will operate or would have operated in the past in a manner consistent with the results presented on
      this Website.
    </p>
    <p><strong>Intellectual Property Rights</strong></p>
    <p>
      The Website and all of the Content is HSBC's proprietary and confidential information. The copyright in such
      material is owned by HSBC and/or its licensors. You may view the Content, download the Content to your computer or
      other electronic device (collectively, your "<strong>Computer</strong>") and print out hard copies solely for your
      own use in reviewing and evaluating the Content, provided that you agree not to remove any copyright or other
      notices contained therein. Except for the foregoing, you may not copy, reproduce, publish, disclose or otherwise
      disseminate any Content, in whole or in part, without HSBC's prior written consent.
    </p>
    <p>
      You agree that by submitting any ideas, concepts, comments, suggestions, techniques or know-how (collectively,
      "<strong>Submissions</strong>") to HSBC on or about the Website, you hereby irrevocably transfer and assign to
      HSBC any copyright or other rights you may have in the Submissions and represent and warrant that none of your
      Submissions is subject to a confidentiality obligation. You also agree that HSBC may use, copy, publicly perform,
      digitally perform, publicly display and distribute such Submissions and sell, modify, create derivative works from
      and/or to incorporate such Submission into other works in any form, medium or technology, whether now known or
      hereafter developed, in each case, for HSBC&rsquo;s own commercial benefit, without being required to compensate
      you in any way.<a
        href="#_ftn1"
        name="_ftnref1"
        >[1]</a
      >
    </p>
    <p>
      The Content is the property of HSBC or other Information Providers and is protected by applicable copyright,
      patent, trademark or other intellectual property laws. HSBC does not control the information, data, text, sound,
      photographs, graphics, video, messages and other materials available through the Website which is owned or
      generated by third parties and available through the Website ("<strong>Third Party Content</strong>") and does not
      guarantee the accuracy, integrity or quality of such Third Party Content. You understand that by using the Website
      you may be exposed to Third Party Content that is false, misleading, fraudulent, offensive, indecent and/or
      objectionable. Under no circumstances will HSBC be liable in any way for any Third Party Content, including any
      errors or omissions in any Third Party Content, or any loss or damage of any kind incurred as a result of your use
      of any Third Party Content. You are responsible for complying with all laws applicable to the Submissions you
      submit to the Website. HSBC makes no representations, warranties or guarantees whatsoever as to the accuracy,
      integrity or quality of such Third Party Content and under no circumstances will HSBC be liable in any way for any
      Third Party Content posted by HSBC on the Website, including any errors or omissions in any such Third Party
      Content, or any loss or damage of any kind incurred as a result of your use of any such Third Party Content. You
      agree that you must evaluate and bear all risks associated with the use of any Third Party Content, including any
      reliance on the content, integrity, and accuracy of such Third Party Content. You agree and acknowledge that HSBC
      is neither your investment adviser nor your fiduciary. You should not rely on any Third Party Content in any way
      when making investment decisions. Your use of any and all Third Party Content on or through the Website is subject
      to the section headed "Limitation of Liability" above. HSBC strongly recommends you read that limitation on HSBC's
      liability.
    </p>
    <p>
      Nothing in these Terms of Use grants you a license to develop, create or offer any products or services based on
      any of the Content or Third Party Content. Please see the section headed "Licensing" below for further details.
    </p>
    <p>
      Except as expressly authorized herein, you may not use, reproduce, transmit, sell, display, distribute, publish,
      broadcast, circulate, modify, edit, translate, disseminate, or commercially exploit any Content or Third Party
      Content in any manner (including electronic, print or other media now known or hereafter developed) without HSBC's
      written consent. You are not authorized to sell access to or to distribute, directly or indirectly, any such
      materials to others regardless of the method of distribution and in any manner whatsoever, including by posting
      such materials to forums, list services, newsgroups, mailing lists or electronic bulletin boards. You also agree
      not to use the Content or Third Party Content for any unlawful purpose, and you shall comply with any request by
      HSBC or any of the Information Providers to protect their respective rights in the Content or the Third Party
      Content. The provisions of this section will survive termination of these Terms of Use. Except as expressly and
      unambiguously provided herein, neither HSBC nor its licensors, suppliers or Information Providers grant you any
      express or implied rights and all rights not expressly provided by use are retained by HSBC for HSBC's benefit.
    </p>
    <p>
      You may not develop or create any product that uses, is based on, or is developed in connection with any of the
      proprietary material (including any Index) available on this Website without HSBC's prior written consent. Any
      product developed by a party other than HSBC that uses, is based on, or is developed in connection with any HSBC
      proprietary index (each, a "<strong>Third Party Product</strong>") is not sponsored, endorsed, sold or promoted by
      HSBC and HSBC makes no representation regarding the advisability of investing in any Third Party Product. HSBC
      makes no representation or warranty, express or implied, to the owners of any Third Party Products or any member
      of the public regarding the advisability of investing in securities generally or in Third Party Products
      particularly or the ability of any index to track general stock market performance. HSBC is not responsible for
      and has not participated in the determination of the timing of, prices at, or quantities of any Third Party
      Products to be issued or in the determination or calculation of the equation by which any Third Party Products are
      to be converted into cash. HSBC has no obligation or liability in connection with the administration, marketing or
      trading of any Third Party Product.
    </p>
    <p><strong>Availability of the Website and Content May Not be Continuous and Content May Change</strong></p>
    <p>
      The availability of the Website and/or the Content may be blocked, discontinued or suspended wholly or partially
      at any time either to all users or to certain persons, with or without reason and/or notice. HSBC reserves the
      right, in its sole discretion and without any notice requirement, to suspend or deny your access to the Website at
      any time. HSBC may discontinue or change any of the Content on the Website at any time.
    </p>
    <p>
      HSBC does not accept any liability for any loss or damage caused to you or any third party as a result of the
      foregoing.
    </p>
    <p><strong>Provision of Information</strong></p>
    <p>
      No liability whatsoever is accepted for any loss arising (such as loss of profit, business, data, damage to
      goodwill, trading losses and any direct or consequential loss) from any use of (or inability to use or access) the
      Content.
    </p>
    <p>
      Although all Content has been obtained from, and is based upon, sources believed to be reliable, it may be
      incomplete or condensed and its timeliness, accuracy and completeness cannot be guaranteed. HSBC reserves the
      right, in its sole discretion, without any obligation and without any notice requirement, to change, improve or
      correct any Content on the Website. HSBC may discontinue, remove or change any of the Content at any time. Any
      Content which is dated reflects the Content as of such date. Neither HSBC nor any other person or entity that
      allows HSBC to distribute its information or data (each, an "<strong>Information Provider</strong>") is under an
      obligation to update any Content to reflect circumstances that may occur after the earlier of the date first
      appearing on the Website or the date contained in the Content.
    </p>
    <p>
      Any opinion attributed to HSBC is its judgment as of the date of the relevant material and is subject to change
      without notice.
    </p>
    <p>
      Any scenario analysis is for illustrative purposes only and does not represent actual termination or unwind
      prices, neither does it present all possible outcomes or describe all factors that may affect the value of an
      investment.
    </p>
    <p>
      Any product or strategy ideas included in the Website are provided by HSBC&rsquo;s Sales and Trading department.
      Such material is therefore not a direct recommendation from HSBC&rsquo;s Research department and the directives on
      the independence of research do not apply. On the occasions where the information provided includes extracts or
      summary material derived from research reports published by HSBC's Research department, you are advised to obtain
      and review the original piece of research to see the research analyst's full analysis.
    </p>
    <p><strong>No Agency or Fiduciary Relationship</strong></p>
    <p>
      The Index Sponsor and each Index Calculation Agent shall each act as principal and not as agent or fiduciary of
      any other person.
    </p>
    <p><strong>Potential Conflicts of Interest</strong></p>
    <p>
      During the normal course of their businesses, any Relevant Person may enter into or promote, offer or sell
      transactions or investments (structured or otherwise) linked to any Index, any constituent of an Index and/or any
      other securities or instruments referred to in the Website. In addition, any Relevant Person may from time to time
      have, or may have had, long or short principal positions and/or actively trade, by making markets to HSBC&rsquo;s
      clients, positions in or economically related to any Index, any constituent of an Index and/or any other
      securities or instruments referred to in the Website or may engage in transactions with other persons, or on
      behalf of such persons, relating to any of these items. Relevant Persons may also undertake hedging transactions
      related to the initiation or termination of financial products or transactions that may adversely affect the
      market price, rate, level or other market factor(s) underlying any Index, its constituents or other securities or
      instruments referred to in the Website, and consequently affect their value. Relevant Persons may have an
      investment banking or other commercial relationship (including fee-sharing) with and access to information from
      the issuer(s) of Index constituents or any other securities or instruments referred to in the Website.
    </p>
    <p>
      Such activity may or may not have an impact on the level of any Index or any other securities or instruments
      referred to in the Website, but potential investors and counterparties should be aware that a conflict of interest
      could arise where anyone is acting in more than one capacity, and such conflict may have an impact (either
      positive or negative) on the level of any Index or any such securities or instruments.
    </p>
    <p><strong>Notional Exposures</strong></p>
    <p>
      Each Index reflects the performance of notional investment positions in its constituents. There is no actual
      portfolio of assets to which any person is entitled or in which any person has any ownership interest. Each Index
      merely identifies certain hypothetical investment positions, the performance of which will be used as a reference
      point for the purpose of calculating the level of the relevant Index.
    </p>
    <p><strong>Suitability</strong></p>
    <p>
      The Indices and Index Linked Products are not necessarily suitable for everyone. The Content is descriptive and
      general in nature. The Website does not contain information with respect to specific Index Linked Products. Any
      decision to purchase an Index Linked Product should be based upon the information contained in the relevant
      prospectus or offering document, however described, together with the Index Conditions for the relevant Index. The
      Content is therefore qualified in its entirety by such offering documents. In the case of offering documents with
      a section headed "Risk Factors", "Investment Considerations" or equivalent, please refer to that section for a
      discussion of certain factors to be considered in connection with an investment in the applicable Index Linked
      Products.
    </p>
    <p>
      Each Relevant Person makes no recommendation as to the suitability of any Index, any Index Linked Product or any
      other strategy or products described in the Website. Any trading or investment decisions you take are in reliance
      on your own analysis and judgment and/or that of your advisors and not in reliance on any Relevant Person.
      Therefore, before dealing in any financial product or entering into any transaction, you should ensure you have
      identified and understood fully any risks (direct or indirect) associated with such products or transactions as
      well as any legal, tax, accounting or other material considerations to ensure that it is appropriate for a person
      with your experience, investment goals, financial resources or any other relevant circumstance or consideration.
      Where you are acting as an adviser or agent, you should evaluate this communication in light of the circumstances
      applicable to your principal and the scope of your authority.
    </p>
    <p><strong>Forward Looking Information</strong></p>
    <p>
      The Content may contain "forward-looking" information. Such information may include, but is not limited to,
      projections, forecasts or estimates of cashflows, yields or return, scenario analyses and proposed or expected
      portfolio composition. Any forward-looking information is based upon certain assumptions about future events or
      conditions and is intended only to illustrate hypothetical results under those assumptions (not all of which are
      specified herein or can be ascertained at this time). It does not represent actual termination or unwind prices
      that may be available to you. Actual events or conditions are unlikely to be consistent with, and may differ
      significantly from, those assumed. Illustrative performance results may be based on mathematical models that
      calculate those results by using inputs that are based on assumptions about a variety of future conditions and
      events and not all relevant events or conditions may have been considered in developing such assumptions.
      Accordingly, actual results may vary and the variations may be substantial. The products or securities identified
      in any of the illustrative calculations contained in the Website may therefore not perform as described and actual
      performance may differ, and may differ substantially, from that illustrated. When evaluating any forward looking
      information you should understand the assumptions used and, together with your independent advisors, consider
      whether they are appropriate for your purposes.
    </p>
    <p><strong>Hardware and Software Necessary to Access the Website</strong></p>
    <p>
      You agree to be solely responsible for the installation, operation and maintenance of the equipment
      ("<strong>Hardware</strong>") and software ("<strong>Software</strong>") necessary to access the Website. You also
      agree to use the level of encryption security required by HSBC.
    </p>
    <p><strong>Email and Internet Communications and Personal Information</strong></p>
    <p>
      HSBC will use reasonable precautions to maintain the confidentiality of information, material and/or data
      (collectively "<strong>Data</strong>") you receive or provide, create, input or develop in connection with your
      use of the Website.<a
        href="#_ftn2"
        name="_ftnref2"
        >[2]</a
      >
      Nonetheless, you hereby acknowledge and agree that there can be no guarantee that such Data will continue to be
      confidential, including for example because Data you send and receive are provided through the internet. You
      hereby acknowledge and agree that there can be no assurance that such transmissions, or any communication through
      e-mail, will continue to be confidential. In addition, you acknowledge and agree that HSBC may disclose your name
      and other personal and financial information about you to HSBC&rsquo;s employees, representatives, officers,
      agents, and affiliates, as well as a governmental entity or self-regulatory authority, an internet service
      provider or any other third party agent or service provider: (a) for any purpose related to offering, providing,
      administering or maintaining the Website, (b) to comply with applicable rules, orders, subpoenas or other legal
      process, or (c) for any other purpose described in HSBC&rsquo;s privacy notices, online privacy statements,
      disclosures and documents regarding your privacy and the confidentiality of personal information as applicable to
      you as amended from time to time. You acknowledge having access to at least one of these documents relating to
      privacy and confidentiality and agree to be bound thereby.
    </p>
    <p>
      Communications sent over the internet cannot be guaranteed as secure. HSBC does not accept any liability to you or
      any third party for any damages or loss in connection with any communication sent by you to HSBC over the
      internet.
    </p>
    <p>Use of the Website and downloading of any Content are at your own risk.</p>
    <p>
      HSBC may monitor and record all communications (including email and telephone communications) between you and HSBC
      and such records will be HSBC&rsquo;s sole property.
    </p>
    <p>
      When you visit the Website, HSBC's server will record your IP address together with the date, time, pages visited
      and duration of your visit. Please note that HSBC uses cookies on the Website. A cookie is a small data file. Such
      cookies do not retrieve information about you stored on your hard drive and do not corrupt or damage your computer
      or computer files. You are not obliged to accept a cookie that HSBC sends to you, and you can in fact modify your
      browser so that it will not accept cookies. For more information about cookies, including how to set your internet
      browser to reject cookies, please go to&nbsp;<u>[</u>&nbsp; ].
    </p>
    <p>The provisions of this section will survive termination of these Terms of Use.</p>
    <p><strong>Links to Third Party Websites</strong></p>
    <p>
      The Website may contain links to websites controlled or offered by third parties (i.e., non-affiliates of HSBC).<a
        href="#_ftn3"
        name="_ftnref3"
        >[3]</a
      >
      They are provided merely as a convenience and without liability and you use them solely at your own risk. HSBC
      does not test, monitor, endorse, recommend, sponsor, accept any liability for, make any representations or
      warranties in respect of, or guarantee any aspect of any linked site, the site sponsor, the site contents, the
      transmission of software, downloading or uploading of content, or products or services or information available,
      on any such site. Such third parties may have a privacy policy which is different from that of HSBC and third
      party websites may provide less security than HSBC&rsquo;s websites. HSBC reserves the right, in its sole
      discretion, to discontinue links to any third party websites at any time and for any reason.
    </p>
    <p>Third parties may not link any website to the Website without HSBC's prior written consent.</p>
    <p><strong>The Website May Not Be Used for Certain Purposes</strong></p>
    <p>
      The transmission or use of any material or Content in violation of these Terms of Use, or any applicable law,
      regulation or rule (including, without limitation, any self-regulatory organization that you are a member of), or
      the rights of any third party, is prohibited. This includes, but is not limited to, copyrighted material, material
      that is defamatory, libelous, threatening, obscene or material protected by trademark, trade secret, or patent
      laws, or material that results in an invasion of privacy or publicity right.
    </p>
    <p>
      HSBC does not provide tax or legal advice. You understand and agree that all Content and any tax-related
      statements are not intended or written to be used, and cannot be used or relied upon, by any taxpayer for the
      purpose of avoiding tax penalties. Tax related statements, if any, may have been written in connection with the
      "promotion or marketing" of the transaction(s) or matters(s) addressed by such materials, to the extent allowed by
      applicable law. Any such taxpayer should seek advice based on the taxpayer's particular circumstances from an
      independent tax advisor.
    </p>
    <p>
      Unauthorized use of the Website and systems, including, but not limited to, unauthorized entry into HSBC&rsquo;s
      systems, misuse of passwords or misuse of any other information, is strictly prohibited. You may not: (i) transmit
      any viruses, worms, defects, Trojan horses or other items of a destructive nature; (ii) access, retrieve or index
      any portion of the Website for purposes of constructing or populating a database or compilation of information;
      (iii) collect or store personal data or any other information about other users of the Website in connection with
      the prohibited activities described in this paragraph, or for any other purpose other than for your own personal,
      non-commercial use; (iv) reformat, frame or place pop-up windows over any portion of the Website, or otherwise
      affect the way that the Website is displayed; (v) add or use ActiveX, Java, JavaScript, cookies, web beacons or
      other tracking technologies on the Website; (vi) create or transmit unwanted electronic communications such as
      "spam" to HSBC or to other users of the Website or otherwise interfere with other users' or members' enjoyment of
      Website; (vii) forge headers or manipulate identifiers or other data in order to disguise the origin of any
      Content or Third Party Content transmitted through the Website or to manipulate your presence on the Website;
      (viii) use the Website to violate the security of any computer network, crack passwords or security encryption
      codes, transfer or store illegal material including materials that are deemed threatening or obscene; (ix) copy or
      store any Content or Third Party Content on the Website for other than your own personal use; (x) use any device,
      software or routine that interferes with the proper working of the Website or otherwise attempt to interfere with
      the proper working of the Website; (xi) take any action that imposes, or may impose in HSBC's sole discretion, an
      unreasonable or disproportionately large load on HSBC's IT infrastructure; or (xii) use the Website, intentionally
      or unintentionally, to violate any applicable local, state, national or international law.
    </p>
    <p>The provisions of this section will survive termination of these Terms of Use.</p>
    <p><strong>Licensing</strong></p>
    <p>
      An Index Linked Product or any other product which uses in any manner the proprietary data or information
      contained in the Website, may only be developed, sponsored, originated, launched, issued or entered into with the
      prior written consent of the Index Sponsor.
    </p>
    <p>
      For information with respect to the use of any Index in connection with any Index Linked Product, including
      details of the license agreement that the provider of the Index Linked Product will be required to enter into,
      please contact the Index Sponsor at&nbsp;[&nbsp; ]&nbsp;or speak to your usual contact at HSBC.
    </p>
    <p><strong>These Terms of Use, and Access to the Website, May Be Terminated at Any Time</strong></p>
    <p>
      These Terms of Use, and your access to the Website, may be terminated without penalty by HSBC at any time and for
      any reason without prior notice to you. Certain paragraphs or sections of these Terms of Use will survive
      termination of these Terms of Use as noted in such paragraph or section.
    </p>
    <p><strong>When These Terms of Use Can Be Assigned</strong></p>
    <p>
      These Terms of Use and your rights and obligations hereunder may not be assigned by you without HSBC's written
      permission, shall inure to the benefit of HSBC's successors and assigns whether by merger, consolidation or
      otherwise, and shall be binding upon your executors, administrators, heirs, successors and permitted assigns. HSBC
      may assign these Terms of Use or any of its rights or obligations under these Terms of Use to an affiliate or to
      any successor company (whether by merger, consolidation or otherwise), or to any other person or entity at any
      time without your consent. You may not sell or distribute commercially the Content or access to the Website.
    </p>
    <p><strong>Amendment, Modification and Waiver of These Terms of Use</strong></p>
    <p>
      These Terms of Use are in addition to, and do not nullify, any other agreement, including amendments to any such
      agreement (collectively, "<strong>Client Agreement</strong>"), that you have signed or otherwise agreed to
      governing your relationship with HSBC. These Terms of Use are the entire understanding of the parties and
      supersede all previous agreements and understandings, whether written or oral, between you and HSBC concerning
      your use of the Website and the Content. Termination of these Terms of Use will not result in the termination of
      the Client Agreement, the terms of which will continue to be in full force and effect.
    </p>
    <p>
      Except as provided in these Terms of Use, no waiver, modification or amendment of any provision of these Terms of
      Use will be effective against HSBC unless the same is in writing and signed by an authorized official of HSBC.
      HSBC may modify these Terms of Use at any time, including upon prior written notice or by notice through the
      Website. You agree to review these Terms of Use each time you access the Website so that you are aware of any and
      all modifications made to these Terms of Use since your last visit. At the time of such modification, you will
      have the opportunity to reject such modification by e-mail or written notice to HSBC, which rejection shall
      constitute a termination of these Terms of Use and of your rights to access and use the Website and the Content.
      You agree that your use of the Website or the Content after the date on which such modifications are posted to the
      Website will constitute your acceptance of such modifications.
    </p>
    <p>
      Should any term or provision of these Terms of Use be held to be illegal, invalid or unenforceable by any court of
      competent jurisdiction or by a governmental agency or self-regulatory authority, or subsequently become illegal,
      invalid or unenforceable as a result of a change in applicable law, the remaining terms and provisions shall
      continue in full force and effect.
    </p>
    <p>
      HSBC's failure to insist at any time upon strict compliance with any term of these Terms of Use, or any delay or
      failure on HSBC's part to exercise any power or right given to HSBC in these Terms of Use, or a continued course
      of such conduct on HSBC's part shall at no time operate as a waiver of such power or right, nor shall any single
      or partial exercise preclude any other future exercise. All rights and remedies given to HSBC in these Terms of
      Use are cumulative and not exclusive of any other rights or remedies which HSBC otherwise has at law or equity.
    </p>
    <p><strong>Indemnification</strong></p>
    <p>
      You agree to indemnify and hold harmless all Relevant Persons, and any internet service provider and Information
      Provider, from and against any and all claims, losses, liabilities, damages, costs and expenses (including
      reasonable attorney's fees and costs) arising out of or related to your breach of your agreements, representations
      and warranties contained in these Terms of Use, or the use of the Website and the Content by you. This
      indemnification shall be binding upon you and your executors, administrators, heirs, successors and permitted
      assigns. The provisions of this section will survive termination of these Terms of Use.
    </p>
    <p><strong>Third Party Trademarks</strong></p>
    <p>
      Any names, whether or not appearing in large print, italics or with the trademark/service mark symbol are the
      trademarks and service marks of HSBC or the Information Providers, unless otherwise noted. The use or misuse of
      these trademarks/service marks or any other Content, Third Party Content or materials, except as expressly
      permitted in these Terms of Use, is expressly prohibited and may be in violation of copyright law, trademark law
      and/or other relevant laws. Please be advised that HSBC actively and aggressively enforces its intellectual
      property rights to the fullest extent of the law.
    </p>
    <p>
      You agree you will not, and will not permit any third party to obstruct, receive, modify or otherwise interfere
      with the display or delivery of advertisements on the Website.
    </p>
    <p><strong>Miscellaneous</strong></p>
    <p>
      A non-HSBC entity acts as Index Calculation Agent in respect of each Index. The Index Calculation Agents do not
      sponsor, support, sell, or promote any Index Linked Products. The Index Calculation Agents do not offer any
      express or implicit guarantee or assurance either with regard to the results of using any Index and/or index trade
      mark or the relevant level of any Index at any time or in any other respect. The Index Calculation Agents shall
      have no obligation to point out errors in any Index to third parties. Neither publication of an Index by an Index
      Calculation Agent nor the licensing of an Index or index trade mark for the purpose of use in connection with a
      financial instrument constitutes a recommendation by such Index Calculation Agent to invest capital in a financial
      instrument nor does it in any way represent an assurance or opinion of such Index Calculation Agent with regard to
      any investment in a financial instrument.
    </p>
    <p><strong>Governing Law</strong></p>
    <p>
      Your access to and use of the Website, these Terms of Use and the Disclaimer are governed by the laws of the State
      of New York without reference to principles of conflicts of law.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>2022 [HSBC Bank plc], all rights reserved.</p>
    <p>&nbsp;</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Location of this component is a bit weird. It should be inside `components` and shared across pages.
 *
 * However, due to how auth strategy folder works (sync across all branches), we are placing this component inside the
 * auth so that the auth for HSBC is self contained (does not import components that does not exist in other branches).
 *
 * In HSBC platform, this component is a public component and can be imported despite the "auth module is private" rule.
 */
export default defineComponent({
  name: 'TermsAndConditionsContent',
});
</script>
