import { Ref, computed, unref } from 'vue';
import { MaybeRef } from '@vueuse/core';
import { QueryObserverResult } from '@tanstack/vue-query';

/**
 * Returns true if all arguments are true or undefined (or: none are `false`).
 *
 * Default to return true if no value/all undefined is passed in.
 *
 * This is intended to use for `enabled` options in vue-query where we got some
 * pre-condition (eg a parameter should not be nullish) to match before fire the query.
 */
export function chainEnabled(...enables: Array<MaybeRef<boolean | undefined>>): Ref<boolean> {
  return computed(() => {
    return enables.map((item) => unref(item)).every((item) => item == null || item);
  });
}

/**
 * Accepts the result of a `useQueriesRef`, (i.e., retval where retval = useQueriesRef(...))
 * This cannot be combined with the function above because the types vary significantly
 */
export function areQueriesLoading(queries: Readonly<Ref<QueryObserverResult<unknown, unknown>[]>>): Ref<boolean> {
  return computed(() => {
    return queries.value.some((q) => q.isLoading || q.isFetching);
  });
}

/**
 * Assert given value is not nullish.
 *
 * This is used to convince typescript that the value is not nullish. Useful in
 * query function where the value will not be nullish because of enabled
 * condition.
 *
 * The name `unwrap` came from rust FYI.
 */
export function unwrap<T>(value: MaybeRef<T>, message?: string): NonNullable<T> {
  const v = unref(value);
  if (v == null) {
    throw new Error(message ?? 'Tried to unwrap a nullish value');
  }

  return v;
}
