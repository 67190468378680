
<script setup lang="ts">
const id = "funding-allocation"
 const title = "Funding Allocation"
 const categories = ["PORTFOLIO_REBALANCING"]
 const hiddenCategories = ["CUSTOM_REBALANCING","PORTFOLIO_ANALYSIS","PORTFOLIO_CONSTRUCTION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"funding-allocation","title":"Funding Allocation","categories":["PORTFOLIO_REBALANCING"],"hiddenCategories":["CUSTOM_REBALANCING","PORTFOLIO_ANALYSIS","PORTFOLIO_CONSTRUCTION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"funding-allocation","title":"Funding Allocation","categories":["PORTFOLIO_REBALANCING"],"hiddenCategories":["CUSTOM_REBALANCING","PORTFOLIO_ANALYSIS","PORTFOLIO_CONSTRUCTION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Percentage of the portfolio in cash (could be lent or borrowed). 100% implies that an amount equivalent to the portfolio value is in cash at the rebalancing date. -100% implies that an amount equivalent to the portfolio value is borrowed at the rebalancing date.</p>
</div>
</template>
