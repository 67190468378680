import { ref } from 'vue';

const isSidebarCollapsed = ref(false);
const isClientAdmin = ref(false);

function setIsClientAdmin(payload: boolean) {
  isClientAdmin.value = payload;
}

export function useAdminStore() {
  return {
    isSidebarCollapsed,
    isClientAdmin,
    setIsClientAdmin,
  };
}
