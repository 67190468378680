import request from '@/api-v2/request';
import { AttributeBLMParameter } from './types/AttributeBLMParameter';
import { BLMView } from './types/BLMView';
import { AxiosResponse } from 'axios';

export const getBlmViews = (): Promise<BLMView[]> =>
  request({
    url: `/blm/options`,
    method: 'get',
  }).then(
    (o): BLMView[] => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

/**
 * @deprecated
 * TODO: delete this after the Market Regime feature is approved for client use
 */
export const getLastUsedBlmViewForPortfolio = ({ slug }: { slug: string }): Promise<BLMView> =>
  request({
    url: `/blm/portfolios/${slug}`,
    method: 'GET',
  }).then((o): BLMView => o.data);

/**
 * @deprecated
 * TODO: delete this after the Market Regime feature is approved for client use
 */
export const setLastUsedBlmViewForPortfolio = ({
  slug,
  attributeId,
}: {
  slug: string;
  attributeId: string;
}): Promise<BLMView> =>
  request({
    url: `/blm/portfolios/${slug}`,
    method: 'PUT',
    // only one thing passed, so we use PARAMS, not DATA.
    // if we are passing a more complex object, then we use DATA
    params: {
      attributeId,
    },
  }).then(
    (o): BLMView => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

export const createBlmView = ({
  name,
  parameter,
}: {
  name: string;
  parameter: AttributeBLMParameter[];
}): Promise<BLMView> =>
  request({
    url: `/blm/options`,
    method: 'post',
    data: { name, parameter },
  }).then(
    (o): BLMView => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

export const updateBlmView = ({ attributeId, view }: { attributeId: string; view: BLMView }): Promise<BLMView> =>
  request({
    url: `/blm/options/${attributeId}`,
    method: 'PUT',
    data: { name: view.name, parameter: view.parameter },
  }).then(
    (o): BLMView => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

export const deleteBlmView = ({ attributeId }: { attributeId: string }): Promise<AxiosResponse> =>
  request({
    url: `/blm/options/${attributeId}`,
    method: 'DELETE',
  }).then(
    (o): AxiosResponse => o,
    (err) => {
      console.error(err);
      throw err;
    },
  );
