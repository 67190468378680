/* eslint-disable camelcase */
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { FactorConstants } from '@/constants/FactorConstants';
import { Status } from '@/constants/Status';
import { AllocationDTO } from './portfolio/AllocationDTO';
import { WeightingTypeConstants } from './portfolio/AllocationWeightingConstants';
import { IMetric, IStrategy } from './strategy';

export enum PortfolioType {
  PORTFOLIO = 'portfolio',
  CONSTITUENT = 'constituent',
  START_FRESH = 'start_fresh',
  EQUITY_BASKET = 'equity_basket',
}

export const PortfolioTypeName: { [key in PortfolioType]: string } = {
  [PortfolioType.PORTFOLIO]: 'Portfolio',
  [PortfolioType.CONSTITUENT]: 'Constituent',
  [PortfolioType.START_FRESH]: 'Start Fresh',
  [PortfolioType.EQUITY_BASKET]: 'Equity Basket',
};

export interface CorrelationMatrixDatum {
  value: number;
  x: string;
  y: string;
}

export interface IPortfolioTrack {
  correlation: [CorrelationMatrixDatum, CorrelationMatrixDatum][];

  incrementalReturn: {
    [code: string]: number;
  };
  incrementalVar: {
    [code: string]: number;
  };
  incrementalVolatility: {
    [code: string]: number;
  };

  monthlyRtnDates: string[];
  monthlyRtn: number[];

  yearlyRtnDates: string[];
  yearlyRtn: number[];

  timeseriesDates: string[];
  timeseriesEquityVariation: number[];
  timeseriesRollingDrawDowns: number[];

  timeseriesWeights: { [strategyCode: string]: number }[];
  timeseriesLeverage: [number, number][];

  weightAssetClass: {
    [AssetClassConstants.COMMODITY]: number;
    [AssetClassConstants.CREDIT]: number;
    [AssetClassConstants.EQUITY]: number;
    [AssetClassConstants.FIXED_INCOME]: number;
    [AssetClassConstants.FOREIGN_EXCHANGE]: number;
    [AssetClassConstants.MULTI_ASSETS]: number;
  };

  weightFactor: {
    [FactorConstants.CARRY]: number;
    [FactorConstants.LOW_VOL]: number;
    [FactorConstants.MOMENTUM]: number;
    [FactorConstants.MULTI]: number;
    [FactorConstants.QUALITY]: number;
    [FactorConstants.SIZE]: number;
    [FactorConstants.VALUE]: number;
    [FactorConstants.VOLATILITY]: number;
  };
}

/**
 * @deprecated - we should be using IPortfolioTree wherever possible now
 */
export interface IPortfolio {
  id: number;
  slug: string;
  title: string;
  weighting: WeightingTypeConstants;
  type: PortfolioType;
  userId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  inceptionDate: string | null;
  historyStartDate: string | null;
  draftOf: number | null;
  allocationOptions: AllocationDTO;
  trackCode: string | null;
  assetClass: AssetClassConstants;
  factor: string;
  strategy: {
    ids: string[];
    activeIds: number[];
    activeCodes: string[];
  };
  portfolioStrategies: IPortfolioStrategy[];
  metrics?: {
    [period: string]: IMetric;
  };
  tracks?: {
    [period: string]: IPortfolioTrack;
  };
}

export interface IPortfolioStrategy {
  strategy: IStrategy;
  suspended: Status;
  timeSeriesId: number;
  weighting: number;
}

export enum ExposureMode {
  LONG_SHORT = 'Long / Short',
  GROSS = 'Gross',
  NET = 'Net',
}

export enum MonthlyReturnDistributionMode {
  LINEAR_SCALE = 'Linear Scale',
  LOGARITHMIC_MODE = 'Logarithmic Scale',
}
