<template>
  <fieldset
    class="d-flex align-items-center"
    :disabled="loading"
  >
    <i18n-t
      keypath="MODALS.GENERAL.DELETE_PROMPT"
      tag="div"
      class="flex-grow-1"
    >
      <b>{{ portfolioName }}</b>
    </i18n-t>

    <StandardButton
      variant="danger"
      class="mr-3"
      @click="$emit('submit')"
    >
      {{ translate({ path: 'GLOBAL.DELETE' }) }}
    </StandardButton>
    <b-button @click="$emit('cancel')">{{ translate({ path: 'GLOBAL.CANCEL' }) }}</b-button>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useTranslation from '@/composables/useTranslation';

export default defineComponent({
  props: {
    portfolioName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['submit', 'cancel'],
  setup() {
    const { translate } = useTranslation();

    return {
      translate,
    };
  },
});
</script>
