<template>
  <b-popover
    v-bind="{ ...props, ...attrs }"
    :custom-class="customClass"
  >
    <template #title>
      <slot name="title" />
    </template>
    <slot />
  </b-popover>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

/**
 * For full list of props and events, please see the official docs here
 * https://bootstrap-vue.org/docs/components/popover
 *
 * Do NOT use the custom-class prop - instead supply it to the
 * custom-classes prop, and it will be added to the default ones supplied
 * to this component
 */
export default defineComponent({
  name: 'StandardPopover',
  props: {
    customClasses: {
      type: String,
      required: false,
    },
  },
  setup(props, { attrs }) {
    const customClass = computed(() => {
      return `shadow ${props.customClasses}`;
    });

    return {
      /**
       * pass all props / attrs and bind them to the original modal so that we don't have to
       * explicitly re-bind all the possible props.
       */
      props,
      attrs,
      customClass,
    };
  },
});
</script>
