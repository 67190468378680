
<script setup lang="ts">
const id = "x-var"
 const title = "Value at Risk"
 const categories = ["PERFORMANCE_METRICS","EX_ANTE","VALUE_AT_RISK"]
 const hiddenCategories = ["RISK_METRICS"]
 const related = ["var-historical","var-parametric","var-conditional","var-modified"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"x-var","title":"Value at Risk","categories":["PERFORMANCE_METRICS","EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS"],"related":["var-historical","var-parametric","var-conditional","var-modified"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"x-var","title":"Value at Risk","categories":["PERFORMANCE_METRICS","EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS"],"related":["var-historical","var-parametric","var-conditional","var-modified"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Value at Risk (VaR) represents the risk of an asset or a portfolio by the size of a potential large loss. At a chosen confidence level <span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mi>C</mi></mrow><annotation encoding="application/x-tex">C</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.6833em;"></span><span class="mord mathnormal" style="margin-right:0.07153em;">C</span></span></span></span> and time horizon, VaR estimates the threshold of maximum loss over the time span according to the confidence level. It corresponds to the <span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mo stretchy="false">(</mo><mn>1</mn><mtext>–</mtext><mi>C</mi><mo stretchy="false">)</mo></mrow><annotation encoding="application/x-tex">(1 – C)</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:1em;vertical-align:-0.25em;"></span><span class="mopen">(</span><span class="mord">1–</span><span class="mord mathnormal" style="margin-right:0.07153em;">C</span><span class="mclose">)</span></span></span></span> quantile of the loss in portfolio value.</p>
<p>For Performance Metrics, the VaR of a Portfolio or Strategy with reference over a specific time period is
computed at the 95% confidence level.</p>
</div>
</template>
