
<script setup lang="ts">
const id = "regression-principle"
 const title = "Regression Principle"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"regression-principle","title":"Regression Principle","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"regression-principle","title":"Regression Principle","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The platform’s regression uses Ordinary Least Squares (OLS) to estimate the beta coefficients of the multiple
linear regression.</p>
</div>
</template>
