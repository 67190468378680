import { Currency } from '@/constants/Currency';
import { FxConversion } from '@/constants/FxConversion';

/**
 * strategyFxConversion does NOT exist for templates
 */
export type StrategyCurrencyConversion =
  | {
      strategyFxConversion?: FxConversion;
      strategyToCurrency?: Currency;
    }
  | {
      strategyFxConversion: FxConversion;
      strategyToCurrency: Currency;
    };

export type RequiredCurrencyConversion = {
  fxType: FxConversion;
  toCurrency: Currency;
};

export type CurrencyConversion =
  | {
      fxType?: FxConversion;
      toCurrency?: Currency;
    }
  | RequiredCurrencyConversion;

export const hasCurrencyConversion = (
  itemToUse: Record<string, unknown>,
): itemToUse is Required<CurrencyConversion> => {
  return typeof itemToUse.fxType !== 'undefined' && typeof itemToUse.toCurrency !== 'undefined';
};
