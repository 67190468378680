<template>
  <!--
    Noted that the scroll-margin-top should be placed
    on the element with the ID attribute (the scroll target)
   -->
  <div
    :id="id"
    class="py-4"
    :style="{ 'scroll-margin-top': `${scrollMargin}px` }"
  >
    <GlossaryItemHeader
      :id="id"
      :shown-categories="shownCategories"
      :title="title"
      :categories="categories"
      @toggle-category="toggleCategory"
    />
    <dd class="font-weight-400 text-dark-gray">
      <component
        :is="component"
        class="glossary-content"
      />

      <template v-if="relatedItems.length > 0">
        <p class="mt-5">
          <span class="mr-3">Related Definitions:</span>
          <span
            v-for="(item, idx) of relatedItems"
            :key="item.label"
          >
            <span
              v-if="idx !== 0"
              class="mx-3 font-weight-300"
              >&nbsp;|</span
            >&nbsp;
            <b-link
              :href="item.href"
              class="text-info"
            >
              {{ item.label }}
            </b-link>
          </span>
        </p>
      </template>
    </dd>
  </div>
</template>

<script lang="ts">
import { GlossaryCategory } from '@/types/glossary';
import { computed, defineComponent, PropType } from 'vue';
import GlossaryItemHeader from './GlossaryItemHeader.vue';
import { GlossaryFrontmatter, isGlossaryCategoryArray } from './GlossaryFrontmatter';
import { useScrollToUrlHash } from '@/composables/useScrollToUrlHash';

import 'katex/dist/katex.min.css';

/**
 * Vue component wrapper for markdown glossary item.
 */
export default defineComponent({
  name: 'GlossaryItem',
  components: {
    GlossaryItemHeader,
  },
  props: {
    /**
     * ID of the glossary item
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Component to be rendered.
     *
     * Should be the default export of the markdown module.
     */
    component: {
      type: Object,
      required: true,
    },
    /**
     * Title (human-readable) of the glossary
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * List of categories this glossary belongs to.
     */
    categories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
      // Assuming this data came from markdown frontmatter, aka untyped value.
      // Adding runtime validation to make sure we are good to go
      validator: isGlossaryCategoryArray,
    },
    /**
     * List of categories this glossary belongs to, but should not be rendered on
     * the page (only use for filtering purpose).
     */
    hiddenCategories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
      validator: isGlossaryCategoryArray,
    },
    /**
     * List of related glossaries' id.
     */
    related: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },

    // Following are state related the current rendered glossary list
    /**
     * List of currently shown categories.
     *
     * This props is for display purpose only. Filtering should be done by the parent.
     */
    shownCategories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
    },
    /**
     * List of all glossaries.
     *
     * This is required for making "related" property works
     */
    glossaryMap: {
      type: Map as PropType<Map<string, GlossaryFrontmatter>>,
      required: true,
    },
  },
  emits: {
    'toggle-category': (val: GlossaryCategory) => isGlossaryCategoryArray([val]),
  },
  setup(props, { emit }) {
    const { scrollMargin } = useScrollToUrlHash();

    const relatedItems = computed(() => {
      return props.related.map((item) => {
        const match = props.glossaryMap.get(item);
        if (!match) {
          console.warn(`Unknown related glossary item ${item}`);
          return {
            href: '',
            label: item,
          };
        }

        return {
          href: `#${match.id}`,
          label: match.title,
        };
      });
    });

    const toggleCategory = (val: GlossaryCategory) => {
      emit('toggle-category', val);
    };

    return {
      scrollMargin,

      relatedItems,
      toggleCategory,
    };
  },
});
</script>

<style scoped>
.glossary-content :deep(h1) {
  margin-top: 1.5rem;
  font-weight: 400;
  font-size: 1.09375rem; /* Copied from h5 rule */
  color: var(--primary);
}

/* Block math */
.glossary-content :deep(> .katex-block) {
  color: var(--primary);
  margin: 2rem 0 !important;
}

/* Definitions */
.glossary-content :deep(dl) {
  color: var(--primary);
  font-size: 0.75rem;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2px;
  row-gap: 0.5rem;
  align-items: center;
}

.glossary-content :deep(dt) {
  font-weight: 400;
  justify-self: end;
}

.glossary-content :deep(dd) {
  margin-bottom: 0;
}

/* Ordered list */
.glossary-content :deep(ol) {
  list-style: lower-roman;
}

.glossary-content :deep(li) {
  margin-bottom: 1rem;
}
</style>
