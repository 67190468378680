import { ref } from 'vue';

const isTourActive = ref(false);
const identifierText = ref('');

function setIsTourActive(payload: boolean) {
  isTourActive.value = payload;
}

function setIdentifierText(payload: string) {
  identifierText.value = payload;
}

export function useTourStore() {
  return {
    isTourActive,
    identifierText,
    setIsTourActive,
    setIdentifierText,
  };
}
