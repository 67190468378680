import { BootstrapBreakpoints } from '@/constants/BootstrapBreakpoint';
import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

export function useMobileResponsiveUtilities() {
  const { width } = useWindowSize();
  const isSmallScreen = computed(() => width.value < BootstrapBreakpoints.LG);
  const isLargeScreen = computed(() => width.value >= BootstrapBreakpoints.LG);

  return {
    isSmallScreen,
    isLargeScreen,
    width,
  };
}
