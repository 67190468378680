import { SettingsModule } from '@/store/barrel';
import { computed, ComputedRef } from 'vue';

const scrollbarWidth = computed((): number => SettingsModule.scrollbarWidth);
const screenSizeInt = computed((): number => SettingsModule.screenSizeInt);
const screenSize = computed((): string => SettingsModule.screenSize);

export const useScreenSizes = function (): {
  scrollbarWidth: ComputedRef<number>;
  screenSizeInt: ComputedRef<number>;
  screenSize: ComputedRef<string>;
} {
  return {
    scrollbarWidth,
    screenSizeInt,
    screenSize,
  };
};
