
<script setup lang="ts">
const id = "residual-cumulative"
 const title = "Cumulative Residual"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"residual-cumulative","title":"Cumulative Residual","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"residual-cumulative","title":"Cumulative Residual","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Cumulative Residual is the difference in performance between the original track and the regression benchmark. The Cumulative Residual graph can be used to identify time periods when the original track overperforms or underperforms compared to the risk-adjusted regression benchmark.</p>
</div>
</template>
