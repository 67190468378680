import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { StyleConstants } from '@/constants/StyleConstants';
import { UniverseVisualizationType } from '@/constants/UniverseVisualizationType';
import { DiscoveryUniversePeriod } from '@/types/discover';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { ATTRIBUTE_DATABASE_NAME } from '@/types/strategy';
import { useLocalStorage } from '@vueuse/core';

type UniverseOptions = {
  visualizationType: UniverseVisualizationType;
  type: StyleConstants;
  color: ATTRIBUTE_DATABASE_NAME.AssetClass | ATTRIBUTE_DATABASE_NAME.Type | ATTRIBUTE_DATABASE_NAME.Factor;
  period: DiscoveryUniversePeriod;
};

const DEFAULT_UNIVERSE_OPTIONS: UniverseOptions = {
  visualizationType: UniverseVisualizationType.RISK_RETURN,
  type: StyleConstants.ALPHA,
  color: ATTRIBUTE_DATABASE_NAME.AssetClass,
  period: PeriodAbbrevEnum.FIVE_Y,
};

/**
 * Get or set the current universe settings.
 */
export function useUniverseOptions() {
  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyUniverse = useLocalStorage<UniverseOptions>(
    LocalStorageEntities.UNIVERSE_OPTIONS,
    DEFAULT_UNIVERSE_OPTIONS,
  );
  const universe = useLocalStorage<UniverseOptions>(LocalStorageEntities.UNIVERSE_OPTIONS_V2, legacyUniverse.value);

  return {
    universe,
  };
}
