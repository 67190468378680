import { coreAuth } from './core';
import { barclaysAuth } from './barclays';
import { bofaAuth } from './bofa';
import { AuthStrategy } from './types';
import { hsbcAuth } from './hsbc';

export { default as AuthProvider } from './AuthProvider.vue';
export { SKIP_AUTH_ROUTES } from './shared/SkipAuthRoutes';

function getAuthStrategy(): AuthStrategy {
  switch (import.meta.env.VITE_CLIENT) {
    case 'Premialab': {
      return coreAuth();
    }
    case 'HSBC': {
      return hsbcAuth();
    }
    case 'Barclays': {
      return barclaysAuth();
    }
    case 'BofA': {
      return bofaAuth();
    }
    default: {
      throw new Error(`Unknown VITE_CLIENT value ${import.meta.env.VITE_CLIENT}`);
    }
  }
}

/**
 * Current active authentication strategy.
 *
 * The actual implementation of the strategy depends on the platform's
 * requirement.
 */
export const authStrategy = getAuthStrategy();
