<template>
  <b-container
    id="login-page"
    fluid
    class="login-page bg-primary outline-aquamarine text-login-page position-relative"
  >
    <div class="h-100">
      <b-row class="h-100">
        <b-col
          id="welcome-container"
          class="d-none d-lg-flex justify-content-center align-items-start"
          lg="6"
        >
          <div class="d-flex flex-column align-items-start justify-content-center h-100">
            <h3 class="text-white welcome-text">{{ translate({ path: 'LOGIN', item: 'Welcome to' }) }}</h3>
            <h1
              class="text-white font-weight-bold w-100 text-center"
              style="font-weight: 900"
            >
              {{ translate({ path: 'LOGIN', item: 'HSBC' }) }}
            </h1>
          </div>
        </b-col>
        <b-col
          id="login-container"
          class="d-flex align-items-center justify-content-center"
        >
          <div
            id="center-circle"
            class="position-absolute d-none d-lg-block"
          >
            <div
              class="position-absolute bg-light"
              style="border-radius: 50%; width: 70px; height: 70px"
            >
              <img
                id="center-logo"
                src="@/assets/whitelabel/hsbc/hsbc-logo.svg"
                height="25"
              />
            </div>
          </div>
          <div class="login-card">
            <b-card
              bg-variant="white"
              body-class="d-flex flex-column align-items-center py-5"
              style="width: 400px"
            >
              <h3
                class="d-flex justify-content-center mt-3"
                style="font-weight: 500"
              >
                {{
                  shouldShowPasswordCard
                    ? translate({ path: 'LOGIN', item: 'Enter your password' })
                    : translate({ path: 'LOGIN', item: 'Welcome' })
                }}
              </h3>
              <div class="position-relative w-80 mt-3">
                <label class="position-absolute label-on-input-border bg-white px-1">
                  {{ translate({ path: 'LOGIN', item: 'Email address' }) }}</label
                >
                <b-form-input
                  v-model="username"
                  size="lg"
                />
              </div>
              <div
                v-if="shouldShowPasswordCard"
                class="position-relative w-80 mt-3"
              >
                <label class="position-absolute label-on-input-border bg-white px-1">
                  {{ translate({ path: 'LOGIN', item: 'Password' }) }}</label
                >
                <b-form-input
                  v-model="password"
                  type="password"
                  size="lg"
                />
              </div>
              <b-button
                block
                class="text-white w-80 mt-3"
                variant="accent"
                @click="handleIdentifierContinueClicked"
              >
                {{ translate({ path: 'LOGIN', item: 'Continue' }) }}
              </b-button>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import useTranslation from '@/composables/useTranslation';
import { useToasts } from '@/composables/useToasts';
import { useRouter } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';
import { basicLogin } from '@/api-v2/web/auth';

export default defineComponent({
  name: 'HSBCLogin',
  setup() {
    const router = useRouter();
    const { translate } = useTranslation();
    const shouldShowPasswordCard = ref(false);
    const username = ref('');
    const password = ref('');

    const { errorToast } = useToasts();

    const handleIdentifierContinueClicked = async () => {
      shouldShowPasswordCard.value = true;

      if (!username.value || !password.value) {
        return;
      }

      const adminConsoleRoute = router.resolve({
        name: RouteName.TRACK_MANAGEMENT,
      });

      try {
        await basicLogin({
          username: username.value,
          password: password.value,
        });

        // router does not work in this context, so we must use window.location
        window.location.href = adminConsoleRoute.resolved.path;
      } catch {
        errorToast(translate({ path: 'TOASTS.LOGIN_ERROR_TOAST' }));
      }
    };

    return {
      translate,
      handleIdentifierContinueClicked,
      shouldShowPasswordCard,
      username,
      password,
    };
  },
});
</script>

<style lang="scss" scoped>
#login-page {
  --navbar-height: 75px;
  --translucent: rgba(255, 255, 255, 0);
  --translucent-black: rgba(0, 0, 0, 0.5);

  margin-top: var(--navbar-height);
  overflow: hidden;
  background: url('@/assets/whitelabel/hsbc/marketspace.jpg');
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: 100vw auto;
  background-repeat: no-repeat;
}

#welcome-container,
#login-page {
  height: calc(100vh - var(--navbar-height));
  background-color: var(--translucent-black);
}

#center-circle {
  left: -50px;
  top: calc(50% - 45px);
  z-index: 100;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-width: 15px;
  border-style: solid;
  border-image: initial;

  transform: rotate(-45deg);
  border-color: var(--translucent) transparent transparent var(--translucent);
}

#center-logo {
  position: absolute;
  transform: rotate(45deg);
  top: 24px;
  left: 10px;
}

.welcome-text {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.1rem;
}

.welcome-text-premialab {
  font-size: 60px;
  font-weight: 800;
  color: white;
  letter-spacing: 0.2rem;
}

.label-on-input-border {
  top: -10px;
  left: 10px;
}
</style>
