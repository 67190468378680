import { getLiveDateHSBC } from '@/api-v2/web/settings';
import { useBrowserLocation } from '@vueuse/core';
import { PdfDownloadTypes } from '../constants/PdfDownloadTypes';
import { PdfDownloadTypesToFileName } from '../constants/PdfDownloadTypesToFileName';

export const downloadPdf = async (strategyCode: string, type: PdfDownloadTypes, locale: string) => {
  /**
   * Strategies that should show PDFs from a previous date because HSBC stopped providing us data for them
   */
  const shouldShowPreviousPDF = ['HSIEMATR', 'HSIEMALT', 'HSIEMAER'];

  /**
   * For the strategies in shouldShowPreviousPDF, we will use the date of LAST_AVAILABLE_DATE because HSBC stopped providing us data after that
   */
  const LAST_AVAILABLE_DATE = '2022-07';

  let date = null;

  // check for exception
  if (shouldShowPreviousPDF.includes(strategyCode)) date = LAST_AVAILABLE_DATE;
  // else get the date of the live pdf set by the admin console user
  else date = (await getLiveDateHSBC()).value;

  const fileName = `${strategyCode}_${PdfDownloadTypesToFileName[type]}_${date}_${locale}.pdf`;

  const location = useBrowserLocation();

  const url = `${location.value.origin}/download/${type}/${strategyCode}/${fileName}`;

  const newTab = window.open(url, '_blank');
  if (newTab) newTab.focus();
};

export const downloadPdfOnSpecificDate = async (
  strategyCode: string,
  type: PdfDownloadTypes,
  locale: string,
  date: string,
) => {
  /**
   * Strategies that should show PDFs from a previous date because HSBC stopped providing us data for them
   */
  const shouldShowPreviousPDF = ['HSIEMATR', 'HSIEMALT', 'HSIEMAER'];

  /**
   * For the strategies in shouldShowPreviousPDF, we will use the date of LAST_AVAILABLE_DATE because HSBC stopped providing us data after that
   */
  const LAST_AVAILABLE_DATE = '2022-07';

  // check for exception
  if (shouldShowPreviousPDF.includes(strategyCode)) date = LAST_AVAILABLE_DATE;

  const fileName = `${strategyCode}_${PdfDownloadTypesToFileName[type]}_${date}_${locale}.pdf`;

  const location = useBrowserLocation();

  const url = `${location.value.origin}/download/${type}/${strategyCode}/${fileName}`;

  const newTab = window.open(url, '_blank');
  if (newTab) newTab.focus();
};
