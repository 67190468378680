export enum EquityBasketStatus {
  DRAFT = 'DRAFT',
  COMPLIANT = 'COMPLIANT',
  NON_COMPLIANT = 'NON_COMPLIANT',
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ReturnType {
  PRICE_RETURN = 'Price',
  TOTAL_RETURN = 'Total Return',
  NET_TOTAL_RETURN = 'Net Return',
}

export enum DividendTreatment {
  DISTRIBUTED_AS_CASH = 'Distributed as Cash',
  REINVEST_AT_RISK_FREE_RATE = 'Reinvest at Risk Free Rate',
  REINVEST_AT_STOCKS = 'Reinvest at Dividend Stocks',
}

export enum MeasurementTypeConstants {
  UNIT = 'Unit',
  WEIGHTS = 'Weights',
}
