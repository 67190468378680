export const strategyToVideo: { [strategy: string]: string } = {
  AIMAX: `${location.origin}/download/videos/aimax.mp4`,
  AIMAX5: `${location.origin}/download/videos/aimax5.mp4`,
  AIPEX: `${location.origin}/download/videos/aipex.mp4`,
  AIPEX5: `${location.origin}/download/videos/aipex5.mp4`,
  AIPEX6: `${location.origin}/download/videos/aipex.mp4`,
  AIPEXAR: `${location.origin}/download/videos/aipexar.mp4`,
  HSIEAIGT: `${location.origin}/download/videos/hsieaigt.mp4`,
  AIGO8: `${location.origin}/download/videos/aigo8.mp4`,
  AIGO: `${location.origin}/download/videos/aigo.mp4`,
};

export const strategyToVideoTitle: { [strategy: string]: string } = {
  AIMAX: 'HSBC AI POWERED MULTI-ASSET',
  AIMAX5: 'HSBC AI POWERED MULTI-ASSET VT 5%',
  AIPEX: 'HSBC AI POWERED US EQUITY',
  AIPEX5: 'HSBC AI POWERED EQUITY VT 5%',
  AIPEX6: 'HSBC AI POWERED EQUITY VT 6%',
  AIPEXAR: 'HSBC AI POWERED US EQUITY ADJUSTED',
  HSIEAIGT: 'AI GLOBAL TACTICAL',
  AIGO: 'AI POWERED GLOBAL OPPORTUNITIES',
};

export const strategyToVideoBanner: { [strategy: string]: string } = {
  AIMAX: `${location.origin}/download/factsheet-banners/aimax.mp4`,
  AIPEX: `${location.origin}/download/factsheet-banners/aipex.mp4`,
  HSIEAIGT: `${location.origin}/download/factsheet-banners/hsieaigt.mp4`,
  AIGO: `${location.origin}/download/factsheet-banners/aigo.mp4`,
  VARCH: `${location.origin}/download/factsheet-banners/varch.mp4`,
};

export const AISignatureIndices = [
  'HSIEAIGT',
  'HSIEAIGB',
  'AIMAX',
  'AIMAX5',
  'AIPEX',
  'AIPEX5',
  'AIPEX6',
  'AIPEXAR',
  'AIPEXTR',
  'AIGO8',
  'AIGO',
];

export const MRSignatureIndices = ['VARCH'];
