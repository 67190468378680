<template>
  <div class="p-4">
    <h1>Terms and Conditions</h1>
    <br />

    <div class="bg-white font-size-big rounded scroll-lg-fixed-height mb-4">
      <TermsAndConditionsContent />
    </div>
    <div class="font-weight-bold mb-2">
      {{ iAgreeText }}
    </div>

    <div class="d-flex justify-content-end">
      <b-button
        class="px-4 mr-3"
        variant="outline-accent"
        @click="handleDeclineButtonClick"
      >
        Decline
      </b-button>
      <b-button
        class="px-4"
        variant="accent"
        @click="handleAcceptButtonClick"
      >
        Accept
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TermsAndConditionsContent from './TermsAndConditionsContent.vue';

export default defineComponent({
  name: 'DisclaimerTermsAndConditions',
  components: {
    TermsAndConditionsContent,
  },
  emits: {
    termsAgreed: (value: boolean) => typeof value === 'boolean',
    showText: (value: boolean) => typeof value === 'boolean',
  },
  setup(props, { emit }) {
    const handleAcceptButtonClick = () => {
      emit('termsAgreed', true);
    };

    const handleDeclineButtonClick = () => {
      emit('termsAgreed', false);
      emit('showText', true);
    };

    return {
      handleAcceptButtonClick,
      handleDeclineButtonClick,
      iAgreeText:
        'By clicking "Accept", I acknowledge I have read and accept the terms above and wish to continue into this site.',
      errorText: 'Please agree to the terms and conditions to proceed',
    };
  },
});
</script>
