import { ChartTypes } from '../ChartTypes';
import { AnalysisSubstep, AnalysisStep } from './AnalysisStep';

export const FACTOR_DECOMPOSITION_TABLE: AnalysisSubstep = {
  title: 'Factor Decomposition',
  path: 'regression-table',
  graphTypes: [
    ChartTypes.FACTOR_HEATMAP,
    ChartTypes.RETURN_ATTRIBUTION,
    ChartTypes.REGRESSION_TRACK,
    ChartTypes.CUMULATIVE_RESIDUAL,
  ],
};

export const FACTOR_DECOMPOSITION_ROLLING: AnalysisSubstep = {
  title: 'Rolling',
  path: 'rolling',
};

/**
 * TODO: how to handle multi names (i.e., portfolio AND strategy regressions)
 */
export const FACTOR_DECOMPOSITION: AnalysisStep = {
  name: 'Factor Decomposition',
  legacyName: 'Factor Regression',
  baseRoute: '/factor-decomposition',
  route: '/factor-decomposition/portfolio',
  secondaryRoute: '/factor-decomposition/strategy',
  disabled: false,
  substeps: [FACTOR_DECOMPOSITION_TABLE, FACTOR_DECOMPOSITION_ROLLING],
  icon: 'chart-area',
  mobileOnly: false,
  defaultSubstepPath: FACTOR_DECOMPOSITION_TABLE.path,
};
