import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';

export const TermsRouter: RouteConfig[] = [
  {
    name: RouteName.TERMS,
    path: '/terms_conditions',
    meta: {
      title: 'Terms & Conditions',
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
    },
    component: () => import('@/whitelabel/hsbc/views/footer/TermsAndConditions.vue'),
  },
];

export const PrivacyPolicyRouter: RouteConfig[] = [
  {
    name: RouteName.PRIVACY_POLICY,
    path: '/privacy_policy',
    meta: {
      title: 'Privacy Policy',
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
    },
    component: () => import('@/whitelabel/hsbc/views/footer/PrivacyPolicy.vue'),
  },
];

export const AboutRouter: RouteConfig[] = [
  {
    name: RouteName.ABOUT,
    path: '/about',
    meta: {
      title: 'About',
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
    },
    component: () => import('@/whitelabel/hsbc/views/articles/About.vue'),
  },
];
