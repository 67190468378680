import request from '@/api-v2/request';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { MorningstarDataResponseDTO } from '@/types/admin/MorningstarDataResponseDTO';
import { BatchUpdateStrategiesTeamIdsRequestDTO } from './types/BatchUpdateStrategiesTeamIdsRequestDTO';
import { ContributionSummaryDTO } from './types/ContributionSummaryDTO';
import { StrategyListItemResponseDTO } from './types/StrategyListItemResponseDTO';
import {
  TimeSeriesStaticInfoResponse,
  TimeSeriesStaticInfoOptionsResponseDTO,
  TimeSeriesStaticInfoRequest,
  TimeSeriesDetailResponseDTO,
} from './types/TimeSeriesStaticInfo';
import {
  StrategyAttachmentUploadRequestDTO,
  StrategyAttachmentTicketRequestDTO,
  StrategyAttachmentTicketResponseDTO,
} from './types/StrategyAttachment';
import { SignalListItemResponseDTO } from './types/SignalListItemResponseDTO';
import { UpdateStrategyNotesDTO } from './types/UpdateStrategyNotesDTO';

export const adminGetStrategies = (
  type: TimeSeriesEntityTypeConstants,
  signal?: AbortSignal,
): Promise<StrategyListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies`,
    method: 'GET',
    params: { type },
    signal,
  }).then((o) => o.data);

export const adminGetPrivateTracks = (teamId: number, signal?: AbortSignal): Promise<StrategyListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies/user-tracks/${teamId}`,
    method: 'GET',
    signal,
  }).then((o) => o.data);

export const adminGetSignals = (signal?: AbortSignal): Promise<SignalListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies/signals`,
    method: 'GET',
    signal,
  }).then((o) => o.data);

export const adminBatchUpdateStrategiesTeamIds = (
  body: BatchUpdateStrategiesTeamIdsRequestDTO,
  signal?: AbortSignal,
): Promise<StrategyListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies/team-ids`,
    method: 'PATCH',
    data: body,
    signal,
  }).then((o) => o.data);

export const adminGetStrategiesSearch = (
  query: string,
  exactMatch: boolean,
  signal?: AbortSignal,
): Promise<StrategyListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies/search`,
    method: 'GET',
    params: { q: query, match: exactMatch ? 'exact' : '' },
    signal,
  }).then((o) => o.data);

export const adminGetPrivateTracksSearch = (
  teamId: number,
  query: string,
  signal?: AbortSignal,
): Promise<StrategyListItemResponseDTO[]> =>
  request({
    url: `/admin/strategies/user-tracks/${teamId}/search`,
    method: 'GET',
    params: { q: query },
    signal,
  }).then((o) => o.data);

export const adminGetOptions = (): Promise<TimeSeriesStaticInfoOptionsResponseDTO> =>
  request({
    url: `/admin/strategies/options`,
    method: 'GET',
  }).then((o) => o.data);

export const adminCreateStrategy = (strategy: TimeSeriesStaticInfoRequest): Promise<TimeSeriesStaticInfoResponse> =>
  request({
    url: `/admin/strategies`,
    method: 'POST',
    data: strategy,
  }).then((res) => res.data);

export const adminGetStrategy = (code: string, signal?: AbortSignal): Promise<TimeSeriesDetailResponseDTO> =>
  request({
    url: `/admin/strategies/${code}`,
    method: 'GET',
    signal,
  }).then((o) => o.data);

export const adminGetStrategyPrice = (
  id: string,
  valueType: string | null = null,
  exchangeId: string | null = null,
  signal?: AbortSignal,
): Promise<{ date: string; value: number }[] | null> =>
  request({
    url: `/admin/strategies/${id}/price`,
    params: {
      // Do not set the query param if it is null
      valueType: valueType ?? undefined,
      exchangeId: exchangeId ?? undefined,
    },
    method: 'GET',
    signal,
    validateStatus: function (status) {
      // Do not throw when status is 404
      return (status >= 200 && status <= 399) || status === 404;
    },
  }).then((o) => {
    if (o.status === 404) {
      return null;
    }
    return o.data;
  });

export const adminGetContributionSummary = (
  id: string,
  valueType: string | null,
  exchangeId: string | null,
  signal?: AbortSignal,
): Promise<ContributionSummaryDTO[]> =>
  request({
    url: `/admin/strategies/${id}/contribution-summary`,
    params: {
      // Do not set the query param if it is null
      valueType: valueType ?? undefined,
      exchangeId: exchangeId ?? undefined,
    },
    method: 'GET',
    signal,
  }).then((o) => o.data);

export const adminTriggerStrategyDelete = (id: string): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/soft-delete`,
    method: 'POST',
  }).then((o) => o.data);

export const adminTriggerStrategyRecover = (id: string): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/recover`,
    method: 'POST',
  }).then((o) => o.data);

export const adminUpdateStrategy = (
  id: string,
  strategy: TimeSeriesStaticInfoRequest,
): Promise<TimeSeriesStaticInfoResponse> =>
  request({
    url: `/admin/strategies/${id}`,
    method: 'POST',
    data: strategy,
  }).then((o) => o.data);

export const adminUpdateStrategyNotes = (id: string, body: UpdateStrategyNotesDTO): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/update-strategy-notes`,
    method: 'POST',
    data: body,
  }).then((o) => o.data);

export const adminSyncTrackSource = (id: string, trackId: number): Promise<TimeSeriesStaticInfoResponse> =>
  request({
    url: `/admin/strategies/${id}/track-source/${trackId}`,
    method: 'POST',
    data: {},
  }).then((o) => o.data);

export const adminNewStrategyAttachmentUpload = (
  data: StrategyAttachmentTicketRequestDTO,
): Promise<StrategyAttachmentTicketResponseDTO> =>
  request({
    url: `/admin/strategies/attachment/upload`,
    method: 'POST',
    data,
  }).then((res) => res.data);

export const adminStrategyAttachmentUploadConfirm = (
  id: string,
  data: StrategyAttachmentUploadRequestDTO,
): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/attachment`,
    method: 'POST',
    data,
  }).then((res) => res.data);

export const adminStrategyUnlinkAttachmentUpload = (code: string): Promise<void> =>
  request({
    url: `/admin/strategies/${code}/unlink-attachment`,
    method: 'POST',
  }).then((res) => res.data);

export const adminGetMorningstarStaticData = (
  mstarId: string,
  signal?: AbortSignal,
): Promise<MorningstarDataResponseDTO> =>
  request({
    url: `/admin/strategies/morningstar-data/${mstarId}`,
    method: 'GET',
    signal,
  }).then((res) => res.data);

export const adminSuspendTrackSource = (id: string, trackId: number): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/track-source/${trackId}/suspend`,
    method: 'POST',
  }).then((o) => o.data);

export const adminUnsuspendTrackSource = (id: string, trackId: number): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/track-source/${trackId}/unsuspend`,
    method: 'POST',
  }).then((o) => o.data);

export const adminTriggerBuildTracks = (id: string): Promise<void> =>
  request({
    url: `/admin/strategies/${id}/build-tracks`,
    method: 'POST',
  }).then((o) => o.data);
