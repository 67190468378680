
<script setup lang="ts">
const id = "strategy-net-return"
 const title = "Net Return Strategy"
 const categories = ["STRATEGY_FILTER"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"strategy-net-return","title":"Net Return Strategy","categories":["STRATEGY_FILTER"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"strategy-net-return","title":"Net Return Strategy","categories":["STRATEGY_FILTER"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Net return refers to strategies or indices performance that are inclusive of all index fees, trading fees, and embedded costs. The exact definition may vary on the calculation methodology of the specific index provider.</p>
</div>
</template>
