import platformDefaults from '@/styles/_export.module.scss';
import { IconSet } from './IconSet';

// Splitting out colors declaration so that we can reuse it in other defaults
const colors = {
  Accent: platformDefaults.accent,
  BlmGreen: platformDefaults['blm'],
  BlmTulipInnerPetals: platformDefaults['blm-tulip-inner-petals'],
  BlmTulipOuterPetals: platformDefaults['blm-tulip-outer-petals'],
  Danger: platformDefaults.danger,
  DarkGray: platformDefaults['dark-gray'],
  Gray: platformDefaults.gray,
  Info: platformDefaults.info,
  Light: platformDefaults.light,
  LightGray: platformDefaults['light-gray'],
  LighterGray: platformDefaults['lighter-gray'],
  LightestGray: platformDefaults['lightest-gray'],
  NegativeReturn: platformDefaults['negative-return'],
  OffWhite: platformDefaults['off-white'],
  Primary: platformDefaults.primary,
  Success: platformDefaults.success,
  OutOfSample: platformDefaults['out-of-sample'],
  BodyGray: platformDefaults['body-gray'],
  Warning: platformDefaults.warning,
  ActiveReturn: platformDefaults['active-return'],
  LabDefault: platformDefaults['lab-default'],
};

export const ApplicationDefaults = {
  colors,
  pdf: {
    logoImage: '',
    navbarColor: colors.Primary,
    navbarTextColor: '#ffffff',
    navbarLineColor: colors.Info,
    headerColor: colors.Primary,
    headerLineColor: colors.Info,
    disclaimer: '',
  },
  fonts: {
    regular: platformDefaults.font.replace(/['"]+/g, ''),
    monospace: platformDefaults.monospaceFont.replace(/['"]+/g, ''),
  },
  /**
   * In pixels. Bootstrap's default corner radius is 0.25rem
   * which converts to 4px
   */
  cornerRadius: 4,
  iconSet: IconSet.REGULAR,
};
