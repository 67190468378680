import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { AppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';

export const adminChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.ACCOUNT_MANAGEMENT,
    path: 'users',
    components: {
      default: () => import('@/views/admin/users/AdminUsers.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Users',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_USER,
    path: 'users/:id',
    components: {
      default: () => import('@/views/admin/users/AdminUserEdit.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit User',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TRACK_MANAGEMENT,
    path: 'strategies',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/strategies/AdminStrategies.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Strategies',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.TRACK_SYNC_JOBS,
    path: 'strategies/jobs',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/jobs/AdminTrackSyncJobs.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track Sync Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.CREATE_STRATEGY,
    path: 'strategies/new',
    components: {
      default: () => import('@/views/admin/strategies/AdminStrategyCreate.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_STRATEGY,
    path: 'strategies/:id',
    components: {
      default: () => import('@/whitelabel/hsbc/views/admin/strategies/AdminStrategyEdit.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Strategy',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PRIVATE_TRACKS,
    path: 'private-tracks',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/strategies/AdminTrackManagement.vue'),
      default: () => import('@/views/admin/private-tracks/AdminPrivateTracks.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.VIEW_PRIVATE_TRACK,
    path: 'private-tracks/:id',
    components: {
      default: () => import('@/views/admin/private-tracks/AdminPrivateTrackView.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Private Tracks',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.TEAM_MANAGEMENT,
    path: 'teams',
    components: {
      default: () => import('@/views/admin/team/AdminTeams.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Teams',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EDIT_TEAM,
    path: 'teams/:id',
    components: {
      default: () => import('@/views/admin/team/AdminTeamEdit.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Team',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_MANAGEMENT,
    path: 'constituents',
    components: {
      default: () => import('@/views/admin/constituents/index/AdminConstituents.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_CONSTITUENT,
    path: 'constituents/:id',
    components: {
      default: () => import('@/views/admin/constituents/overview/AdminConstituentInspect.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITIONS,
    path: 'constituents/:id/position',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Positions',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_POSITION,
    path: 'constituents/:id/position/:date',
    components: {
      default: () => import('@/views/admin/constituents/position/AdminConstituentInspectPosition.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Constituent Position',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.RISK_MANAGEMENT,
    path: 'risk',
    components: {
      default: () => import('@/views/admin/risk/AdminRisk.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Risk',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.API_KEYS,
    path: 'keys',
    components: {
      default: () => import('@/views/admin/api-key/AdminApiKey.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | API Keys',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.SETTINGS,
    path: 'settings',
    components: {
      default: () => import('@/views/admin/setting/AdminSetting.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Settings',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfig.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Config',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_BACKFILL,
    path: 'perf-contribution/backfill',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionBackfill.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Backfill',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_GENERATED_DATA,
    path: 'perf-contribution/generated-data',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionGeneratedData.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Generated Data',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.PEFORMANCE_CONTRIBUTION_JOB_STATUS,
    path: 'perf-contribution/job-status',
    components: {
      'admin-console-toolbar': () => import('@/views/admin/perf-contribution/AdminPerfContributionToolbar.vue'),
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionJobStatus.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Perf Contribution | Job Status',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
      hasAdminConsoleToolbar: true,
    },
  },
  {
    name: RouteName.EDIT_PERFORMANCE_CONTRIBUTION,
    path: 'perf-contribution/:id',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Edit Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CREATE_PERFORMANCE_CONTRIBUTION_CONFIG,
    path: 'perf-contribution-create',
    components: {
      default: () => import('@/views/admin/perf-contribution/AdminPerfContributionConfigForm.vue'),
      sidebar: () => import('@/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Create Perf Contribution',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.PLATFORM_HEALTH,
    path: 'platform-health',
    components: {
      default: () => import('@/views/admin/platform-health/AdminPlatformHealth.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Platform Health',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.SUBSCRIPTIONS,
    path: 'subscriptions',
    components: {
      default: () => import('@/views/admin/subscriptions/AdminSubscriptions.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Subscriptions',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CUSTOM_REPORT,
    path: 'custom-report',
    components: {
      default: () => import('@/views/admin/custom-report/AdminCustomReport.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Custom Report Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.QA,
    path: 'qa',
    components: {
      default: () => import('@/views/admin/qa/AdminQA.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | QA',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_TRACK_QA,
    path: 'qa/:workflowId/:runId/track',
    components: {
      default: () => import('@/views/admin/qa/AdminTrackQAInspect.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Track QA',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.REPORT_FIELDS,
    path: 'report-fields',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFields.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Fields',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.VIEW_REPORT_FIELD,
    path: 'report-fields/:fieldId',
    components: {
      default: () => import('@/views/admin/report-fields/AdminReportFieldInspect.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Report Field',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.EXCEL_JOBS,
    path: 'jobs/excel',
    components: {
      default: () => import('@/views/admin/jobs/AdminExcelJobs.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Excel Jobs',
      navbar: true,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.CONTENT_MANAGEMENT,
    path: 'articles',
    components: {
      default: () => import('@/views/admin/articles/AdminContentManagement.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Content Management',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },

  {
    name: RouteName.PDF_MANAGEMENT_ADMIN,
    path: 'pdf-management',
    components: {
      default: () => import('@/whitelabel/hsbc/views/admin/strategies/AdminStrategiesPDF.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | PDF Management',
      permission: [UserPermission.ADMIN],
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
      isMainContentContainerFluid: true,
    },
  },
  {
    name: RouteName.PLATFORM_ANALYTICS,
    path: 'platform-analytics',
    components: {
      default: () => import('@/views/admin/AdminPlatformAnalytics.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Platform Analytics',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
  {
    name: RouteName.MIGRATIONS_ADMIN,
    path: 'migrations',
    components: {
      default: () => import('@/views/admin/migrations/AdminMigrations.vue'),
      sidebar: () => import('@/whitelabel/hsbc/views/admin/AdminSideBar.vue'),
    },
    meta: {
      title: 'Admin | Migrations',
      navbar: false,
      shouldAdminConsoleSidebarBeShown: true,
    },
  },
];

export const AdminRouter: RouteConfig[] = [
  {
    path: '/console',
    name: RouteName.CONSOLE,
    components: {
      default: AppMain,
    },
    redirect: { name: RouteName.TRACK_MANAGEMENT },
    meta: {
      title: 'Admin',
      permission: [UserPermission.ADMIN],
    },
    children: adminChildrenRouter,
  },
];
