import { ClientConstants } from '@/constants/ClientConstants';
import { computed, ComputedRef, Ref, ref } from 'vue';

const shouldEmulateProduction = ref(false);
const isActuallyProduction = import.meta.env.VITE_MODE === 'production';
const isProduction = computed(() => isActuallyProduction || shouldEmulateProduction.value);
const isLocal = window.location.href.includes('localhost');
const isHSBCEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.HSBC;
const isCoreEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB;
const isBofAEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.BOFA;
const isBarclaysEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.BARCLAYS;
/**
 * Check if we are running in prerender.io crawler
 * See https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
 */
const isPrerender = window.navigator.userAgent.includes('Prerender (+https://github.com/prerender/prerender)');

// Map from the VITE_MODE to the stage name used by API and everywhere else
// Keep this in sync with `getEnvironment()` in `./src/plugins/apm.ts`.
// It is duplicated here to avoid circular dependency issues.
const apiStageMap: Record<ImportMetaEnv['VITE_MODE'], string> = {
  development: 'dev',
  test: 'test',
  staging: 'uat',
  production: 'prod',
};

const apiStage = apiStageMap[import.meta.env.VITE_MODE] ?? 'uat';

/**
 * In general, you should NOT BE USING THIS FUNCTION.
 * We are using this to selectively show portfolio tree ids on local (but not Cypress)
 * so that we may more conveniently troubleshoot portfolio tree id problems.
 * Everything else should remain the same in the test environment, otherwise it rather defeats
 * the purpose of testing in general...
 */
export function getIsCypress() {
  return window.Cypress;
}

export default function (): {
  shouldEmulateProduction: Ref<boolean>;
  isProduction: ComputedRef<boolean>;
  isActuallyProduction: boolean;
  isLocal: boolean;
  isPrerender: boolean;
  isHSBCEnvironment: boolean;
  isCoreEnvironment: boolean;
  isBofAEnvironment: boolean;
  isBarclaysEnvironment: boolean;
  apiStage: string;
} {
  return {
    shouldEmulateProduction,
    isProduction,
    isActuallyProduction,
    isLocal,
    isPrerender,
    isHSBCEnvironment,
    isCoreEnvironment,
    isBofAEnvironment,
    isBarclaysEnvironment,
    apiStage,
  };
}
