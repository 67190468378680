<template>
  <b-dropdown
    ref="dropdown"
    variant="outline-grey"
    :toggle-class="toggleClasses"
    block
    no-caret
    v-bind="{
      ...$attrs,
      ...$props,
      'menu-class': menuClasses,
    }"
    v-on="$listeners"
  >
    <template #button-content>
      <span class="d-flex justify-content-between align-items-center position-relative">
        <!-- Added the font-size-sm here to override the font size css of analysis-step-toolbar,
        meanwhile we are passing size="sm" from FactorDecompositionParametersDropdown to style the
      padding, line height and border radius -->
        <span
          class="text-ellipsis"
          :class="{ 'font-size-sm': shouldUseSmallButtonSize }"
          style="min-width: 0"
        >
          <slot name="button-content" />
        </span>
        <span
          class="mimic-bv-caret position-absolute"
          style="right: 0"
          :style="shouldUseSmallButtonSize ? 'top: calc(50% - 12px)' : 'top: calc(50% - 9px)'"
        />
      </span>
    </template>
    <slot />
  </b-dropdown>
</template>

<script lang="ts">
import { ClassProp, handlePossibleClassPropTypes } from '@/utils/bootstrapVue';
import { BDropdown } from 'bootstrap-vue';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'StandardFormDropdown',
  props: {
    menuClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    toggleClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    shouldUseSmallButtonSize: {
      type: Boolean,
      required: false,
    },
    showErrorState: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const dropdown = ref<BDropdown | null>(null);

    const menuClasses = computed(() => {
      return handlePossibleClassPropTypes(props.menuClass ?? { 'w-100': true });
    });

    const toggleClasses = computed(() => {
      if (props.toggleClass)
        return [
          ...handlePossibleClassPropTypes(props.toggleClass),
          'standard-form-dropdown',
          ...(props.showErrorState ? ['is-invalid'] : []),
        ];

      return {
        'text-decoration-none overflow-hidden text-left standard-form-dropdown': true,
        'is-invalid': !!props.showErrorState,
      };
    });

    const hide = () => {
      dropdown.value?.hide();
    };

    return {
      dropdown,
      hide,
      menuClasses,
      toggleClasses,
    };
  },
});
</script>
<style lang="scss">
.standard-form-dropdown.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
