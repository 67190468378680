import { IPortfolioTreeSubportfolio, IPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';

/**
 * Meant to hold any items from the portfolio tree which are not direct descendants of the active tree.
 * Cases:
 * - Portfolio Construction - Pinning any items that are not in the active tree
 * - Constituent Risk - All items that are (light blue) and not direct descendants of the active tree
 */
export default createGlobalState(() => {
  const overlayData = ref<(IPortfolioTreeSubportfolio | IPortfolioTreeStrategy)[]>([]);

  /**
   * If the new itemUnderAnalysis, or suspended item, or deleted item was previously pinned and in overlayData,
   * We want to remove it, so that it does not get repinned
   * @param treeId of item to remove from overlay
   */
  const removeItem = ({ treeId }: { treeId: string }) => {
    const itemIndex = overlayData.value.findIndex((x) => x.portfolioTreeId === treeId);
    if (itemIndex !== -1) {
      const newOverlayData = overlayData.value;
      newOverlayData.splice(itemIndex, 1);
      overlayData.value = [...newOverlayData];
    }
  };

  return {
    overlayData,
    removeItem,
  };
});
