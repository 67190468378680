import { reactive, computed, set, del, onBeforeUnmount, watch } from 'vue';
import useUser from './useUser';
import Bowser from 'bowser';
import { ACCEPTED_TRACKED_TITLES } from '@/types/analytics';
import { mapKeys, camelCase, uniqueId, isEqual } from 'lodash';
import { MaybeRef, toRef, useEventListener, useSessionStorage } from '@vueuse/core';
import { useIsPdf } from './usePdf';
import { AnalyticsBrowser } from '@segment/analytics-next';

const DEBUG = false;

const { user, isUserImpersonating } = useUser();

const timer: { [uniqueId: number]: number } = reactive({});

/**
 * NOTE: You can't enable segment in local because analytics-next force https
 * and that's not gonna work in local server.
 */
const enabled = import.meta.env.VITE_MODE !== 'development' && import.meta.env.VITE_ENABLE_SEGMENT === 'true';

const bowser = computed(() => Bowser.parse(window.navigator.userAgent));
const browser = computed(() => bowser.value.browser);
const os = computed(() => bowser.value.os);
const isPdf = useIsPdf();

const analytics = enabled
  ? AnalyticsBrowser.load(
      {
        writeKey: import.meta.env.VITE_ANALYTICS_KEY,
        cdnURL: '/_integration/segment/cdn',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: `${location.host}/_integration/segment/t/v1`,
          },
        },
      },
    )
  : null;
if (enabled) {
  window.analytics = analytics;
}

const getUserInfoForAnalytics = () => {
  try {
    if (user.value) {
      const userInfo = {
        userId: user.value.id,
        userName: user.value.firstname + ' ' + user.value.lastname,
        userTeamId: user.value.teamId,
        userEmail: user.value.email,
        userCountry: user.value.country,
        userTeamName: user.value.company,
      };

      const sessionInfo = {
        userEnvironment: window.location.hostname,
        userBrowser: browser.value.name,
        userBrowserVersion: browser.value.version,
        userOs: os.value.name,
        userOsVersion: os.value.version,
      };

      return Object.assign(userInfo, sessionInfo);
    }
  } catch (err) {
    console.error(err);
    return {};
  }
};

const track = (title: ACCEPTED_TRACKED_TITLES, dataToPass?: Record<string, unknown>): void => {
  try {
    if (analytics && !isUserImpersonating.value && !isPdf.value) {
      const userAndSessionInfo = getUserInfoForAnalytics();
      const data = dataToPass ? Object.assign(dataToPass, userAndSessionInfo) : userAndSessionInfo;

      const dataToRecord = mapKeys(data, (value, key) => camelCase(key));

      analytics.track(title, dataToRecord);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackButtonClick = (buttonName: string, dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const buttonData = Object.assign(dataToPass, { buttonName: buttonName });
      track(ACCEPTED_TRACKED_TITLES.BUTTON_CLICKED, buttonData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackSearchInput = (dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const buttonData = Object.assign(dataToPass);
      track(ACCEPTED_TRACKED_TITLES.SEARCH_INPUT, buttonData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackSearchHit = (dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const buttonData = Object.assign(dataToPass);
      track(ACCEPTED_TRACKED_TITLES.SEARCH_HIT, buttonData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackModalShown = (modalName: string, dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const buttonData = Object.assign(dataToPass, { modalName });
      track(ACCEPTED_TRACKED_TITLES.MODAL_SHOWN, buttonData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackPCAModified = (modificationType: string, dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const modificationData = Object.assign(dataToPass, {
        modificationType: modificationType,
      });
      track(ACCEPTED_TRACKED_TITLES.PCA_MODIFIED, modificationData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackRegressionModified = (modificationType: string, dataToPass: Record<string, unknown>): void => {
  try {
    if (enabled) {
      const modificationData = Object.assign(dataToPass, {
        modificationType: modificationType,
      });
      track(ACCEPTED_TRACKED_TITLES.REGRESSION_MODIFIED, modificationData);
    }
  } catch (err) {
    console.error(err);
  }
};

const trackTabView = (
  pageName: string,
  subpageName: string,
  tabView?: string,
  dataToPass?: Record<string, unknown>,
): void => {
  try {
    if (enabled) {
      const data = dataToPass || {};
      const tabData = Object.assign(data, { pageName, subpageName, tabView });
      track(ACCEPTED_TRACKED_TITLES.TAB_VIEWED, tabData);
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Starts the timer for tracking page view duration
 * Returns the id necessary to stop the timer
 *
 * @param debug - set this parameter to true in order to force this function is run even if you are in local
 * However, this function shouldn't be supplied with this parameter normally
 */
const startTimer = (debug?: boolean): number => {
  try {
    if (enabled || debug) {
      const id = parseInt(uniqueId(), 10);
      set(timer, id, Date.now());
      return id;
    }
    return -1;
  } catch (err) {
    console.error(err);
    return -1;
  }
};

/**
 * Ends the timer for tracking page view duration
 * The `id` parameter must exist in order for this to work properly.
 */
const endTimer = (id: number, debugPrefix = ''): { timeElapsed: number; timeElapsedFormatted: string } | undefined => {
  try {
    const startTime = timer[id];

    if (startTime) {
      const endTime = Date.now();

      const timeElapsed = (endTime - startTime) / 1000;

      const timeElapsedFormatted = getReadableTime(endTime - startTime);

      del(timer, id);

      if (DEBUG || debugPrefix)
        console.log(debugPrefix, {
          timeElapsed,
        });
      return { timeElapsed, timeElapsedFormatted };
    }
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const getReadableTime = (duration: number): string => {
  try {
    const portions = [];

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
      portions.push(`${hours}`);
      duration -= hours * msInHour;
    } else portions.push('00');

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    let minutesString = minutes.toString();
    if (minutes.toString().length === 1) minutesString = `0${minutes}`;
    if (minutes > 0) {
      portions.push(`${minutesString}`);
      duration -= minutes * msInMinute;
    } else portions.push('00');

    const seconds = Math.trunc(duration / 1000);
    let secondsString = seconds.toString();
    if (seconds.toString().length === 1) secondsString = `0${seconds}`;
    if (seconds > 0) {
      portions.push(`${secondsString}`);
    } else portions.push('00');

    return portions.join(':');
  } catch (err) {
    console.error(err);
    return '';
  }
};

export interface ITrackPageViewOptions {
  pageName: string;
  subpageName?: string;
  /**
   * If true, run the track immediately (as passed into `watch`).
   * Set this to false if the parameter required need vue-query/axios.
   *
   * Note: This is not reactive.
   *
   * @default true
   */
  immediate?: boolean;

  /**
   * Additional (in addition to pageName and subpageName) key/value pair for the tracking event.
   *
   * @default {}
   */
  additional?: Record<string, unknown>;
  /**
   * In order to prevent a tracking call from going out, set this to false
   */
  enabled?: boolean;
}

export function identify() {
  if (enabled && user.value && !isUserImpersonating.value) {
    const { id, email } = user.value;

    if (analytics) {
      analytics.identify(id.toString(), {
        email,
      });
    }
  }
}

/**
 * Track the page view and page view duration for the mounted page.
 *
 * This composable will watch the given options and send metrics when the given
 * ref changes.
 */
export function useTrackPageView(options: MaybeRef<ITrackPageViewOptions>) {
  const { track } = useAppMetrics();

  identify();

  /**
   * Currently tracking timer.
   */
  const timer = {
    /**
     * Unique id used to link `endTimer` and `startTimer` for page view duration
     */
    id: -1,

    /**
     * Parameter for the current timer.
     *
     * We need this for determine if the tracking page has changed.
     */
    parameters: {},
  };

  const storedDurationData = useSessionStorage('tempDurationData', {});

  /**
   * Stops the timer for the given id and submits the page view duration event
   *
   * Note: This does not reset `timer` id
   *
   * We keep `timeOnPage` and `timeOnPageFormatted` in order to maintain
   * backwards compatibility for the analytics data we retain
   */
  const clearTimer = (shouldSaveData?: boolean) => {
    if (timer.id === -1) {
      return;
    }

    const { timeElapsed, timeElapsedFormatted } = endTimer(timer.id) || {};
    track(ACCEPTED_TRACKED_TITLES.PAGE_VIEW_DURATION, {
      ...timer.parameters,
      timeOnPage: timeElapsed,
      timeOnPageFormatted: timeElapsedFormatted,
    });

    // clear storage
    storedDurationData.value = null;

    if (shouldSaveData) {
      const tempDurationData = {
        ...timer.parameters,
        timeOnPage: timeElapsed,
        timeOnPageFormatted: timeElapsedFormatted,
      };

      storedDurationData.value = tempDurationData;
    }
  };

  const optionsRef = toRef(options);

  useEventListener(window, 'pagehide', () => {
    clearTimer(true);
  });

  watch(
    optionsRef,
    (opt) => {
      if (isEqual(opt, timer.parameters)) {
        // Same track parameter. Skipping the track.
        return;
      }

      // clear the session storage first thing when page loads
      if ('timeOnPage' in storedDurationData.value) {
        track(ACCEPTED_TRACKED_TITLES.PAGE_VIEW_DURATION, storedDurationData.value);
        storedDurationData.value = null;
      }

      // don't track anything if the enabled is false
      // however keep the timeOnPage tracking because that's previously valid data
      if (opt.enabled !== undefined && opt.enabled === false) {
        return;
      }

      const data = {
        ...opt.additional,
        pageName: opt.pageName,
        subpageName: opt.subpageName,
      };
      clearTimer();

      track(ACCEPTED_TRACKED_TITLES.PAGE_VIEWED, data);
      timer.id = startTimer();
      timer.parameters = data;
    },
    { immediate: optionsRef.value.immediate ?? true },
  );

  onBeforeUnmount(() => {
    clearTimer();
  });
}

export default function useAppMetrics(): {
  track: (title: ACCEPTED_TRACKED_TITLES, dataToPass?: Record<string, unknown>) => void;
  trackButtonClick: (buttonName: string, dataToPass: Record<string, unknown>) => void;
  trackSearchInput: (dataToPass: Record<string, unknown>) => void;
  trackSearchHit: (dataToPass: Record<string, unknown>) => void;
  trackPCAModified: (modificationType: string, dataToPass: Record<string, unknown>) => void;
  trackRegressionModified: (modificationType: string, dataToPass: Record<string, unknown>) => void;
  trackTabView: (pageName: string, subpageName: string, tabView?: string, dataToPass?: Record<string, unknown>) => void;
  trackModalShown: (modalName: string, dataToPass: Record<string, unknown>) => void;
  startTimer: (debug?: boolean) => number;
  endTimer: (id: number, debugPrefix?: string) => { timeElapsed: number; timeElapsedFormatted: string } | undefined;
} {
  return {
    track,
    trackButtonClick,
    trackSearchInput,
    trackSearchHit,
    trackPCAModified,
    trackRegressionModified,
    trackTabView,
    trackModalShown,
    startTimer,
    endTimer,
  };
}
