
<script setup lang="ts">
const id = "residual"
 const title = "Residual"
 const categories = ["FACTOR_REGRESSION"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"residual","title":"Residual","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"residual","title":"Residual","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Residual is the unexplained portion of the regression. The variance proportion of the Residual row will always be equal to <span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mn>1</mn></mrow><annotation encoding="application/x-tex">1</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.6444em;"></span><span class="mord">1</span></span></span></span> – <span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><msup><mi>R</mi><mn>2</mn></msup></mrow><annotation encoding="application/x-tex">R^2</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.8141em;"></span><span class="mord"><span class="mord mathnormal" style="margin-right:0.00773em;">R</span><span class="msupsub"><span class="vlist-t"><span class="vlist-r"><span class="vlist" style="height:0.8141em;"><span style="top:-3.063em;margin-right:0.05em;"><span class="pstrut" style="height:2.7em;"></span><span class="sizing reset-size6 size3 mtight"><span class="mord mtight">2</span></span></span></span></span></span></span></span></span></span></span>, while the Return p.a. proportion of that row will always be equal to the 100% – Return p.a. proportion explained by regression.</p>
</div>
</template>
