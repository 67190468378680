export enum TimeSeriesEntityTypeConstants {
  STRATEGY = 'STRATEGY',
  BENCHMARK = 'BENCHMARK',
  PURE_FACTOR = 'PURE_FACTOR',
  PRIVATE_STRATEGY = 'PRIVATE_STRATEGY',
  FUND = 'FUND',
  PUBLIC_THEMATIC = 'PUBLIC_THEMATIC',
  THEMATIC = 'THEMATIC',
  THEMATIC_BENCHMARK = 'THEMATIC_BENCHMARK',
  /**
   * HSBC-specific: Should have data on hsbc only.
   */
  SIGNAL = 'SIGNAL',
  MARKET_DATA = 'MARKET_DATA',
  STOCK = 'STOCK',
}
