
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p><strong>Conditional VaR</strong> (CVaR), also known as Expected Shortfall (ES), is another
representation of risk by size of potential large loss. Instead of estimating
the threshold of an unlikely large loss, CVaR estimates the average loss given
the loss exceeds the threshold. For a confidence level C and the corresponding
VaR, CVaR is the expected value of portfolio loss on the condition that the loss
is larger than VaR.</p>
</div>
</template>
