<template>
  <div class="h-100">
    <b-img
      style="object-fit: cover; min-height: 770px"
      :src="imgSrc"
      alt="Network of nodes superimposed over a cityscape at night"
      fluid
      class="h-100"
    />
    <BannerTitle />
  </div>
</template>

<script lang="ts">
import BannerTitle from './BannerTitle.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DisclaimerModalBanner',
  components: { BannerTitle },
  props: {
    imgSrc: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
});
</script>
