
<script setup lang="ts">
const id = "carry"
 const title = "Carry"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"carry","title":"Carry","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"carry","title":"Carry","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Carry strategies are designed to take advantage of the outperformance of higher yielding assets over lower
yielding assets. They are adopted by investors across most asset classes, but especially popular in currencies
and fixed income.</p>
</div>
</template>
