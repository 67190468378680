import { AppMain } from '@/layout/components/index';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';

export const StrategiesRouter: RouteConfig[] = [
  {
    path: '/indices',
    alias: '/strategy',
    components: {
      default: AppMain,
    },
    children: [
      {
        path: '',
        redirect: { name: RouteName.DATA },
      },
      {
        name: RouteName.STRATEGY_FACTSHEET,
        path: ':indexUniqueIdentifier',
        components: {
          default: () => import('@/whitelabel/hsbc/views/factsheets/StrategyFactsheet.vue'),
          sidebar: () => import('@/whitelabel/hsbc/layout/components/sidebar/ApplicationSidebar.vue'),
          'analysis-step-toolbar': () => import('@/whitelabel/hsbc/views/factsheets/FactsheetToolbar.vue'),
        },
        meta: {
          title: 'Index Overview',
          shouldApplicationSidebarBeShown: true,
          isMainContentContainerFluid: true,
          permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
        },
      },
      {
        name: RouteName.STRATEGY_FACTSHEET_PDF,
        path: ':indexUniqueIdentifier/pdf',
        component: () => import('@/whitelabel/hsbc/views/factsheets/StrategyFactsheetPdf.vue'),
        meta: {
          title: 'Strategy Factsheet PDF',
          pdf: true,
        },
      },
    ],
  },
];
