import request from '@/api-v2/request';
import { IDataDiscoveryTrackResponse } from './types/IDataDiscoveryTrackResponse';
import { IDataDiscoveryMstRequest } from './types/IDataDiscoveryMstRequest';
import { IDataDiscoveryMstResponse } from './types/IDataDiscoveryMstResponse';
import { DataDiscoveryResponseDTO } from './types/DataDiscoveryResponseDTO';
import {
  DataDiscoveryMetricsRequestDTO,
  DataDiscoveryMetricsResponseDTO,
  DataDiscoveryUniverseMetricsRequestDTO,
  DataDiscoveryUniverseMetricsResponseDTO,
} from './types/DataDiscoveryMetricsDTO';
import { PortfolioSummaryResponseDTO } from './types/PortfolioSummaryResponseDTO';
import { Period } from '@/types/period';
import { IMetric } from '@/types/strategy';

export const getDataUniverse = (): Promise<DataDiscoveryResponseDTO> =>
  request({
    url: `/discover`,
    method: 'get',
  }).then((o): DataDiscoveryResponseDTO => o.data);

export const getDataUniverseMetrics = (
  query: DataDiscoveryMetricsRequestDTO,
): Promise<DataDiscoveryMetricsResponseDTO> =>
  request({
    url: `/discover/metrics`,
    method: 'get',
    params: query,
  }).then((o): DataDiscoveryMetricsResponseDTO => o.data);

export const getDataUniverseMetricsV2 = (
  query: DataDiscoveryUniverseMetricsRequestDTO,
): Promise<DataDiscoveryUniverseMetricsResponseDTO> =>
  request({
    url: `/discover/universe-metrics`,
    method: 'get',
    params: query,
  }).then((o): DataDiscoveryUniverseMetricsResponseDTO => o.data);

/**
 * Fetches metrics for a strategy for a given period
 * @param code Strategy code to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getStrategyMetrics = (
  code: string,
  volScaling: boolean = false,
  volScalePeriod?: Period,
  analyticsPeriod?: Period,
): Promise<{ result: IMetric }> =>
  request({
    url: `/discover/strategy/${code}/metrics`,
    params: {
      volScaling,
      startDate: analyticsPeriod?.fromDateString(),
      endDate: analyticsPeriod?.toDateString(),
      volScalingStartDate: volScalePeriod?.fromDateString(),
      volScalingEndDate: volScalePeriod?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

/**
 * Fetches metrics for a portfolio for a given period
 * @param slug Portfolio slug to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getPortfolioMetrics = (
  slug: string,
  volScaling: boolean = false,
  volScalePeriod?: Period,
  analyticsPeriod?: Period,
): Promise<{ result: IMetric }> =>
  request({
    url: `/discover/portfolio/${slug}/metrics`,
    params: {
      volScaling,
      startDate: analyticsPeriod?.fromDateString(),
      endDate: analyticsPeriod?.toDateString(),
      volScalingStartDate: volScalePeriod?.fromDateString(),
      volScalingEndDate: volScalePeriod?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

/**
 * Fetches track for a strategy for a given period with option of Volatility scaling
 * @param code Strategy code to fetch track for
 * @param volScaling Should volatility scaling be applied
 * @param period Period for which track needs to be fetched
 */
export const getStrategyTrack = (
  code: string,
  volScaling: boolean = false,
  period?: Period,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/strategy/${code}/track`,
    params: {
      volScaling,
      startDate: period?.fromDateString(),
      endDate: period?.toDateString(),
      volScalingStartDate: period?.fromDateString(),
      volScalingEndDate: period?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

/**
 * Fetches track for a portfolio for a given period with option of Volatility scaling
 * @param slug Portfolio slug to fetch track for
 * @param volScaling Should volatility scaling be applied
 * @param period Period for which track needs to be fetched
 */
export const getPortfolioTrack = (
  slug: string,
  volScaling = false,
  period?: Period,
): Promise<IDataDiscoveryTrackResponse> =>
  request({
    url: `/discover/portfolio/${slug}/track`,
    params: {
      volScaling,
      startDate: period?.fromDateString(),
      endDate: period?.toDateString(),
      volScalingStartDate: period?.fromDateString(),
      volScalingEndDate: period?.toDateString(),
    },
    method: 'get',
  }).then((o) => o.data);

export const getMst = (body: IDataDiscoveryMstRequest): Promise<IDataDiscoveryMstResponse> =>
  request({
    url: `/discover/mst`,
    method: 'post',
    data: body,
  }).then((o) => o.data);

export const getPortfolioSummary = (): Promise<PortfolioSummaryResponseDTO[]> =>
  request({
    url: `/discover/portfolios/summary`,
    method: 'get',
  }).then((o) => o.data);
