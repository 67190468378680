<template>
  <div
    id="app"
    :class="{ 'no-focus-outline': !shouldShowFocusOutline }"
    @keyup.tab="shouldShowFocusOutline = true"
    @click="shouldShowFocusOutline = false"
  >
    <LayoutComponent />
  </div>
</template>

<script lang="ts">
import LayoutComponent from '@/layout/index.vue';
import { defineComponent, ref, computed } from 'vue';
import * as am5 from '@amcharts/amcharts5';
import { SaveChangesStore } from './store/modules/SaveChangesStore';
import useAnalysisDates from '@/composables/useAnalysisDates';
import { useLocaleCookie } from '@/composables/useLocale';
import { useStoreRootI18n } from '@/composables/useTranslation';
import { useEventListener } from '@vueuse/core';

const { savePeriodToLocalStorage } = useAnalysisDates();

export default defineComponent({
  name: 'App',
  components: {
    LayoutComponent,
  },
  setup() {
    am5.addLicense('AM5C168616911');

    useLocaleCookie();
    useStoreRootI18n();

    const shouldShowFocusOutline = ref(false);

    /**
     * Because this is the app file, it craps out if you import this from the
     * usePromptForSaving composable. I believe you shouldn't be importing any
     * composable in this file for that reason. This may change when we switch
     * to Vue 3.
     */
    const doesPageHaveChanges = computed(() => SaveChangesStore.hasChanges);

    const onWindowLeave = (e: BeforeUnloadEvent) => {
      savePeriodToLocalStorage();

      if (!doesPageHaveChanges.value) return;
      // https://www.javascripttutorial.net/javascript-dom/javascript-beforeunload-event/
      // According to the specification, you need to call the preventDefault() method inside the beforeunload event handler
      e.preventDefault();

      // Google Chrome requires returnValue to be set.
      e.returnValue = '';
    };

    useEventListener(window, 'beforeunload', onWindowLeave);

    return {
      shouldShowFocusOutline,
    };
  },
});
</script>
<style lang="scss">
.red-herring {
  // this class is used to force Vue to load the scss into this file
}

.scroll-bar-ruler {
  position: absolute;
  z-index: 20000;
  height: 0px;
}
</style>
