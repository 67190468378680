export enum ChartTypes {
  CORRELATION = 'Correlation',
  MONTHLY_RETURN_DISTRIBUTION = 'Monthly Return Distribution',
  LOG_PROBABILITY_DENSITY = 'Log Probability Density',
  RETURN_REGRESSION = 'Return Regression',
  ROLLING_CORRELATION = 'Rolling Correlation',
  ROLLING_SHARPE = 'Rolling Sharpe',
  ROLLING_VOLATILITY = 'Rolling Volatility',
  DRAWDOWN = 'Drawdown',
  METRICS = 'Metrics',
  CORRELATION_MATRIX = 'Correlation Matrix',
  MST = 'Minimum Spanning Tree (MST)',
  PERFORMANCE_ATTRIBUTION_GROUP_BY = 'Performance Attribution Group By',
  CUMULATIVE_MONTHLY_RETURN_BREAKDOWN = 'Cumulative Monthly Return Breakdown',
  RANKING_TABLE = 'Ranking Table',

  SCALE = 'Scale',
  VOLATILITY_CONTRIBUTION = 'Volatility Contribution',
  VAR_CONTRIBUTION = 'VaR Contribution',
  RETURN_ATTRIBUTION = 'Return Attribution',
  EXPOSURE = 'Exposure',

  TRACK_RECORD = 'Track Record',
  EFFICIENT_FRONTIER = 'Efficient Frontier',
  EFFICIENT_FRONTIER_BLM = 'Efficient Frontier BLM',
  FRONTIER_WEIGHTS = 'Frontier Weights',

  FACTOR_HEATMAP = 'Heatmap',
  REGRESSION_TRACK = 'Regression Track',
  CUMULATIVE_RESIDUAL = 'Cumulative Residual',
  CONVEXITY = 'Convexity',
  MONTHLY_RETURN = 'Monthly Return',
  ROLLING = 'Rolling',

  COMMODITY_TREE_MAP = 'Commodity Tree Map',
  COMMODITY_BAR_LINE = 'Commodity Bar Line',
  COMMODITY_LADDER = 'Commodity Ladder',
  COMMODITY_HISTORY = 'Commodity History',

  EQUITY_TREE_MAP = 'Equity Tree Map',
  EQUITY_LAYERED_BAR = 'Equity Layered Bar',
  EQUITY_LADDER = 'Equity Ladder',
  EQUITY_HISTORY = 'Equity History',

  VAR_WATERFALL = 'Var Waterfall',
  VAR_DONUT = 'Var Donut',
  VAR_COLUMNS = 'Var Columns',
  VAR_COLUMNS_EXPOSURE = 'Var Columns Exposure',
  VAR_ESG_DISTRIBUTION = 'Var ESG Distribution',
  VAR_FACTOR_DISTRIBUTION = 'Var Factor Distribution',
  VAR_HISTORY = 'Var History',
  VAR_EXPOSURE_HISTORY = 'Var Exposure History',

  STRESS_TEST_MAIN_GRAPH = 'Stress Test Main Graph',
  STRESS_TEST_HISTORY = 'Stress Test History',

  FX_MAIN_GRAPH = 'FX Main Graph',
  FX_LADDER = 'FX Ladder',
  FX_HISTORY = 'FX History',

  INTEREST_RATE_MAIN_GRAPH = 'Interest Rate Main Graph',
  INTEREST_RATE_LADDER = 'Interest Rate Ladder',
  INTEREST_RATE_CHILD_1 = 'Interest Rate Child 1',
  INTEREST_RATE_HISTORY = 'Interest History',

  CREDIT_MAIN_GRAPH = 'Credit Main Graph',
  CREDIT_HISTORY = 'Credit History',
  CREDIT_CHILD_1 = 'Credit Child 1',

  ACTIVE_RETURN = 'Active Return',
  RETURN_COMPARISON = 'Return Comparison',
}

export enum FactsheetChartTypes {
  CORRELATION = 'Correlation',
  MONTHLY_RETURN_DISTRIBUTION = 'Monthly Return Distribution',
  LOG_PROBABILITY_DENSITY = 'Log Probability Density',
  RETURN_DISTRIBUTION = 'Return Distribution',
  RETURN_REGRESSION = 'Return Regression',
  ROLLING_CORRELATION = 'Rolling Correlation',
  ROLLING_SHARPE = 'Rolling Sharpe',
  ROLLING_VOLATILITY = 'Rolling Volatility',
  DRAWDOWN = 'Drawdown',
  METRICS = 'Metrics',

  // WEIGHTING = 'Weighting',
  SCALE = 'Scale',
  VOLATILITY_CONTRIBUTION = 'Volatility Contribution',
  VAR_CONTRIBUTION = 'VaR Contribution',
  RETURN_ATTRIBUTION = 'Return Attribution',
  EXPOSURE = 'Exposure',
}

export enum PerformanceContributionColumn {
  TOTAL_CONTRIBUTION = 'totalContribution',
  EFFECTIVE_RETURN = 'effectiveReturn',
  AVERAGE_WEIGHT = 'averageWeight',
  AVERAGE_GROSS_WEIGHT = 'averageGrossWeight',
  EFFECTIVE_GROSS_RETURN = 'effectiveGrossReturn',
  CUMULATIVE_RETURN = 'cumulativeReturn',
}
