
<script setup lang="ts">
const id = "rolling-regression"
 const title = "Rolling Regression (Beta Coefficient)"
 const categories = ["FACTOR_REGRESSION"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"rolling-regression","title":"Rolling Regression (Beta Coefficient)","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"rolling-regression","title":"Rolling Regression (Beta Coefficient)","categories":["FACTOR_REGRESSION"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Rolling Regression is a technique of applying OLS regression on a rolling basis.</p>
<p>For example, if Moving Window is Monthly, and Window is 250 days, this means that you are recalibrating the factor beta coefficients every month, and each regression is based on a period of 250 days.</p>
<p>Rolling Regression is used to assess the stability of the beta coefficients over time.</p>
</div>
</template>
