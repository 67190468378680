export enum PeriodAbbrevEnum {
  CUST = 'custom',
  TEN_Y = '10y',
  FIVE_Y = '5y',
  THREE_Y = '3y',
  LIVE = 'live',
  ONE_Y = '1y',
  SIX_M = '6m',
  THREE_M = '3m',
  TWO_M = '2m',
  ONE_M = '1m',
  MIN = 'min',
  YTD = 'ytd',
  MAX = 'max',
}
