import { RouteConfig } from 'vue-router';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName } from '@/constants/RouteName';
import { AppMain } from '@/layout/components';
import { ClientConstants } from '@/constants/ClientConstants';

const articleEditRoute: RouteConfig = {
  name: RouteName.ARTICLE_EDIT,
  path: '/article/edit/:articleCode?',
  meta: {
    title: 'Article Edit',
    isMainContentContainerFluid: true,
    permission: import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB ? [UserPermission.ADMIN] : [],
  },
  component: () => import('@/views/articles/ArticleEditor.vue'),
};

const articleFeedRoute: RouteConfig = {
  name: RouteName.ARTICLE_FEED,
  path: '/article/feed/:articleCode',
  meta: {
    title: 'Article Feed',
    isMainContentContainerFluid: true,
    permission: import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB ? [UserPermission.ADMIN] : [],
  },
  component: () => import('@/views/articles/ArticleFeed/ArticleFeed.vue'),
};

const articlesAdminRoute: RouteConfig = {
  name: RouteName.ARTICLES_ADMIN,
  path: '/articles/admin-page',
  meta: {
    title: 'Insights Admin',
    permission: import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB ? [UserPermission.ADMIN] : [],
  },
  component: () => import('@/views/admin/articles/AdminArticlePage.vue'),
  props: true,
};

export const ArticleRouter: RouteConfig[] = [
  {
    path: '/articles',
    meta: {
      title: 'Insights',
      permission: import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB ? [UserPermission.ADMIN] : [],
    },
    components: {
      default: AppMain,
    },
    children: [
      articlesAdminRoute,
      articleFeedRoute,
      articleEditRoute,
      {
        name: RouteName.ARTICLES,
        path: '',
        meta: {
          title: 'Insights',
        },
        components: {
          default: () => import('@/views/articles/ArticleLandingPage.vue'),
        },
      },
    ],
  },
];
