// Utils Should not include a computed, just functions
import { useConstituentRiskAnalysisSteps } from '@/composables/useAnalysisSteps';
import { useConstituentRiskUtilities } from '@/composables/useConstituentRiskUtilities';
import { DateFormat } from '@/constants/DateFormat';
import { ConstituentRiskModule, SettingsModule } from '@/store/barrel';
import {
  dataComponents,
  dataHistoryComponents,
  ExposureConstants,
  FilterConstants,
  RiskTableTabs,
  RiskTabs,
  TableLayerConstants,
  VarGrouping,
} from '@/types/analytics/ConstituentRisk';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { computed, nextTick, ref } from 'vue';
import { IConstituentRisk, IConstituentRiskQuery } from '@/types/IConstituentRisk';
import usePortfolioTree from '@/composables/usePortfolioTree';
import useSystemLoading from '@/composables/useSystemLoading';
import { LoadingItems } from '@/constants/LoadingItems';
import useTranslation from '@/composables/useTranslation';
import useUser from '@/composables/useUser';
import { useToasts } from '@/composables/useToasts';
import { Currency, getCurrencySymbol } from '@/constants/Currency';
import { MetricConstants } from '@/constants/MetricConstants';
import { useRiskPreferences } from '@/composables/useRiskPreferences';
import usePinned from '@/composables/usePinned';
import { findTreeComponentByStrategyCode } from './portfolioTree';
import { isStrategy } from './strategy';
import { createGlobalState } from '@vueuse/shared';

const getComponents = (getMain: boolean = false) => {
  const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();
  const { isHistoryMode } = useConstituentRiskUtilities();
  if (!activeAnalysisSubstep.value) return [];

  let components = dataComponents[activeAnalysisSubstep.value.path as RiskTabs] ?? [];
  if (isHistoryMode.value) components = dataHistoryComponents[activeAnalysisSubstep.value.path as RiskTabs];
  if (!getMain) components = [];
  return components;
};

export const getConstituentRiskOptions = async (portfolioTreeDraftIdentifier?: string) => {
  const { positionDate } = useConstituentRiskUtilities();
  const { translate } = useTranslation();
  const { errorToast } = useToasts();
  const { removeLoadingItem } = useSystemLoading();

  if (!positionDate.value || !portfolioTreeDraftIdentifier) return;

  const query = {
    slug: portfolioTreeDraftIdentifier,
    date: positionDate.value.toFormat(DateFormat.YYYY_MM_DD),
  };

  try {
    await ConstituentRiskModule.GetConstituentRiskOptions(query);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.statusCode && e.statusCode === 500) {
      errorToast(translate({ path: 'ERROR.MISSING_DATA' }));
    }
    removeLoadingItem(LoadingItems.CONSTITUENT_RISK_DATA);
  }
};

const validateQuery = (query: IConstituentRiskQuery) => {
  const queryToReturn = { ...query };

  if (queryToReturn.equityMetric === MetricConstants.CONVEXITY) {
    queryToReturn.equityMetric = MetricConstants.DELTA;
  }
  if (queryToReturn.commodityMetric === MetricConstants.CONVEXITY) {
    queryToReturn.commodityMetric = MetricConstants.DELTA;
  }

  return queryToReturn;
};

export const getSegmentLayers = () => {
  const { preferences } = useRiskPreferences();
  const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();

  return computed(() => {
    const activeSubstep = activeAnalysisSubstep.value?.path;

    if (activeSubstep === RiskTabs.COMMODITY) {
      if (
        preferences.value.commodity.tableMetricName === RiskTableTabs.COMMODITY_DELTA ||
        preferences.value.commodity.tableMetricName === RiskTableTabs.COMMODITY_GAMMA
      ) {
        return preferences.value.commodity.tableLayers;
      }
      if (preferences.value.commodity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER) {
        return preferences.value.commodity.ladderTableLayers;
      }
    }

    if (
      activeSubstep === RiskTabs.INTEREST_RATE &&
      preferences.value['interest-rate'].tableMetricName === RiskTableTabs.DV01_TENOR
    ) {
      return preferences.value['interest-rate'].tableLayers;
    }
    if (
      (activeSubstep === RiskTabs.INTEREST_RATE &&
        preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_NORMAL_LADDER) ||
      preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_BS_LADDER
    ) {
      return preferences.value['interest-rate'].ladderTableLayers;
    }
    if (activeSubstep === RiskTabs.CREDIT && preferences.value.credit.tableMetricName === RiskTableTabs.CS01_TENOR) {
      return preferences.value.credit.tableLayers;
    }
    if (
      activeSubstep === RiskTabs.EQUITY &&
      preferences.value.equity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER
    ) {
      return preferences.value.equity.ladderTableLayers;
    }
    if (activeSubstep === RiskTabs.FX && preferences.value.fx.tableMetricName === RiskTableTabs.FX_DELTA) {
      return preferences.value.fx.tableLayers;
    }
    if (activeSubstep === RiskTabs.FX && preferences.value.fx.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER) {
      return preferences.value.fx.ladderTableLayers;
    }
    return preferences.value.options.tableLayers;
  });
};

/**
 * Sets the API parameters to fetch the data of the current page.
 * @param getPositionRibbon Flag to notify when to fetch new data for the position ribbon. This is used to make the ribbon load faster by removing the rolling date from the query
 * @param getRibbon Flag to notify when to fetch new data for the ribbon
 * @param getMain Flag to notify when to fetch new data for the main graph portion
 * @param getTable Flag to notify when to fetch new data for the table
 */
export const getConstituentRiskData = async ({
  draftSlug,
  analysisCodes,
  getPositionRibbon,
  getRibbon,
  getMain,
  isPdf,
  components,
}: {
  /**
   * This is "optional", but only to make it simpler on the caller side, so we may feed the draft slug directly
   * without checking if it's undefined
   */
  draftSlug: string | undefined;
  originalSlug: string | undefined;
  /**
   * Replaces `suspendedForRisk` in the portfolioTreeDraft. Query will only be run using codes in this property
   */
  analysisCodes: string[];
  getPositionRibbon?: boolean;
  getRibbon?: boolean;
  getMain?: boolean;
  isPdf?: boolean;
  components?: (keyof IConstituentRisk)[];
}): Promise<void> => {
  const { positionDate, rollingDate, getTrackSegment } = useConstituentRiskUtilities();
  const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();
  const { translate } = useTranslation();
  const { removeLoadingItem } = useSystemLoading();
  const { errorToast } = useToasts();
  const { preferences, varMetric1 } = useRiskPreferences();
  const { isUserAdmin } = useUser();
  const { itemUnderAnalysis, masterPortfolioTree } = usePortfolioTree();

  if (
    !positionDate.value ||
    (!activeAnalysisSubstep.value && !isPdf) ||
    !masterPortfolioTree.value ||
    !draftSlug ||
    !itemUnderAnalysis.value ||
    isStrategy(itemUnderAnalysis.value)
  ) {
    return;
  }

  const segmentLayers = getSegmentLayers();

  const query: IConstituentRiskQuery = validateQuery({
    slug: draftSlug,
    component: components ?? getComponents(getMain),
    analysisCodes,
    portfolioTreeId: itemUnderAnalysis.value.portfolioTreeId,
    date: positionDate.value.toFormat(DateFormat.YYYY_MM_DD),
    rollingDate: rollingDate.value ? rollingDate.value.toFormat(DateFormat.YYYY_MM_DD) : undefined,
    unit: preferences.value.options.unit,
    equityMetric: preferences.value.equity.metric,
    equityFilterLevel1: preferences.value.equity.filterLevel1,
    equityFilterLevel2: preferences.value.equity.filterLevel2,
    commodityMetric: preferences.value.commodity.metric,
    commodityFilter: preferences.value.commodity.filter,
    varMetric1: varMetric1.value,
    varMetric1GroupBy:
      preferences.value.var.grouping === VarGrouping.ALLOCATED
        ? preferences.value.var.category
        : FilterConstants.STRATEGY,
    varMetric2: preferences.value.var.metric2,
    ribbonFieldNames: [
      `Historical ${preferences.value.options.var} Allocated`,
      preferences.value.options['stress-test'],
      `EQ.${preferences.value.options.equity}`,
      'IR.DV01(1bp)',
      'CR.CS01(1bp)',
      `FX.${preferences.value.options.fx}[${masterPortfolioTree.value.portfolioTree.toCurrency ?? Currency.USD}]`,
      `CM.${preferences.value.options.commodity}`,
    ],
    stressTestOptions: preferences.value['stress-test'].testOptions,
    stressTestHistoryOption: preferences.value['stress-test'].historyTestOption,
    stressTestFilter: preferences.value['stress-test'].filter,
    fxFilter: preferences.value.fx.filter,
    trackSegment: getTrackSegment(),
    basisPoints: preferences.value.options.basisPoints,
    riskTableLayers: isPdf ? [TableLayerConstants.STRATEGY] : segmentLayers.value,
    isNavRescaling: isUserAdmin.value ? preferences.value.options.navRescaling : false,
  });

  try {
    // Since the table has a lot of data to fetch, it has been decided to call the the ribbon and graphs separately from the table data
    // The mainQuery is used to call the graphs, when needed based on getMain.
    // The ribbonQuery is used to call the ribbon, when needed based on getRibbon.
    // While the tableQuery is used for the table, when needed based on getTable.
    const mainQuery = getMain || components ? ConstituentRiskModule.GetConstituentRiskData(query) : undefined;

    // The positionRibbonQuery is used to make the ribbon load faster by removing the rolling date from the query
    // We then later call the normal ribbon again when the page has fully loaded to the display the rolling values
    const alteredPositionRibbonQuery = {
      ...query,
      rollingDate: undefined,
    };
    const positionRibbonQuery = getPositionRibbon
      ? ConstituentRiskModule.GetConstituentRibbon(alteredPositionRibbonQuery)
      : undefined;
    const ribbonQuery = getRibbon ? ConstituentRiskModule.GetConstituentRibbon(query) : undefined;

    await Promise.allSettled([mainQuery, positionRibbonQuery, ribbonQuery]).then(() => {
      if (!activeAnalysisSubstep.value) return;
      ConstituentRiskModule.SetHasPageOptionsChanged({
        newVal: [
          {
            tabName: activeAnalysisSubstep.value.path as RiskTabs,
            newVal: false,
          },
        ],
      });
    });
  } catch (e) {
    errorToast(translate({ path: 'ERROR.FETCH_CONSTITUENT_DATA' }));
    throw e;
  } finally {
    await nextTick();
    removeLoadingItem(LoadingItems.CONSTITUENT_RISK_DATA);
  }
};

/**
 * Formats a large number to make it more shorter and easier to read.
 * @param value Currency value to be formatted
 */
export const formatLongNumber = (value: number, ccy: Currency = Currency.USD): string => {
  let valToUse: string = value.toString();
  const suffixes = ['', ' k', ' M', ' B', ' T'];
  let suffixNum = 0;
  let shortValue = value;
  if (value >= 1000 || value <= -1000) {
    const stringValue = Math.round(Math.abs(value)).toString();

    // Counts how many groups of three digits in the value to determine which suffix to use
    suffixNum = Math.floor((stringValue.length - 1) / 3);

    // For loop to increase the precision of the output
    for (let precision = 3; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

      if (dotLessShortValue.length <= 3) {
        break;
      }
    }
  }
  if (shortValue % 1 !== 0) shortValue = parseFloat(shortValue.toFixed(AnalyticsStore.numDecimalPoints));
  valToUse = shortValue.toString() + suffixes[suffixNum];

  return `${getCurrencySymbol(ccy)} ` + valToUse;
};

/**
 * Handles the pluralization of the unit basis point based on the value.
 *
 * @param value Basis point value to be formatted
 * @param unitOnly If true, only the unit will be returned
 * @returns the appropriate basis point unit (plural or singular)
 */
export const formatBasisPoints = (value: string, unitOnly: boolean = false): string => {
  let unit = 'bps';
  const singular = ['1', '+1', '-1', '0', '', '-'];
  if (singular.includes(value)) {
    unit = 'bp';
  }
  return unitOnly ? unit : `${value} ${unit}`;
};

export const useGetHistoricalTrackColor = () => {
  const { masterPortfolioTree, portfolioName } = usePortfolioTree();
  const { colorMap } = usePinned();

  return (name: string): string => {
    if (name === portfolioName.value || name === ExposureConstants.NET || name === 'Subtotal' || name === 'Strategy')
      return SettingsModule.portfolioColor;
    if (colorMap.value[name]) return colorMap.value[name].color;
    if (masterPortfolioTree.value) {
      const component = findTreeComponentByStrategyCode(masterPortfolioTree.value.portfolioTree, name);
      if (component && colorMap.value[component.portfolioTreeId])
        return colorMap.value[component.portfolioTreeId].color;
    }
    return SettingsModule.defaultChildColor;
  };
};

/**
 * Codes passed to the risk APIs
 */
export const useAnalysisCodesForRisk = createGlobalState(() => {
  return ref<string[]>([]);
});
