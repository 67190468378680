import { ApplicationDefaults } from '@/constants/ApplicationDefaults';
import { SettingsModule } from '@/store/barrel';
import { isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { isStrategy } from '@/utils/strategy';
import { computed } from 'vue';
import useFxConversion from './useFxConversion';
import { useBrowserLocation } from '@vueuse/core';
import useTranslation from './useTranslation';
import { hasCurrencyConversion } from '@/types/CurrencyConversion';

/**
 * https://github.com/puppeteer/puppeteer/blob/main/docs/api.md#pagepdfoptions
 */
type PrintToPdfOptions = {
  format?: string;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  headerTemplate?: string;
  footerTemplate?: string;
};

export function useIsPdf() {
  const location = useBrowserLocation();
  return computed(() => {
    const url = location.value.href?.toString();
    return Boolean(url?.includes('/pdf') || url?.includes('-pdf'));
  });
}

/**
 * Turn this boolean to true to test PDFs in the browser
 * If it's false and you are on local, instead it will generate
 * the PDF from UAT and return it to you.
 *
 * should be `false` in cypress environment as we need to test the download pdf feature.
 */
const shouldTestPdfInBrowser = !window.Cypress;

export function usePdfPreferences() {
  const isPdf = useIsPdf();

  const sectionHeaderTextColor = computed(() =>
    isPdf.value ? { color: `${SettingsModule.pdfSectionHeaderTextColor} !important` } : null,
  );

  const sectionDividerColor = computed(() =>
    isPdf.value ? SettingsModule.pdfSectionDividerColor : ApplicationDefaults.colors.Info,
  );

  return {
    sectionHeaderTextColor,
    sectionDividerColor,
  };
}

const generateAnalysisItemAttributes = (
  item: IItemUnderAnalysis,
  /**
   * Set this to true for pages not using the navigation panel and not saving the portfolio draft
   */
  isLegacyApiPage?: boolean,
  shouldShowFxType: boolean = true,
): string | undefined => {
  const { toCurrency, fxType } = useFxConversion();
  const { translate } = useTranslation();

  let assetClass = '';
  let factor = '';
  let currency = toCurrency.value || '';
  let fxHedging = fxType.value || '';

  const itemToUse: Partial<IItemUnderAnalysis> | undefined =
    isStrategy(item) || isPortfolioTreeSubportfolio(item) ? item : item.strategy;
  if (!itemToUse) return;

  assetClass = itemToUse.assetClass || '';
  factor = itemToUse.factor || '';

  // Allows for the pdf to use the itemUnderAnalysis' toCurrency and fxType
  // since the factor decomposition page can create a pdf on subportfolio or strategy
  // Otherwise, it will always use the root portfolio
  // Which is not applicable in PCA yet, since we are not using portfolio-trees there
  if (!isLegacyApiPage && hasCurrencyConversion(itemToUse)) {
    currency = itemToUse.toCurrency;
    fxHedging = itemToUse.fxType;
  }

  return `${translate({
    path: 'GLOBAL.ASSET_CLASS_CONSTANTS',
    item: assetClass,
  })} | ${translate({
    path: 'GLOBAL.FACTOR_CONSTANTS',
    item: factor,
  })} | ${currency} ${
    shouldShowFxType
      ? translate({
          path: 'GLOBAL.FX_CONVERSION',
          item: fxHedging,
        })
      : ''
  }`;
};

/**
 * Default pdf options to send to the sls-chromeless project
 *
 * FOR THE WHITELABEL BRANCHES, WE MAY NEED TO HAVE THIS INSTEAD:
 *  {
 *    footer: ''
 *    margin: {
 *      top: 0,
 *      bottom: 0,
 *    },
 *  }
 *
 */
export function useDefaultPdfOptions(): PrintToPdfOptions {
  return {
    margin: {
      top: 0,
      bottom: 0,
    },
  };
}

/**
 * The PDF can look very ugly with lots of factors, so we limit it to this number.
 */
const factorDecompositionPdfFactorLimit = 20;

export default function (): {
  shouldTestPdfInBrowser: boolean;
  generateAnalysisItemAttributes: (
    item: IItemUnderAnalysis,
    isLegacyApiPage?: boolean,
    shouldShowFxType?: boolean,
  ) => string | undefined;
  factorDecompositionPdfFactorLimit: number;
} {
  return {
    shouldTestPdfInBrowser,
    generateAnalysisItemAttributes,
    factorDecompositionPdfFactorLimit,
  };
}
