export enum PortfolioGroupingConstants {
  HIERARCHY = 'Hierarchy',
  ASSET_CLASS = 'Asset Class',
  CURRENCY = 'Currency',
  FACTOR = 'Factor',
  PROVIDER = 'Provider',
  REGION = 'Region',
  RETURN_TYPE = 'Return Type',
  STYLE = 'Style',
  TYPE = 'Type',
}
