import { AxiosError } from 'axios';

/**
 * For many functions, 404 is an expected error and we just don't have this resource.
 * Use this function to prevent retries if we receive a 404.
 */
export default function ignoreRetryOn404(failureCount: number, error: Error) {
  // if it's a 404, we don't have this resource and we don't expect to retry
  if (error instanceof AxiosError && error.response?.data.statusCode === 404) {
    return false;
  }

  // otherwise fallback to global default of two more retries
  return failureCount < 2;
}
