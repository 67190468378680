import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { PinnedMap } from '@/types/Pinned';
import AnalyticsStore from './AnalyticsStore';
import { Vue } from 'vue-property-decorator';
import { isStrategy } from '@/utils/strategy';

@Module({ dynamic: true, store, name: 'PinnedStore' })
class PinnedStore extends VuexModule {
  public colorMap: PinnedMap = {};
  public hoveredItemId: string | null = null;
  public firstMatrixHoveredItemId: string | null = null;
  public secondMatrixHoveredItemId: string | null = null;
  public hoveredItemColor = '#b7294f';

  @Mutation
  private SET_COLOR_MAP(payload: PinnedMap): void {
    this.colorMap = payload;
  }
  @Action({ rawError: true })
  public async SetColorMap(payload: PinnedMap): Promise<void> {
    this.SET_COLOR_MAP(payload);
  }

  @Mutation
  private UNPIN_ALL_EXCEPT_SUBPORTFOLIO_UNDER_FOCUS(): void {
    const itemUnderAnalysis = AnalyticsStore.itemUnderAnalysis;
    if (!itemUnderAnalysis || isStrategy(itemUnderAnalysis)) return;

    for (const portfolioTreeId in this.colorMap) {
      if (portfolioTreeId !== itemUnderAnalysis.portfolioTreeId) {
        Vue.delete(this.colorMap, portfolioTreeId);
      }
    }
  }
  @Action({ rawError: true })
  public async UnpinAllExceptSubportfolioUnderFocus(): Promise<void> {
    this.UNPIN_ALL_EXCEPT_SUBPORTFOLIO_UNDER_FOCUS();
  }

  @Mutation
  private SET_HOVERED_ITEM_ID(id: string | null): void {
    this.hoveredItemId = id;
  }
  @Action({ rawError: true })
  public async SetHoveredItemId({ id }: { id: string | null }): Promise<void> {
    this.SET_HOVERED_ITEM_ID(id);
  }

  @Mutation
  private SET_FIRST_MATRIX_HOVERED_ITEM_ID(payload: { id: string | null }): void {
    this.firstMatrixHoveredItemId = payload.id;
  }
  @Action({ rawError: true })
  public async SetFirstMatrixHoveredItemId(payload: { id: string | null }): Promise<void> {
    this.SET_FIRST_MATRIX_HOVERED_ITEM_ID(payload);
  }

  @Mutation
  private SET_SECOND_MATRIX_HOVERED_ITEM_ID(payload: { id: string | null }): void {
    this.secondMatrixHoveredItemId = payload.id;
  }
  @Action({ rawError: true })
  public async SetSecondMatrixHoveredItemId(payload: { id: string | null }): Promise<void> {
    this.SET_SECOND_MATRIX_HOVERED_ITEM_ID(payload);
  }
}
export default getModule(PinnedStore);
