<template>
  <div class="p-1">
    <select
      v-model="localeModel"
      class="bg-primary text-white border-0 text-uppercase"
      style="text-align-last: right"
    >
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ langNames[lang] }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { LocaleFullNames, Locales, LOCALES_SET } from '@/constants/Locales';
import { computed, defineComponent } from 'vue';
import { useLocale } from '@/composables/useLocale';
import useTranslation from '@/composables/useTranslation';

export default defineComponent({
  name: 'LocaleChanger',
  setup() {
    const { locale, setLocale } = useLocale();

    const { translate } = useTranslation();
    const localeModel = computed<Locales>({
      get() {
        return locale.value;
      },
      set(toLocale) {
        return setLocale(toLocale);
      },
    });

    return {
      localeModel,
      langs: LOCALES_SET,
      langNames: LocaleFullNames,
      translate,
    };
  },
});
</script>
