
<script setup lang="ts">
const id = "moving-window"
 const title = "Moving Window"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"moving-window","title":"Moving Window","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"moving-window","title":"Moving Window","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The frequency with which you are recalibrating the factor beta coefficients in Rolling Regression analysis. For
example, if Moving Window = Monthly, this means that you are recalibrating the beta coefficients every month to
assess its stability over time.</p>
</div>
</template>
