
<script setup lang="ts">
const id = "pure-factors"
 const title = "Pure Factors"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"pure-factors","title":"Pure Factors","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"pure-factors","title":"Pure Factors","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Premialab’s proprietary benchmarks on risk premia and factor performances across asset classes constructed by
harvesting strategy data from leading index providers to capture the general market consensus. Premialab’s
methodology to construct an unbiased factor model consists of a rigorous three-step statistical analysis,
including clustering and PCA, to extract the maximum amount of market information while eliminating
model-specific interference. Pure Factor performances are in excess to their related market benchmark.</p>
</div>
</template>
