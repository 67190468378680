import { BasicAuthRequestDTO } from './types/BasicAuthRequestDTO';
import { PublicUserType } from './types/PublicUserType';
import axios from 'axios';

/**
 * Map of authentication-related URLs
 *
 * These are endpoints in api project, but client should redirect the browser to
 * these url instead of fetching them.
 */
export const AuthURL = {
  logout: `${import.meta.env.VITE_API_V2}/auth/logout`,
  auth0Login: (redirect?: string) => `${import.meta.env.VITE_API_V2}/auth/auth0/login?redirect=${redirect || ''}`,
  samlLogin: (provider: string = 'default') =>
    `${import.meta.env.VITE_API_V2}/auth/login/connection/saml?provider=${provider}`,
  /**
   * Public login. Used in HSBC for individual/institutional dialog.
   */
  publicLogin: (userType: PublicUserType) => `${import.meta.env.VITE_API_V2}/auth/public/login/${userType}`,
  // Technically impersonation is in admin, but placing here, sticking with other
  // auth-related urls.
  impersonate: (userId: number) => `${import.meta.env.VITE_API_V2}/admin/users/${userId}/impersonate`,
  unimpersonate: `${import.meta.env.VITE_API_V2}/auth/unimpersonate`,
  teamImpersonate: {
    impersonate: (teamId: string | number) => `${import.meta.env.VITE_API_V2}/auth/team-impersonate/${teamId}`,
    switchBack: `${import.meta.env.VITE_API_V2}/auth/team-impersonate/switch-back`,
  },
};

/**
 * Perform a basic (username/password) login.
 *
 * This endpoint is a bit special: After the promise resolve, the caller should
 * reload (by assigning `location.href`) the page and assume the authentication
 * has succeed. The server will set session cookie into the browser.
 */
export const basicLogin = (form: BasicAuthRequestDTO): Promise<unknown> =>
  axios({
    url: `${import.meta.env.VITE_API_V2}/auth/basic/login`,
    method: 'post',
    data: form,
  });
