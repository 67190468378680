import { ToastVariant } from '@/constants/ToastVariant';
// eslint-disable-next-line no-restricted-imports -- Import BvToastOptions to override it with simplier types
import { BvToastOptions } from 'bootstrap-vue';

export const DISPLAY_TOAST_EVENT = 'display-toast';

export type DisplayToastEventBus = Record<typeof DISPLAY_TOAST_EVENT, { variant: ToastVariant; options: ToastOptions }>;

// Patch type `BvToastOptions` to prevent the following ts error:
// `Type instantiation is excessively deep and possibly infinite. ts(2589)`
export type ToastOptions = BvToastOptions & { title?: string };
