
<script setup lang="ts">
const id = "undetermined-attribution"
 const title = "Variance Attribution by the undetermined component"
 const categories = ["PCA"]
 const hiddenCategories = ["PCA_FULL"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"undetermined-attribution","title":"Variance Attribution by the undetermined component","categories":["PCA"],"hiddenCategories":["PCA_FULL"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"undetermined-attribution","title":"Variance Attribution by the undetermined component","categories":["PCA"],"hiddenCategories":["PCA_FULL"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The unexplained variance that arises from allocation and rebalancing, expressed in variance term</p>
<p class="katex-block"><span class="katex-display"><span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML" display="block"><semantics><mrow><mi>V</mi><mi>a</mi><mi>r</mi><mo stretchy="false">(</mo><mi>e</mi><mo stretchy="false">)</mo></mrow><annotation encoding="application/x-tex">
Var(e)
</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:1em;vertical-align:-0.25em;"></span><span class="mord mathnormal">Va</span><span class="mord mathnormal" style="margin-right:0.02778em;">r</span><span class="mopen">(</span><span class="mord mathnormal">e</span><span class="mclose">)</span></span></span></span></span></p>
<dl>
<dt><span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mi>e</mi><mo>=</mo></mrow><annotation encoding="application/x-tex">e =</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.4306em;"></span><span class="mord mathnormal">e</span><span class="mspace" style="margin-right:0.2778em;"></span><span class="mrel">=</span></span></span></span></dt>
<dd>Error Term</dd>
</dl>
</div>
</template>
