import { RouteName } from '@/constants/RouteName';
import { RouteConfig } from 'vue-router';
import { authStrategy } from '@/auth';

export const AuthRouter: RouteConfig[] = [
  {
    name: RouteName.LOGIN,
    path: '/login',
    components: {
      // FIXME: Router is not updated with new component type definition
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: authStrategy.loginComponent as any,
    },
    meta: {
      title: 'Login',
    },
  },
  {
    name: RouteName.LOGOUT,
    path: '/logout',
    component: () => import('@/views/LogoutPage.vue'),
    meta: {
      title: 'Logout',
    },
  },
  {
    name: RouteName.UNAUTHORIZED,
    path: '/unauthorised',
    meta: { title: 'Unauthorised' },
    component: () => import('@/views/Unauthorised.vue'),
  },
];
