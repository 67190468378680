<script lang="ts">
import { defineComponent } from 'vue';
import { useUnauthRedirect } from '@/auth/shared/useUnauthRedirect';
import { AuthURL } from '@/api-v2/web/auth';
import { useBlockedRedirect } from '../shared/useIsAuthenticated';

export default defineComponent({
  name: 'BarclaysAuthRoot',
  setup() {
    useBlockedRedirect();

    // There is no way we can know which provider we are using in this case. Try with the default then.
    useUnauthRedirect(() => AuthURL.samlLogin());

    return () => null;
  },
});
</script>
