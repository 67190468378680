<template>
  <div
    style="width: inherit"
    class="position-relative"
  >
    <b-form-input
      ref="searchBox"
      v-model="searchBoxQuery"
      v-bind="$attrs"
      :debounce="300"
      autocomplete="off"
      :disabled="disabled"
      :state="state"
      class="pr-4"
      :class="classToUse"
      v-on="$listeners"
    />
    <b-form-invalid-feedback class="position-absolute">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <b-button
      v-if="searchBoxQuery !== '' && !disabled"
      class="position-absolute"
      :style="{
        'z-index': '1',
        top: '50%',
        right: '0%',
        transform: state !== null ? 'translateY(-50%) translateX(-20%)' : 'translateY(-50%) translateX(20%)',
      }"
      variant="blind-background"
      @click.stop="clearText"
    >
      <icon
        icon="remove"
        class="small-icon text-danger px-2"
      />
    </b-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useVModel } from '@vueuse/core';

/**
 * This component is meant to be used on all input elements, which are be used for searching or filtering
 * It adds a cross to the far right of the element allowing the text to be removed faster.
 *
 * We should aim to not use <b-input /> and only use <b-form-input /> when the input box is for a number.
 */
/**
 * For full list of props and events, please see the official docs here
 * https://bootstrap-vue.org/docs/components/form-input
 */
export default defineComponent({
  name: 'ClearableFormInput',
  components: {},
  props: {
    searchQuery: {
      type: String,
      required: true,
      default: '',
    },
    classToUse: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
    },
    state: {
      type: Boolean as PropType<boolean | null>,
      default: null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const searchBox = ref<HTMLInputElement | null>(null);

    const focus = () => {
      searchBox.value?.focus();
    };

    const select = () => {
      searchBox.value?.select();
    };

    const searchBoxQuery = useVModel(props, 'searchQuery', emit, { passive: true });

    /**
     * Clears the query and updates prop
     */
    const clearText = () => {
      searchBoxQuery.value = '';
    };

    return {
      // return the focus/select method so that it is exposed on the component
      // and can be called by the parent
      focus,
      select,
      searchBox,
      searchBoxQuery,
      clearText,
    };
  },
});
</script>
