import {
  DataDiscoveryResponseDTO,
  getDataUniverse,
  getPortfolioSummary,
  PortfolioItemResponseDTO,
  PortfolioSummaryResponseDTO,
  StrategyItemResponseDTO,
} from '@/api-v2/web/discover';
import { DataTypes, normalizePortfolioType, normalizeType } from '@/types/setting';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';

const keys = {
  /**
   * Note: keep this in sync with `discoveryAllKey()` in {@link file://./useStrategies.ts} and
   * {@link file://./usePortfolioTreeData.ts}.
   */
  all: () => [{ scope: 'discover' }] as const,
  portfolioSummary: () => [{ ...keys.all()[0], entity: 'portfolio-summary' }],
};

/**
 * Get the whole universe
 */
export function useDataDiscovery(options: VQQueryOptions<DataDiscoveryResponseDTO> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
  });
}

/**
 * Get all strategies.
 */
export function useAllStrategies(options: VQQueryOptions<StrategyItemResponseDTO[]> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    select(universe) {
      return universe.strategy ?? [];
    },
    ...options,
  });
}

/**
 * Get a map of strategy id -> strategy
 */
export function useAllStrategiesById(options: VQQueryOptions<StrategyItemResponseDTO[]> = {}) {
  const universe = useAllStrategies(options);

  return {
    data: computed(() => {
      return new Map(
        (universe.data.value ?? []).map((strategy) => {
          return [strategy.id, strategy];
        }),
      );
    }),
    isLoading: universe.isLoading,
  };
}

/**
 * Get a map of strategy code -> strategy
 *
 * Note: avoid using the objects returned by this map via `.get()` in a computed ref which is being fed back into
 * another vue-query. Calling `.has()` instead seems to be fine.
 */
export function useAllStrategiesByCode(options: VQQueryOptions<StrategyItemResponseDTO[]> = {}) {
  const universe = useAllStrategies(options);

  return {
    data: computed(() => {
      return new Map(
        (universe.data.value ?? []).map((strategy) => {
          return [strategy.code, strategy];
        }),
      );
    }),
    isLoading: universe.isLoading,
  };
}

/**
 * Get an array of strategy of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 *
 * This also exclude some DataTypes which are not queried by the same API.
 */
export function useStrategyByType(
  type: Exclude<DataTypes, DataTypes.SIGNAL | DataTypes.PORTFOLIO>,
  options: VQQueryOptions<StrategyItemResponseDTO[]> = {},
) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return (universe.strategy ?? []).filter((s) => normalizeType(s) === type);
    },
  });
}

/**
 * Get all portfolios.
 * For a map of portfolios, use {@link useAllPortfoliosBySlug} instead
 */
export function useAllPortfolios(options: VQQueryOptions<PortfolioItemResponseDTO[]> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return [...(universe.portfolio ?? [])];
    },
  });
}

/**
 * Get a map of portfolio id -> portfolio
 */
export function useAllPortfoliosById(options: VQQueryOptions<PortfolioItemResponseDTO[]> = {}) {
  const universe = useAllPortfolios(options);

  return {
    data: computed(() => {
      return new Map(
        (universe.data.value ?? []).map((portfolio) => {
          return [portfolio.portfolioId, portfolio];
        }),
      );
    }),
    isLoading: universe.isLoading,
  };
}

/**
 * Get a map of portfolio slug -> portfolio
 */
export function useAllPortfoliosBySlug(options: VQQueryOptions<PortfolioItemResponseDTO[]> = {}) {
  const universe = useAllPortfolios(options);

  return {
    data: computed(() => {
      return new Map(
        (universe.data.value ?? []).map((portfolio) => {
          return [portfolio.slug, portfolio];
        }),
      );
    }),
    isLoading: universe.isLoading,
  };
}

/**
 * Get an array of portfolios of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 */
export function usePortfolioByType(
  type: DataTypes.PORTFOLIO | DataTypes.BENCHMARK,
  options: VQQueryOptions<PortfolioItemResponseDTO[]> = {},
) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return (universe.portfolio ?? []).filter((o) => normalizePortfolioType(o) === type);
    },
  });
}

/**
 * Gets a summary of portfolios which strategies can be added to.
 */
export function usePortfolioSummary(options: VQQueryOptions<PortfolioSummaryResponseDTO[]> = {}) {
  return useQuery(keys.portfolioSummary(), () => getPortfolioSummary(), options);
}
