import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useQuery } from '@tanstack/vue-query';
import axios from 'axios';
import { Ref } from 'vue';

const keys = {
  all: () => [{ scope: 'knowledge-base' }] as const,
  article: (url: Ref<string | null>) => [{ ...keys.all()[0], entity: 'article', url }] as const,
};

/**
 * useKnowledgeBaseArticle - Get knowledge base article given url
 *
 * Note that the Knowledge Base articles are located in the public directory under /public/notion
 */
export function useKnowledgeBaseArticle(url: Ref<string>, options: VQQueryOptions<string> = {}) {
  return useQuery({
    ...options,
    queryKey: keys.article(url),
    queryFn: ({ signal }) => axios.get(url.value, { signal }).then((o) => o.data),
    staleTime: Infinity,
  });
}
