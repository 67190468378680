
<script setup lang="ts">
const id = "notional-exposure"
 const title = "Notional Exposure"
 const categories = ["EX_ANTE"]
 const hiddenCategories = null
 const related = null
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"notional-exposure","title":"Notional Exposure","categories":["EX_ANTE"],"hiddenCategories":null,"related":null}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"notional-exposure","title":"Notional Exposure","categories":["EX_ANTE"],"hiddenCategories":null,"related":null}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Notional values are useful for measuring leverage and market exposure. They are reported on Net, Gross, Long and Short basis and allow users to determine the size of their holdings.</p>
<p>At a Strategy level, notional is calculated bottom-up, as a sum across all holdings. By comparing the Gross Notional to the Strategy Value (calculated as Index Price * Number of Units of Index invested), we get the extent of leverage taken by that strategy. In the example, below an investor allocates $100 million to a strategy, with a gross notional calculated as $750 million and a resulting leverage of 7.5 times.</p>
<p>Notional definitions are specific to instrument types. Broadly speaking, for non-derivative instruments such as Common Stocks, ETFs or REITs, the notional is same as their market value. While, as a generalization, for derivative instruments the notional relates to exposure in an underlying asset.</p>
<div class="table-responsive">
 <table class="table my-5">
  <thead class="table-active">
   <tr>
    <th>Strategy Index Price</th>
    <th>Units Invested</th>
    <th>Strategy Value</th>
    <th>Gross Notional</th>
    <th>Implied leverage</th>
   </tr>
  </thead>
  <tbody>
   <tr>
    <td>$250</td>
    <td>40,000</td>
     <td>$ 100,000,000</td>
    <td>$ 750,047,980</td>
    <td>7.5x</td>
   </tr>
  </tbody>
 </table>
</div>
<p>For derivative instruments, another nuance is the assignment of positive or negative sign to the notional. For instance, if a portfolio holds an interest rate swap paying fixed rate and receiving floating, the notional is expressed as a positive amount, while notional for a receive-fixed swap is reported as a negative value.</p>
<p>Instrument-level notional values can be aggregated at higher levels, such as Strategy (as shown in the above example).</p>
<p>The following table describes notional calculations per product type. Currency conversion is applied to instruments where applicable and the notional is ultimately shown in the reporting currency, which is USD by default.</p>
<div class="table-responsive">
 <table class="table table-hover my-5">
  <thead class="table-active"> 
    <tr>
      <th scope="col">Product Type</th>
      <th scope="col">Notional (in instrument currency)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Bond Future<br>Interest Rate Future</td>
      <td>Number of Contracts * Notional Per Contract</td>
    </tr>
    <tr>
      <td>Cash</td>
      <td>Amount</td>
    </tr>
    <tr>
      <td>CDS Index<br></td>
      <td><u>Protection buyer</u>: Notional Amount<br><u>Protection seller</u>: -1 * Notional Amount</td>
    </tr>
    <tr>
      <td>CDS Index Option</td>
      <td><u>Long Protection buyer Call/Put</u>: Notional Amount<br><u>Short Protection buyer Call/Put</u>: -1 * Notional Amount</td>
    </tr>
    <tr>
      <td>Equity Index Future<br>Commodity Future</td>
      <td>Number of Contracts * Number of Shares Per Contract * Underlying Price</td>
    </tr>
    <tr>
      <td>Equity Option<br>Future Option</td>
      <td><u>Long Call/Put</u>: Number of Contracts * Number of Shares Per Contract * Underlying Price<br><u>Short Call/Put</u>: -1 * Number of Contracts * Number of Shares Per Contract * Underlying Price</td>
    </tr>
    <tr>
      <td>FX Forward</td>
      <td>Notional Amount of Forward Currency to be bought or sold</td>
    </tr>
    <tr>
      <td>FX Option</td>
      <td>Notional Amount of Option Currency to be bought or sold</td>
    </tr>
    <tr>
      <td>Interest Rate Swap</td>
      <td><u>Pay fix</u>: Notional Amount<br><u>Receive fix</u>: -1 * Notional Amount</td>
    </tr>
    <tr>
      <td>Common Stock, ETF, REIT, Index</td>
      <td>Number of Shares * Underlying Price</td>
    </tr>
    <tr>
      <td>Swaption</td>
      <td><u>Payer/Call</u>: Notional Amount<br><u>Receiver/Put</u>: -1 * Notional Amount </td>
    </tr>
    <tr>
      <td>Variance Swap</td>
      <td>Notional Amount</td>
    </tr>
  </tbody>
 </table>
</div>
</div>
</template>
