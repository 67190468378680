
<script setup lang="ts">
const id = "eigenvectors"
 const title = "Eigenvectors"
 const categories = ["PCA"]
 const hiddenCategories = ["PCA_FULL"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"eigenvectors","title":"Eigenvectors","categories":["PCA"],"hiddenCategories":["PCA_FULL"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"eigenvectors","title":"Eigenvectors","categories":["PCA"],"hiddenCategories":["PCA_FULL"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The eigenvectors represent a mapping between the independent Principal Components and the original strategies.
It allows us to calculate the Principal Component returns on each date as a linear combination of the strategy
returns, and vice versa.</p>
</div>
</template>
