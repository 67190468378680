import { Union } from 'ts-toolbelt';

/**
 * Assert given item is not null or undefined.
 *
 * Designed to use in `Array.prototype.filter` as this will make type inference work properly.
 */
export function notNull<T>(item: T): item is Union.NonNullable<T> {
  return item != null;
}
