
<script setup lang="ts">
const id = "momentum"
 const title = "Momentum"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"momentum","title":"Momentum","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"momentum","title":"Momentum","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Momentum refers to systematic strategies exploring the persistence of relative outperformance / underperformance
across a set of assets, by comparing their momentum versus each other (Cross-Sectional Momentum).</p>
</div>
</template>
