import { MetricConstants } from '@/constants/MetricConstants';
import { RouteName } from '@/constants/RouteName';
import { ChartTypes } from '../ChartTypes';
import {
  CONSTITUENT_RISK_VAR,
  CONSTITUENT_RISK_EQUITY,
  CONSTITUENT_RISK_INTEREST_RATE,
  CONSTITUENT_RISK_CREDIT,
  CONSTITUENT_RISK_FX,
  CONSTITUENT_RISK_STRESS_TEST,
  CONSTITUENT_RISK_COMMODITY,
} from './ConstituentRisk';
import {
  EQUITY_BASKET_ACTIVE_RETURN,
  EQUITY_BASKET_CORRELATION,
  EQUITY_BASKET_EFFICIENT_FRONTIER,
  EQUITY_BASKET_PERF_ATTRIB,
  EQUITY_BASKET_ROLLING,
  EQUITY_BASKET_SIMULATION,
} from './EquityBasket';
import { NavItem } from '../NavItem';
export interface AnalysisSubstepComponent {
  name: string;
  subComponents?: AnalysisSubstepComponent[];
}

export type PortfolioConstructionGraphTypes =
  | ChartTypes.TRACK_RECORD
  | ChartTypes.EXPOSURE
  | ChartTypes.EFFICIENT_FRONTIER
  | ChartTypes.ROLLING_VOLATILITY
  | ChartTypes.ROLLING_SHARPE
  | ChartTypes.PERFORMANCE_ATTRIBUTION_GROUP_BY
  | ChartTypes.CUMULATIVE_MONTHLY_RETURN_BREAKDOWN
  | ChartTypes.ROLLING_CORRELATION
  | ChartTypes.CORRELATION
  | ChartTypes.RETURN_REGRESSION
  | ChartTypes.CORRELATION_MATRIX
  | ChartTypes.MST
  | ChartTypes.ACTIVE_RETURN
  | ChartTypes.RETURN_COMPARISON
  | ChartTypes.RANKING_TABLE;

export type FactorDecompositionGraphTypes =
  | ChartTypes.FACTOR_HEATMAP
  | ChartTypes.RETURN_ATTRIBUTION
  | ChartTypes.REGRESSION_TRACK
  | ChartTypes.CUMULATIVE_RESIDUAL
  | ChartTypes.MONTHLY_RETURN
  | ChartTypes.ROLLING;

export interface AnalysisSubstep {
  title: string;
  graphTypes?: PortfolioConstructionGraphTypes[] | FactorDecompositionGraphTypes[];
  metricComponent?: {
    [key in MetricConstants]?: AnalysisSubstepComponent;
  };
  defaultComponents?: AnalysisSubstepComponent;
  historyComponents?: AnalysisSubstepComponent;
  path: string;
}

// TODO: FIND A WAY TO USE LEGACY NAME LESS
export type AnalysisStep = NavItem & {
  baseRoute?: string;
  legacyRoute?: string;
  legacyName: string;
  substeps: AnalysisSubstep[];
  iconSet?: string;
  mobileOnly: boolean;
  defaultSubstepPath: string;
  secondaryRoute?: string;
};

export const FOLDER: AnalysisStep = {
  // Temporarily Make this into the Portfolio Select modal button
  name: RouteName.FOLDER,
  // route: '/folder',
  route: '',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'folder',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const PERFORMANCE_ATTRIBUTION: AnalysisStep = {
  name: RouteName.PERFORMANCE_ATTRIBUTION,
  route: '/performance',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'chart-mixed',
  iconSet: 'fas',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const FACTSHEET: AnalysisStep = {
  // TODO: sort out what name this should be...
  name: 'Factsheet',
  route: '/portfolio',
  secondaryRoute: '/strategy',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-lines',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const CLUSTERING_ANALYSIS: AnalysisStep = {
  name: RouteName.PRINCIPAL_COMPONENT,
  route: '/clustering-analysis',
  legacyName: RouteName.CLUSTERING_ANALYSIS,
  disabled: true,
  substeps: [],
  icon: 'chart-network',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const CONSTITUENT_RISK: AnalysisStep = {
  name: RouteName.CONSTITUENT_RISK,
  legacyName: 'Constituent Risk',
  route: '/constituent-risk',
  disabled: false,
  substeps: [
    CONSTITUENT_RISK_VAR,
    CONSTITUENT_RISK_EQUITY,
    CONSTITUENT_RISK_INTEREST_RATE,
    CONSTITUENT_RISK_CREDIT,
    CONSTITUENT_RISK_FX,
    CONSTITUENT_RISK_STRESS_TEST,
    CONSTITUENT_RISK_COMMODITY,
  ],
  icon: 'chart-pie',
  mobileOnly: false,
  defaultSubstepPath: CONSTITUENT_RISK_VAR.path,
};

export const CONSTITUENT_POSITION: AnalysisStep = {
  name: RouteName.CONSTITUENT_POSITION,
  legacyName: 'Constituent Position',
  route: '/constituent-position',
  disabled: false,
  substeps: [],
  icon: 'cubes',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const BROCHURE_DOWNLOAD: AnalysisStep = {
  name: RouteName.BROCHURE_DOWNLOAD,
  route: '/',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'download',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const EQUITY_BASKET: AnalysisStep = {
  name: RouteName.EQUITY_BASKET,
  route: '/equity-basket',
  legacyName: 'Equity Basket',
  disabled: false,
  substeps: [
    EQUITY_BASKET_SIMULATION,
    EQUITY_BASKET_EFFICIENT_FRONTIER,
    EQUITY_BASKET_PERF_ATTRIB,
    EQUITY_BASKET_ROLLING,
    EQUITY_BASKET_CORRELATION,
    EQUITY_BASKET_ACTIVE_RETURN,
  ],
  icon: 'shopping-basket',
  mobileOnly: false,
  defaultSubstepPath: EQUITY_BASKET_SIMULATION.path,
};
