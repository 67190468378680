
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The performance attribution reflects the contribution of underlying index components to the overall index performance for a given time period. Contribution of “cash and fees” is used to reflect the return in excess of cash where applicable and embedded costs and fees, if any. The average weights represent the average of the daily weights of underlying index components for a given time period. The weights shown are calculated in such a way that they sum up to 100% (where applicable) and aim to reflect the relative importance of underlying assets within the portfolio. The effective return reflects underlying index component performance contribution divided by the respective average weight for a given time period. Sector definition follows Refintiv Business Sector classification; Regions/Countries/Currency data are provided by HSBC.</p>
</div>
</template>
