
<script setup lang="ts">
const id = "momentum-mean-reversion"
 const title = "Momentum (Mean Reversion)"
 const categories = ["STRATEGY_FILTER","FACTOR_DEFINITION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"momentum-mean-reversion","title":"Momentum (Mean Reversion)","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"momentum-mean-reversion","title":"Momentum (Mean Reversion)","categories":["STRATEGY_FILTER","FACTOR_DEFINITION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Mean Reversion Momentum strategies exploit the tendency for momentum to revert to its short-term or long-term
mean. It can work in either absolute or relative term.</p>
</div>
</template>
