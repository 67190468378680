<template>
  <div class="my-7">
    <h1 class="mb-5">About HSBC QIS</h1>
    <div class="font-size-big mb-5">
      HSBC’s Quantitative Investment Solutions (‘QIS’) are a diverse portfolio of innovative systematic strategies
      available across multiple asset classes including equities, fixed income, FX, commodities.
    </div>
    <div class="font-size-big">
      HSBC’s QIS Group is an award winning team of experienced market professionals that develops and implements unique
      rules-based solutions for institutional and professional clients around the world.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DisclaimerModalText',
});
</script>
