
<script setup lang="ts">
const id = "f-statistic"
 const title = "F-Statistic"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"f-statistic","title":"F-Statistic","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"f-statistic","title":"F-Statistic","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>It is the explained variance divided by the unexplained variance. A large F-statistic indicates that some or all chosen factors are statistically significant, while a small F-statistic indicates that the chosen factors do not have explanatory power. The F-statistic ranges from zero to infinity.</p>
</div>
</template>
