<template>
  <b-container>
    <b-overlay
      :show="loading"
      class="overlay-container"
    >
      <div ref="swaggerEl" />
      <b-card
        v-if="forbidden"
        text-variant="danger"
        header="Do not have right"
        class="text-center"
      >
        <b-card-text>Please contact us if you wish to have API access</b-card-text>
      </b-card>
    </b-overlay>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import 'swagger-ui-dist/swagger-ui.css';
import { SwaggerUIBundle } from 'swagger-ui-dist';
import { getSwagger } from '@/api-v2/web/docs';
import { AxiosError } from 'axios';
import { useToasts } from '@/composables/useToasts';
import { useTrackPageView } from '@/composables/useAppMetrics';

const { errorToast } = useToasts();
const pageName = 'Resources';
const subpageName = 'API Documentation';

export default defineComponent({
  name: 'ApiDocs',
  setup() {
    const loading = ref(false);
    const forbidden = ref(false);

    const swaggerEl = ref<HTMLElement | null>(null);
    useTrackPageView({ pageName, subpageName });

    onMounted(() => {
      loadSwagger();
    });

    const loadSwagger = async () => {
      loading.value = true;
      try {
        const swagger = await getSwagger();
        SwaggerUIBundle({
          domNode: swaggerEl.value as HTMLElement,
          defaultModelsExpandDepth: 3,
          spec: swagger,
        });
      } catch (e) {
        console.error(e);
        const response = (e as AxiosError).response;
        forbidden.value = !!(response && response.status === 403);
        errorToast('Failed to get swagger file');
      }
      loading.value = false;
    };

    return {
      loading,
      forbidden,
      swaggerEl,
    };
  },
});
</script>

<style lang="scss" scoped>
.overlay-container {
  min-height: 300px;
}
</style>
