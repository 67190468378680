# Translation override for whitelabel
# This should be empty for core platform

#########################################################################################################################
################################################# GLOBAL TRANSLATION ####################################################
#########################################################################################################################

GLOBAL:
  CHART_TITLE:
    TRACK_RECORD: 'Index Performance'

  DATABASE_TRANSLATOR:
    RETURN: 'Annualized Return'
    VOLATILITY: 'Annualized Volatility'
    SHARPE: 'Sharpe Ratio'
    MAXDD: 'Max Drawdown'
    PROVIDER: 'Index Sponsor'
    INDEXSOURCE: 'Calculation Agent'
    INDEXFEES: 'Index Fees'
    MANAGEMENT_FEE: 'Index Fees'
    HISTORY_START_DATE: 'Start Date'
    EFFECTIVE_RETURN: 'Effective Return'

  PORTFOLIO_CURRENCY: 'Currency'

#########################################################################################################################
################################################# FACTSHEET TRANSLATION #################################################
#########################################################################################################################

FACTSHEETS:
  PERFORMANCE_SUMMARY:
    TITLE: 'Performance Summary'
  INDEX_OVERVIEW: 'Index Overview'

DATA:
  DISPLAYING_NUM_TRACKS: 'Universe of %{num} Strategy | Universe of %{num} Strategies'
  DATA_UNIVERSE: 'Strategy Universe'

ADMIN:
  STRATEGIES_EDIT:
    UPLOAD_DOCUMENT: 'Upload Brochure'
    FACTSHEET: 'Index Overview'

ARTICLES:
  BLOCKS:
    TABLE:
      COL:
        RANK_DELTA: 'Change of Ranking'
        RATE_DELTA: 'Change in Forecast'
        RATING: 'Forecast'
  BANNER:
    FACTSHEET: 'Index Overview'

NAVIGATION_PANEL:
  ROW:
    SETTINGS:
      FACTSHEET: 'Index Overview'

APPLICATION_NAVBAR:
  DATA: 'Indices'
  INSIGHTS: 'Insights'
  INDICES: 'Indices'
  ABOUT: 'About'

PERFORMANCE_ATTRIBUTION:
  RETURN_DAILY_CHART_TITLE: '{groupFilter} Effective Return'
  RETURN_HISTORY: 'Effective Return History'

TOASTS:
  LOGIN_ERROR_TOAST: 'Wrong credentials - please try again or contact our IT team'
