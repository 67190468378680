import { ComponentOptions } from 'vue';
import { GlossaryCategory } from '@/types/glossary';

/**
 * Interface type for the frontmatter (The `---` block in markdown file)
 */
export interface GlossaryFrontmatter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentOptions<any>;
  id: string;
  title: string;
  categories: GlossaryCategory[];
  hiddenCategories: GlossaryCategory[];
  allCategories: GlossaryCategory[];
  related: string[];
}

const enumMap = new Map(Object.entries(GlossaryCategory));
const enumValues = new Set(Object.values(GlossaryCategory));

export const isGlossaryCategoryArray = (val: unknown) =>
  Array.isArray(val) && val.every((item) => enumValues.has(item));

function mapCategories(inputs: string[]): GlossaryCategory[] {
  const ret: GlossaryCategory[] = [];

  for (const input of inputs) {
    const item = enumMap.get(input);
    if (item == null) {
      console.warn(`Unknown glossary category ${input}`);
      continue;
    }

    ret.push(item);
  }

  // Ensure the categories is unique
  return [...new Set(ret).keys()];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseGlossaryFrontmatter(item: any): GlossaryFrontmatter {
  const categories = mapCategories(item.frontmatter.categories ?? []);
  const hiddenCategories = mapCategories(item.frontmatter.hiddenCategories ?? []);

  return {
    component: item.default,
    id: item.frontmatter.id,
    title: item.frontmatter.title,
    categories,
    hiddenCategories,
    related: item.frontmatter.related ?? [],
    allCategories: [...categories, ...hiddenCategories],
  };
}
