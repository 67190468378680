export enum DateFormat {
  YYYY_MM_DD = 'y-LL-dd',
  YYYY_MM_DD_HH_MM_SS = 'y-LL-dd hh:mm:ss',
  DD_MM_YYYY = 'dd-LL-y',
  YYYY_MM = 'y-LL',
  YYYY = 'y',
  MMMM = 'LLLL',
  MM_DD_YY = 'LL-dd-yy',
  DD_MM_YY = 'dd-LL-yy',
  YY_MM_DD = 'yy-LL-dd',
  MM_YY_DD = 'LL-yy-dd',
  DD_YY_MM = 'dd-yy-LL',
  YY_DD_MM = 'yy-dd-LL',
  DD_MMM_YYYY = 'dd LLL y',
  D_MMM_YYYY = 'd LLL y',
  // AMCHART formats need to follow the amcharts date format and not the luxon format
  D_MMM_YYYY_AMCHART = 'd MMM yyyy',
  ISO8601 = 'y-LL-ddTHH:mm:ssZ',
  ISO8601_MILLI = 'y-LL-ddTHH:mm:ss.SSSZ',
  HH_MM = 'HH:mm',
  MM_DD = 'LL-dd',
  MMM = 'LLL',
  MMM_YY = 'LLL yy',
  MMM_YYYY = 'LLL y',
  MMM_YYYY_AMCHART = 'MMM yyyy',
}

/**
 * Constants for modifying the behavior of createDate() and the formatDate Vue filter.
 */
export enum InputDateFormat {
  /**
   * Auto detect and parses dates as ISO8601 (w/ or w/o milliseconds) or YYYY-MM-DD only.
   * This is the default value.
   */
  AUTO_DETECT = 'auto_detect',
  /**
   * Parses dates as ISO8601 (w/ or w/o milliseconds) dates only.
   */
  ISO8601 = 'iso8601',
}
