import { computed, Ref } from 'vue';
import { isKnownLocale, Locales } from '@/constants/Locales';
import { useI18n } from 'vue-i18n-bridge';
import Cookies from 'js-cookie';
import { loadLocaleMessage } from '@/locales/loader';

const LOCALE_COOKIE = 'locale';

export interface UseLocaleReturn {
  /**
   * Current selected locale.
   *
   * This ref is not writable. Use `setLocale` for updating.
   */
  locale: Ref<Locales>;

  /**
   * @deprecated Please use the i18n functional component instead of this. Check PerformanceContributionToolbar.vue for example
   * Effectively a computed check on {@link locale} compare with {@link Locales.ENGLISH}.
   *
   * Some code are using this for formatting conjunction/grammar.
   * Not encouraged to use this. Please properly format your translations with parameters.
   */
  isLocaleEnglish: Ref<boolean>;
  setLocale: (locale: Locales) => Promise<void>;
}

/**
 * Get or set the current locale settings.
 *
 * Use this composable instead of `useI18n` for type safety on the known locales.
 */
export function useLocale(): UseLocaleReturn {
  const i18n = useI18n();

  const locale = computed((): Locales => {
    if (!isKnownLocale(i18n.locale.value)) {
      console.warn('$i18n.locale is not a known Locales', i18n.locale.value);
      return Locales.ENGLISH;
    }

    return i18n.locale.value;
  });

  const isLocaleEnglish = computed(() => locale.value === Locales.ENGLISH);

  const setLocale = async (toLocale: Locales) => {
    if (!isKnownLocale(toLocale)) {
      console.warn('Trying to set locale to unsupported locale. Setting to english instead', toLocale);
      toLocale = Locales.ENGLISH;
    }

    if (!i18n.availableLocales.includes(toLocale)) {
      console.log('Locale data not found in i18n. Loading locale', toLocale);
      const messages = await loadLocaleMessage(toLocale);
      i18n.setLocaleMessage(toLocale, messages);
    }

    i18n.locale.value = toLocale;
    document.documentElement.lang = toLocale;
    Cookies.set(LOCALE_COOKIE, toLocale);
  };

  return {
    locale,
    isLocaleEnglish,
    setLocale,
  };
}

/**
 * Get cookie locale setting and change locale base on the cookie.
 *
 * This is intentionally not reactive. Cookie set is managed by {@link useLocale}.
 *
 * This should be called once in the root component
 */
export function useLocaleCookie() {
  const { locale, setLocale } = useLocale();

  const cookie = Cookies.get(LOCALE_COOKIE) ?? Locales.ENGLISH;
  const toLocale = isKnownLocale(cookie) ? cookie : Locales.ENGLISH;

  if (locale.value === toLocale) {
    return;
  }

  setLocale(toLocale);
}
