import axios from 'axios';

export enum KnownError {
  /**
   * Timeout and network unavailable are treated identically as on some operating systems let the request timeout
   * instead of aborting the connection immediately when there is no network.
   */
  NetworkError = 'NetworkError',
}

/**
 * List of constants which are returned as the `errorCode` from API.
 *
 * Keep this in sync with API.
 */
export enum ErrorCodeConstants {
  /**
   * Returned when portfolio tree calculation APIs cannot calculate weight, track, etc because there are no active
   * portfolios in that portfolio tree.
   */
  NO_ACTIVE_STRATEGIES = 'NO_ACTIVE_STRATEGIES',
  /**
   * Returned when one of the correlation is not calculated or length of track does not match with length of dates.
   */
  BENCHMARK_NOT_ENOUGH_DATA = 'BENCHMARK_NOT_ENOUGH_DATA',
  /**
   * Returned when the specified strategy has no track
   */
  MISSING_TRACK = 'MISSING_TRACK',
  /**
   * Returned when the portfolio slug is not recognized
   */
  PORTFOLIO_NOT_FOUND = 'PORTFOLIO_NOT_FOUND',
  /**
   * Returned when the given upload ticket has expired
   */
  UPLOAD_TICKET_EXPIRED = 'UPLOAD_TICKET_EXPIRED',

  // TODO : Change these depending on api response error message

  /**
   * Returned as default when error 500 has no reason
   */
  ERROR_LOADING_DATA = 'ERROR_LOADING_DATA',
  /**
   * Returned as default when error 500 has no reason for item under analysis master portfolio tree
   */
  ERROR_MASTER_PORTFOLIO = 'ERROR_MASTER_PORTFOLIO',
  CORRELATION_IS_NOT_AVAILABLE_FOR_SINGLE_ITEMS = 'CORRELATION_IS_NOT_AVAILABLE_FOR_SINGLE_ITEMS',
}

export const ErrorCodeConstantsTranslated = {
  [ErrorCodeConstants.NO_ACTIVE_STRATEGIES]: { path: 'GLOBAL.ERROR', item: ErrorCodeConstants.NO_ACTIVE_STRATEGIES },
  [ErrorCodeConstants.ERROR_LOADING_DATA]: { path: 'GLOBAL.ERROR', item: ErrorCodeConstants.ERROR_LOADING_DATA },
  [ErrorCodeConstants.ERROR_MASTER_PORTFOLIO]: {
    path: 'GLOBAL.ERROR',
    item: ErrorCodeConstants.ERROR_MASTER_PORTFOLIO,
  },
  [ErrorCodeConstants.MISSING_TRACK]: {
    path: 'GLOBAL.ERROR',
    item: ErrorCodeConstants.MISSING_TRACK,
  },
  [ErrorCodeConstants.CORRELATION_IS_NOT_AVAILABLE_FOR_SINGLE_ITEMS]: {
    path: 'FACTSHEETS.SMALL_GRAPH',
    item: ErrorCodeConstants.CORRELATION_IS_NOT_AVAILABLE_FOR_SINGLE_ITEMS,
  },
};

/**
 * Parses the exception thrown and identifies network errors and API errors.
 */
export const parseResponseError = (e: unknown): KnownError | ErrorCodeConstants | null => {
  if (!axios.isAxiosError(e)) {
    return null;
  }

  if (e.code === 'ECONNABORTED' || e.code === 'ERR_NETWORK') {
    return KnownError.NetworkError;
  }

  if (e.response) {
    const data: { errorCode?: unknown } = e.response.data ?? {};

    if (data && typeof data.errorCode === 'string') {
      const errorCode = data.errorCode ?? null;

      if (Object.values(ErrorCodeConstants).includes(errorCode as ErrorCodeConstants)) {
        return data.errorCode as ErrorCodeConstants;
      }
    }
  }

  return null;
};
