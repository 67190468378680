// eslint-disable-next-line no-restricted-imports -- We are wrapping useMutation here so need to import it.
import { MutationFunction, MutationKey, QueryClient, useMutation, useQueryClient } from '@tanstack/vue-query';
import { onScopeDispose } from 'vue';

export interface DefineMutationOptions<TData, TError, TVariables, TContext> {
  /**
   * The mutation function (usually HTTP POST request to be made)
   */
  mutationFn: MutationFunction<TData, TVariables>;

  /**
   * Optional mutation key for identifying the mutation or inspecting the mutation through `useIsMutating`.
   *
   * This is currently optional in our vue-query usage guideline. We may change
   * this later on.
   */
  mutationKey?: MutationKey;

  /**
   * Function that updates the query cache base on response data. This is essentially `onSuccess` callback. But we
   * have additional logic around these callback functions.
   *
   * You probably want to call `client.setQueriesData` in this function.
   *
   * This function will run in `onSuccess` phase (and will not run if the mutation returned error).
   *
   * Do **not** return a promise here. This callback is expected to be synchronous.
   *
   * @param client Query client returned from `useQueryClient`
   * @param data Data returned from the mutationFn (probably the server's data)
   * @param variables Variable used for the mutation
   *
   * @see invalidateCache
   */
  updateCache?: (client: QueryClient, data: TData, variables: TVariables) => void;

  /**
   * Function for invalidating the query cache.
   *
   * If `updateCache` is defined, this will run in `onSettled` callback. Otherwise this will run in `onSuccess` and `onError`
   * callback.
   *
   * You should be returning the promises from `client.invalidateQueries` in this function. If there are multiple keys
   * to be invalidated, use {@link Promise.all} to aggregate them.
   *
   * @param client Query client returned from `useQueryClient`
   * @param data Data returned from the mutationFn (probably the server's data)
   * @param variables Variable used for the mutation
   *
   * @see updateCache
   */
  invalidateCache: (client: QueryClient, variables: TVariables, data: TData | undefined) => Promise<unknown>;

  onMutate?: (variables: TVariables) => Promise<TContext | undefined> | TContext | undefined;

  /**
   * Basically the `onSuccess` callback from {@link useMutation}.
   *
   * When this is called, the data in query cache must have been updated by
   * either {@link invalidateCache} or {@link updateCache}.
   */
  onSuccess?: (data: TData, variables: TVariables) => Promise<unknown> | void;
  onError?: (err: TError, variables: TVariables) => Promise<unknown> | void;
  onSettled?: (data: TData | undefined, error: TError | null, variables: TVariables) => Promise<unknown> | void;
}

export interface VQMutationOptions<TData = unknown, TError = unknown, TVariables = void> {
  onSuccess?: (data: TData, variables: TVariables) => Promise<unknown> | void;
  onError?: (err: TError, variables: TVariables) => Promise<unknown> | void;
  onSettled?: (data: TData | undefined, error: TError | null, variables: TVariables) => Promise<unknown> | void;
}

/**
 * Create a mutation with managed lifecycle on data invalidation.
 *
 * This is a lower level function. Use this if your `mutationFn` requires other
 * composables. Otherwise you should use {@see defineMutation} instead.
 *
 * @see defineMutation
 */
export function useManagedMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  factoryOptions: DefineMutationOptions<TData, TError, TVariables, TContext>,
  options: VQMutationOptions<TData, TError, TVariables>,
) {
  const client = useQueryClient();

  // Flag for tracking if we are still in an effect scope (usually means if the component is still mounted).
  // We are not calling options passed in by component if the component has unmounted.
  let inEffect = true;
  onScopeDispose(() => {
    inEffect = false;
  });

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: factoryOptions.mutationFn,
    mutationKey: factoryOptions.mutationKey,
    onMutate: factoryOptions.onMutate,

    async onSuccess(data, variables) {
      if (factoryOptions.updateCache) {
        factoryOptions.updateCache(client, data, variables);
      } else {
        await factoryOptions.invalidateCache(client, variables, data);
      }

      await factoryOptions.onSuccess?.(data, variables);
      if (inEffect) {
        await options.onSuccess?.(data, variables);
      }
    },

    async onError(err, variables) {
      if (!factoryOptions.updateCache) {
        await factoryOptions.invalidateCache(client, variables, undefined);
      }

      await factoryOptions.onError?.(err, variables);
      if (inEffect) {
        await options.onError?.(err, variables);
      }
    },

    async onSettled(data, err, variables) {
      if (factoryOptions.updateCache) {
        await factoryOptions.invalidateCache(client, variables, data);
      }

      await factoryOptions.onSettled?.(data, err, variables);
      if (inEffect) {
        await options.onSettled?.(data, err, variables);
      }
    },
  });
}

export function defineMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  factoryOptions: DefineMutationOptions<TData, TError, TVariables, TContext>,
) {
  return (options: VQMutationOptions<TData, TError, TVariables> = {}) => {
    return useManagedMutation(factoryOptions, options);
  };
}
