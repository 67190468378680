import { DateFormat, InputDateFormat } from '@/constants/DateFormat';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { MaybeRef } from '@vueuse/core';
import { groupBy, mapValues } from 'lodash';
import { DateTime, DurationObjectUnits } from 'luxon';
import { computed, unref } from 'vue';

/**
 * Supported date source
 */
export type DateSource = DateTime | Date | string | null;

export interface CreateDateOptions {
  /**
   * Format of the input. Only used when the input date is type `string`.
   *
   * It is recommended to use the formats specified in {@link InputDateFormat}.
   * However, this input also accepts format recognized by luxon.
   *
   * @default InputDateFormat.AUTO_DETECT
   * @see https://moment.github.io/luxon/#/parsing?id=ad-hoc-parsing
   */
  format?: InputDateFormat | string; // Add `InputDateFormat` for better type hint

  /**
   * If false, the returned date will be corrected to the closest business day.
   *
   * In general, you do NOT want to use this parameter. However for some cases we
   * want to allow non business dates (e.g., for the monthly return array, the beginning of the month could be
   * a non business day). So in that case we will allow a non business day.
   *
   * @default false
   */
  allowNonBusiness?: boolean;

  /**
   * If false and the requested date is not a business date, then it will go BACKWARDS to find the next business date.
   * If true and the requested date is not a business date, then it will go FORWARDS to find the next business date.
   *
   * @default false
   */
  add?: boolean;
}

const dateFromNow = (): DateTime => {
  return DateTime.utc().startOf('day');
};

const dateFromJS = (date: Date): DateTime => {
  return DateTime.fromJSDate(date, { zone: 'utc' }).startOf('day');
};

const dateFromString = (date: string, format: InputDateFormat | string): DateTime => {
  // Automatically detect ISO8601 dates (w/ or w/o milliseconds). Otherwise, we assume YYYY-MM-DD.
  if (format === InputDateFormat.AUTO_DETECT) {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{1,3})?Z$/.test(date)) {
      return DateTime.fromISO(date, { zone: 'utc' }).startOf('day');
    } else {
      return DateTime.fromFormat(date, DateFormat.YYYY_MM_DD, { zone: 'utc' }).startOf('day');
    }
  }

  // Handle ISO8601 dates (w/ or w/o milliseconds).
  if (format === InputDateFormat.ISO8601 || format === DateFormat.ISO8601 || format === DateFormat.ISO8601_MILLI) {
    return DateTime.fromISO(date, { zone: 'utc' }).startOf('day');
  }

  return DateTime.fromFormat(date, format, { zone: 'utc' }).startOf('day');
};

/**
 * Case switch for calling `dateFrom*`
 */
const dateFrom = (date: DateSource, format: InputDateFormat | string): DateTime => {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return dateFromJS(date);
  }

  if (typeof date === 'string') {
    return dateFromString(date, format);
  }

  // Remaining typeof date should be null. So if it's not null someone has passed in incorrect type.
  if (date !== null) {
    throw new Error('Unknown date source type');
  }

  return dateFromNow();
};

/**
 * Calculate the business date by adding/subtracting days from given date
 */
const updateBusinessDate = (date: DateTime, add: boolean, days: number): DateTime => {
  date = convertToBusiness(date, add);

  for (let i = 0; i < days; i++) {
    date = add ? date.plus({ days: 1 }) : date.minus({ days: 1 });
    date = convertToBusiness(date, add);
  }

  return date;
};

/**
 * Convert given date to a business date.
 *
 * @see createDate
 */
export const convertToBusiness = (date: DateTime, add?: boolean): DateTime => {
  /**
   * Add .plus(0) because of some strange bug with zones or something
   * https://github.com/moment/luxon/issues/740#issuecomment-873669016
   */
  const isSaturday = date.plus(0).weekday === 6;
  const isSunday = date.plus(0).weekday === 7;
  if (isSaturday) {
    if (add) return date.plus({ days: 2 }).startOf('day');
    return date.minus({ days: 1 }).startOf('day');
  }
  if (isSunday) {
    if (add) return date.plus({ days: 1 }).startOf('day');
    return date.minus({ days: 2 }).startOf('day');
  }
  return date.startOf('day');
};

/**
 * Plus business days to given date and if the given date is not a business day,
 * it will be converted to the closest business day first
 */
export const plusBusinessDays = (date: DateTime, days: number): DateTime => {
  return updateBusinessDate(date, true, days);
};

/**
 * Minus business days to given date and if the given date is not a business day,
 * it will be converted to the closest business day first
 */
export const minusBusinessDays = (date: DateTime, days: number): DateTime => {
  return updateBusinessDate(date, false, days);
};

/**
 * Try create a date from given input.
 *
 * We centralized date creation into this function so that the date correction mechanism
 * is explicit throughout our codebase.
 *
 * @param date Source date.
 * If string, this will parse the date with format specified in `option.format`
 * If null/undefined, this will return the current date.
 * @param options See documentation of the option interface
 *
 * This function defaults to going BACKWARDS in time. If you want the given date or AFTER,
 * you must supply { add: true } to the options.
 */
export const createDate = (date: DateSource = null, options: CreateDateOptions = {}): DateTime => {
  const format = options.format ?? InputDateFormat.AUTO_DETECT;
  const allowNonBusiness = options.allowNonBusiness ?? false;

  const retval = dateFrom(date, format);

  if (retval.invalidExplanation) {
    console.error({
      date,
      format,
      reason: retval.invalidExplanation,
    });
  }

  if (allowNonBusiness) {
    return retval;
  }

  return convertToBusiness(retval, options.add);
};

export const formatDate = (
  input: DateTime | string | undefined | null,
  outputFormat = DateFormat.YYYY_MM_DD,
  inputFormat: string = InputDateFormat.AUTO_DETECT,
): string => {
  if (!input) return '';
  if (input === '-') return input;

  let dateToUse: DateTime;
  if (typeof input === 'string') {
    dateToUse = dateFromString(input, inputFormat);
  } else {
    dateToUse = input;
  }

  if (dateToUse.invalidExplanation) {
    console.error({
      dateToUse,
      outputFormat,
      inputFormat,
      reason: dateToUse.invalidExplanation,
    });

    if (typeof input === 'string') return input;
  }

  return dateToUse.toFormat(outputFormat);
};

/**
 * Gets a calendar representation of a month in a given year.
 *
 * Returns an array of weeks. Weeks start on Sundays. Each week always has 7 days. Days belonging to the passed in
 * yearMonth are returned in YYYY-MM-DD format. Days belonging to other yearMonths are returned as null.
 *
 * If a historyStartDate is specified, the calendar starts from the week of it. Any days in that week that are earlier
 * than it are also returned as null.
 *
 * @param yearMonth The month in YYYY-MM format
 * @param historyStartDate The minimum date to start from in YYYY-MM-DD format.
 */
function getCalendarMonth(yearMonth: string, historyStartDate?: string): (string | null)[][] {
  const weeks = [];

  const startDate =
    historyStartDate && historyStartDate.startsWith(yearMonth)
      ? new Date(historyStartDate)
      : new Date(`${yearMonth}-01`);

  let currentWeek: (string | null)[] = [];
  // Pad the week with null for days that belong to the previous month, or days that are earlier than the
  // historyStartDate
  for (let i = 0; i < startDate.getDay(); i++) {
    currentWeek.push(null);
  }
  weeks.push(currentWeek);

  // Loop through all days in month
  const date = new Date(startDate);
  while (date.getUTCMonth() === startDate.getUTCMonth()) {
    // Add new week
    if (currentWeek.length == 7) {
      currentWeek = [];
      weeks.push(currentWeek);
    }

    currentWeek.push(date.toISOString().slice(0, 10));

    // Loop to next day
    date.setDate(date.getDate() + 1);
  }

  // Pad the week with null for days that belong to the next month
  while (currentWeek.length < 7) {
    currentWeek.push(null);
  }

  return weeks;
}

/**
 * Get the text to display in a calendar table cell for a date.
 */
function getCellTextForDate(date: string, hasShownMonthName: boolean) {
  const dateObject = new Date(date);
  const dayOfWeek = dateObject.getUTCDay();

  // Don't return any text if the day of week is sat / sun
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return '';
  }

  // If the month name has been shown once, just print out the day of month
  if (hasShownMonthName) {
    return dateObject.getUTCDate().toString();
  }

  // Print out the day of month and the month short name
  return createDate(date, { allowNonBusiness: true }).toFormat('d LLL');
}

export type DenseCalendar = {
  [year: string]: {
    /**
     * Other array: weeks in month
     * Inner array: days in week (null means that that day belongs to previous/next month)
     */
    [month: string]: ({
      displayDate: string;
      date: string;
      count?: number | undefined;
    } | null)[][];
  };
};

/**
 * Generates a DenseCalendar object which can be used with the DenseCalendar.vue component
 */
export function generateDenseCalendar(
  input: {
    date: string;
    count: number;
  }[],
  historyStartDate?: string,
): DenseCalendar {
  return mapValues(
    groupBy(input, (o) => o.date.slice(0, 4)),
    (yearlyDatum) =>
      mapValues(
        groupBy(yearlyDatum, (o) => o.date.slice(0, 7)),
        (monthlyDatum, monthName) => {
          const monthlyDatumMap = new Map(monthlyDatum.map((o) => [o.date, o]));

          let hasShownMonthName = false;
          const datesGroupedByWeek = getCalendarMonth(monthName, historyStartDate);

          const weeks = Object.entries(datesGroupedByWeek).map(([, weekDays]) => {
            return weekDays.map((date) => {
              if (!date) {
                return null;
              }
              const displayDate = getCellTextForDate(date, hasShownMonthName);
              if (displayDate) {
                hasShownMonthName = true;
              }
              const datum = monthlyDatumMap.get(date);
              return { ...datum, displayDate, date };
            });
          });
          const weeksWithData = weeks.filter((weekDay) => weekDay.some((o) => o && (o.displayDate || o.count)));
          return weeksWithData;
        },
      ),
  );
}

/**
 * Generates a text summary of a list of dates
 */
export function getDateSummary(dates: string[] | null) {
  if (dates === null) {
    return '';
  }
  if (dates.length === 0) {
    return 'No dates found';
  }
  if (dates.length === 1) {
    return `${dates[0]} \u2014 1 date found`;
  }
  if (dates.length === 2) {
    return `${dates[0]}, ${dates[1]} \u2014 2 dates found`;
  }
  return `${dates[0]} ... ${dates[dates.length - 1]} \u2014 ${dates.length} dates found`;
}

/**
 * Used to check if date follows intended string format (YYYY-MM-DD)
 */
export const REGEX_DATE_INPUT_FORMAT = /^\d{4}-\d{2}-\d{2}$/;

interface QueryWithDates {
  startDate: string;
  endDate: string;
}

interface QueryWithMultipleStartDates {
  startDates: string[];
  endDate: string;
}

/**
 * Designed to be used with the majority of our queries
 * Prevents the calling of the query if the `startDate` and `endDate`
 * parameters are the same
 */
export const enforceMinimumDate = (
  query: MaybeRef<QueryWithDates | QueryWithMultipleStartDates | undefined | null>,
) => {
  return computed(() => {
    const unwrapped = unref(query);

    // return true to guarantee same results without this computed
    if (!unwrapped) return true;

    if ('startDates' in unwrapped) {
      return (unwrapped.startDates as string[]).some((date) => date === unwrapped.endDate);
    }

    // otherwise return false if the dates are the same
    return unwrapped.endDate !== unwrapped.startDate;
  });
};

export const convertReturnIntervalToDurationInput = (
  num: number,
  returnInterval: ReturnInterval,
): DurationObjectUnits => {
  switch (returnInterval) {
    case ReturnInterval.DAILY:
      return { days: num };
    case ReturnInterval.WEEKLY:
      return { weeks: num };
    case ReturnInterval.MONTHLY:
      return { months: num };
  }
};
