import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';
import { AppMain } from '@/layout/components';

const articleEditRoute: RouteConfig = {
  name: RouteName.ARTICLE_EDIT,
  path: 'edit/:articleCode?',
  meta: {
    title: 'Insights Edit',
    isMainContentContainerFluid: true,
  },
  component: () => import('@/views/articles/ArticleEditor.vue'),
};

const articleFeedRoute: RouteConfig = {
  name: RouteName.ARTICLE_FEED,
  path: 'feed/:articleCode',
  meta: {
    title: 'Insights Feed',
    isMainContentContainerFluid: true,
  },
  component: () => import('@/views/articles/ArticleFeed/ArticleFeed.vue'),
};

const articlesAdminRoute: RouteConfig = {
  name: RouteName.ARTICLES_ADMIN,
  path: 'admin-page',
  meta: {
    title: 'Insights Admin',
  },
  component: () => import('@/views/admin/articles/AdminArticlePage.vue'),
  props: true,
};

export const ArticleRouter: RouteConfig[] = [
  {
    path: '/insights',
    alias: '/articles',
    meta: {
      title: 'Insights',
    },
    components: {
      default: AppMain,
    },
    children: [
      articlesAdminRoute,
      articleFeedRoute,
      articleEditRoute,
      {
        name: RouteName.ARTICLES,
        path: '',
        meta: {
          title: 'Insights',
        },
        components: {
          default: () => import('@/views/articles/ArticleLandingPage.vue'),
        },
      },
    ],
  },
];
