
<script setup lang="ts">
const id = "var-parametric"
 const title = "Parametric VaR"
 const categories = ["EX_ANTE","VALUE_AT_RISK"]
 const hiddenCategories = ["RISK_METRICS","PERFORMANCE_METRICS"]
 const related = ["x-var"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"var-parametric","title":"Parametric VaR","categories":["EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS","PERFORMANCE_METRICS"],"related":["x-var"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"var-parametric","title":"Parametric VaR","categories":["EX_ANTE","VALUE_AT_RISK"],"hiddenCategories":["RISK_METRICS","PERFORMANCE_METRICS"],"related":["x-var"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Parametric VaR (also called variance-covariance VaR) method assumes that returns follow normal distributions. The method looks at a historical period and calculates the standard deviation of the portfolio for this period. The VaR is then calculated by multiplying the portfolio standard deviation by the quantile of the chosen confidence level (e.g., 99% confidence level parametric VaR = 2.33 <span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mo>×</mo></mrow><annotation encoding="application/x-tex">\times</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.6667em;vertical-align:-0.0833em;"></span><span class="mord">×</span></span></span></span> portfolio volatility)</p>
</div>
</template>
