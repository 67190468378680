import { RouteConfig } from 'vue-router';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName } from '@/constants/RouteName';
import { CONSTITUENT_RISK } from '@/types/analytics/AnalysisStep';

export const ConstituentRiskRouter: RouteConfig[] = [
  {
    name: RouteName.CONSTITUENT_RISK,
    path: 'constituent-risk/:indexUniqueIdentifier?/:substep?',
    alias: ['constituent-risk-new/:indexUniqueIdentifier?/:substep?'],
    meta: {
      title: CONSTITUENT_RISK.name,
      shouldNavigationPanelBeShown: true,
      isMainContentContainerFluid: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.CONSTITUENT],
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/ConstituentRisk.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ConstituentRiskToolbar.vue'),
    },
  },
  {
    name: RouteName.CONSTITUENT_RISK_PDF,
    path: 'constituent-risk-pdf/:indexUniqueIdentifier',
    component: () => import('@/views/analytics/constituent-risk/ConstituentRiskPdf.vue'),
    meta: {
      title: 'Constituent Risk PDF',
      pdf: true,
    },
  },
];
