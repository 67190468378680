
<script setup lang="ts">
const id = "minimum-spanning-tree"
 const title = "Minimum Spanning Tree"
 const categories = null
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"minimum-spanning-tree","title":"Minimum Spanning Tree","categories":null}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"minimum-spanning-tree","title":"Minimum Spanning Tree","categories":null}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Minimum Spanning Tree (MST) represents the relationship between a list of strategies where the the visible connecting edges are the largest correlation pair between two strategies and the length of each edge is inversely proportional to the correlation value.</p>
<p>The first step of the MST computation is to generate a correlation matrix, then the Prim’s algorithm is applied to build the tree structure one vertex at a time, from an arbitrary strategy as a root, at each step adding the highest correlation pair from the tree to another strategy.</p>
</div>
</template>
