<template>
  <div>
    <b-row
      class="pt-2"
      no-gutters
    >
      <b-col class="d-flex align-items-center">
        <h3 class="m-0">{{ translate({ path: 'KNOWLEDGE_BASE', item: 'SELECT_A_CATEGORY' }) }}</h3>
      </b-col>
    </b-row>
    <b-row :cols="cols">
      <b-col
        v-for="category of categories"
        :key="category.key"
        class="mt-4"
      >
        <b-card
          class="h-100 cursor-pointer hover-grey"
          header-class="my-auto border-0"
          header-bg-variant="transparent"
          body-class="my-auto"
          img-left
          @click.prevent="clickCategory(category.key)"
        >
          <template #header>
            <Icon
              :icon="category.icon"
              size="3x"
              fixed-width
              class="text-info"
            />
          </template>
          <b-card-title>{{ translate({ path: 'KNOWLEDGE_BASE.CATEGORIES', item: category.key }) }}</b-card-title>
          <b-card-sub-title>{{ translate({ path: 'KNOWLEDGE_BASE', item: 'LEARN_MORE' }) }}</b-card-sub-title>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <h3>{{ translate({ path: 'KNOWLEDGE_BASE', item: 'POPULAR_ARTICLES' }) }}</h3>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-list-group>
          <b-list-group-item v-if="articles.length == 0">
            {{ translate({ path: 'VIRTUAL_LIST.NO_RESULTS' }) }}
          </b-list-group-item>
          <b-list-group-item
            v-for="article of articles.slice(0, 5)"
            :key="article.title"
            button
            @click="clickArticle(article.category)"
          >
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-ellipsis"> {{ article.title }} </span>
              <icon
                icon="angle-right"
                class="float-right"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { useElementSize } from '@vueuse/core';
import { KnowledgeBaseCategory, KnowledgeBaseMode } from '@/types/KnowledgeBase';
import useTranslation from '@/composables/useTranslation';
import useAppMetrics from '@/composables/useAppMetrics';
import { TRACKING_BUTTON_NAME } from '@/types/analytics';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { useRouteRef } from '@/composables/useRouter';
import { useHasPermission } from '@/composables/usePermission';
import { UserPermission } from '@/constants/UserPermission';
import { ApplicationNavbarHeight } from '@/constants/ApplicationNavbarHeight';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

export default defineComponent({
  name: 'KnowledgeBaseLandingPage',
  components: {},
  props: {},
  setup() {
    const { translate } = useTranslation();

    const { mode, articles, sidebarHtmlElement, navigateTo } = useKnowledgeBase();

    const route = useRouteRef();

    const { trackButtonClick } = useAppMetrics();

    const isAdmin = useHasPermission(UserPermission.ADMIN);

    // Dynamically adjust the number of b-cards in a row
    // based on the width of the sidebar
    const { width } = useElementSize(sidebarHtmlElement);

    const maxWidthForOneColumn = 720;
    const maxWidthForTwoColumns = 1080;

    const cols = computed(() => {
      if (mode.value === KnowledgeBaseMode.PAGE || width.value > maxWidthForTwoColumns) {
        return 3;
      }
      if (width.value <= maxWidthForTwoColumns && width.value > maxWidthForOneColumn) {
        return 2;
      }
      return 1;
    });

    /**
     * Trigger a navigation event when an article is clicked
     * @param val category of the article being clicked
     */
    const clickArticle = (val: KnowledgeBaseCategory) => {
      navigateTo(val);
      trackButtonClick(TRACKING_BUTTON_NAME.KNOWLEDGE_BAR_ARTICLE, {
        pageName: mode.value === KnowledgeBaseMode.PAGE ? 'Knowledge Base Page' : 'Knowledge Base Sidebar',
        subpageName: val,
      });
    };

    /**
     * Trigger a navigation event when a category is clicked
     * @param val category being clicked
     */
    const clickCategory = (val: KnowledgeBaseCategory) => {
      navigateTo(val);
      trackButtonClick(TRACKING_BUTTON_NAME.KNOWLEDGE_BAR_CATEGORY, {
        pageName: mode.value === KnowledgeBaseMode.PAGE ? 'Knowledge Base Page' : 'Knowledge Base Sidebar',
        subpageName: val,
      });
    };

    const { canUserSeeKnowledgeBaseArticles, shouldShowNotionAsCategory } = useFeatureFlag();

    const categories = computed(() => {
      return [
        {
          key: KnowledgeBaseCategory.GETTING_STARTED,
          icon: 'laptop',
        },
        ...(canUserSeeKnowledgeBaseArticles.value
          ? [
              {
                key: KnowledgeBaseCategory.PURE_FACTORS,
                icon: 'chart-scatter-3d',
              },
            ]
          : []),
        {
          key: KnowledgeBaseCategory.WHITEPAPER,
          icon: 'file-chart-pie',
        },
        {
          key: KnowledgeBaseCategory.MY_LAB,
          icon: 'cloud-arrow-up',
        },
        {
          key: KnowledgeBaseCategory.PORTFOLIO_CONSTRUCTION,
          icon: 'chart-line',
        },
        ...(canUserSeeKnowledgeBaseArticles.value
          ? [
              {
                key: KnowledgeBaseCategory.PRINCIPAL_COMPONENT,
                icon: 'chart-network',
              },
              {
                key: KnowledgeBaseCategory.FACTOR_DECOMPOSITION,
                icon: 'chart-area',
              },
              {
                key: KnowledgeBaseCategory.CONSTITUENT_RISK,
                icon: 'chart-pie',
              },
            ]
          : []),
        {
          key: KnowledgeBaseCategory.FACTSHEET,
          icon: 'file-lines',
        },
        // Disable until we have case studies
        // {
        //   key:KnowledgeBaseCategory.CASE_STUDY,
        //   icon: 'file-lines',
        // },
        // Hide the API category if
        // the user has no admin permission
        ...(isAdmin.value && canUserSeeKnowledgeBaseArticles.value
          ? [
              {
                key: KnowledgeBaseCategory.API_DOCUMENTATION,
                icon: 'code',
              },
            ]
          : []),
        {
          key: KnowledgeBaseCategory.GLOSSARY,
          icon: 'scroll-old',
        },
        ...(isAdmin.value && canUserSeeKnowledgeBaseArticles.value && shouldShowNotionAsCategory.value
          ? [
              {
                key: KnowledgeBaseCategory.NOTION,
                icon: 'triangle-person-digging',
              },
            ]
          : []),
      ];
    });

    const stickyTopPosition = computed(() =>
      mode.value === KnowledgeBaseMode.PAGE ? `${ApplicationNavbarHeight}px` : '0px',
    );

    const goToGlossary = () => {
      const offset = 415;
      if (route.value.hash.startsWith('#glossary-')) {
        // Handle cases like  glossary-A-items.glossary-B-items
        const glossaryTerm = route.value.hash.split('-').slice(1).join('-');
        const regex = /^[A-Z]-items$/;
        if (regex.test(glossaryTerm)) {
          const glossaryItem = categories.value.find((category) => category.key === KnowledgeBaseCategory.GLOSSARY);
          if (glossaryItem) {
            clickCategory(glossaryItem.key);
            setTimeout(() => {
              const glossaryElement = document.getElementById('glossary-' + glossaryTerm);
              if (glossaryElement) {
                window.scrollTo({
                  top: glossaryElement.offsetTop + offset,
                  behavior: 'smooth',
                });
              }
            }, 500);
          }
        }

        // Handle cases like glossary-gamma, glossary-var-historical..
        const glossaryItem = categories.value.find((category) => category.key === KnowledgeBaseCategory.GLOSSARY);
        if (glossaryItem) {
          clickCategory(glossaryItem.key);
          setTimeout(() => {
            const glossaryElement = document.getElementById(glossaryTerm);
            if (glossaryElement) {
              window.scrollTo({
                top: glossaryElement.offsetTop + offset,
                behavior: 'smooth',
              });
            }
          }, 500);
        }
      }
    };

    watch(route, goToGlossary, { immediate: true });

    return {
      translate,

      cols,
      width,

      articles,
      categories,

      clickArticle,
      clickCategory,

      stickyTopPosition,
    };
  },
});
</script>
