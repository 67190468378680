<template>
  <!-- 
    Ref: sidebarHtmlElement
    @click.stop       prevent propagation which would scroll the body element
    scroll-behavior   enable smooth scrolling when navigated to an element with id
    scrollbar-gutter  prevent the content jumping when the scrollbar shows up dynamically
   -->
  <b-container
    v-if="sidebarShown"
    ref="sidebarHtmlElement"
    class="position-fixed px-0 d-flex bg-white border-left shadow above-sticky-top overflow-x-hidden overflow-y-auto pr-4"
    style="top: 0; right: 0; scroll-behavior: smooth; scrollbar-gutter: stable"
    :style="{
      width: `${pressed ? newSidebarWidth : sidebarWidth}px`,
      maxWidth: `${width}px`,
      maxHeight: `calc(100vh - ${applicationNavbarHeight}px)`,
      marginTop: `${applicationNavbarHeight}px`,
    }"
    @click.stop
  >
    <b-row
      ref="resizeControl"
      class="mx-2 sticky-top align-items-center"
      style="cursor: col-resize"
    >
      <b-col class="px-1">
        <div
          class="bg-dark"
          style="height: 76px; width: 4px"
        />
      </b-col>
    </b-row>
    <KnowledgeBase />
  </b-container>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { onKeyStroke, useMouse, useMouseInElement, useMousePressed, useWindowSize } from '@vueuse/core';
import KnowledgeBase from './KnowledgeBase.vue';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { max } from 'lodash';
import { unwrap } from '@/utils/queries';
import { ApplicationNavbarHeight } from '@/constants/ApplicationNavbarHeight';
import { useRouteRef } from '@/composables/useRouter';

const RESIZE_CONTROL_OFFSET = 16;

const SIDEBAR_MIN_WIDTH = 600;

export default defineComponent({
  name: 'KnowledgeBaseSidebar',
  components: {
    KnowledgeBase,
  },
  props: {},
  setup() {
    const { sidebarHtmlElement, sidebarShown } = useKnowledgeBase();

    const sidebarWidth = ref(0);

    const { x } = useMouse();

    const { width } = useWindowSize();

    const resizeControl = ref<HTMLElement | null>(null);

    const { pressed } = useMousePressed({ target: resizeControl });

    const { isOutside } = useMouseInElement(sidebarHtmlElement);

    const newSidebarWidth = computed(() => max([width.value - x.value + RESIZE_CONTROL_OFFSET, SIDEBAR_MIN_WIDTH]));

    /**
     * Click and hold the resize control to resize the sidebar
     * by calculating the difference between the mouse position
     * and the window width
     */
    watch(pressed, () => {
      if (pressed) sidebarWidth.value = unwrap(newSidebarWidth.value);
    });

    /**
     * On hitting the escape key, close the sidebar
     * only if the mouse is inside the sidebar
     *
     * This is to prevent the sidebar from closing
     * when the user tried to close other modal
     */
    onKeyStroke('Escape', () => {
      if (!isOutside.value) {
        sidebarShown.value = false;
      }
    });

    // Close the sidebar when the route changes
    const route = useRouteRef();

    watch(route, () => {
      if (sidebarShown.value) sidebarShown.value = false;
    });

    return {
      sidebarShown,
      sidebarHtmlElement,

      resizeControl,
      pressed,

      width,
      sidebarWidth,
      newSidebarWidth,

      applicationNavbarHeight: ApplicationNavbarHeight,
    };
  },
});
</script>
