export enum KnowledgeBaseMode {
  PAGE = 'page',
  SIDEBAR = 'sidebar',
}

export enum KnowledgeBaseCategory {
  HELP_CENTER = 'help-center',
  GETTING_STARTED = 'getting-started',
  PURE_FACTORS = 'pure-factors',
  WHITEPAPER = 'whitepaper',
  MY_LAB = 'my-lab',
  PORTFOLIO_CONSTRUCTION = 'portfolio-construction',
  PRINCIPAL_COMPONENT = 'principal-component',
  FACTOR_DECOMPOSITION = 'factor-decomposition',
  CONSTITUENT_RISK = 'constituent-risk',
  FACTSHEET = 'factsheet',
  CASE_STUDY = 'case-study',
  API_DOCUMENTATION = 'api-documentation',
  GLOSSARY = 'glossary',
  NOTION = 'notion',
}

export enum PublishingStatus {
  ADMIN_ONLY = 'Admin Only',
  PUBLISHED = 'Published',
}

export enum EditingStatus {
  NOT_STARTED = 'Not Started',
  DRAFT = 'Draft',
}

export interface Article {
  id: string;
  url: string;
  title: string;
  publishingStatus: PublishingStatus;
  editingStatus: EditingStatus;
}

export interface Category {
  category: KnowledgeBaseCategory;
  publishingStatus: PublishingStatus;
  editingStatus: EditingStatus;
  articles: Article[];
}
