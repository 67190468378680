<template>
  <div
    v-if="!isPdf && components.length > 0"
    class="d-print-none d-none d-lg-block"
    :style="tooltipStyle"
  >
    <icon
      :id="id"
      icon="info-circle"
      class="text-accent"
      tabindex="0"
      aria-hidden="true"
    />
    <StandardPopover
      custom-classes="border border-accent"
      :target="id"
      triggers="hover"
      placement="bottomleft"
      class="p-0"
    >
      <div class="text-left tooltip-body">
        <component
          :is="comp"
          v-for="(comp, idx) of components"
          :key="idx"
        />
      </div>
    </StandardPopover>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { uniqueId } from 'lodash';
import { useIsPdf } from '@/composables/usePdf';
import StandardPopover from '@/components/standard-components/StandardPopover.vue';
import { registry } from '@/whitelabel/hsbc/components/disclaimer/registry';
import { notNull } from '@/utils/notnull';

const isPdf = useIsPdf();

export default defineComponent({
  name: 'FactsheetTooltip',
  components: {
    StandardPopover,
  },
  props: {
    names: {
      type: [String, Array] as PropType<string | string[]>,
      required: true,
    },
    topOffset: {
      type: String,
      required: false,
    },
    rightOffset: {
      type: String,
      required: false,
      default: '50px',
    },
  },
  setup(props) {
    const id = uniqueId();

    const names = computed(() => (typeof props.names === 'string' ? [props.names] : props.names));

    const components = computed(() => {
      return names.value.map((name) => registry[name as keyof typeof registry]).filter(notNull);
    });

    const tooltipStyle = computed(() => {
      return {
        position: 'absolute',
        top: 0,
        right: 0,
        'margin-top': props.topOffset,
        'padding-left': '0.3455rem',
        'margin-right': props.rightOffset,
      };
    });

    return {
      id,
      isPdf,
      components,
      tooltipStyle,
    };
  },
});
</script>

<style scoped>
.tooltip-body:deep(p) {
  margin-bottom: 0;
}
</style>
