
<script setup lang="ts">
const id = "strategy-alpha"
 const title = "Alpha Strategy"
 const categories = ["STRATEGY_FILTER"]
 const hiddenCategories = ["ANALYTICS_COMPUTATION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"strategy-alpha","title":"Alpha Strategy","categories":["STRATEGY_FILTER"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"strategy-alpha","title":"Alpha Strategy","categories":["STRATEGY_FILTER"],"hiddenCategories":["ANALYTICS_COMPUTATION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Alpha strategies have a long/short implementation, aim to be market-neutral and are intended to generate returns regardless of market state.</p>
</div>
</template>
