
<script setup lang="ts">
const id = "lasso-selection"
 const title = "LASSO Selection"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"lasso-selection","title":"LASSO Selection","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"lasso-selection","title":"LASSO Selection","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>The Least Absolute Shrinkage and Selection Operator (LASSO) optimises your regression by shrinking it down to the ‘best’ selection of factors based on your original list of selected factors and target number of factors.</p>
</div>
</template>
