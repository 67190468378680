import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { Ref } from 'vue';
import { useSessionStorage, useLocalStorage } from '@vueuse/core';

export type AnalysisPeriod = {
  startDate?: string;
  endDate?: string;
  period?: PeriodAbbrevEnum;
  useToday?: boolean;
};

export default function (): {
  datesToTry: Ref<AnalysisPeriod>;
  savePeriodToLocalStorage: () => void;
  savePeriodToSessionStorage: (period: AnalysisPeriod) => void;
} {
  const lastPeriodUsedInCurrentTab = useSessionStorage<AnalysisPeriod>(LocalStorageEntities.AnalysisPeriod, {});

  const lastPeriodUsedInBrowser = useLocalStorage<AnalysisPeriod>(LocalStorageEntities.AnalysisPeriod, {});

  /**
   * Save the date to be used in the current tab
   */
  const savePeriodToSessionStorage = (period: AnalysisPeriod) => {
    lastPeriodUsedInCurrentTab.value = period;
  };

  /**
   * Save to local storage before closing the tab so the date will be available
   * when the user comes back
   */
  const savePeriodToLocalStorage = () => {
    lastPeriodUsedInBrowser.value = lastPeriodUsedInCurrentTab.value;
  };

  return {
    datesToTry: lastPeriodUsedInCurrentTab,
    savePeriodToSessionStorage,
    savePeriodToLocalStorage,
  };
}
