import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as FullStory from '@fullstory/browser';
import { capitalize, chain, startCase } from 'lodash';
import { getUserInfo, updateUserInfo, IUserInfoResponse, IUpdateUser } from '@/api-v2/web/user';
import store from '@/store';
import useEnv from '@/composables/useEnv';

const { isProduction } = useEnv();

export interface IUserState {
  user: IUserInfoResponse | null;
}

export interface IJwtPayload {
  exp: number;
  userId: number;
}

@Module({ dynamic: true, store, name: 'User' })
class User extends VuexModule implements IUserState {
  public user: IUserInfoResponse | null = null;

  @Mutation
  private SET_USER(user: IUserInfoResponse): void {
    this.user = user;
  }

  @Action({ rawError: true })
  public async GetUserInfo(): Promise<void> {
    const user = await getUserInfo();
    this.SET_USER(user);
  }

  @Action({ rawError: true })
  public async UpdateUserInfo(userInfo: IUpdateUser): Promise<void> {
    const user = await updateUserInfo(userInfo);
    this.SET_USER(user);
  }

  @Action({ rawError: true })
  public async IdentifyFullstory(): Promise<void> {
    if (!isProduction.value || import.meta.env.VITE_CLIENT !== 'Premialab') {
      return;
    }

    FullStory.init({ orgId: 'H93Q7' });
    if (!this.user) return;

    let uid = `${import.meta.env.VITE_MODE}-${this.user.id}`;
    if (this.user.impersonator) {
      uid = `${import.meta.env.VITE_MODE}-${this.user.impersonator.id}-as-${this.user.id}`;
    }

    FullStory.identify(uid, {
      email: this.user.impersonator ? undefined : this.user.email.toLowerCase(),
      displayName: this.user.impersonator
        ? `${this.user.impersonator.email.toLowerCase()} as ${this.user.email.toLowerCase()}`
        : undefined,
      env: import.meta.env.VITE_MODE,
      client: import.meta.env.VITE_CLIENT,
      createdAt: this.user.createdAt.split(' ').join('T'),
      lastName: chain(this.user.lastname).split(' ').capitalize().join(' ').value(),
      firstName: chain(this.user.firstname).split(' ').capitalize().join(' ').value(),
      name: `${this.user.firstname} ${this.user.lastname}`,
      phone: this.user.telephone || undefined,
      country: this.user.country || undefined,
      title: capitalize(startCase(this.user.title)) || undefined,
      company: startCase(this.user.company) || undefined,
      industry: startCase(this.user.sector) || undefined,
      // Custom traits
      'User Phone': this.user.telephone || undefined,
      'User Country Code': this.user.country || undefined,
      'User Title': startCase(this.user.title) || undefined,
      'User Company Name': startCase(this.user.company) || undefined,
      'User Company Industry': startCase(this.user.sector) || undefined,
      impersonator: this.user.impersonator ? this.user.impersonator.email.toLowerCase() : undefined,
      impersonatorId: this.user.impersonator ? this.user.impersonator.id : undefined,
      impersonating: this.user.impersonator ? this.user.email.toLowerCase() : undefined,
      impersonatingId: this.user.impersonator ? this.user.id : undefined,
    });
  }
}

export const UserModule = getModule(User);
