import { UserPermission } from '@/constants/UserPermission';
import { computed, Ref, unref } from 'vue';
import useUser from '@/composables/useUser';
import { RouteConfig } from 'vue-router';
import { checkRoutePermission } from '@/utils/permission';
import { MaybeRef } from '@vueuse/core';

/**
 * Check if the user has the given permission.
 *
 * If the user has not been loaded yet, the computed value will return false.
 *
 * @param permission Permission to check for. If `null` is passed in, this will
 * always return false.
 */
export function useHasPermission(permission: MaybeRef<UserPermission | null>): Ref<boolean> {
  const { user } = useUser();

  return computed(() => {
    const check = unref(permission);
    if (check == null) {
      return false;
    }

    const perm = user.value?.permission;

    if (perm == null) {
      return false;
    }

    return perm.includes(check);
  });
}

export interface ViewableRouteInfo {
  route: RouteConfig;

  /**
   * True if the current user is authorized to view the route.
   *
   * This will be false when the user info have not been loaded yet.
   */
  authorized: boolean;

  /**
   * True if the route requires admin permission to view. Could be used to hide away routes instead of disabling them
   */
  admin: boolean;
}

/**
 * Enrich given list of routes on the permission about the routes.
 *
 * This does not remove the unauthorized routes from the list. You may need to `v-if` them.
 */
export function useViewableRoutes(routes: RouteConfig[]): Ref<Array<ViewableRouteInfo>> {
  const { user } = useUser();

  const userPermission = computed(() => new Set(user.value?.permission ?? []));

  return computed(() => {
    return routes.map(
      (route): ViewableRouteInfo => ({
        route,
        authorized: checkRoutePermission(userPermission.value, route.meta?.permission ?? []),
        admin: route.meta?.permission?.includes(UserPermission.ADMIN) ?? false,
      }),
    );
  });
}
