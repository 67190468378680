import { computed, nextTick, ref, Ref } from 'vue';
import { ActiveReturnMode } from '@/types/ActiveReturnMode';
import { useRouteRef } from './useRouter';
import useRouteChecks from './useRouteChecks';
import { createGlobalState } from '@vueuse/core';
import useTranslation from './useTranslation';
import { useGetBlmViews } from './queries/useBlmView';
import { useGetRegimeSets } from './queries/useRegimeAnalysis';
import { BLMView } from '@/api-v2/web/blm';

const useActiveReturnState = createGlobalState(() => {
  const activeReturnMode = ref<ActiveReturnMode>(ActiveReturnMode.TOTAL_RETURN);

  return {
    activeReturnMode,
  };
});

function useActiveReturnMode() {
  const { translate } = useTranslation();

  const { activeReturnMode } = useActiveReturnState();
  const isActiveReturnStateActive = computed(() => activeReturnMode.value === ActiveReturnMode.ACTIVE_RETURN);

  const route = useRouteRef();
  const { isOnFactorDecomposition } = useRouteChecks(route);

  const activeReturnModeOptions = computed(() => {
    const returnOption = {
      value: ActiveReturnMode.TOTAL_RETURN,
      text: translate({
        path: 'GLOBAL.RETURN_TYPE_CONSTANTS',
        item: 'RETURN',
      }),
    };
    const activeReturnOption = {
      value: ActiveReturnMode.ACTIVE_RETURN,
      text: translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: ActiveReturnMode.ACTIVE_RETURN }),
    };
    /**
     * Currently this is only implemented for Factor Decomposition, so we hide it if we're not on FD
     */
    const excessReturnOption = {
      value: ActiveReturnMode.EXCESS_RETURN,
      text: translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: ActiveReturnMode.EXCESS_RETURN }),
    };

    if (isOnFactorDecomposition.value) {
      return [returnOption, activeReturnOption, excessReturnOption];
    }

    return [returnOption, activeReturnOption];
  });

  const getActiveReturnModeText = (mode?: Ref<ActiveReturnMode>) => {
    const modeToUse = mode ? mode.value : activeReturnMode.value;

    if (modeToUse === ActiveReturnMode.ACTIVE_RETURN) {
      return translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: ActiveReturnMode.ACTIVE_RETURN });
    }

    if (modeToUse === ActiveReturnMode.EXCESS_RETURN) {
      return translate({ path: 'GLOBAL.RETURN_TYPE_CONSTANTS', item: ActiveReturnMode.EXCESS_RETURN });
    }

    return translate({
      path: 'GLOBAL.RETURN_TYPE_CONSTANTS',
      item: 'RETURN',
    });
  };

  const activeReturnModeText = computed(() => getActiveReturnModeText(activeReturnMode));

  return {
    activeReturnMode,
    activeReturnModeOptions,
    activeReturnModeText,
    getActiveReturnModeText,
    isActiveReturnStateActive,
  };
}

/**
 * This global state is used to track the current blmView id and
 * whether or not blmViw is active.
 */
const useBlmState = createGlobalState(() => {
  const storeBlmViewId = ref<string | undefined>(undefined);
  const isBlmActive = ref(false);

  return {
    storeBlmViewId,
    isBlmActive,
  };
});

/**
 * This composable is used to track the current blmView and its id,
 * the list of blmViews, and whether or not blmView is active.
 */
function useBlm() {
  const state = useBlmState();
  const query = useGetBlmViews();

  const route = useRouteRef();
  const { isOnSimulation, isOnEfficientFrontier } = useRouteChecks(route);

  const doesPageHaveBlmImplemented = computed(() => isOnSimulation.value || isOnEfficientFrontier.value);

  /**
   * if isBlmActive is false, return null. There is no storeBlmView if BLM View is not active.
   */
  const storeBlmView = computed(() => {
    if (!state.isBlmActive.value) return null;

    return query.data.value?.find((view) => view.id === state.storeBlmViewId.value) ?? null;
  });

  return {
    ...state,
    query,
    storeBlmView,
    doesPageHaveBlmImplemented,
  };
}

/**
 * This global state is used to track the current blmView id and
 * whether or not blmViw is active.
 */
const useCreateRegimeAnalysisState = createGlobalState(() => {
  const storeRegimeId = ref<string | undefined>();
  const isRegimeAnalysisActive = ref(false);
  const hoveredThresholdName = ref<string | undefined>();

  return {
    storeRegimeId,
    isRegimeAnalysisActive,
    hoveredThresholdName,
  };
});

/**
 * This composable is used to track the current regime and its id,
 * the list of regimes, and whether or not Regime Analysis mode is active.
 */
function useRegimeAnalysisState() {
  const state = useCreateRegimeAnalysisState();
  const query = useGetRegimeSets();

  /**
   * if isRegimeAnalysisActive is false, return null. There is no storeRegime if Regime Analysis mode is not active.
   */
  const storeRegime = computed(() => {
    if (!state.isRegimeAnalysisActive.value) return null;

    return query.data.value?.find((view) => view.attributeId === state.storeRegimeId.value) ?? null;
  });

  return {
    ...state,
    query,
    storeRegime,
  };
}

/**
 * We have some analysis modes that are not necessarily able to be combined with each other.
 * Place utilities regarding this in this composable
 *
 * if I activate a regime:
 * - if BLM is active, turn it off
 * - if i'm in Active Return or Excess Return, switch to Total Return
 *
 * if I activate BLM:
 * - if a regime is active, turn it off
 * - if i'm in Active Return or Excess Return, switch to Total Return
 *
 * if I activate Active Return or Excess Return:
 * - if BLM is active, turn it off
 * - if a regime is active, turn it off
 */
export default function useAnalysisModes() {
  const {
    activeReturnMode,
    getActiveReturnModeText,
    activeReturnModeText,
    activeReturnModeOptions,
    isActiveReturnStateActive,
  } = useActiveReturnMode();
  const { storeBlmViewId, isBlmActive, storeBlmView, doesPageHaveBlmImplemented } = useBlm();
  const { storeRegimeId, isRegimeAnalysisActive, storeRegime, hoveredThresholdName } = useRegimeAnalysisState();

  const turnOffBlm = () => {
    isBlmActive.value = false;
    storeBlmViewId.value = undefined;
  };
  const turnOffRegime = () => {
    isRegimeAnalysisActive.value = false;
    storeRegimeId.value = undefined;
    hoveredThresholdName.value = undefined;
  };

  const changeActiveReturnMode = (newMode: ActiveReturnMode) => {
    turnOffBlm();
    turnOffRegime();

    activeReturnMode.value = newMode;
  };

  const applyForwardLookingScenario = (view: BLMView) => {
    turnOffRegime();
    changeActiveReturnMode(ActiveReturnMode.TOTAL_RETURN);

    // because in changing the active return mode above we automatically turn off BLM,
    // we wait one tick here to re-apply BLM
    nextTick(() => {
      storeBlmViewId.value = view.id;
      isBlmActive.value = true;
    });
  };

  const applyRegime = (regimeId: string) => {
    turnOffBlm();
    changeActiveReturnMode(ActiveReturnMode.TOTAL_RETURN);

    // because in changing the active return mode above we automatically turn off regimes,
    // we wait one tick here to re-apply the regime
    nextTick(() => {
      storeRegimeId.value = regimeId;
      isRegimeAnalysisActive.value = true;
    });
  };
  const setHoveredThresholdName = (newVal: string | undefined) => {
    hoveredThresholdName.value = newVal;
  };

  return {
    changeActiveReturnMode,
    activeReturnMode: computed(() => activeReturnMode.value),
    isActiveReturnStateActive,

    getActiveReturnModeText,
    activeReturnModeOptions,
    activeReturnModeText,

    storeBlmViewId: computed(() => storeBlmViewId.value),
    storeBlmView: computed(() => storeBlmView.value),
    isBlmActive: computed(() => isBlmActive.value),
    doesPageHaveBlmImplemented,
    applyForwardLookingScenario,
    turnOffBlm,

    storeRegimeId: computed(() => storeRegimeId.value),
    storeRegime: computed(() => storeRegime.value),
    hoveredThresholdName: computed(() => hoveredThresholdName.value),
    isRegimeAnalysisActive: computed(() => isRegimeAnalysisActive.value),
    applyRegime,
    setHoveredThresholdName,
    turnOffRegime,
  };
}
