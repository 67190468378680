import { MetricConstants } from '@/constants/MetricConstants';
import { RouteName } from '@/constants/RouteName';
import { ChartTypes } from '@/types/ChartTypes';

export interface AnalysisSubstepComponent {
  name: string;
  subComponents?: AnalysisSubstepComponent[];
}

export type PortfolioConstructionGraphTypes =
  | ChartTypes.TRACK_RECORD
  | ChartTypes.EXPOSURE
  | ChartTypes.EFFICIENT_FRONTIER
  | ChartTypes.ROLLING_VOLATILITY
  | ChartTypes.ROLLING_SHARPE
  | ChartTypes.PERFORMANCE_ATTRIBUTION_GROUP_BY
  | ChartTypes.CUMULATIVE_MONTHLY_RETURN_BREAKDOWN
  | ChartTypes.ROLLING_CORRELATION
  | ChartTypes.CORRELATION
  | ChartTypes.RETURN_REGRESSION
  | ChartTypes.CORRELATION_MATRIX
  | ChartTypes.MST
  | ChartTypes.ACTIVE_RETURN
  | ChartTypes.RETURN_COMPARISON
  | ChartTypes.RANKING_TABLE;

export type FactorDecompositionGraphTypes =
  | ChartTypes.FACTOR_HEATMAP
  | ChartTypes.RETURN_ATTRIBUTION
  | ChartTypes.REGRESSION_TRACK
  | ChartTypes.CUMULATIVE_RESIDUAL
  | ChartTypes.MONTHLY_RETURN
  | ChartTypes.ROLLING;

export interface AnalysisSubstep {
  title: string;
  graphTypes?: PortfolioConstructionGraphTypes[] | FactorDecompositionGraphTypes[];
  metricComponent?: {
    [key in MetricConstants]?: AnalysisSubstepComponent;
  };
  defaultComponents?: AnalysisSubstepComponent;
  historyComponents?: AnalysisSubstepComponent;
  path: string;
}
// TODO: FIND A WAY TO USE LEGACY NAME LESS
export interface AnalysisStep {
  name: string;
  route: string;
  baseRoute?: string;
  legacyRoute?: string;
  secondaryRoute?: string;
  legacyName: string;
  disabled: boolean;
  substeps: AnalysisSubstep[];
  icon: string;
  iconSet?: string;
  mobileOnly: boolean;
  defaultSubstepPath: string;
}

export const FOLDER: AnalysisStep = {
  // Temporarily Make this into the Portfolio Select modal button
  name: RouteName.FOLDER,
  // route: '/folder',
  route: '',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'folder',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const PERFORMANCE_ATTRIBUTION: AnalysisStep = {
  name: RouteName.PERFORMANCE_ATTRIBUTION,
  route: '/performance',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'chart-mixed',
  iconSet: 'fas',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const HOME: AnalysisStep = {
  name: 'Indices',
  route: '/indices',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'fa-regular fa-chart-scatter fa-2x',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const FACTSHEET: AnalysisStep = {
  // TODO: sort out what name this should be...
  name: 'Index Overview',
  route: '/indices/',
  secondaryRoute: '/strategy',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-lines',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const CLUSTERING_ANALYSIS: AnalysisStep = {
  name: RouteName.PRINCIPAL_COMPONENT,
  route: '/clustering-analysis',
  legacyName: RouteName.CLUSTERING_ANALYSIS,
  disabled: true,
  substeps: [],
  icon: 'chart-network',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const BROCHURE_DOWNLOAD: AnalysisStep = {
  name: RouteName.BROCHURE_DOWNLOAD,
  route: '/brochure',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-pdf',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const RISK_FACTOR_DOWNLOAD: AnalysisStep = {
  name: RouteName.RISK_FACTOR_DOWNLOAD,
  route: '/risk-factor',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-arrow-down',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const ARTICLE: AnalysisStep = {
  name: 'Index Insights',
  route: '/feed',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'lightbulb',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const AI_ARTICLE: AnalysisStep = {
  name: 'AI Insights',
  route: '/ai-feed',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'brain-circuit',
  mobileOnly: false,
  defaultSubstepPath: '',
};

export const MARKET_REGIME_ARTICLE: AnalysisStep = {
  name: 'Market Regime Insights',
  route: '/mr-feed',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'compass',
  mobileOnly: false,
  defaultSubstepPath: '',
};

/**
 * SPECIFIC TO HSBC WHITELABEL APPLICATION SIDEBAR
 */

export const FACTSHEET_DOWNLOAD: AnalysisStep = {
  // TODO: sort out what name this should be...
  name: 'Index Overview Download',
  route: '/factsheet_download',
  secondaryRoute: '',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-chart-pie',
  mobileOnly: false,
  // img: 'factsheet',
  defaultSubstepPath: '',
};

export const PERFORMANCE_ATTRIBUTION_DOWNLOAD: AnalysisStep = {
  // TODO: sort out what name this should be...
  name: 'Attribution Download',
  route: '/attribution_download',
  secondaryRoute: '',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'file-chart-column',
  mobileOnly: false,
  // img: 'factsheet',
  defaultSubstepPath: '',
};

export const VIDEO_POPUP: AnalysisStep = {
  // TODO: sort out what name this should be...
  name: 'Video',
  route: '/video-popup',
  secondaryRoute: '',
  legacyName: '',
  disabled: false,
  substeps: [],
  icon: 'clapperboard-play',
  mobileOnly: false,
  // img: 'factsheet',
  defaultSubstepPath: '',
};

export enum AnalyticsGroupBy {
  STRATEGY = 'Strategy',
  PORTFOLIO = 'Portfolio',
  ASSET_CLASS = 'Asset Class',
  FACTOR = 'Factor',
  REGION = 'Region',
  TYPE = 'Type',
}
