<template>
  <b-popover
    v-bind="{
      ...props,
      ...attrs,
    }"
    :custom-class="customClass"
  >
    <slot />
  </b-popover>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';

/**
 * IMPORTANT: The target element for the popover must exist in the document before
 * <b-popover> is mounted. If the target element is not found during mount,
 * the popover will never open. Always place your <b-popover> component lower
 * in the DOM than your target element. This rule also applies if a callback
 * function is used as target element, since that callback is called only
 * once on mount.
 *
 * Related to above, you likely will always want to use v-show instead of v-if
 * if you are using this popover with an element that's not always shown.
 * Otherwise, your popover will never work :(
 *
 * For full list of props and events, please see the official docs here
 * https://bootstrap-vue.org/docs/components/popover
 *
 * Do NOT use the custom-class prop - instead supply it to the
 * custom-classes prop, and it will be added to the default ones supplied
 * to this component
 */
export default defineComponent({
  name: 'HelpPopover',
  props: {
    customClasses: {
      type: String,
      required: false,
    },
    triggers: {
      type: String as PropType<'manual' | 'hover focus'>,
      required: false,
      default: 'manual',
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { attrs }) {
    const customClass = computed(() => {
      return `shadow ${props.customClasses ?? ''}`;
    });

    return {
      /**
       * pass all props / attrs and bind them to the popover component so that we don't have to
       * explicitly re-bind all the possible props.
       */
      props,
      attrs,
      customClass,
    };
  },
});
</script>
