/**
 * This file contains the global states of the constituent risk page
 * that are currently being managed by the ConstituentRiskModule store.
 * We aim to migrate everything from the store to this composable.
 */
import { RiskDashboardTableComponentsConstants } from '@/types/IConstituentRisk';
import { createGlobalState } from '@vueuse/shared';
import { ref } from 'vue';

export const getFieldsToCall = createGlobalState(() => {
  return ref<string[]>([]);
});

export const getTableComponent = createGlobalState(() => {
  return ref<RiskDashboardTableComponentsConstants>();
});
