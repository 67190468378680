import { AuthStrategy } from '../types';
import HSBCAuthRoot from './HSBCAuthRoot.vue';
import HSBCLogin from './HSBCLogin.vue';

export function hsbcAuth(): AuthStrategy {
  return {
    rootComponent: HSBCAuthRoot,
    navbarOnLogin: true,
    loginComponent: HSBCLogin,
  };
}
