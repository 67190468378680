export enum PinItemType {
  BENCHMARK = 1,
  ACTIVE_RETURN = 2,
}

export interface PinnedItem {
  color: string;
  thickness: string;
  /**
   * strategyCode exists to handle cases where the same strategy is added twice within a master portfolio
   */
  strategyCode?: string;
  type: PinItemType;
}

export interface PinnedMap {
  [codeOrSlug: string]: PinnedItem;
}
