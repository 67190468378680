export enum Locales {
  ENGLISH = 'en',
  JAPANESE = 'ja',
  CHINESE_TRADITIONAL = 'zh-Hant',
  CHINESE_SIMPLIFIED = 'zh-Hans',
}

export const LOCALES_SET = new Set(Object.values(Locales));

export const LocaleFullNames = {
  [Locales.ENGLISH]: 'English',
  [Locales.JAPANESE]: '日本語',
  [Locales.CHINESE_TRADITIONAL]: '中文(繁體)',
  [Locales.CHINESE_SIMPLIFIED]: '中文(简体)',
};

export function isKnownLocale(o: string): o is Locales {
  return LOCALES_SET.has(o as Locales);
}
