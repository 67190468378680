<script lang="ts">
import { defineComponent } from 'vue';
import { useUnauthRedirect } from '@/auth/shared/useUnauthRedirect';
import { AuthURL } from '@/api-v2/web/auth';
import { useBlockedRedirect } from '../shared/useIsAuthenticated';

export default defineComponent({
  name: 'CoreAuthRoot',
  setup() {
    useBlockedRedirect();

    useUnauthRedirect(() => {
      return AuthURL.auth0Login(location.pathname + location.search + location.hash);
    });

    return () => null;
  },
});
</script>
