<template>
  <!-- TODO: (vue3) Remove div wrapper and make this multi-root -->
  <div>
    <template v-for="currency of g5Currencies">
      <!--
      @slot Slot for customizing the <b-dropdown-item> render for the given currency. Remember to include `:key`.
      @param {string} currency Currency name to be rendered
      -->
      <slot :currency="currency">
        <b-dropdown-item :key="currency">
          {{ currency }}
        </b-dropdown-item>
      </slot>
    </template>

    <slot name="divider">
      <b-dropdown-divider class="mx-3" />
    </slot>

    <template v-for="currency of currencyTypes">
      <slot :currency="currency">
        <b-dropdown-item :key="currency">
          {{ currency }}
        </b-dropdown-item>
      </slot>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Currency } from '@/constants/Currency';

/**
 * Simple container for holding currency dropdown items, with divider for g5 currencies.
 *
 * Note this only renders the dropdown items. Parent component should be responsible
 * for creating the containing `<b-dropdown>` component.
 */
export default defineComponent({
  name: 'CurrencyDropdownItems',
  setup() {
    const currencyTypes = Object.values(Currency);
    const g5Currencies = currencyTypes.splice(0, 5);

    return {
      g5Currencies,
      currencyTypes,
    };
  },
});
</script>
