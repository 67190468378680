import { ChartTypes } from '@/types/ChartTypes';
import { EquityBasketTabs } from '../IPortfolioTree';
import { AnalysisSubstep } from './AnalysisStep';
import { EquityBasketStatus } from '@/constants/EquityBasket';

export enum AuditLogEvent {
  PORTFOLIO_CONSTRUCTION = 'Portfolio Construction',
  CHECK_COMPLIANCE = 'Check Compliance',
  RUN_OPTIMIZER = 'Run Optimizer',
  PRODUCT_CREATION = 'Product Creation',
  REQUEST_PRICING_OR_FUNDING = 'Request Pricing/Funding',
  SEND_TO_CALC_AGENT = 'Send to Calc Agent',
  REQUEST_REBALANCE = 'Request Rebalance',
}

export enum AuditLogStatus {
  DRAFT = 'Draft',
  COMPLIANT = 'Compliant',
  NON_COMPLIANT = 'Non Compliant',
  APPROVAL_REQUESTED = 'Approval Requested',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  LIVE = 'Live',
  ARCHIVED = 'Archived',
}

export const EQUITY_BASKET_SIMULATION: AnalysisSubstep = {
  title: 'Simulation',
  graphTypes: [ChartTypes.TRACK_RECORD, ChartTypes.EXPOSURE],
  path: EquityBasketTabs.SIMULATION,
};
export const EQUITY_BASKET_EFFICIENT_FRONTIER: AnalysisSubstep = {
  title: 'Efficient Frontier',
  graphTypes: [ChartTypes.EFFICIENT_FRONTIER],
  path: EquityBasketTabs.FRONTIER,
};
export const EQUITY_BASKET_ROLLING: AnalysisSubstep = {
  title: 'Rolling Metric',
  graphTypes: [ChartTypes.ROLLING_VOLATILITY, ChartTypes.ROLLING_SHARPE],
  path: EquityBasketTabs.ROLLING,
};
export const EQUITY_BASKET_PERF_ATTRIB: AnalysisSubstep = {
  title: 'Performance Contribution',
  graphTypes: [
    ChartTypes.PERFORMANCE_ATTRIBUTION_GROUP_BY,
    ChartTypes.CUMULATIVE_MONTHLY_RETURN_BREAKDOWN,
    ChartTypes.RANKING_TABLE,
  ],
  path: EquityBasketTabs.PERFORMANCE_ATTRIBUTION,
};
export const EQUITY_BASKET_CORRELATION: AnalysisSubstep = {
  title: 'Correlation',
  graphTypes: [
    ChartTypes.ROLLING_CORRELATION,
    ChartTypes.CORRELATION,
    ChartTypes.RETURN_REGRESSION,
    ChartTypes.CORRELATION_MATRIX,
    ChartTypes.MST,
  ],
  path: EquityBasketTabs.CORRELATION,
};
export const EQUITY_BASKET_ACTIVE_RETURN: AnalysisSubstep = {
  title: 'Active Return',
  graphTypes: [ChartTypes.ACTIVE_RETURN, ChartTypes.RETURN_COMPARISON],
  path: EquityBasketTabs.ACTIVE_RETURN,
};

interface EquityBasketStatusStyle {
  status: EquityBasketStatus;
  icon: string;
  iconColor: string;
  statusColor: string;
}

//  TODO: Confirm the styling with John and Ryan.
const GREY_COLOR = '#b8b8b9';
const TRAFFIC_LIGHT_GREEN = '#00847E';
const PACIFIC_BLUE = '#00A3E0';
const RED_COLOR = '#FF3838';
const TRAFFIC_LIGHT_AMBER = '#E8A215';

export const EquityBasketStatusStyleConfig: EquityBasketStatusStyle[] = [
  {
    status: EquityBasketStatus.DRAFT,
    icon: 'fa solid fa-circle-question',
    iconColor: GREY_COLOR,
    statusColor: GREY_COLOR,
  },
  {
    status: EquityBasketStatus.COMPLIANT,
    icon: 'fa solid fa-circle-check',
    iconColor: TRAFFIC_LIGHT_GREEN,
    statusColor: PACIFIC_BLUE,
  },
  {
    status: EquityBasketStatus.APPROVAL_REQUESTED,
    icon: 'fa solid fa-circle-check',
    iconColor: TRAFFIC_LIGHT_GREEN,
    statusColor: TRAFFIC_LIGHT_AMBER,
  },
  {
    status: EquityBasketStatus.APPROVED,
    icon: 'fa solid fa-circle-check',
    iconColor: TRAFFIC_LIGHT_GREEN,
    statusColor: TRAFFIC_LIGHT_GREEN,
  },
  {
    status: EquityBasketStatus.NON_COMPLIANT,
    icon: 'fa solid fa-circle-xmark',
    iconColor: RED_COLOR,
    statusColor: RED_COLOR,
  },
];
