<template>
  <b-collapse
    id="navbar-toggle-collapse"
    is-nav
    @shown="onShown"
  >
    <b-navbar-nav :class="!isSmallScreen ? 'w-100 d-flex justify-content-center' : ''">
      <!-- for the nav items, we disable the active route because otherwise it may lead to some
            unexpected behavior for the user. For example, if the user is on Constituent Risk Analysis
            and clicks the Analytics item, she will be brought to the Portfolio Construction page,
            despite the Analytics item being highlighted as active -->

      <b-nav-item-dropdown
        v-if="isSmallScreen && shouldShowApplicationSidebar"
        ref="sidebarDropdown"
        :text="translate({ path: 'APPLICATION_NAVBAR.DATA' })"
        right
        class="px-2"
        menu-class="bg-primary text-white"
      >
        <div
          v-for="navItem in sidebarNavItems"
          :key="navItem.name"
        >
          <b-dropdown-item
            :key="navItem.name"
            active-class="bg-light text-primary"
            :disabled="AnalysisStepItems[navItem.name].disabled"
            :active="
              route.name === AnalysisStepItems[navItem.name].route ||
              route.name === AnalysisStepItems[navItem.name].secondaryRoute
            "
            :to="AnalysisStepItems[navItem.name].to"
            @click="() => AnalysisStepItems[navItem.name].onClick()"
          >
            {{ translate({ path: 'APPLICATION_NAVBAR', item: navItem.name }) }}
          </b-dropdown-item>
        </div>
      </b-nav-item-dropdown>

      <b-nav-item
        v-for="navItem in navItems"
        :key="navItem.to"
        :to="{
          name: navItem.to,
        }"
        :exact="navItem.name === 'Data'"
        :active-class="'bg-light text-primary px-3'"
        class="px-2 font-weight-400"
        @click.exact="onNavItemClick(navItem)"
        @click.ctrl.exact="onNavItemCtrlClick(navItem)"
      >
        {{ translate({ path: 'APPLICATION_NAVBAR', item: navItem.name }) }}
      </b-nav-item>

      <LocaleChanger class="d-block d-md-none" />

      <b-nav-item-dropdown
        v-if="isUserAdmin"
        right
        toggle-class="d-flex align-items-center"
        menu-class="dropdown-menu-light"
        no-caret
        class="d-none d-md-block"
      >
        <template #button-content>
          <icon
            icon="circle-user"
            style="font-size: 20px"
            class="text-white"
          />&nbsp;
          <span class="mimic-bv-caret text-white mt-1" />
        </template>
        <template v-for="(options, groupName, idx) in menuOptions">
          <b-dropdown-group :key="`group-${groupName}`">
            <b-dropdown-item
              v-for="option of options"
              :key="option.text"
              :to="option.to || null"
              :href="option.href || null"
              @click="option.onClick"
            >
              <icon
                :icon="option.icon"
                :class="option.class"
                :fas="option.fas"
                fixed-width
              />
              {{ option.text }}
            </b-dropdown-item>
          </b-dropdown-group>
          <b-dropdown-divider
            v-if="idx < Object.values(menuOptions).length - 1"
            :key="`divider-${idx}`"
          />
        </template>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <LocaleChanger
      class="d-none d-md-block"
      style="width: 70px"
    />
  </b-collapse>
</template>

<script lang="ts">
import { UserPermission } from '@/constants/UserPermission';
import { groupBy } from 'lodash';
import { SaveChangesModalResponse, SaveChangesStore } from '@/store/modules/SaveChangesStore';
import { NavItemNames } from '@/constants/NavbarConstants';
import { RouteName } from '@/constants/RouteName';
import useEnv from '@/composables/useEnv';
import useUser from '@/composables/useUser';
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRouteRef } from '@/composables/useRouter';
import { RawLocation } from 'vue-router';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { useMobileResponsiveUtilities } from '@/composables/useMobileResponsiveUtilities';
import {
  FOLDER,
  FACTSHEET,
  PERFORMANCE_ATTRIBUTION,
  BROCHURE_DOWNLOAD,
  VIDEO_POPUP,
  ARTICLE,
  FACTSHEET_DOWNLOAD,
  PERFORMANCE_ATTRIBUTION_DOWNLOAD,
} from '@/whitelabel/hsbc/types/analytics/AnalysisStep';
import useTranslation from '@/composables/useTranslation';
import useAppMetrics from '@/composables/useAppMetrics';
import { BNavItemDropdown } from 'bootstrap-vue';
import { useLocale } from '@/composables/useLocale';
import { strategyToVideo } from '@/whitelabel/hsbc/content/ContentMaps';
import { getLatestArticleIdByCode } from '@/api-v2/web/cms';
import LocaleChanger from '@/layout/components/navbar/LocaleChanger.vue';
import { PdfDownloadTypes } from '@/whitelabel/hsbc/constants/PdfDownloadTypes';
import { downloadPdf } from '../../utils/WhitelabelPdfDownloadUtils';
import { useStrategyIdentifier } from '@/composables/useCorrectIdentifier';
import { useApplicationSidebar } from '@/composables/useApplicationSidebar';
import { usePerformanceContributionOptions } from '@/composables/usePerformanceContributionData';
import { PerformanceAttributionStatus } from '@/constants/PerformanceAttributionStatus';
import { useDiscoverStore } from '@/composables/useDiscoverStore';
import { useDataDiscovery } from '@/composables/queries/useDataDiscovery';

type BaseNavItem = {
  name: NavItemNames;
  permission: string[];
};
interface SpaNavItem extends BaseNavItem {
  to: RawLocation;
}
interface LegacyNavItem extends BaseNavItem {
  href: string;
}

interface MenuItem {
  section: string;
  text: string;
  icon: string;
  fas?: boolean;
  class?: string;
  onClick: () => void;
  to?: RawLocation;
  permission?: string[];
  href?: string;
}

function useMenuOptions() {
  const defaultMenu = computed((): MenuItem[] => {
    return [
      {
        section: 'Admin',
        text: 'Admin Console',
        icon: 'user-secret',
        to: {
          name: RouteName.PLATFORM_ANALYTICS,
        },
        permission: [`${UserPermission.ADMIN}&!${UserPermission.IMPERSONATE}`],
        onClick: () => {},
      },
      {
        section: '',
        text: 'Logout',
        icon: 'sign-out',
        href: '',
        to: {
          name: RouteName.LOGOUT,
        },
        onClick: () => {},
      },
    ];
  });

  const menuOptions = computed(() => {
    return groupBy(defaultMenu.value, (item) => item.section);
  });

  return menuOptions;
}

export type AnalysisStepNavItem = {
  shouldShowStep: boolean;
  disabled: boolean;
  onClick: () => void;
  route?: string;
  to?: Location;
  secondaryRoute?: string;
};

export default defineComponent({
  name: 'ApplicationNavbarHSBC',
  components: {
    LocaleChanger,
  },
  setup() {
    const route = useRouteRef();

    const strategyIdentifier = useStrategyIdentifier();

    const router = useRouter();

    const sidebarDropdown = ref<BNavItemDropdown | null>(null);

    const { translate } = useTranslation();

    const latestAttachment = ref<{ signedUrl: string } | null>(null);
    const latestArticleId = ref<string | null>(null);

    const { shouldEmulateProduction, isActuallyProduction } = useEnv();
    const { isUserAdmin } = useUser();
    const { eventBus } = useGlobalEventBus();

    /**
     * UNIVERSE INSTANTIATION SINGLETON
     *
     * This logic is not truly used in this page, but the index universe is used extensively throughout the application
     * Here we instantiate it once and when it changes, we update the Discover Store.
     *
     * This is the way we must move forward to comply with tanstack-query's requirements to v5 because the
     * useQuery's `onSuccess` callback is being deprecated.
     */
    const { setUniverse } = useDiscoverStore();
    const universe = useDataDiscovery();
    watch(universe.data, (newVal) => {
      // Use structuredClone to force the object to not be reactive
      if (newVal) setUniverse(structuredClone(newVal));
    });

    // TODO : Add all the application sidebar components with permissions over here
    // Right now only adding Factsheet and Performance Attribution

    const { trackButtonClick } = useAppMetrics();

    const { locale } = useLocale();

    watch(strategyIdentifier, async (newVal) => {
      if (!newVal) return;
      latestArticleId.value = await getLatestArticleIdByCode(newVal);
    });

    const onShown = () => {
      sidebarDropdown.value?.show();
    };

    const videoPopupURL = computed(() => {
      if (!strategyIdentifier.value) return;

      return strategyToVideo[strategyIdentifier.value] ?? null;
    });

    const AnalysisStepItems = computed((): { [key: string]: AnalysisStepNavItem } => {
      if (!strategyIdentifier.value) return {};

      const performanceContributionOptions = usePerformanceContributionOptions(strategyIdentifier);

      /**
       * HSBC will always have only Bank type and not IPV type
       */

      const isPerformanceContributionValidated = computed(
        () =>
          performanceContributionOptions.data.value?.parameters.bank?.status === PerformanceAttributionStatus.VALIDATED,
      );

      const retval = {
        [FACTSHEET.name]: {
          shouldShowStep: true,
          disabled: false,
          to: {
            name: RouteName.STRATEGY_FACTSHEET,
            params: { indexUniqueIdentifier: strategyIdentifier.value },
          },
          onClick: () => {},
          route: RouteName.STRATEGY_FACTSHEET,
        },
        [PERFORMANCE_ATTRIBUTION.name]: {
          shouldShowStep: isPerformanceContributionValidated.value,
          to: {
            name: RouteName.PERFORMANCE_ATTRIBUTION,
            params: { indexUniqueIdentifier: strategyIdentifier.value },
          },
          onClick: () => {},
          disabled: !isPerformanceContributionValidated.value,
          route: RouteName.PERFORMANCE_ATTRIBUTION,
        },
        [BROCHURE_DOWNLOAD.name]: {
          shouldShowStep: latestAttachment.value && latestAttachment.value.signedUrl,
          onClick: () => {
            if (!latestAttachment.value?.signedUrl) return;
            const newTab = window.open(latestAttachment.value.signedUrl, '_blank');
            if (newTab) newTab.focus();
            trackButtonClick(BROCHURE_DOWNLOAD.name, {
              pageName: route.value.name,
              subpageName: '',
              strategyCodes: strategyIdentifier.value,
            });
          },
          disabled: false,
          route: null,
        },
        [VIDEO_POPUP.name]: {
          shouldShowStep: !!videoPopupURL.value,
          onClick: () => {
            eventBus.emit('open-analysis-video-popup');
            trackButtonClick(VIDEO_POPUP.name, {
              pageName: route.value.name,
              subpageName: '',
              strategyCodes: strategyIdentifier.value,
            });
          },
          disabled: false,
          route: null,
        },
        [ARTICLE.name]: {
          shouldShowStep: latestArticleId.value,
          onClick: () => {
            router.push({
              name: RouteName.ARTICLE_FEED,
              params: { articleCode: String(latestArticleId.value) },
            });

            trackButtonClick(ARTICLE.name, {
              pageName: route.value.name,
              subpageName: '',
              strategyCodes: strategyIdentifier.value,
            });
          },
          disabled: false,
          route: null,
        },
        [FACTSHEET_DOWNLOAD.name]: {
          shouldShowStep: true,
          onClick: async () => {
            await downloadPdf(strategyIdentifier.value as string, PdfDownloadTypes.STRATEGY, locale.value);
            trackButtonClick(FACTSHEET_DOWNLOAD.name, {
              pageName: route.value.name,
              subpageName: '',
              strategyCodes: strategyIdentifier.value,
            });
          },
          disabled: false,
        },
        // Convert indexUniqueIdentifier to uppercase incase user enters lowercase in URL
        [PERFORMANCE_ATTRIBUTION_DOWNLOAD.name]: {
          shouldShowStep: isPerformanceContributionValidated.value,
          onClick: async () => {
            await downloadPdf(
              strategyIdentifier.value as string,
              PdfDownloadTypes.PERFORMANCE_ATTRIBUTION,
              locale.value,
            );
            trackButtonClick(PERFORMANCE_ATTRIBUTION_DOWNLOAD.name, {
              pageName: route.value.name,
              subpageName: '',
              strategyCodes: strategyIdentifier.value,
            });
          },
          disabled: false,
        },
      };
      return retval as { [key: string]: AnalysisStepNavItem };
    });

    // TODO : Add Factor Decomposition and Constituent Risk

    const sidebarNavItems = computed(() =>
      [
        FOLDER,
        FACTSHEET,
        PERFORMANCE_ATTRIBUTION,
        BROCHURE_DOWNLOAD,
        ARTICLE,
        FACTSHEET_DOWNLOAD,
        PERFORMANCE_ATTRIBUTION_DOWNLOAD,
        VIDEO_POPUP,
      ].filter((x) => AnalysisStepItems.value[x.name]?.shouldShowStep),
    );

    const { shouldShowApplicationSidebar } = useApplicationSidebar();

    /**
     * It's important to put the PARENT route for each of these tabs,
     * otherwise the router will not properly detect that the given tab
     * is active
     */
    const defaultTabs = computed(() => {
      return [
        {
          name: NavItemNames.INDICES,
          to: RouteName.DATA,
          permission: [`${UserPermission.UNBLOCK}&${UserPermission.PLATFORM}`],
        },

        ...(!isActuallyProduction
          ? [
              {
                name: NavItemNames.PORTFOLIO_CONSTRUCTION,
                to: RouteName.PORTFOLIO_CONSTRUCTION,
                permission: [`${UserPermission.UNBLOCK}&!${UserPermission.PLATFORM}`],
              },
            ]
          : []),
        {
          name: NavItemNames.INSIGHTS,
          to: RouteName.ARTICLES,
          permission: [`${UserPermission.UNBLOCK}&${UserPermission.PLATFORM}`],
        },
        {
          name: NavItemNames.ABOUT,
          to: RouteName.ABOUT,
          permission: [`${UserPermission.UNBLOCK}&${UserPermission.PLATFORM}`],
        },
      ];
    });

    const navItems = computed(() => {
      let items = defaultTabs.value;
      if (isSmallScreen.value && shouldShowApplicationSidebar.value) return items.filter((o) => o.name !== 'Indices');
      return items;
    });

    const hasUnsavedChanges = computed(() => SaveChangesStore.hasChanges);

    const isLegacyNavItem = (tab: SpaNavItem | LegacyNavItem): tab is LegacyNavItem => {
      return (tab as LegacyNavItem).href !== undefined;
    };

    const onNavItemClick = async (navItem: SpaNavItem | LegacyNavItem) => {
      if (!isLegacyNavItem(navItem)) return;

      const result = await SaveChangesStore.WaitForSaveChangesModalResponse();

      if (result !== SaveChangesModalResponse.CANCEL) {
        window.location.href = navItem.href;
      }
    };

    const onNavItemCtrlClick = async (navItem: SpaNavItem | LegacyNavItem) => {
      if (!isLegacyNavItem(navItem)) return;

      const newTab = window.open(navItem.href, '_blank');
      if (newTab) newTab.focus();
    };

    const emulateProduction = computed({
      get() {
        return shouldEmulateProduction.value;
      },
      set(newVal: boolean) {
        shouldEmulateProduction.value = newVal;
      },
    });

    const menuOptions = useMenuOptions();

    const { isSmallScreen } = useMobileResponsiveUtilities();

    return {
      navItems,
      isLegacyNavItem,
      onNavItemClick,
      onNavItemCtrlClick,
      menuOptions,
      isUserAdmin,
      hasUnsavedChanges,
      isActuallyProduction,
      emulateProduction,
      isSmallScreen,
      shouldShowApplicationSidebar,
      sidebarNavItems,
      AnalysisStepItems,
      route,
      translate,
      sidebarDropdown,
      onShown,
    };
  },
});
</script>
