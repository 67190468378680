import { DateFormat } from '@/constants/DateFormat';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { DateTime } from 'luxon';

/**
 * @property abbrev: abbreviation of the period name
 * @property fromDate: start date of the period
 * @property toDate: end date of the period
 * @property enabled: whether the period is enabled or not
 * @property show: whether to show the period or not
 */
interface IPeriod {
  abbrev: PeriodAbbrevEnum;
  fromDate: DateTime;
  toDate: DateTime;
}

/**
 * @property abbrev: abbreviation of the period name
 * @property fromDate: start date of the period
 * @property toDate: end date of the period
 * @property enabled: whether the period is enabled or not
 * @property show: whether to show the period or not
 */
class Period {
  public abbrev!: PeriodAbbrevEnum;
  public fromDate!: DateTime;
  public toDate!: DateTime;

  public fromDateString: () => string = () => {
    return this.fromDate.toFormat(DateFormat.YYYY_MM_DD);
  };
  public toDateString: () => string = () => {
    return this.toDate.toFormat(DateFormat.YYYY_MM_DD);
  };

  public constructor(params: IPeriod) {
    this.abbrev = params.abbrev;
    this.fromDate = params.fromDate;
    this.toDate = params.toDate;
  }
}

interface IPeriodState {
  periods: { [key: string]: Period } | null;
  sortedPeriods: Period[];
  currentPeriod: Period | null;
  isPeriodValid: boolean;
  disabledBeforeDate: string;
}

export { Period, IPeriodState };
