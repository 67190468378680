<script lang="ts">
import { defineComponent } from 'vue';
import { useUnauthRedirect } from '@/auth/shared/useUnauthRedirect';
import { useBlockedRedirect } from '../shared/useIsAuthenticated';

export default defineComponent({
  name: 'BofaAuthRoot',
  setup() {
    useBlockedRedirect();

    // Redirecting to login page to determine if we should show basic login form or with saml.
    // FIXME: Go with SAML directly when we drop basic login support
    // useUnauthRedirect(() => AuthURL.samlLogin());
    useUnauthRedirect(() => '/login');

    return () => null;
  },
});
</script>
