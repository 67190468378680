export enum GlossaryCategory {
  ANALYTICS_COMPUTATION = 'Analytics Computation',
  CUSTOM_REBALANCING = 'Custom Rebalancing',
  DURATION_CONVEXITY = 'Duration & Convexity',

  EX_ANTE = 'Ex-Ante Risk Analysis',
  FX_CONVERSION = 'FX Conversion',
  FACTOR_REGRESSION = 'Factor Regression',
  FACTOR_DEFINITION = 'Factor Definition',
  GREEKS = 'Greeks',

  PCA = 'PCA',
  PCA_FULL = 'Principal Component Analysis',

  PERFORMANCE_METRICS = 'Performance Metrics',

  PORTFOLIO_ANALYSIS = 'Portfolio Analysis',
  PORTFOLIO_CONSTRUCTION = 'Portfolio Construction',
  PORTFOLIO_REBALANCING = 'Portfolio Rebalancing',
  PORTFOLIO_VALUE = 'Portfolio Value',

  RISK_METRICS = 'Risk Metrics',

  STRATEGY_FILTER = 'Strategy Filter',

  VALUE_AT_RISK = 'Value at Risk',
}

export enum SEARCH_TERM_TYPE {
  CATEGORY = 'Category',
  DEFINITION = 'Definition',
}

export type GlossarySearchItem =
  | {
      type: SEARCH_TERM_TYPE.CATEGORY;
      title: GlossaryCategory;
    }
  | {
      type: SEARCH_TERM_TYPE.DEFINITION;
      title: string;
      categories: GlossaryCategory[];
      id: string;
    };
