<!-- eslint-disable vue/no-lone-template -->
<template>
  <StandardModal
    id="save-changes"
    v-model="isShown"
    static
    centered
    no-close-on-backdrop
    footer-class="border-top-0"
    :dismissable="true"
    :should-show-overlay="loading"
    :on-cancel="onCancel"
    :on-confirm="onConfirm"
    @show="onShow"
  >
    <template>
      <h3 class="mb-3 text-center">{{ title }}</h3>
      <h4 class="mb-0 text-center">{{ subtitle }}</h4>
    </template>
    <template #footer="{ cancel, ok }">
      <div class="d-flex justify-content-between">
        <b-button
          variant="white"
          class="w-20 border"
          @click="cancel"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
        <b-button
          variant="danger"
          class="w-20"
          @click="discardChanges"
        >
          {{ translate({ path: 'GLOBAL.DISCARD' }) }}
        </b-button>
        <b-button
          :disabled="isSaveDisabled"
          variant="info"
          class="w-20"
          @click="ok"
        >
          {{ translate({ path: 'GLOBAL.SAVE' }) }}
        </b-button>
      </div>
    </template>
  </StandardModal>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import useTranslation from '@/composables/useTranslation';
import { SaveChangesModalResponse, SaveChangesStore, SaveItemUniqueKeys } from '@/store/modules/SaveChangesStore';
import useGlobalEventBus from '@/composables/useGlobalEventBus';

export default defineComponent({
  name: 'SaveChangesModal',
  setup() {
    const { translate } = useTranslation();
    const { eventBus } = useGlobalEventBus();

    const loading = ref(false);

    const isShown = computed({
      get() {
        return SaveChangesStore.isModalShown;
      },
      set(newVal: boolean) {
        SaveChangesStore.SetIsModalShown({ isModalShown: newVal });
      },
    });

    const defaultTitle = computed(() => {
      return translate({ path: 'SAVE_CHANGES_PROMPT.TITLE.UNSAVED_CHANGES' });
    });
    const title = ref(defaultTitle.value);

    const onCancel = () => {
      SaveChangesStore.ChangeModalResponse({ newVal: SaveChangesModalResponse.CANCEL });
    };

    const itemsWithChanges = computed(() => SaveChangesStore.itemsWithChanges);

    const isSaveDisabled = computed(() => SaveChangesStore.hasError);

    const subtitle = computed(() => {
      const changes = Object.keys(itemsWithChanges.value);
      if (isSaveDisabled.value) {
        return translate({
          path: 'SAVE_CHANGES_PROMPT.ERROR.SAVE_DISABLED',
        });
      }

      // If a custom prompt is given, use that
      if (SaveChangesStore.customSavingPrompt) {
        return SaveChangesStore.customSavingPrompt;
      }

      if (
        (changes.includes(SaveItemUniqueKeys.PORTFOLIO_TREE) ||
          changes.includes(SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT)) &&
        changes.find((c) => c !== SaveItemUniqueKeys.PORTFOLIO_TREE && c !== SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT)
      ) {
        return translate({
          path: 'SAVE_CHANGES_PROMPT.SUBTITLE.WRAPPER',
          params: {
            changeType: `${translate({ path: 'SAVE_CHANGES_PROMPT.SUBTITLE.PORTFOLIO_CHANGES' })} ${translate({
              path: 'GLOBAL.CONJUNCTIONS.AND',
            })} ${translate({
              path: 'SAVE_CHANGES_PROMPT.SUBTITLE.ANALYSIS_CHANGES',
            })}`,
          },
        });
      }

      if (changes.includes(SaveItemUniqueKeys.PORTFOLIO_TREE)) {
        return translate({
          path: 'SAVE_CHANGES_PROMPT.SUBTITLE.WRAPPER',
          params: {
            changeType: translate({ path: 'SAVE_CHANGES_PROMPT.SUBTITLE.PORTFOLIO_CHANGES' }),
          },
        });
      }

      if (changes.includes(SaveItemUniqueKeys.ARTICLE)) {
        return translate({
          path: 'SAVE_CHANGES_PROMPT.SUBTITLE.WRAPPER',
          params: {
            changeType: translate({ path: 'SAVE_CHANGES_PROMPT.SUBTITLE.ARTICLE_CHANGES' }),
          },
        });
      }

      return translate({
        path: 'SAVE_CHANGES_PROMPT.SUBTITLE.WRAPPER',
        params: {
          changeType: translate({ path: 'SAVE_CHANGES_PROMPT.SUBTITLE.ANALYSIS_CHANGES' }),
        },
      });
    });

    const onShow = () => {
      SaveChangesStore.ChangeModalResponse({ newVal: null });
    };

    const showModal = (customTitle?: string) => {
      title.value = customTitle || defaultTitle.value;

      SaveChangesStore.ChangeModalResponse({ newVal: null });
      isShown.value = true;
    };

    onMounted(() => {
      eventBus.on('display-save-discard-changes-modal', showModal);
    });

    onBeforeUnmount(() => {
      eventBus.off('display-save-discard-changes-modal', showModal);
    });

    const discardChanges = async () => {
      loading.value = true;
      const items = Object.values(itemsWithChanges.value);
      SaveChangesStore.SetCustomSavingPrompt(null);

      return Promise.all(Object.values(itemsWithChanges.value).map((i) => i.discardFn()))
        .then((results) => {
          results.forEach((promise, index) => {
            const itemToRemove = items[index];

            // we want to make sure we don't trigger the modal again because we just handled the changes
            SaveChangesStore.RemoveItemsToSave({ keys: itemToRemove.key });
          });

          SaveChangesStore.ChangeModalResponse({ newVal: SaveChangesModalResponse.DISCARD });
          isShown.value = false;
        })
        .catch((err) => {
          throw new Error('SaveChangesModal discardChanges error: ' + err);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const onConfirm = async () => {
      loading.value = true;
      const items = Object.values(itemsWithChanges.value);
      SaveChangesStore.SetCustomSavingPrompt(null);

      return Promise.all(Object.values(itemsWithChanges.value).map((i) => i.saveFn()))
        .then((results) => {
          results.forEach((promise, index) => {
            const itemToRemove = items[index];

            // we want to make sure we don't trigger the modal again because we just handled the changes
            SaveChangesStore.RemoveItemsToSave({ keys: itemToRemove.key });
          });

          SaveChangesStore.ChangeModalResponse({ newVal: SaveChangesModalResponse.SAVE });
          isShown.value = false;
        })
        .catch((err) => {
          throw new Error('SaveChangesModal discardChanges error: ' + err);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      translate,
      itemsWithChanges,
      loading,
      onShow,
      onConfirm,
      discardChanges,
      onCancel,
      title,
      subtitle,
      isShown,
      isSaveDisabled,
    };
  },
});
</script>
