import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

export enum DeviceType {
  Mobile,
  Desktop,
}

export interface IAppState {
  sidebar: {
    withoutAnimation: boolean;
  };
}

@Module({ dynamic: true, store, name: 'App' })
class App extends VuexModule implements IAppState {
  public sidebar = {
    // opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false,
  };

  /**
   * TODO: I don't think we need this... it just logs it to the console.
   */
  @Action({ rawError: true })
  public async ShowErrorModal(errorMessage: string): Promise<void> {
    console.error(errorMessage);
  }
}

export const AppModule = getModule(App);
