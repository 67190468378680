import AnnualReturn from './content/annual-return.md';
import AverageDrawdown from './content/average-drawdown.md';
import Calmar from './content/calmar.md';
import CVar from './content/cvar.md';
import Drawdown from './content/drawdown.md';
import IndexPerformance from './content/index-performance.md';
import InfoRatio from './content/info-ratio.md';
import Kurtosis from './content/kurtosis.md';
import Maxdd from './content/maxdd.md';
import Metrics from './content/metrics.md';
import MonthlyReturnDistribution from './content/monthly-return-distribution.md';
import MonthlyReturn from './content/monthly-return.md';
import Omega from './content/omega.md';
import PerformanceContributionDaily from './content/performance-attribution-daily.md';
import PerformanceContributionMonthlyReturnDistribution from './content/performance-attribution-monthly-return-distribution.md';
import PerformanceContributionReturnDaily from './content/performance-attribution-return-daily.md';
import PerformanceContributionWeightDaily from './content/performance-attribution-weight-daily.md';
import Return from './content/return.md';
import RiskMetrics from './content/risk-metrics.md';
import RollingSharpe from './content/rolling-sharpe.md';
import RollingVolatility from './content/rolling-volatility.md';
import Sharpe from './content/sharpe.md';
import Skew from './content/skew.md';
import Sortino from './content/sortino.md';
import Top10Bottom10Table from './content/top10-bottom10-table.md';
import Track from './content/x-track.md';
import Var from './content/x-var.md';
import Volatility from './content/volatility.md';

export const registry = {
  ['return']: Return,
  ['AReturn']: AnnualReturn,
  ['volatility']: Volatility,
  ['sharpe']: Sharpe,
  ['Info. Ratio']: InfoRatio,
  ['skew']: Skew,
  ['Kurtosis']: Kurtosis,
  ['Calmar']: Calmar,
  ['Omega']: Omega,
  ['Sortino']: Sortino,
  ['var']: Var,
  ['CVaR']: CVar,
  ['Average Drawdown']: AverageDrawdown,
  ['maxDD']: Maxdd,
  ['Index Performance']: IndexPerformance,
  ['Drawdown']: Drawdown,
  ['Metrics']: Metrics,
  ['Monthly Return Distribution']: MonthlyReturnDistribution,
  ['Rolling Sharpe']: RollingSharpe,
  ['Rolling Volatility']: RollingVolatility,
  ['Monthly Return']: MonthlyReturn,
  ['Risk Metrics']: RiskMetrics,
  ['track']: Track,
  ['PerformanceContributionWaterfallChart']: PerformanceContributionDaily,
  ['PerformanceContributionEffectiveReturn']: PerformanceContributionReturnDaily,
  ['PerformanceContributionAverageWeightChart']: PerformanceContributionWeightDaily,
  ['PerformanceContributionMonthlyReturnDistribution']: PerformanceContributionMonthlyReturnDistribution,
  ['Top10Bottom10Table']: Top10Bottom10Table,
};
