
<script setup lang="ts">
const id = "performance-contribution"
 const title = "Performance Contribution"
 const categories = ["PERFORMANCE_METRICS"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"performance-contribution","title":"Performance Contribution","categories":["PERFORMANCE_METRICS"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"performance-contribution","title":"Performance Contribution","categories":["PERFORMANCE_METRICS"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>This section applies to both portfolio and strategy performance pages.</p>
<h1>Performance Contribution</h1>
<p>Contribution quantifies the return generated by an asset or a group of assets within a portfolio. It is calculated by dividing the asset’s P&amp;L over the selected period by the portfolio’s Net Asset Value (NAV) on the period’s start date.</p>
<h1>Average Weight</h1>
<p>Average Weight calculates the average of the start-of-day weights of the period under consideration. It provides a measure of the average allocation of assets within the portfolio during the specified time frame.</p>
<h1>Effective Return</h1>
<p>Effective Return measures the contribution of the portfolio to the overall return. It is calculated by dividing the Contribution by the Average Weight.</p>
</div>
</template>
