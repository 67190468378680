
<script setup lang="ts">
const id = "regression-track"
 const title = "Regression Track"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"regression-track","title":"Regression Track","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"regression-track","title":"Regression Track","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>A risk-adjusted benchmark generated from the chosen factors and their corresponding static beta coefficients.
This enables the user to benchmark the performance of the strategy and/or fund against the broader market
consensus. Exact period where the track overperforms or underperforms can be identified with this benchmarking
technique.</p>
</div>
</template>
