import { computed, Ref } from 'vue';
import usePortfolioTree from '@/composables/usePortfolioTree';
import { IPortfolioTreeStrategy, IPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';

export function usePortfolioInfo(portfolio: Ref<IPortfolioTreeSubportfolio | IPortfolioTreeStrategy | null>) {
  const { masterPortfolioTree } = usePortfolioTree();
  const isMaster = computed(() => {
    if (portfolio.value == null || masterPortfolioTree.value == null) {
      return false;
    }

    return portfolio.value.portfolioTreeId === masterPortfolioTree.value.portfolioId;
  });

  return {
    masterPortfolioTree,
    isMaster,
  };
}
