import { PluginObject, ObjectDirective } from 'vue';

const focusOnInsert: ObjectDirective = {
  inserted: (el): void => {
    setTimeout(() => {
      el.focus();

      if (el instanceof HTMLInputElement) {
        el.select();
      }
    });
  },
};

export const focusOnInsertPlugin: PluginObject<void> = {
  install(vue) {
    vue.directive('focusOnInsert', focusOnInsert);
  },
};
