<template>
  <div>
    <WhitelabelPdfHeader v-if="shouldUseWhitelabelCustomPdfHeaderComponent" />
    <b-navbar
      v-else
      id="pdf-header"
      :style="pdfPageHeaderStyle"
      style="z-index: 4000"
      class="d-print-block px-4"
      toggleable="sm"
      type="dark"
      variant="primary"
    >
      <b-container>
        <b-row
          :style="textStyle"
          class="w-100 text-white"
          no-gutters
        >
          <b-col
            cols="8"
            class="d-flex py-1"
          >
            <div
              :style="pdfAccentBarStyle"
              class="py-3 px-2"
            >
              <img
                v-if="!customLogo"
                src="@/assets/new-premialab-logo.svg"
                class="pr-2"
                height="30"
              />
              <img
                v-else
                :src="customLogo"
                class="pr-2"
                height="30"
              />
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pl-4">
              <span class="d-inline-block font-size">
                {{ title }}
              </span>
              <span class="d-inline-block font-size-sm">
                {{ subtitle }}
              </span>
              <span class="d-inline-block font-size-sm">
                {{ analysisItemAttributes }}
              </span>
              <span
                v-if="regimeName"
                class="d-inline-block font-size-sm"
              >
                {{ translate({ path: 'FACTSHEETS.STRATEGY_FACTSHEET_PDF.MARKET_REGIME' }) }}: {{ regimeName }}
              </span>
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex flex-column justify-content-center align-items-end"
          >
            <div v-if="rightSideItems.length">
              <template v-for="(item, index) in rightSideItems">
                <span
                  :key="index"
                  class="d-block font-size-sm force-print-white"
                >
                  {{ item }}
                </span>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import { SettingsModule } from '@/store/barrel';
import WhitelabelPdfHeader from '@/layout/components/pdf/WhitelabelPdfHeader.vue';
import { computed, defineComponent, watch } from 'vue';
import useUser from '@/composables/useUser';
import barclaysLogo from '@/assets/whitelabel/barclays/barclays-logo.svg';
import bofaLogo from '@/assets/whitelabel/bofa/bofa-logo-white.svg';
import useEnv from '@/composables/useEnv';
import useTranslation from '@/composables/useTranslation';
import { useDataDiscovery } from '@/composables/queries/useDataDiscovery';
import { useDiscoverStore } from '@/composables/useDiscoverStore';

export default defineComponent({
  components: {
    WhitelabelPdfHeader,
  },
  setup() {
    const { isBarclaysEnvironment, isBofAEnvironment } = useEnv();
    const { translate } = useTranslation();

    /**
     * UNIVERSE INSTANTIATION SINGLETON
     *
     * This logic is not truly used in this page, but the index universe is used extensively throughout the application
     * Here we instantiate it once and when it changes, we update the Discover Store.
     *
     * This is the way we must move forward to comply with tanstack-query's requirements to v5 because the
     * useQuery's `onSuccess` callback is being deprecated.
     */
    const { setUniverse } = useDiscoverStore();
    const universe = useDataDiscovery();
    watch(universe.data, (newVal) => {
      // Use structuredClone to force the object to not be reactive
      if (newVal) setUniverse(structuredClone(newVal));
    });

    const title = computed(() => {
      return SettingsModule.printTitle;
    });

    const subtitle = computed(() => {
      return SettingsModule.printSubtitle;
    });

    const analysisItemAttributes = computed(() => {
      return SettingsModule.printAnalysisItemAttributes;
    });

    const regimeName = computed(() => {
      return SettingsModule.regimeName;
    });

    const { user } = useUser();

    const rightSideItems = computed(() => {
      return SettingsModule.printHeaderRightSideItems;
    });

    const pdfPageHeaderStyle = computed((): { [key: string]: string | number | null } => {
      return SettingsModule.pdfPageHeaderStyle;
    });

    const pdfAccentBarStyle = computed(() => {
      return SettingsModule.pdfPageHeaderAccentBarStyle;
    });

    const textStyle = computed(() => {
      return SettingsModule.pdfPageHeaderTextStyle;
    });

    /**
     * On whitelabel branches, set this boolean to true in order to
     * completely replace the PDF header on each page
     */
    const shouldUseWhitelabelCustomPdfHeaderComponent = computed(() => {
      return SettingsModule.shouldUseWhitelabelCustomPdfHeaderComponent;
    });

    const customLogo = computed(() => {
      if (isBarclaysEnvironment) {
        return barclaysLogo;
      }
      if (isBofAEnvironment) {
        return bofaLogo;
      }
      if (SettingsModule.pdfPageHeaderCustomDynamicLogo) {
        return SettingsModule.pdfPageHeaderCustomDynamicLogo;
      }
      if (!user.value || !user.value.logoImage) return '';
      return user.value.logoImage;
    });

    return {
      shouldUseWhitelabelCustomPdfHeaderComponent,
      pdfPageHeaderStyle,
      textStyle,
      pdfAccentBarStyle,
      customLogo,
      title,
      subtitle,
      analysisItemAttributes,
      rightSideItems,
      regimeName,
      translate,
    };
  },
});
</script>
