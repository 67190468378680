export enum PrimaryConstraintTypeConstants {
  NONE = 'None',
  STRATEGY = 'Strategy',
}

export enum SecondaryConstraintTypeConstants {
  NONE = 'None',
  ASSET_CLASS = 'Asset Class',
  FACTOR = 'Factor',
}
