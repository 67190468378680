export const MorningstarTourDict = {
  step1: {
    title: 'New Morningstar Data Source',
    text: 'Select the Morningstar tab to maintain your Funds and ETFs investment universe.',
  },
  step2: {
    title: 'Add New Funds',
    text: 'Access the selection popup from here.',
  },
  step3: {
    title: 'ISIN or Morningstar Identifiers',
    text: 'Paste a list of ISIN or Morningstar ID. We can recognise identifiers inside a free text or email.',
  },
  step4: {
    title: 'Statistics and Monthly Allowance',
    text: 'Visualise your allocation statistics. Contact your account manager if you need more.',
  },
  step5: {
    title: 'Subscribed Funds',
    text: 'Subscribed funds are downloaded immediately then updated daily. If an ISIN offers more than 1 share class, simply choose the right one from “ISIN Variants” hyperlink.',
  },
  step6: {
    title: 'Fund Maintenance',
    text: 'Select lines to remove funds you don’t need anymore.',
  },
};
