import { AuthStrategy } from '../types';
import CoreLogin from './CoreLogin.vue';
import CoreAuthRoot from './CoreAuthRoot.vue';

export function coreAuth(): AuthStrategy {
  return {
    loginComponent: CoreLogin,
    rootComponent: CoreAuthRoot,
  };
}
