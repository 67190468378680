import { RouteName } from '@/constants/RouteName';

/**
 * Set of special auth-related routes.
 *
 * Ok this is a lie. This also includes 404 route. These routes does not require user info.
 *
 * If we are in these routes, it is fine if we don't have user info because we are either in the process of
 * getting authenticated or logging out.
 *
 * The type includes `| null | undefined` to satisfies typescript's RouteName.
 * In runtime, `.has(null)` must return `false`.
 */
export const SKIP_AUTH_ROUTES = new Set<string | null | undefined>([
  RouteName.LOGIN, // Allow admin to login without that modal
  RouteName.LOGOUT, // This will get redirected away like immediately
  RouteName.NOT_FOUND,
  RouteName.UNAUTHORIZED,
]);
