import Color from 'color';

const lightestBlue = '#a3ceff';
const darkestBlue = '#002147';

const lightestRed = '#fbeff2';

export const TRANSPARENT = 'rgba(0, 0, 0, 0)';

export const darken = (hexString: string, percentage: number = 0.125): string => {
  const color = Color(hexString).hsl().object();

  color.l = color.l * (1 - percentage);

  return Color(color).hex();
};

export const canUseDarkTextColor = (hexString: string): boolean => {
  const color = Color(hexString).rgb().object();

  return color.r * 0.299 + color.g * 0.587 + color.b * 0.114 > 170;
};

export const mimicLighterOpacity = (hexString: string, percentage: number = 0.5): string => {
  const color = Color(hexString).rgb().object();

  color.r = 255 - percentage * (255 - color.r);
  color.g = 255 - percentage * (255 - color.g);
  color.b = 255 - percentage * (255 - color.b);

  return Color(color).hex();
};

/**
 * @param lightColor hex color
 * @param darkColor hex color
 * @param numSteps number of desired steps between the two colors
 */
export const getColorStepper = (light: string, dark: string, numSteps: number): number => {
  const lightestColor = Color(light).hsl().object().l;
  const darkestColor = Color(dark).hsl().object().l;

  const lightnessDifference = lightestColor - darkestColor;
  const lightnessStepper = lightnessDifference / (numSteps * 100);

  return lightnessStepper;
};

export const getOpacityStepper = (numSteps: number): number => {
  return 0.65 / numSteps;
};

export const getBlueColorStepper = (
  numSteps: number,
): {
  initialBlue: string;
  stepper: number;
} => {
  return {
    initialBlue: lightestBlue,
    stepper: getColorStepper(lightestBlue, darkestBlue, numSteps),
  };
};

export const getRedColorStepper = (
  numSteps: number,
): {
  initialRed: string;
  stepper: number;
} => {
  return {
    initialRed: lightestRed,
    stepper: getColorStepper(lightestBlue, darkestBlue, numSteps),
  };
};
