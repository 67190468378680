/* eslint-disable @typescript-eslint/no-explicit-any */
import { all } from 'deepmerge';
import { Locales } from '@/constants/Locales';
import en from './en.yaml';
import enOverride from './en.override.yaml';

/**
 * Map of locale message source.
 */
const LocaleMessageMap: Record<Locales, Array<() => Promise<any>>> = {
  /**
   * Note: English should be empty as the locale is loaded in {@link englishLocaleMessage} instead.
   */
  [Locales.ENGLISH]: [],
  [Locales.JAPANESE]: [
    () => import('./ja.yaml').then((mod) => mod.default),
    () => import('./ja.override.yaml').then((mod) => mod.default),
  ],
  [Locales.CHINESE_SIMPLIFIED]: [
    () => import('./zh-Hans.yaml').then((mod) => mod.default),
    () => import('./zh-Hans.override.yaml').then((mod) => mod.default),
  ],
  [Locales.CHINESE_TRADITIONAL]: [
    () => import('./zh-Hant.yaml').then((mod) => mod.default),
    () => import('./zh-Hant.override.yaml').then((mod) => mod.default),
  ],
};

/**
 * Get english locale message.
 *
 * This should be importing the resource statically for use in fallback message.
 */
export function englishLocaleMessage(): any {
  const messages = [en, enOverride];

  return all(messages);
}

export async function loadLocaleMessage(locale: Locales): Promise<any> {
  const promises = LocaleMessageMap[locale].map((factory) => factory());

  const messages = await Promise.all(promises);

  return all(messages);
}
