import { computed } from 'vue';
import { useRouteRef } from '@/composables/useRouter';
import { AuthenticationStatus, useIsAuthenticated } from './useIsAuthenticated';

const FORCE_FORM_QS = 'basiclogin';

/**
 * Check the login strategy for the current page.
 *
 * Used for platform with SAML only.
 */
export function useLoginStrategy() {
  const route = useRouteRef();
  const isAuthenticated = useIsAuthenticated();

  const strategy = computed(() => {
    if (route.value.query[FORCE_FORM_QS] === '1') {
      return 'basic';
    }

    return 'saml';
  });

  const renderForm = computed(() => {
    return strategy.value === 'basic' && isAuthenticated.value === AuthenticationStatus.UNAUTH;
  });

  return {
    strategy,
    renderForm,
  };
}
