export interface SampleStrategyItem {
  shortName: string;
  longName: string;
  code: string;
  bgColor: string;
  percentage: string;
}

export enum DisplayNames {
  SHORT_NAME = 'short_name',
  LONG_NAME = 'long_name',
  CODE = 'code',
}

export interface TooltipItem {
  key: string;
  value: string;
  isIdentifier?: boolean;
}
