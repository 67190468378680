<template>
  <b-dropdown
    ref="button"
    variant="outline-info"
    no-caret
    size="sm"
    dropright
    toggle-class="squared-center-fa-btn d-print-none"
    :menu-class="`${menuClass} shadow`"
  >
    <template #button-content>
      <icon
        fixed-width
        icon="cog"
      />
    </template>
    <slot>
      <slot name="header" />
      <b-dd-form novalidate>
        <b-form-checkbox
          v-for="option in allOptions"
          :key="option"
          v-model="selected"
          :value="option"
          :disabled="!selected.includes(option) && selected.length >= maxOptionsSelected"
          class="py-1"
        >
          {{ translateMetric(option) }}
        </b-form-checkbox>
      </b-dd-form>
    </slot>
  </b-dropdown>
</template>

<script lang="ts">
import useTranslation from '@/composables/useTranslation';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue';

/**
 * I purposefully didn't move this component to use ParentDropdown / ChildDropdown
 * because that seemed too complicated *shrug*
 */
export default defineComponent({
  name: 'SettingsButton',
  props: {
    allOptions: {
      type: Array as PropType<string[]>,
      required: false,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxOptionsSelected: {
      type: Number,
      required: false,
      default: 6,
    },
    menuClass: {
      type: String,
      required: false,
    },
    childOptionsOpen: {
      type: Boolean,
      required: false,
    },
    translateFn: {
      type: Function,
      required: false,
    },
  },
  emits: ['show', 'shown', 'hide', 'hidden'],
  setup(props, { emit }) {
    const selected = useVModel(props, 'selectedOptions', emit, { defaultValue: [] });

    const { translate } = useTranslation();

    /**
     * TODO: change the databaseTranslator to actually translate the other metrics
     */
    const translateMetric = (option: string) => {
      if (props.translateFn !== undefined) {
        return props.translateFn(option);
      }
      return translate({ path: 'GLOBAL.DATABASE_TRANSLATOR', item: option });
    };

    return {
      selected,
      translateMetric,
    };
  },
});
</script>
