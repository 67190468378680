import request, { checkBlockPopup } from '@/api-v2/request';
import {
  CustomStressTest,
  FilterConstants,
  EquityGroupByConstants,
  IConstituentRiskOptions,
  StressTestDropdownType,
  TableLayerConstants,
  UnitConstants,
} from '@/types/analytics/ConstituentRisk';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import { IConstituentRibbonQuery, IConstituentRisk, IConstituentRiskTableQuery } from '@/types/IConstituentRisk';
import { IConstituentRiskRibbonResponse } from '@/types/IConstituentRiskRibbon';
import { FileService } from '@/api-v2/web/file';
import { RouteName } from '@/constants/RouteName';
import { MetricConstants } from '@/constants/MetricConstants';
import { useDefaultPdfOptions } from '@/composables/usePdf';
import { Locales } from '@/constants/Locales';

/**
 * Fetches the constituent risk data.
 * @param slug Portfolio slug name
 * @param component Array of data components to receive
 * @param portfolioTreeId treeId of select subportfolio
 * @param date Portfolio position date
 * @param fields Portfolio fields chosen by options
 * @param rollingDate Reference date
 * @param unit Can either be % or $
 * @param equityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param equityFilterLevel1 First filter level
 * @param equityFilterLevel2 Second filter level
 * @param commodityMetric Metric to use in the calculation (Delta, Gamma, Vega)
 * @param varMetric1 Var item to fetch
 * @param varMetric1GroupBy Var group by for allocated
 * @param varMetric2 Exposure item to fetch
 * @param ribbonFieldNames List of metrics for the ribbon
 * @param stressTestOptions Dictionary of stress tests and their shocks
 * @param stressTestHistoryOption Stress test to use for history mode
 * @param stressTestFilter Filter
 * @param fxFilter Filter
 * @param trackSegment Time Chart should be calculated at the portfolio or strategy level
 * @param commodityTableExposureType Exposure to show for table
 * @param basisPoints Basis Point to use for the ribbon and charts of Credit and Interest Rate
 * @param riskTableLayers List of table layers
 * @param isNavRescaling Boolean to turn on navRescaling
 */
export const getConstituentRiskData = (
  slug: string,
  component: string[],
  portfolioTreeId: string,
  /**
   * Replaces `suspendedForRisk` in the portfolioTreeDraft. Query will only be run using codes in this property
   */
  analysisCodes: string[],
  date: string,
  fields?: string[],
  rollingDate?: string,
  unit?: UnitConstants,
  equityMetric?: MetricConstants,
  equityFilterLevel1?: EquityGroupByConstants | FilterConstants,
  equityFilterLevel2?: EquityGroupByConstants | FilterConstants,
  commodityMetric?: MetricConstants,
  varMetric1?: string,
  varMetric1GroupBy?: FilterConstants,
  varMetric2?: string,
  ribbonFieldNames?: string[],
  stressTestOptions?: StressTestDropdownType[],
  stressTestHistoryOption?: StressTestDropdownType,
  stressTestFilter?: FilterConstants,
  fxFilter?: FilterConstants,
  trackSegment?: string,
  basisPoints?: number,
  riskTableLayers?: TableLayerConstants[],
  isNavRescaling?: boolean,
): Promise<IConstituentRisk> =>
  request({
    url: `/risk-dashboard/dashboard`,
    method: 'post',
    data: {
      slug,
      component,
      portfolioTreeId,
      analysisCodes,
      fields,
      date,
      rollingDate,
      unit,
      equityMetric,
      equityFilterLevel1,
      equityFilterLevel2,
      commodityMetric,
      varMetric1,
      varMetric1GroupBy,
      varMetric2,
      ribbonFieldNames,
      stressTestOptions,
      stressTestHistoryOption,
      stressTestFilter,
      fxFilter,
      trackSegment,
      basisPoints,
      riskTableLayers,
      isNavRescaling,
    },
  }).then(
    (o): IConstituentRisk => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Fetches the constituent risk tables
 */
export const getConstituentRiskTable = (query: IConstituentRiskTableQuery): Promise<IConstituentRisk> =>
  request({
    url: `/risk-dashboard/dashboard/risk-table`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRisk => o.data);

/**
 * Fetches the constituent risk ribbon
 */
export const getConstituentRiskRibbon = (query: IConstituentRibbonQuery): Promise<IConstituentRiskRibbonResponse[]> =>
  request({
    url: `/risk-dashboard/dashboard/ribbon`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRiskRibbonResponse[] => o.data);

/**
 * Fetches the list of available dates for the portfolio.
 * @param slug portfolio slug name
 * @returns Array of date strings. String Format: ('YYYY-MM-DD')
 */
export const getConstituentRiskDates = (slug: string): Promise<string[]> =>
  request({
    url: `/risk-dashboard/${slug}/dates`,
    method: 'get',
  }).then(
    (o): string[] => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Fetches the list of available fields for each tab.
 * @param slug portfolio slug name
 */
export const getConstituentRiskOptions = ({
  slug,
  params,
}: {
  slug: string;
  params: { date: string };
}): Promise<IConstituentRiskOptions> =>
  request({
    url: `/risk-dashboard/${slug}/options`,
    method: 'get',
    params,
  }).then(
    (o): IConstituentRiskOptions => o.data,
    (err) => {
      throw err;
    },
  );

/**
 * Constituent Risk Excel
 * @param slug portfolio slug name
 * @param date portfolio constituent date
 * @param
 */
export const getExcel = async (slug: string, date: string, indexName: string): Promise<void> => {
  const dto = await request({
    url: `/risk-dashboard/${slug}/excel`,
    method: 'POST',
    params: {
      date,
    },
  }).then((o) => o.data as FileSignedUrlDTO);

  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'xlsx', {
    tool: RouteName.CONSTITUENT_RISK,
    indexName,
    date,
  });
};

const defaultPdfOptions = useDefaultPdfOptions();

/**
 * Constituent Risk PDF
 * @param slug portfolio slug name
 * @param date portfolio constituent date
 * @param indexName name of the index, masked if appropriate
 */
export const getPdf = async (
  slug: string,
  url: string,
  params: {
    /**
     * Stringified preferences
     */
    preferences: string;
    startDate: string;
    locale: Locales;
  },
  indexName: string,
): Promise<void> => {
  const resp = await request({
    url: `/risk-dashboard/${slug}/pdf`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  });
  const dto: FileSignedUrlDTO = resp.data;
  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.CONSTITUENT_RISK,
    indexName,
    date: params.startDate,
  });
};

/**
 * Extract all stress test option for the user
 */
export const getCustomStressTest = (): Promise<CustomStressTest[]> =>
  request({
    url: `/risk-dashboard/stress-test`,
    method: 'get',
  }).then((o) => o.data);

/**
 * Create a new stress test option
 */
export const uploadCustomStressTest = (body: CustomStressTest): Promise<CustomStressTest> =>
  request({
    url: `/risk-dashboard/stress-test`,
    method: 'post',
    data: body,
  }).then((o) => o.data);

/**
 * Create a new stress test option
 */
export const updateCustomStressTest = ({
  attributeId,
  body,
}: {
  attributeId: string;
  body: CustomStressTest;
}): Promise<CustomStressTest> =>
  request({
    url: `/risk-dashboard/stress-test/${attributeId}`,
    method: 'put',
    data: body,
  }).then((o) => o.data);

/**
 * Delete a stress test option
 */
export const deleteCustomStressTest = (attributeId: string): Promise<void> =>
  request({
    url: `/risk-dashboard/stress-test/${attributeId}`,
    method: 'delete',
  }).then((o) => o.data);
