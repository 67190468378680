<template>
  <b-overlay
    :show="busy"
    rounded
    opacity="0.6"
    spinner-small
    :spinner-variant="$attrs.variant"
    :class="overlayClasses"
  >
    <b-button
      ref="button"
      :disabled="disabledButton"
      :block="block === '' || block"
      v-bind="$attrs"
      :class="buttonClasses"
      v-on="$listeners"
    >
      <slot name="icon">
        <Icon
          v-if="icon"
          :class="{
            'mr-2': !iconOnly,
          }"
          :icon="icon"
        />
      </slot>

      <slot />
    </b-button>
  </b-overlay>
</template>

<script lang="ts">
import { ClassProp, handlePossibleClassPropTypes } from '@/utils/bootstrapVue';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'StandardButton',
  props: {
    /**
     * If true, this button will be disabled and a loading spinner will be shown
     */
    busy: {
      type: Boolean,
      default: false,
    },
    /**
     * Inherit v-button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon to be displayed before `<slot />`.
     *
     * This is only for simple use cases. If you need to further customize the icon, use the `icon` slot instead.
     */
    icon: {
      type: String,
      required: false,
    },
    block: {
      type: [String, Boolean],
      required: false,
    },
    overlayClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
    buttonClass: {
      type: [String, Object, Array] as PropType<ClassProp>,
      required: false,
    },
  },
  setup(props, { slots }) {
    const disabledButton = computed(() => props.busy || props.disabled);

    /**
     * Check if the component is rendering in icon-only mode.
     */
    const iconOnly = computed(() => {
      return slots.default == null && (props.icon != null || slots.icon != null);
    });

    const overlayClasses = computed(() => {
      return [
        {
          'w-100': props.block === '' || props.block,
        },
        'd-inline-block',
        ...handlePossibleClassPropTypes(props.overlayClass ?? {}),
      ];
    });

    const buttonClasses = computed(() => {
      return handlePossibleClassPropTypes(props.buttonClass ?? {});
    });

    return {
      disabledButton,
      iconOnly,
      overlayClasses,
      buttonClasses,
    };
  },
});
</script>
