/**
 * These options should be used when there's content below a dropdown.
 * The default BootstrapVue options show 1px of the content behind the
 * dropdown to the left. This setting moves it 1px over to prevent it from
 * appearing behind the dropdown.
 *
 * TODO: the ultimate way to fix this would be to change the styling of the
 * dropdown menus to be 2px wider, 1px for the left and 1px to the right.
 * Not sure how to accomplish this off the top of my head...
 */
const defaultPopperOptsDropdown = {
  modifiers: {
    offset: {
      enabled: true,
      offset: '-1',
    },
  },
};

/**
 * These options should be used when using a 'blind' or 'link' button. Because these
 * button types have no borders, we add a negative offset to the top of the popper,
 * so the distance between the button text and the dropdown is reduced.
 */
const defaultPopperOptsBlind = {
  modifiers: {
    offset: {
      enabled: true,
      offset: '-1, -20',
    },
    flip: {
      // necessary to move the menu on top of the original dropdown element
      enabled: false,
    },
  },
};

export default function (): {
  defaultPopperOptsDropdown: {
    modifiers: unknown;
  };
  defaultPopperOptsBlind: {
    modifiers: unknown;
  };
} {
  return {
    defaultPopperOptsDropdown,
    defaultPopperOptsBlind,
  };
}
