export enum LeverageTypeConstants {
  NONE = 'None',
  FIXED_LEVERAGE = 'Fixed Leverage',
  TARGET_VOLATILITY = 'Target Volatility',
  TARGET_EXPOSURE = 'Target Exposure',
  TARGET_LONG_EXPOSURE = 'Target Long Exposure',
}

// Leverage Type = Target Volatility
export enum TargetIntervalConstants {
  '1M' = '1M',
  '3M' = '3M',
  '6M' = '6M',
  '1Y' = '1Y',
  '2Y' = '2Y',
  '3Y' = '3Y',
}
