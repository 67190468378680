
<script setup lang="ts">
const id = "standard-error"
 const title = "Standard Error"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"standard-error","title":"Standard Error","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"standard-error","title":"Standard Error","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>Standard Error is the standard deviation of the beta coefficient. Standard error is a measure of the accuracy of
the beta estimation.</p>
</div>
</template>
