<!-- eslint-disable vue/no-lone-template -->
<template>
  <PremialabStandardModal
    id="disclaimerModal"
    v-model="isShown"
    centered
    size="xl"
    hide-footer
    :dismissable="false"
    hide-header
    body-class="p-0 m-0"
    :custom-modal-body="true"
    :on-cancel="onCancel"
    @shown="onShown"
  >
    <template>
      <b-row no-gutters>
        <b-col lg="6">
          <DisclaimerModalBanner
            :img-src="bannerUrl"
            :title="bannerTitle"
          />
        </b-col>
        <b-col>
          <div class="m-4">
            <transition name="slide">
              <DisclaimerModalText v-if="shouldShowText" />
            </transition>
            <div>
              <transition name="slide">
                <div
                  v-if="shouldShowText"
                  class="font-weight-bold text-left text-accent mb-2"
                >
                  Select your Profile
                </div>
              </transition>
              <div class="d-flex">
                <b-button
                  v-for="option in options"
                  :key="option.value"
                  class="w-100"
                  :active="option.value === clientType"
                  :variant="option.value === clientType ? 'accent' : 'outline-accent'"
                  @click="handleOptionSelect(option.value)"
                  >{{ option.label }}
                </b-button>
              </div>
            </div>
          </div>
          <transition name="slide">
            <DisclaimerTermsAndConditions
              v-if="!shouldShowText"
              @termsAgreed="handleTermsAgreed"
            />
          </transition>
        </b-col>
      </b-row>
    </template>
  </PremialabStandardModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import DisclaimerModalBanner from './DisclaimerModalBanner.vue';
import DisclaimerModalText from './DisclaimerModalText.vue';
import DisclaimerTermsAndConditions from './DisclaimerTermsAndConditions.vue';
import PremialabStandardModal from '@/components/standard-components/StandardModal.vue';
import { useVModel } from '@vueuse/core';
import NProgress from 'nprogress';
import { PublicUserType } from '@/api-v2/web/auth';

export default defineComponent({
  name: 'DisclaimerModal',
  components: {
    DisclaimerModalBanner,
    DisclaimerModalText,
    DisclaimerTermsAndConditions,
    PremialabStandardModal,
  },
  props: {
    /**
     * Controls open of the modal. This is available as `v-model` binding
     */
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    input: (val: boolean) => typeof val === 'boolean',
    /**
     * Fires when the user agrees on the terms.
     *
     * Note that when this method fires, the dialog will still kept open. Parent may/should close the dialog.
     *
     * @param val The user type user chosen to agree terms for.
     */
    agree: (val: PublicUserType) => typeof val === 'string' && Object.values(PublicUserType).includes(val),
  },
  setup(props, { emit }) {
    const isShown = useVModel(props, 'value', emit, { eventName: 'input' });

    const clientType = ref<PublicUserType | null>(null);
    const shouldShowText = ref<boolean>(true);

    const options = [
      { value: PublicUserType.INDIVIDUAL, label: 'Professional' },
      { value: PublicUserType.INSTITUTIONAL, label: 'Institutional' },
    ];

    const onShown = () => {
      // Make sure there is no weird loading progress bar in the background when
      // we are doing authentication
      NProgress.done();
    };

    const onCancel = () => {
      clientType.value = null;
    };

    /** Will add the functionality once the api endpoint is available */
    const handleOptionSelect = (option: PublicUserType) => {
      shouldShowText.value = false;
      clientType.value = option;
    };

    const handleTermsAgreed = async (value: boolean) => {
      // Decline the terms. Back to select user type.
      if (!value) {
        shouldShowText.value = true;
        return;
      }

      if (clientType.value == null) {
        throw new Error('Expected clientType not be null when agreeing terms');
      }

      emit('agree', clientType.value);
    };

    return {
      bannerUrl: `${location.origin}/download/images/city-network.webp`,
      bannerTitle: 'HSBC Quantitative Investment Solutions',
      options,
      handleOptionSelect,
      shouldShowText,
      clientType,
      handleTermsAgreed,
      isShown,
      onShown,
      onCancel,
    };
  },
});
</script>

<style scoped>
/** This makes the slide-up-slide-down transition smooth */

.slide-enter-active {
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.slide-leave-active {
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.slide-enter-to,
.slide-leave {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
