import { PluginObject } from 'vue';
import {
  BVConfigPlugin,
  NavbarPlugin,
  LayoutPlugin,
  DropdownPlugin,
  OverlayPlugin,
  CardPlugin,
  ButtonPlugin,
  FormPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormTagsPlugin,
  FormRadioPlugin,
  FormDatepickerPlugin,
  ModalPlugin,
  CollapsePlugin,
  NavPlugin,
  FormCheckboxPlugin,
  PopoverPlugin,
  VBPopoverPlugin,
  TablePlugin,
  TableSimplePlugin,
  ListGroupPlugin,
  LinkPlugin,
  TabsPlugin,
  ToastPlugin,
  ButtonToolbarPlugin,
  ButtonGroupPlugin,
  FormTextareaPlugin,
  VBVisible,
  BadgePlugin,
  ImagePlugin,
  TooltipPlugin,
  PaginationPlugin,
  AlertPlugin,
} from 'bootstrap-vue';

export const bootstrapPlugin: PluginObject<void> = {
  install(vue) {
    /**
     * We have created a custom breakpoint (see $grid-breakpoints in theme.scss)
     * so here we must tell BootstrapVue about it. Only the original breakpoints
     * (xs, sm, lg, xl) will have the typeahead available on components.
     * For the custom breakpoint xxl, you will have to match the format of
     * the other breakpoints, e.g., <b-col xxl="2" offset-xxl="3"></b-col>
     */
    vue.use(BVConfigPlugin, {
      breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      BDropdownItem: {
        activeClass: 'active',
      },
      VBPopover: {
        customClass: 'shadow',
      },
      BTable: {
        sortIconLeft: true,
      },
      BDropdown: {
        menuClass: 'shadow',
        /**
         * 1px of the border of any button directly below the dropdown will be shown
         * because the default width of the dropdown menu does not account for this dimension.
         * Per Pierre's requirement, we want to hide this. There's no easy way to modify the
         * dropdown menu's width automatically, so this is a workaround - we shift the menu
         * to the left by 1px.
         *
         * The opposite must be done if the `right` prop is used - the menu must be shifted 1px
         * to the right. So in that case, this value should be 1. This must be done on a
         * case-by-case basis, because this configuration has no way to determine if the `right`
         * prop is being used.
         */
        offset: -1,
      },
      BDropdownGroup: {
        headerClasses: 'font-weight-500',
      },
    });
    vue.use(NavbarPlugin);
    vue.use(LayoutPlugin);
    vue.use(DropdownPlugin);
    vue.use(OverlayPlugin);
    vue.use(CardPlugin);
    vue.use(ButtonPlugin);
    vue.use(FormPlugin);
    vue.use(FormSelectPlugin);
    vue.use(InputGroupPlugin);
    vue.use(FormInputPlugin);
    vue.use(FormGroupPlugin);
    vue.use(FormTagsPlugin);
    vue.use(FormRadioPlugin);
    vue.use(FormDatepickerPlugin);
    vue.use(ModalPlugin);
    vue.use(CollapsePlugin);
    vue.use(NavPlugin);
    vue.use(ImagePlugin);

    vue.use(FormCheckboxPlugin);
    vue.use(PopoverPlugin);
    vue.use(VBPopoverPlugin);
    vue.use(TablePlugin);
    vue.use(TableSimplePlugin);
    vue.use(ListGroupPlugin);
    vue.use(LinkPlugin);

    vue.use(TabsPlugin);
    vue.use(ToastPlugin);
    vue.use(ButtonToolbarPlugin);
    vue.use(ButtonGroupPlugin);
    vue.use(FormTextareaPlugin);
    vue.use(BadgePlugin);

    vue.use(TooltipPlugin);
    vue.use(PaginationPlugin);
    vue.use(AlertPlugin);

    vue.directive('b-visible', VBVisible);
  },
};
