import { ref } from 'vue';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import {
  DiscoverChartTypes,
  DiscoveryPeriod,
  DiscoveryUniversePeriod,
  SortedDiscoveryPeriods,
  VolatilityAdjustmentOptions,
} from '@/types/discover';
import { DataDiscoveryResponseDTO, PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { MstColorLogic } from '@/types/MstColorLogic';
import useStrategyMask from './useStrategyMask';
import { useUniverseOptions } from '@/composables/useUniverseOptions';

const { universe } = useUniverseOptions();

const graphTypeOptions: readonly DiscoverChartTypes[] = [
  DiscoverChartTypes.LINE,
  DiscoverChartTypes.MST,
  DiscoverChartTypes.SPIDER,
] as const;

const volatilityAdjustmentOptions: readonly VolatilityAdjustmentOptions[] = [
  VolatilityAdjustmentOptions.NONE,
  VolatilityAdjustmentOptions.VOLATILITY_PERCENT,
] as const;

const returnIntervals = [ReturnInterval.WEEKLY, ReturnInterval.MONTHLY] as const;

const periods = SortedDiscoveryPeriods;
const universePeriods = [PeriodAbbrevEnum.THREE_Y, PeriodAbbrevEnum.FIVE_Y] as const;
const defaultPeriod = PeriodAbbrevEnum.ONE_Y as const;

const colorLogicOptions: readonly MstColorLogic[] = [
  MstColorLogic.ASSET_CLASS,
  MstColorLogic.FACTOR,
  MstColorLogic.PINNED,
  MstColorLogic.LABELS,
] as const;

const currentGraphType = ref(DiscoverChartTypes.LINE);
const currentReturnInterval = ref<ReturnInterval.WEEKLY | ReturnInterval.MONTHLY>(ReturnInterval.WEEKLY);
const currentPeriod = ref<DiscoveryPeriod>(PeriodAbbrevEnum.ONE_Y);
const currentUniversePeriod = ref<DiscoveryUniversePeriod>(universe.value.period);
const currentColorLogic = ref(MstColorLogic.ASSET_CLASS);
const currentVolatilityAdjustment = ref(VolatilityAdjustmentOptions.NONE);

const currentUniverseType = ref(universe.value.type);

const currentUniverseColor = ref(universe.value.color);

const allStrategies = ref<readonly StrategyItemResponseDTO[]>([]);
const allPortfolios = ref<readonly PortfolioItemResponseDTO[]>([]);

const allStrategiesByCode = ref<{ [code: string]: StrategyItemResponseDTO }>({});
const allStrategiesById = ref<{ [id: string]: StrategyItemResponseDTO }>({});
const allPortfoliosById = ref<{ [portfolioId: string]: PortfolioItemResponseDTO }>({});
const allPortfoliosBySlug = ref<{ [slug: string]: PortfolioItemResponseDTO }>({});

function SET_STRATEGY_UNIVERSE(universe: StrategyItemResponseDTO[]): void {
  const { getStrategyMask } = useStrategyMask();
  const strategies = [...universe].sort((a: StrategyItemResponseDTO, b: StrategyItemResponseDTO): number => {
    const aName = getStrategyMask(a).toLowerCase();
    const bName = getStrategyMask(b).toLowerCase();
    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  });

  for (const o of strategies) {
    Object.assign(o, { colorKey: o.id, rankKey: o.code });
  }
  allStrategies.value = Object.freeze(strategies);

  const strategiesByCode = strategies.map((o) => [o.code, o]);
  allStrategiesByCode.value = Object.freeze(Object.fromEntries(strategiesByCode));

  const strategiesById = strategies.map((o) => [o.id, o]);
  allStrategiesById.value = Object.freeze(Object.fromEntries(strategiesById));
}

function SET_PORTFOLIO_UNIVERSE(universe: PortfolioItemResponseDTO[]): void {
  const portfolios = [...universe].sort((a: PortfolioItemResponseDTO, b: PortfolioItemResponseDTO): number => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });
  for (const o of portfolios) {
    Object.assign(o, { colorKey: o.portfolioId, rankKey: o.portfolioId });
  }
  allPortfolios.value = Object.freeze(portfolios);

  const portfoliosById = portfolios.map((o) => [o.portfolioId, o]);
  allPortfoliosById.value = Object.freeze(Object.fromEntries(portfoliosById));

  const portfoliosBySlug = portfolios.map((o) => [o.slug, o]);
  allPortfoliosBySlug.value = Object.freeze(Object.fromEntries(portfoliosBySlug));
}

function setUniverse(universe: DataDiscoveryResponseDTO) {
  SET_STRATEGY_UNIVERSE(universe.strategy ?? []);
  SET_PORTFOLIO_UNIVERSE(universe.portfolio ?? []);
}

export function useDiscoverStore() {
  return {
    /* Options and defaults */
    periods,
    universePeriods,
    defaultPeriod,
    returnIntervals,
    colorLogicOptions,
    graphTypeOptions,
    volatilityAdjustmentOptions,

    /* Current state */
    currentGraphType,
    currentReturnInterval,
    currentPeriod,
    currentUniversePeriod,
    currentColorLogic,
    currentVolatilityAdjustment,
    currentUniverseType,
    currentUniverseColor,

    /* Data universe */
    allStrategies,
    allPortfolios,

    allStrategiesByCode,
    allStrategiesById,
    allPortfoliosById,
    allPortfoliosBySlug,

    /* Actions */
    setUniverse,
  };
}
