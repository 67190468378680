import { PluginObject } from 'vue';
import router from '@/router';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

function enableApm() {
  // Disable apm for bofa for pentest report
  // See WAA-6678
  if (import.meta.env.VITE_CLIENT === 'BofA') {
    return false;
  }

  return import.meta.env.VITE_MODE === 'production' || import.meta.env.VITE_MODE === 'staging';
}

// Keep this in sync with `apiStageMap` in `./src/composables/useEnv.ts`.
// It is duplicated here to avoid circular dependency issues.
function getEnvironment() {
  if (import.meta.env.VITE_MODE === 'production') {
    return 'prod';
  }
  if (import.meta.env.VITE_MODE === 'staging') {
    return 'uat';
  }
  if (import.meta.env.VITE_MODE === 'test') {
    return 'test';
  }
  if (import.meta.env.VITE_MODE === 'development') {
    return 'dev';
  }
  import.meta.env.VITE_MODE satisfies never;
}

export const apmPlugin: PluginObject<void> = {
  install(vue) {
    if (!enableApm()) {
      return;
    }

    vue.use(ApmVuePlugin, {
      router,
      config: {
        serviceName: 'spa-premialab',
        environment: getEnvironment(),
        secretToken: import.meta.env.VITE_APM_TOKEN,
        serverUrl: 'https://3da28fac89004fcc98b584edb216e03a.apm.eu-west-1.aws.cloud.es.io:443',
        breakdownMetrics: true,
      },
      captureErrors: true,
    });
  },
};
