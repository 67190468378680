<!-- eslint-disable vue/no-v-html -->
<template>
  <b-overlay :show="query.isLoading.value">
    <template v-if="rawHtml">
      <b-row>
        <b-col>
          <!--
            @click.prevent        Handle the click event on the rawHtml element (see below)
            @contextmenu.prevent  Disable right click (contextmenu) on the rawHtml element
           -->
          <div
            @click.prevent="handleClick"
            @contextmenu.prevent
            v-html="rawHtml"
          />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <h3>
            {{ translate({ path: 'VIRTUAL_LIST.NO_RESULTS' }) }}
          </h3>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script lang="ts">
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { useScrollToUrlHash } from '@/composables/useScrollToUrlHash';
import useTranslation from '@/composables/useTranslation';
import { ApplicationNavbarHeight } from '@/constants/ApplicationNavbarHeight';
import { KnowledgeBaseCategory, KnowledgeBaseMode } from '@/types/KnowledgeBase';
import { useElementSize } from '@vueuse/core';
import { computed, defineComponent, onMounted, watch } from 'vue';
import { headerPadding } from './KnowledgeBase.vue';
import { notNull } from '@/utils/notnull';
import useGlobalEventBus, { GlobalEventTypes } from '@/composables/useGlobalEventBus';
import { Emitter } from 'mitt';
import { OpenKnowledgeBaseMultiMediaModalEvent } from '@/types/Injectables';
import { useKnowledgeBaseArticle } from '@/composables/queries/useKnowledgeBaseQueries';
import useAppMetrics from '@/composables/useAppMetrics';
import { useRouteRef } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';

export default defineComponent({
  name: 'KnowledgeBaseArticle',
  props: {},
  setup() {
    const { translate } = useTranslation();

    const { eventBus } = useGlobalEventBus();

    const { mode, article, headerHtmlElement } = useKnowledgeBase();

    const { scrollMargin, scrollToUrlHash } = useScrollToUrlHash();

    const { height } = useElementSize(headerHtmlElement);

    const url = computed(() => (article.value ? article.value.url : ''));

    const query = useKnowledgeBaseArticle(url);

    const { trackButtonClick } = useAppMetrics();

    const { navigateTo } = useKnowledgeBase();

    const checkRouteAndNavigate = () => {
      if (route.value.name === RouteName.KNOWLEDGE_BASE && route.value.hash == '') {
        navigateTo(KnowledgeBaseCategory.HELP_CENTER);
      }
    };

    onMounted(checkRouteAndNavigate);

    /**
     * Set the scroll margin top dynamically based
     * on the height of the header and include the
     * application navbar height if it is in page mode
     */
    const route = useRouteRef();

    const hash = computed(() => (route.value.hash ? route.value.hash.toString() : ''));
    watch(
      () =>
        mode.value === KnowledgeBaseMode.PAGE
          ? ApplicationNavbarHeight + height.value + headerPadding
          : height.value + headerPadding,
      (value) => (scrollMargin.value = value),
      { immediate: true },
    );

    /**
     * Extract the rawHtml from the html file exported from Notion
     * and update the dom to be compatible within the platform
     *
     * 1. Set the top margin of the first element to zero
     * 2. Set the scroll margin top to element with id set
     * 3. Update the table of contents links in dom to be compatible (<a> tag with no child elements)
     * 4. Handle the video attachments by replacing <a> tag with HTML5 <video> element
     * 5. Handle the embedded PDFs by replacing <a> tag with HTML5 <embed> element
     * 6. Configure all links to be compatible with handleClick by removing all child (see below)
     * 7. Configure all src paths to be relative from root (located in /notion directory)
     */
    const rawHtml = computed(() => {
      if (!query.data.value) return;

      const parser = new DOMParser();

      const doc = parser.parseFromString(query.data.value, 'text/html');

      const body = doc.querySelector('.page-body');

      if (!body) return;

      // 1. Set the top margin of the first element to zero

      const child = body.children[0];

      if (child) child.setAttribute('style', [child.getAttribute('style'), 'margin-top: 0;'].filter(notNull).join(' '));

      // 2. Set the scroll margin top to element with id set

      const elements = body.querySelectorAll('[id]');

      for (const element of elements) {
        element.setAttribute(
          'style',
          [element.getAttribute('style'), `scroll-margin-top: ${scrollMargin.value}px;`].filter(notNull).join(' '),
        );
      }

      // 3. Update the table of contents links in dom to be compatible (<a> tag with no child elements)

      const items = body.querySelectorAll('.table_of_contents-item');

      for (const item of items) {
        if (item.textContent && item.firstChild) item.firstChild.textContent = item.textContent;
        while (item.childNodes.length > 1 && item.lastChild) item.removeChild(item.lastChild);
      }

      // 4. Handle the video attachments by replacing <a> tag with HTML5 <video> element

      const videos = body.querySelectorAll('a[href$=".mov"]');

      for (const video of videos) {
        video.outerHTML = `<video class="w-100 h-100" controls src="${video.getAttribute('href')}" />`;
      }

      // 5. Handle the embedded PDFs by replacing <a> tag with HTML5 <embed> element

      const pdfs = body.querySelectorAll('.source > a[href$=".pdf"]');

      for (const pdf of pdfs) {
        if (pdf.parentElement)
          pdf.parentElement.outerHTML = `
            <embed
              type="application/pdf"
              src="${pdf.getAttribute('href')}#view=FitH"
              class="w-100"
              style="height: 75vh;"
            />`;
      }

      // 6. Configure all links to be compatible with handleClick by removing all child (see below)

      const links = body.querySelectorAll('a[href^=http],a[href^=https],a[href^=mailto]');

      for (const link of links) {
        if (link.textContent) link.innerHTML = link.textContent;
      }

      // 7. Configure all src paths to be relative from root (located in /notion directory)

      const resources = body.querySelectorAll('[src]:not([src^=http],[src^=https],[src^=mailto])');

      for (const resource of resources) {
        resource.setAttribute(
          'src',
          `/notion/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/${resource.getAttribute('src')}`,
        );
      }

      return body.innerHTML;
    });

    /**
     * Handle click event on the rawHtml element
     *
     * <a>        scroll to the url hash if exists
     * <img>      open the multi media modal in full screen mode
     * <video>    open the multi media modal in full screen mode
     */
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target) return;

      const href = target.getAttribute('href') ?? '';

      switch (target.tagName) {
        case 'A':
          if (/^(http|https|mailto)/.test(href)) {
            openUrl(event);
          } else {
            scrollToUrlHash(event);
            // Delay the URL change to ensure scrolling animation are completed before the URL is changed
            setTimeout(() => {
              history.pushState({}, '', hash.value);
            }, 1000);
          }
          break;
        case 'IMG':
          openKnowledgeBaseMultiMediaModalEvent(target.outerHTML);
          break;
        default:
          return;
      }
      if (href !== '') {
        trackButtonClick('Knowledge Base Article Link', {
          pageName: mode.value === KnowledgeBaseMode.PAGE ? 'Knowledge Base Page' : 'Knowledge Base Sidebar',
          subpageName: href,
        });
      }
    };

    /**
     * Open the url given the mouse event which contains
     * the target element to extract the href from
     *
     * Note that for mailto link, the link will be opened in '_self'
     */
    const openUrl = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target) return;

      const href = target.getAttribute('href');
      if (!href) return;

      window.open(href, href.startsWith('mailto') ? '_self' : '_blank');
    };

    /**
     * Open the multi-media viewer modal given the
     * content to display in raw html format
     */
    const openKnowledgeBaseMultiMediaModalEvent = (content: string) => {
      (eventBus as Emitter<OpenKnowledgeBaseMultiMediaModalEvent>).emit(
        GlobalEventTypes.OPEN_KNOWLEDGE_BASE_MULTI_MEDIA_MODAL,
        { content },
      );
    };

    return {
      translate,

      query,

      rawHtml,
      handleClick,
    };
  },
});
</script>

<!--
  @/styles/notion.scss

  1. Limit the scope to prevent conflicts with other styles
  2. Apply the v-deep selector to apply styles to v-html elements
  3. It is consistent across Notion exports
 -->
<style lang="scss" scoped>
@import '@/styles/notion.scss';
</style>
