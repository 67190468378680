import { UserPermission } from '@/constants/UserPermission';
import { Route } from 'vue-router';

export type RoutePermission = Array<UserPermission | ((permissions: Set<UserPermission>) => boolean)>;

/**
 * Collect the permissions required for the given route.
 */
export function collectRoutePermission(route: Route): RoutePermission {
  return route.matched.flatMap((r) => {
    return r.meta?.permission ?? [];
  });
}

/**
 * Check if user can view the given route
 *
 * @param userPermission Set of permissions the user got
 * @param permissions Permission required to view the route
 */
export function checkRoutePermission(userPermission: Set<UserPermission>, permissions: RoutePermission): boolean {
  if (permissions.length === 0) {
    // Empty array = no permission check required
    return true;
  }

  return permissions.every((perm) => {
    if (typeof perm === 'function') {
      return perm(userPermission);
    }

    return userPermission.has(perm);
  });
}
