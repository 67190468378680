
<script setup lang="ts">
const id = "factor-heatmap"
 const title = "Factor Heatmap"
 const categories = ["FACTOR_REGRESSION"]
 const excerpt = undefined
  defineExpose({ frontmatter: {"id":"factor-heatmap","title":"Factor Heatmap","categories":["FACTOR_REGRESSION"]}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"id":"factor-heatmap","title":"Factor Heatmap","categories":["FACTOR_REGRESSION"]}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>An automated regression performed against the market benchmarks and 45 Pure Factors in our database, reduced to 12 factors using the LASSO technique. The heatmap gives an overview of risk concentration and can guide you to select which factors to add.</p>
</div>
</template>
