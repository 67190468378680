import request from '@/api-v2/request';
import { ArticleTableBlockRequest, ArticleTableBlockResponse } from './types/ArticleTable';
import { ArticleWordcloudBlockRequest, ArticleWordcloudBlockResponse } from './types/ArticleWordcloud';
import { ArticleDTO } from './types/ArticleDTO';
import { CmsTableBlockListResponseDTO } from './types/CmsTableBlockListResponseDTO';
import {
  AssetUploadTicketRequestDTO,
  AssetUploadTicketResponseDTO,
  ConfirmAssetUploadResponseDTO,
  ConfirmUploadTicketRequest,
} from '@/api-v2/web/cms/types/AssetUpload';

export const getPublishedArticles = (language: string) =>
  request({
    url: '/cms/published/articles',
    method: 'GET',
    params: { language },
  }).then((o) => o.data);

export const getArticleById = (articleId: string, language: string): Promise<ArticleDTO> =>
  request({
    url: `/cms/article/${articleId}`,
    method: 'GET',
    params: { language },
  }).then((o): ArticleDTO => o.data);

export const getArticleByUrlCode = (urlCode: string, language: string): Promise<ArticleDTO> =>
  request({
    url: `/cms/article/code/${urlCode}`,
    method: 'GET',
    params: { language },
  }).then((o) => o.data);

export const getLatestArticleIdByCode = (code: string): Promise<string> =>
  request({
    url: `/cms/article/${code}/latest`,
    method: 'GET',
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const getAdminArticles = (language: string): Promise<ArticleDTO[]> =>
  request({
    url: '/cms/admin/articles',
    method: 'GET',
    params: { language },
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const deleteArticlesById = (articleIdList: string[]): Promise<void> =>
  request({
    url: `/cms/articles`,
    method: 'DELETE',
    data: articleIdList,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const createArticle = (articles: ArticleDTO): Promise<ArticleDTO> =>
  request({
    url: '/cms/article',
    method: 'POST',
    data: articles,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const publishArticlesByIdList = (articleIdList: string[]): Promise<void> =>
  request({
    url: `/cms/article/publish`,
    method: 'PUT',
    data: articleIdList,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const unpublishArticlesByIdList = (articleIdList: string[]): Promise<void> =>
  request({
    url: `/cms/article/unpublish`,
    method: 'PUT',
    data: articleIdList,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const updateArticle = (articles: ArticleDTO[]): Promise<ArticleDTO[]> =>
  request({
    url: '/cms/article',
    method: 'PUT',
    data: articles,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const uploadAssetStep1 = (data: AssetUploadTicketRequestDTO): Promise<AssetUploadTicketResponseDTO> =>
  request({
    url: '/cms/asset/upload/step1',
    method: 'POST',
    data,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const uploadAssetStep2 = (data: ConfirmUploadTicketRequest): Promise<ConfirmAssetUploadResponseDTO> =>
  request({
    url: '/cms/asset/upload/step2',
    method: 'POST',
    data,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const getBlockTable = (query: ArticleTableBlockRequest): Promise<ArticleTableBlockResponse> =>
  request({
    url: '/cms/article/block/table',
    method: 'GET',
    params: query,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const listBlockTableTickers = (): Promise<CmsTableBlockListResponseDTO> =>
  request({
    url: '/cms/article/block/table/tickers',
    method: 'GET',
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const getBlockWordcloud = (query: ArticleWordcloudBlockRequest): Promise<ArticleWordcloudBlockResponse> =>
  request({
    url: '/cms/article/block/wordcloud',
    method: 'GET',
    params: query,
  }).then((o) => o.data);

/**
 * Will be moved to separate admin endpoint file once its migrated on API
 */
export const listBlockWordcloudTickers = (): Promise<Array<string>> =>
  request({
    url: '/cms/article/block/wordcloud/tickers',
    method: 'GET',
  }).then((o) => o.data);
