// A subset of composite exchange codes.
const COMPOSITE_EXCHANGE_CODES = ['US', 'CN', 'GR'];

export function useStrategyTicker() {
  /**
   * As suggested by Henry, US, CN, GR have issue on BofA
   * If the ticker's exchange or country code matches any of the PRIORITY_COUNTRY_EXCHANGE_CODES, then we should use that ticker
   * otherwise, we use the first available ticker
   */
  const getCompositeTicker = (tickers: string[] | undefined | null) => {
    if (!Array.isArray(tickers) || !tickers.length) return null;

    const retval = tickers.find((item) => {
      const tickerCountryCode = item.split(' ')[0];
      if (COMPOSITE_EXCHANGE_CODES.includes(tickerCountryCode)) return true;
    });

    return retval ?? tickers[0];
  };

  return { getCompositeTicker };
}
