import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { RouteName } from '@/constants/RouteName';
import { RouteConfig } from 'vue-router';

export const EquityBasketRouter: RouteConfig[] = [
  {
    name: RouteName.EQUITY_BASKET,
    path: 'equity-basket/:indexUniqueIdentifier?/:substep?',
    meta: {
      title: RouteName.EQUITY_BASKET,
      shouldNavigationPanelBeShown: true,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
    },
    beforeEnter: (to, from, next): void => {
      const { hasEquityBasketAccess } = useFeatureFlag();
      if (!hasEquityBasketAccess.value) {
        next({
          name: RouteName.NOT_FOUND,
        });
        return;
      }
      next();
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/EquityBasket.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () => import('@/layout/components/toolbars/AnalysisStepToolbar/EquityBasketToolbar.vue'),
    },
  },
];
