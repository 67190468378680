<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="isShown"
    ref="multiMediaModal"
    class="position-fixed above-modal"
    :style="{
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      top: 0,
      left: 0,
    }"
  >
    <div class="h-100 d-flex justify-content-center align-items-center">
      <!--
        @contextmenu.prevent  Disable right click (contextmenu) on the rawHtml element
       -->
      <div
        ref="mediaContainer"
        @contextmenu.prevent
        v-html="rawHtml"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import useTranslation from '@/composables/useTranslation';
import useGlobalEventBus, { GlobalEventTypes } from '@/composables/useGlobalEventBus';
import { onClickOutside, useVModel } from '@vueuse/core';
import { Emitter } from 'mitt';
import { OpenKnowledgeBaseMultiMediaModalEvent } from '@/types/Injectables';

export default defineComponent({
  name: 'KnowledgeBaseMediaViewerModal',
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    input: (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();

    const { eventBus } = useGlobalEventBus();

    const isShown = useVModel(props, 'value', emit, { eventName: 'input', passive: true });

    const mediaContainer = ref<HTMLElement | null>(null);

    const rawHtml = ref('');

    onClickOutside(mediaContainer, () => (isShown.value = false));

    /**
     * Handle open modal event and initialize modal values
     * @param constituentStatusIds
     */
    const onShouldOpenModal = ({ content }: { content: string }) => {
      rawHtml.value = content;
      isShown.value = true;
    };

    onMounted(() => {
      (eventBus as Emitter<OpenKnowledgeBaseMultiMediaModalEvent>).on(
        GlobalEventTypes.OPEN_KNOWLEDGE_BASE_MULTI_MEDIA_MODAL,
        onShouldOpenModal,
      );
    });

    onBeforeUnmount(() => {
      (eventBus as Emitter<OpenKnowledgeBaseMultiMediaModalEvent>).off(
        GlobalEventTypes.OPEN_KNOWLEDGE_BASE_MULTI_MEDIA_MODAL,
        onShouldOpenModal,
      );
    });

    return {
      translate,

      isShown,
      mediaContainer,

      rawHtml,
    };
  },
});
</script>

<style lang="scss"></style>
