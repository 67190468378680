import { Ref, onMounted, ref, watch } from 'vue';
import { useRouteRef } from './useRouter';

const scrollMargin = ref(0);

/**
 * Note that on clicking a link with a hash, the page will scroll
 * to the element with the browser built-in scrollIntoView method.
 *
 * Functions of useScrollToUrlHash composable:
 * 1. Maintain the appropriate scroll margin top for the
 *    page for builtin scrollIntoView
 *
 *    @see https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin-top
 *
 * 2. Provide scrollToUrlHash function to be used in @click event
 *    which trigger scrolling by updating the url hash
 *
 * 3. Enable watcher which scrolls to the target element when url hash changes
 *    e.g. entering a url with a hash manually
 */
export function useScrollToUrlHash(): {
  scrollMargin: Ref<number>;
  scrollToUrlHash: (event: MouseEvent) => void;
} {
  const route = useRouteRef();

  const scrollToUrlHash = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const href = target?.getAttribute('href');
    const hash = href?.match(/#(.*)$/)?.[1];
    const element = hash ? document.getElementById(hash) : null;

    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };

  const scroll = () => {
    const { hash } = route.value;
    if (!hash) return;

    const el = document.getElementById(hash.slice(1));
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  };

  watch(
    () => route.value.hash,
    () => scroll(),
  );

  /**
   * Scroll to hash on page load
   * 500 ms delay to scroll margin to be calculated
   */
  onMounted(() => {
    setTimeout(() => scroll(), 500);
  });

  return {
    scrollMargin,
    scrollToUrlHash,
  };
}
