<script lang="ts">
import { AuthURL } from '@/api-v2/web/auth';
import { defineComponent } from 'vue';
import { whenUnauthenticatedOrHome } from '../shared/useIsAuthenticated';

export default defineComponent({
  name: 'CoreLogin',
  setup() {
    whenUnauthenticatedOrHome(() => {
      location.href = AuthURL.auth0Login();
    });

    return () => null;
  },
});
</script>
