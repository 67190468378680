/**
 * Port of `useRouter` and `useRoute` composables for vue v2 and vue-router v3
 *
 * This tries to replicate the composables that will exist in vue-router v4.
 * When we upgrade to vue-router v4, we can delete/deprecate (or replace implementation)
 * of these composables.
 *
 * Current implementation of these composables will not work in vue v3, and may not work on vue v2.7.
 *
 * TODO: Vue3 Rewrite this module
 *
 * @module
 * {@see https://premialab.atlassian.net/browse/WAA-2692}
 */

import { computed, getCurrentInstance, Ref } from 'vue';
import VueRouter, { Route } from 'vue-router';

/**
 * Get the router instance.
 *
 * The return router is not wrapped inside a `Ref` as the router instance should not change
 * in the application lifecycle.
 *
 * This should be called inside `setup()` function. Error will be thrown if called outside `setup()`.
 *
 * @returns Router instance
 * @throws Error when called outside setup()
 */
export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useRouter is not called inside setup()');
  }

  return vm.proxy.$root.$router;
}

/**
 * Get current route.
 *
 * Due to vue2 limitation, we cannot return a reactive route object. We must wrap the
 * result in `Ref` to make the result reactive.
 *
 * To indicate the difference with the API in vue-router v4, we are adding `ref`
 * suffix here to make future upgrade easier.
 *
 * This should be called inside `setup()` function. Error will be thrown if called outside `setup()`.
 *
 * @returns Current (reactive) route ref
 * @throws Error when called outside setup()
 */
export function useRouteRef(): Ref<Route> {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useRouteRef is not called inside setup()');
  }

  return computed(() => vm.proxy.$root.$route);
}
