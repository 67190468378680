import useTranslation from '@/composables/useTranslation';
import { Component, Vue } from 'vue-property-decorator';

const { translate } = useTranslation();

@Component({
  name: 'TranslationMixin',
})
export default class TranslationMixin extends Vue {
  /**
   * Fetches the item from the translation file through the useTranslation Composable.
   * A mixin is needed to be used since we do not have access to ctx.root in a non-composition API file
   * @param path Path of item in translation file
   * @param item item to translate
   * @param params the parameters for the translation string (if any are present)
   * @param pluralIndex the index to handle the case of pluralization (one based)
   */
  public translate = translate;
}
