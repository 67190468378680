<template>
  <div
    ref="disclaimerBannerTitle"
    class="d-flex flex-column position-absolute"
    :style="{
      'border-left': 'solid 6px',
      'border-color': marginColor,
      top: disclaimerBannerTitleHeight ? `calc(15% - ${disclaimerBannerTitleHeight / 2}px)` : '100px',
      left: disclaimerBannerTitleWidth ? `calc(50% - ${disclaimerBannerTitleWidth / 2}px)` : '100px',
      opacity: shouldShowBannerTitle ? 1 : 0,
      padding: '0',
    }"
  >
    <div class="text-white pl-3">
      <h1 style="margin-top: -6px">HSBC Quantitative</h1>
      <h1 style="margin-bottom: -6px">Investment Solutions</h1>
    </div>
  </div>
</template>

<script lang="ts">
import { ApplicationDefaults } from '@/constants/ApplicationDefaults';
import { defineComponent, computed, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';

export default defineComponent({
  name: 'BannerTitle',
  props: {
    imgSrc: {
      type: String,
      required: false,
    },
  },
  setup() {
    const disclaimerBannerTitle = ref<HTMLElement | null>(null);

    const marginColor = computed(() => {
      return ApplicationDefaults.colors.Accent;
    });

    /** At times these come to 0 when its mounted, so a condition has been added in the html part of it to check */

    const shouldShowBannerTitle = ref(false);
    const disclaimerBannerTitleWidth = ref<number | null>(null);
    const disclaimerBannerTitleHeight = ref<number | null>(null);

    useResizeObserver(disclaimerBannerTitle, (entries) => {
      const { clientWidth: width, clientHeight: height } = entries[0].target;

      disclaimerBannerTitleWidth.value = width;
      disclaimerBannerTitleHeight.value = height;
      shouldShowBannerTitle.value = true;
    });

    return {
      marginColor,
      disclaimerBannerTitle,
      disclaimerBannerTitleWidth,
      disclaimerBannerTitleHeight,
      shouldShowBannerTitle,
    };
  },
});
</script>
