<template>
  <b-container
    v-if="renderForm"
    fluid
    class="login-page bg-primary outline-aquamarine text-login-page position-relative"
  >
    <div class="h-100">
      <b-row class="h-100">
        <b-col
          class="welcome-container d-none d-lg-flex justify-content-center align-items-start"
          lg="6"
        >
          <div class="d-flex flex-column align-items-start justify-content-center h-100" />
        </b-col>
        <b-col
          id="login-container"
          class="d-flex align-items-center justify-content-center"
        >
          <form
            class="login-card"
            @submit.prevent="basicLogin()"
          >
            <b-card
              bg-variant="white"
              body-class="d-flex flex-column align-items-center py-5"
              style="width: 400px"
            >
              <div class="position-relative w-80 mt-5">
                <label class="position-absolute label-on-input-border bg-white px-1">
                  {{ translate({ path: 'LOGIN', item: 'Email address' }) }}</label
                >

                <b-form-input
                  v-model="form.username"
                  type="text"
                  required
                />
              </div>
              <div class="position-relative w-80 mt-3">
                <label class="position-absolute label-on-input-border bg-white px-1">
                  {{ translate({ path: 'LOGIN', item: 'Password' }) }}</label
                >
                <b-form-input
                  v-model="form.password"
                  type="password"
                  required
                />
              </div>

              <b-button
                type="submit"
                variant="primary"
                class="mt-3 w-80 h-80"
              >
                {{ translate({ path: 'LOGIN', item: 'Continue' }) }}
              </b-button>
            </b-card>
          </form>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useRouter } from '@/composables/useRouter';
import useTranslation from '@/composables/useTranslation';
import { RouteName } from '@/constants/RouteName';
import { AuthURL } from '@/api-v2/web/auth';
import { AuthenticationStatus, useIsAuthenticated } from '../shared/useIsAuthenticated';
import { useLoginStrategy } from '../shared/useLoginStrategy';
import { useBasicLogin } from '../shared/useBasicLogin';

export default defineComponent({
  name: 'BofaLogin',
  setup() {
    const { translate } = useTranslation();
    const { basicLogin, form } = useBasicLogin();
    const isAuthenticated = useIsAuthenticated();
    const { strategy, renderForm } = useLoginStrategy();

    // Goto home if authenticated
    const router = useRouter();
    watch(isAuthenticated, (val) => {
      if (val === AuthenticationStatus.SUCCESS) {
        router.replace({
          name: RouteName.HOME,
        });
      }
    });

    // Start SAML login if we are unauthenticated and it is the chosen strategy
    watch([strategy, isAuthenticated], () => {
      if (strategy.value !== 'saml') {
        return;
      }

      if (isAuthenticated.value !== AuthenticationStatus.UNAUTH) {
        return;
      }

      location.href = AuthURL.samlLogin();
    });

    return {
      renderForm,

      form,
      translate,
      basicLogin,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-page {
  --navbar-height: 0px;
  --translucent: rgba(255, 255, 255, 0);
  --translucent-black: rgba(0, 0, 0, 0.5);

  margin-top: var(--navbar-height);
  overflow: hidden;
  // background: url('/assets/bofa-background.webp');
  background: rgba(0, 0, 255, 0);
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
}

.welcome-container,
.login-page {
  height: calc(100vh - var(--navbar-height));
  background-color: var(--translucent-black);
}

.label-on-input-border {
  top: -10px;
  left: 10px;
}
</style>
