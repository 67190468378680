import { DateFormat } from '@/constants/DateFormat';
import { createDate } from '@/utils/dateUtils';

const isInterfaceTypeIterable = (i: Iterable<unknown> | unknown): i is Iterable<unknown> => {
  return Symbol.iterator in (i as Iterable<unknown>);
};

/**
 * This function iterates through the properties in the given query parameter
 * and if the property contains 'date', then it will convert that date to a business date
 */
export default function <T extends Iterable<unknown> | unknown>(query: T): T {
  const clone = structuredClone(query) as { [s: string]: unknown };

  if (isInterfaceTypeIterable(clone)) return clone as T;

  for (const [key, value] of Object.entries(clone)) {
    if (key.toLowerCase().includes('date')) {
      if (Array.isArray(value)) {
        clone[key] = value.map((d): string => createDate(d).toFormat(DateFormat.YYYY_MM_DD));
        continue;
      }
      if (typeof value === 'string') {
        clone[key] = createDate(value).toFormat(DateFormat.YYYY_MM_DD);
        continue;
      }
    }
    clone[key] = value;
  }

  return clone as T;
}
