import { RouteConfig } from 'vue-router';
import { PERFORMANCE_ATTRIBUTION } from '@/types/analytics/AnalysisStep';
import { RouteName } from '@/constants/RouteName';
import { AppMain } from '@/layout/components';

export const PerformanceContributionRouter: RouteConfig[] = [
  {
    path: PERFORMANCE_ATTRIBUTION.route,
    alias: '/performance-contribution',
    components: {
      default: AppMain,
    },
    children: [
      {
        path: '',
        redirect: {
          name: RouteName.DATA,
        },
      },
      {
        name: RouteName.PERFORMANCE_ATTRIBUTION,
        path: ':indexUniqueIdentifier/:substep?',
        meta: {
          title: PERFORMANCE_ATTRIBUTION.name,
          shouldApplicationSidebarBeShown: true,
          isMainContentContainerFluid: true,
        },
        components: {
          default: () => import('@/views/analytics/performance/PerformanceContributionParent.vue'),
          'analysis-step-toolbar': () => import('@/whitelabel/hsbc/views/analytics/performance/PerformanceToolbar.vue'),
          sidebar: () => import('@/whitelabel/hsbc/layout/components/sidebar/ApplicationSidebar.vue'),
        },
      },
      {
        name: RouteName.PERFORMANCE_ATTRIBUTION_PDF,
        path: `:indexUniqueIdentifier/:substep/pdf`,
        meta: {
          title: PERFORMANCE_ATTRIBUTION.name,
          pdf: true,
        },
        components: {
          default: () => import('@/whitelabel/hsbc/views/analytics/performance/PerformanceContributionPdf.vue'),
        },
      },
    ],
  },
];
