import { useQuery } from '@tanstack/vue-query';
import { computed, Ref } from 'vue';
import { chainEnabled, unwrap } from '@/utils/queries';
import { getConstituentRiskDates, getConstituentRiskOptions } from '@/api-v2/web/risk-dashboard';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { usePortfolioTreeDraftIdentifiers } from '../useCorrectIdentifier';
import { useConstituentRiskUtilities } from '@/composables/useConstituentRiskUtilities';
import { DateFormat } from '@/constants/DateFormat';
import { useToasts } from '../useToasts';
import useTranslation from '../useTranslation';
import useSystemLoading from '../useSystemLoading';
import { LoadingItems } from '@/constants/LoadingItems';

const keys = {
  all: () => [{ scope: 'constituent-risk' }] as const,
  date: (slug: Ref<string | null>) =>
    [
      {
        ...keys.all()[0],
        entity: 'result',
        slug,
      },
    ] as const,
  options: (slug: Ref<string | null>, date: Ref<string | null>) =>
    [
      {
        ...keys.all()[0],
        entity: 'options',
        slug,
        date,
      },
    ] as const,
};

export function useGetRiskDates(slug: Ref<string | null>, options: VQQueryOptions<string[]> = {}) {
  const { errorToast } = useToasts();
  const { translate } = useTranslation();
  const { removeLoadingItem } = useSystemLoading();
  return useQuery(keys.date(slug), () => getConstituentRiskDates(unwrap(slug)), {
    enabled: chainEnabled(
      options.enabled,
      computed(() => !!slug.value),
    ),
    staleTime: Number.POSITIVE_INFINITY,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(e: any) {
      const errorMessage = e.statusCode && e.statusCode === 404 ? 'ERROR.MISSING_DATA' : 'ERROR.MISSING_PORTFOLIO';
      errorToast(translate({ path: errorMessage }));
      removeLoadingItem(LoadingItems.CONSTITUENT_RISK_DATA);
    },
  });
}

export function useGetRiskOptions(options: VQQueryOptions<string[]> = {}) {
  const { draftSlug } = usePortfolioTreeDraftIdentifiers();
  const { positionDate } = useConstituentRiskUtilities();

  const positionDateString = computed(() => positionDate.value?.toFormat(DateFormat.YYYY_MM_DD));

  return useQuery(
    keys.date(draftSlug),
    () => getConstituentRiskOptions({ slug: unwrap(draftSlug), params: { date: unwrap(positionDateString) } }),
    {
      enabled: chainEnabled(
        options.enabled,
        computed(() => !!draftSlug.value && !!positionDateString.value),
      ),
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
}
