import mitt, { Emitter } from 'mitt';

export enum GlobalEventTypes {
  SET_COMPOSITE_LINE,
  TOGGLE_EXPAND_ALL,
  RESET_WEIGHTS,
  OPEN_STATIC_DATA_MODAL,
  OPEN_INSPECT_PERFORMANCE_ATTRIBUTION_MODAL,
  OPEN_INSPECT_PERFORMANCE_ATTRIBUTION_BACKFILL_MODAL,
  OPEN_EDIT_CLIENT_REQUEST_MODAL,
  OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_PROVIDER_MODAL,
  OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_CONSTITUENT_STATUS_MODAL,
  OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_VALIDATION_STATUS_MODAL,
  OPEN_BATCH_EDIT_CLIENT_REQUEST_DETAIL_RELEASE_STATUS_MODAL,
  OPEN_EDIT_PRIVATE_TRACK_MODAL,
  OPEN_KNOWLEDGE_BASE_MULTI_MEDIA_MODAL,
  STRATEGY_SELECTED_BENCHMARK_UPDATED,
}

const eventBus = mitt();

export default function (): {
  /**
   * Until we upgrade the navigation panel to use vue-query, you may listen to
   * the event 'PORTFOLIO-TREE-CALCULATED' to know when to update your analysis
   * (e.g., on Constituent Risk, Factor Decomposition, PCA)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventBus: Emitter<any>;
} {
  return {
    eventBus,
  };
}
