import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { set, del } from 'vue';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { until } from '@vueuse/core';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

const { eventBus } = useGlobalEventBus();

export enum SaveItemUniqueKeys {
  /**
   * PORTFOLIO_TREE tracks whether the user has made changes to their portfolio tree draft
   * and then clicked Calculate (turns the Save button blue)
   */
  PORTFOLIO_TREE = 'portfolio-tree',
  /**
   * PORTFOLIO_TREE_DRAFT tracks whether the user has made changes to their portfolio tree draft
   * but not yet clicked Calculate (turns the Calculate button blue)
   */
  PORTFOLIO_TREE_DRAFT = 'portfolio-tree-draft',
  REGRESSION = 'regression',
  PCA = 'pca',
  CONSTITUENT_OLD = 'constituent-old',
  ARTICLE = 'article',
  DROP_ZONE_FILE = 'drop-zone-file',
}

export interface ItemToSave {
  key: SaveItemUniqueKeys;
  saveFn: () => Promise<unknown> | void;
  discardFn: () => Promise<unknown> | void;
}

export enum SaveChangesModalResponse {
  SAVE = 'save',
  DISCARD = 'discard',
  CANCEL = 'cancel',
  NO_CHANGES = 'no-changes',
}

@Module({ dynamic: true, store, name: 'SaveChangesStore', namespaced: true })
class Store extends VuexModule {
  public itemsWithChanges: Record<string, ItemToSave> = {};

  public modalResponse: SaveChangesModalResponse | null = null;

  public isModalShown = false;

  public hasError = false;

  public customSavingPrompt: string | null = null;

  public get hasChanges(): boolean {
    return Object.values(this.itemsWithChanges).length > 0;
  }

  @Mutation
  public SetIsModalShown({ isModalShown }: { isModalShown: boolean }): void {
    this.isModalShown = isModalShown;
  }

  @Mutation
  public SetErrorState({ hasError }: { hasError: boolean }): void {
    this.hasError = hasError;
  }

  @Mutation
  public ChangeModalResponse({ newVal }: { newVal: SaveChangesModalResponse | null }): void {
    this.modalResponse = newVal;
  }

  @Mutation
  public AddItemToSave({ key, saveFn, discardFn }: ItemToSave): void {
    // completely ignore all changes made by those on the faux whitelabel
    const { isReadOnlyPlatform } = useFeatureFlag();
    if (isReadOnlyPlatform.value) return;

    set(this.itemsWithChanges, key, {
      key,
      saveFn,
      discardFn,
    });
  }

  @Mutation
  public SetCustomSavingPrompt(newVal: string | null): void {
    this.customSavingPrompt = newVal;
  }

  @Mutation
  public RemoveItemsToSave({ keys }: { keys: SaveItemUniqueKeys | SaveItemUniqueKeys[] }): void {
    if (Array.isArray(keys)) {
      for (const key of keys) {
        if (this.itemsWithChanges[key]) del(this.itemsWithChanges, key);
      }
      return;
    }
    if (this.itemsWithChanges[keys]) del(this.itemsWithChanges, keys);
  }

  @Action({ rawError: true })
  public PromptForSavingChanges() {
    if (!this.hasChanges) {
      this.ChangeModalResponse({ newVal: SaveChangesModalResponse.NO_CHANGES });
      return;
    }

    eventBus.emit('display-save-discard-changes-modal');
  }

  @Action({ rawError: true })
  public async WaitForSaveChangesModalResponse(): Promise<SaveChangesModalResponse | null> {
    this.PromptForSavingChanges();
    if (this.modalResponse === SaveChangesModalResponse.NO_CHANGES) {
      return SaveChangesModalResponse.NO_CHANGES;
    }

    await until(() => this.modalResponse).not.toBeNull();

    return this.modalResponse;
  }
}

export const SaveChangesStore = getModule(Store);
