import { useRouter } from '@/composables/useRouter';
import { useToasts } from '@/composables/useToasts';
import { BasicAuthRequestDTO, basicLogin as basicLoginReq } from '@/api-v2/web/auth';
import { RouteName } from '@/constants/RouteName';

/**
 * Form and handler for basic login.
 */
export function useBasicLogin() {
  const form: BasicAuthRequestDTO = {
    username: '',
    password: '',
  };

  const { errorToast } = useToasts();
  const router = useRouter();

  const basicLogin = async () => {
    if (!form.username) {
      errorToast(`Please input Username`);
      return;
    }

    if (!form.username || !form.password) {
      errorToast(`Please input valid credentials`);
      return;
    }

    try {
      await basicLoginReq(form);
    } catch (err) {
      errorToast(`Failed to login, please enter valid credentials or contact our IT team`);
      return;
    }

    const homeRoute = router.resolve({
      name: RouteName.HOME,
    });

    window.location.href = homeRoute.resolved.path;
  };

  return {
    form,
    basicLogin,
  };
}
